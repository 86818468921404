import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import headerImage from '../../Assets/img/about7.jpeg';
import HeaderNavigation from '../HeaderNavigation';
import { Box, Text, Flex } from '@chakra-ui/react';
import Navbar from '../Navbar/index';
import './index.css';
import classesadd from '../../Reducer/Addclass';
import AOS from 'aos';
import "aos/dist/aos.css";
import zIndex from '@material-ui/core/styles/zIndex';

const Header = ({ closeAll, headerv, callcalenderselect }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
		AOS.init();
		AOS.refresh();
	});

	return (
		<Box
			display="flex"
			pos="relative"
			px="15px"
			style={{
				backgroundImage: `url(${headerImage})`,
				backgroundColor: '#0f162c',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				display: 'flex',
				justifyContent: 'center'
			}}
			className='hero-box'
		>
			{/* <HeaderNavigation /> */}
			<Flex justify="center" direction="column" className='hero-text-box'>
				<Text
					fontWeight="bold"
					fontSize='42px'
					maxW='667px'

					style={{
						color: '#FBBF19',
						fontSize: '42px'
					}}
					as="h1"
					mx='auto'
					align={{ base: 'center', md: 'left' }}
				>
					We connect tech talents from Africa to global opportunities
				</Text>
				<Text
					lineHeight="186%"
					mx='auto'
					maxW='667px'

					align={{ base: 'center', md: 'left' }}
					fontSize={{ base: '18px', md: '22px' }}
					style={{ color: '#FFFFFF' }}
				>
					The Bulb™ is a tech talent incubator strategically designed to close the skill gap in Africa’s job market.
				</Text>
			</Flex>
			<Flex
				mx={{ base: 'auto', md: '0' }}
			>
				<div
					className='hero-youtube'
					data-aos='flip-left'
					data-aos-duration='1500'
				>
					<iframe
						width="560"
						height="270"
						src="https://www.youtube.com/embed/FSTzqzwYfF0"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
						style={{ maxWidth: '100%', borderRadius: '12px' }}
					></iframe>
				</div>
			</Flex>
			<Box
				pos="absolute"
				bottom="40px"
				left="50%"
				transform="translate(-50%, -50%)"
				animation="upDown 1.5s infinite"
			>
				<a href="#about-us">
					<img
						src={require('../../Assets/img/arrow.png')}
						alt="Down Arrow"
						className="img-fluid fixxed "
					/>
				</a>
			</Box>
		</Box>
	);
};

const mapStateToProps = state => {
	return {
		navnarclassaddremove: state.classesadd,
		navnarclassbtn: state.classesadd,
		navnarclassbtnexpand: state.classesadd
	};
};

const mapDispatchToProps = dispatch => {
	return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
