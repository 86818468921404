import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../Container/Footer';
import Modal from '../../Components/Modal';
import { Api } from '../../Const/Api';
import axios from 'axios';
import './index.css';
import { Box } from '@chakra-ui/react';
import { SVGHeader } from '../../Components/SVG/header';

function PartnerWithUsForm({ history }) {
	const [attachedFile, setAttachedFile] = useState({
		name: 'No file chosen',
		file: ' '
	});
	const [developerData, setDeveloperData] = useState({});
	const [Loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modelItems, setModelItems] = useState({
		text: '',
		btnText: '',
		imgUrl: require('../../Assets/img/fail.png'),
		handleModal: ''
	});

	useEffect(() => {
		document.getElementById('navTop').style.background = '#10152c';
		window.scrollTo(0, 0);
	}, []);
	const handleModal = () => {
		setShowModal(false);
		history.push('/partner-with-us');
	};
	const closeModal = () => {
		setShowModal(false);
	};

	const handleInputChange = e => {
		setDeveloperData({
			...developerData,
			[e.target.name]: e.target.value
		});
	};

	const handleFileSelect = e => {
		let file = e.target.files[0];
		setAttachedFile({
			name: file.name,
			file: e.target.files[0]
		});
	};

	const handleSubmit = e => {
		setLoading(true);
		e.preventDefault();
		const payload = {
			...developerData,
			myFiles: attachedFile.file
		};

		const formData = new FormData();
		for (const field in payload) {
			formData.append(`${field}`, payload[field]);
		}
		console.log({ payload });
		console.log(formData.get('myFiles'));

		axios
			.post(`${Api.BaseUrl}partner/save`, formData)
			.then(function (response) {
				console.log({ payload });
				console.log({ response });
				setLoading(false);
				setShowModal(true);
				if (response.status === 200) {
					return setModelItems({
						text: 'Your request to partner with us has been successfully submitted , you will be contacted after a careful review of your application.',
						btnText: 'Home',
						imgUrl: require('../../Assets/img/success.svg'),
						handleModal
					});
				}
				if (response.status === 400) {
					return setModelItems({
						text: 'Hey, something went wrong with your submission. Please return to the form page and make sure all fields are completed and resubmit',
						btnText: 'Close',
						imgUrl: require('../../Assets/img/fail.png'),
						handleModal: closeModal
					});
				}
			})
			.catch(error => {
				console.log({ error });
				setLoading(false);
				setShowModal(true);
				return setModelItems({
					text: `${
						error.response.data.payload.message ??
						'Hey, something went wrong with your submission. Please return to the form page and make sure all fields are completed and resubmit'
					}`,
					btnText: 'Close',
					imgUrl: require('../../Assets/img/fail.png'),
					handleModal: closeModal
				});
			});
	};

	return (
		<Box pos="relative" pt="100px" className="partner-form ">
			<SVGHeader />

			<div className="container">
				{showModal && (
					<Modal
						text={modelItems.text}
						btnText={modelItems.btnText}
						imgUrl={modelItems.imgUrl}
						handleModal={modelItems.handleModal}
					/>
				)}
				{/* <div className="img">
					<img
						src={require('../../Assets/img/close.png')}
						alt="close"
						className="d-none d-lg-block close"
						onClick={history.goBack}
					/>
				</div> */}
				<Box h="10px" mb="50px" cursor="pointer" textAlign="right" ml="auto">
					<svg
						onClick={history.goBack}
						className=" close"
						width="20"
						height="20"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M8.42239 7.00537L13.7064 12.2919C14.0972 12.6829 14.0969 13.3165 13.7057 13.7072C13.3146 14.0979 12.6806 14.0976 12.2898 13.7066L7.00539 8.41965L1.70867 13.7062C1.31738 14.0968 0.683436 14.0963 0.292725 13.7052C-0.0979858 13.314 -0.0975145 12.6804 0.293778 12.2898L5.59009 7.00368L0.297052 1.70814C-0.0937731 1.31713 -0.0934864 0.683461 0.297692 0.292805C0.688871 -0.0978514 1.32281 -0.0975648 1.71363 0.293445L7.00709 5.5894L12.2889 0.317664C12.6802 -0.0728784 13.3142 -0.0724073 13.7049 0.318716C14.0956 0.70984 14.0951 1.3435 13.7038 1.73405L8.42239 7.00537Z"
							fill="#050038"
						/>
					</svg>
				</Box>
				<div className="heading my-3 py-1">Our partnership starts here</div>
				<form onSubmit={handleSubmit}>
					<div className="d-flex flex-column flex-lg-row">
						<div className="form-group mt-3 col-lg-6">
							<div className="required">*</div>
							<label htmlFor="first_name" className="pl-1 pr-2">
								First Name
							</label>
							<input
								type="text"
								className="form-control"
								name="first_name"
								id="first_name"
								required
								onChange={handleInputChange}
							/>
						</div>
						<div className="form-group mt-3 col-lg-6">
							<div className="required">*</div>
							<label htmlFor="last_name" className="pl-1 pr-2">
								Last Name
							</label>
							<input
								type="text"
								className="form-control"
								name="last_name"
								id="last_name"
								required
								onChange={handleInputChange}
							/>
						</div>
					</div>
					<div className="d-flex flex-column flex-lg-row">
						<div className="form-group mt-3 col-lg-6">
							<div className="required">*</div>
							<label htmlFor="email" className="pl-1 pr-2">
								Email address
							</label>
							<input
								type="email"
								className="form-control"
								name="email"
								id="email"
								required
								onChange={handleInputChange}
							/>
						</div>
						<div className="form-group mt-3 col-lg-6">
							<div className="required">*</div>
							<label htmlFor="phone" className="pl-1 pr-2">
								Phone number
							</label>
							<input
								type="tel"
								pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
								maxLength={14}
								className="form-control"
								name="phone"
								id="phone"
								required
								onChange={handleInputChange}
							/>
						</div>
					</div>
					<div className="d-flex flex-column flex-lg-row">
						<div className="form-group mt-3 col-lg-6">
							<div className="required-non">*</div>
							<label htmlFor="linkedin_url" className="pl-1 pr-2">
								Linkedin URL
							</label>
							<input
								type="url"
								className="form-control"
								name="linkedin_url"
								id="linkedin_url"
								onChange={handleInputChange}
							/>
						</div>
						<div className="form-group mt-3 col-lg-6">
							<div className="required-non">*</div>
							<label htmlFor="twitter_url" className="pl-1 pr-2">
								Twitter URL
							</label>
							<input
								type="url"
								className="form-control"
								name="twitter_url"
								id="twitter_url"
								onChange={handleInputChange}
							/>
						</div>
					</div>
					<div className="d-flex flex-column flex-lg-row">
						<div className="form-group mt-3 col-lg-6">
							<div className="required">*</div>
							<label htmlFor="partnership_type" className="pl-1 pr-2">
								Partnership type
							</label>
							<select
								name="partnership_type"
								className="form-control"
								id="partnership_type"
								onChange={handleInputChange}
							>
								<option value=""></option>
								<option value="sponsorship">Sponsorship</option>
								<option value="joint-venture">Joint venture</option>
							</select>
						</div>
						<div className="form-group mt-3 col-lg-6">
							<div className="required-non">*</div>
							<label htmlFor="company_url" className="pl-1 pr-2">
								Company URL
							</label>
							<input
								type="url"
								className="form-control"
								name="company_url"
								id="company_url"
								onChange={handleInputChange}
							/>
						</div>
					</div>
					<div style={{ background: 'white' }} className="d-flex align-items-end">
						<div className="file-upload form-group mb-5 pb-5">
							<div style={{ background: 'white' }} className="text mb-4 mt-2 ml-2 ">
								Attach a presentation (Pdf format)
							</div>
							<input
								type="file"
								id="actual-btn"
								style={{
									position: 'absolute',
									zIndex: '-9999'
								}}
								name="attachment"
								accept="application/pdf"
								required
								onChange={handleFileSelect}
							/>
							<label for="actual-btn">
								<img
									src={require('../../Assets/img/file-upload.png')}
									alt=" file upload"
									className="mr-1"
								/>{' '}
								Upload file
							</label>
						</div>
						<div className="mb-3">{attachedFile.name}</div>
					</div>
					<button className="btn button  mb-5  p-2" disabled={Loading}>
						{' '}
						Submit
						{Loading && (
							<div className="spinner-grow sm bg-white ml-1 " role="status">
								<span className="sr-only">Loading...</span>
							</div>
						)}
					</button>
				</form>
			</div>
			<div className="d-lg-none">
				<Footer />
			</div>
		</Box>
	);
}

export default PartnerWithUsForm;
