import React from 'react';
import { Box } from '@chakra-ui/react';
import Header from '../../Assets/img/headerBig.svg';
import SmallerHeader from '../../Assets/img/headerSmall.svg';

export const SVGHeader = () => {
	return (
		<Box
			top={0}
			pos="absolute"
			height="200px"
			w="100vw"
			backgroundImage={{ base: `url(${SmallerHeader})`, md: `url(${Header})` }}
			backgroundSize="contain"
		></Box>
	);
};

export const Cancel = () => {
	return (
		<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M8.42358 7.82637L13.7071 13.1117C14.0979 13.5027 14.0976 14.1364 13.7064 14.527C13.3152 14.9177 12.6812 14.9174 12.2903 14.5264L7.0064 9.24065L1.70888 14.5273C1.31754 14.9179 0.683523 14.9174 0.292762 14.5263C-0.0979982 14.1352 -0.0975269 13.5015 0.293815 13.111L5.59092 7.82468L0.296044 2.52796C-0.0948298 2.13695 -0.094543 1.50329 0.296685 1.11263C0.687912 0.721973 1.32193 0.722259 1.71281 1.11327L7.0081 6.4104L12.2905 1.13879C12.6818 0.748246 13.3159 0.748717 13.7066 1.13984C14.0974 1.53096 14.0969 2.16463 13.7056 2.55517L8.42358 7.82637Z"
				fill="#050038"
			/>
		</svg>
	);
};
