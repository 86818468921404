import React, { useEffect, useState } from 'react'
import Footer from '../../../Container/Footer'
import Corporate from '../../../Container/Traning/Corporate'
import Individuals from '../../../Container/Traning/Individuals'
import "./index.css"
import {Helmet} from 'react-helmet';

const Traning = ({ changetype }) => {
    const [traningtype, setTraningtype] = useState(changetype === true ? 'Corporate' : 'Individuals')
    // useEffect(() => {
    //     document.getElementById("navTop").style.background = "#10152c";
    // }, [])
    return (
        <>
            <Helmet>
                <title> Develop Real-World Skills - Trainings at The Bulb Africa </title>
                <meta name="title" content="Develop Real-World Skills - Trainings at The Bulb Africa" />
                <meta name="description" content="The Bulb Africa's paid training program for students, incubators, and businesses. Enroll to take on the world's pressing challenges and be a positive impact." />
                <meta property="og:title" content="Develop Real-World skills - Trainings at The Bulb Africa" />
                <meta property="og:url" content="https://thebulb.africa/training" />
                <meta property="og:description" content="The Bulb Africa's paid training program for students, incubators, and businesses. Enrol to take on the world's pressing challenges and be a positive impact." />
                <meta property="og:type" content="website." />
                <meta property="og:image" content="https://thebulb.africa/Images/homepage/logo.png" />
                <meta property="og:image:alt" content="The Bulb Africa logo" />

                <meta name="twitter:title" content="Develop Real-Wolrd skills - Trainings at The Bulb  Africa" />
                <meta name="twitter:description" content="The Bulb Africa's paid training program for students, incubators, and businesses. Enrol to take on the world's pressing challenges and be a positive impact." />
                <meta name="twitter:image" content="https://thebulb.africa/Images/homepage/logo.png" />
                <meta name="twitter:image:alt" content="The Bulb Africa logo" />
                <meta name="twitter:site" content="@TheBulbAfrica" />
                <meta name="twitter:card" content="summary" />
                <script type="application/ld+json">
						{
							`
                                "@context": "https://schema.org/",
                                "@type": "WebSite",
                                "name": "The Bulb Africa ",
                                "url": "https://thebulb.africa/training/",
                                "potentialAction": {
                                "@type": "SearchAction",
                                "target": "https://thebulb.africa/training/{search_term_string}https://thebulb.africa/training/",
                                "query-input": "required name=search_term_string"
                                }
							`
						}
					</script>
            </Helmet>
            <div className="training mt-73">
                <div className="training-banner parallax py-5">
                    <div className="container">
                        <div className="row py-md-5 text-center">
                            <div className="col-md-12">
                                <h1 className="text-white font-weight-bold">Building Tomorrow's Leaders</h1>
                                <p className="py-3 mx-auto text-white">Our training model is reflective of our mission to create experiential learning opportunities for incubators, students and businesses with transferable and transportable skill sets for tomorrow’s leaders.
</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ourcourse py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading mb-4">
                                    <h3 className="font-weight-bold">Training</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <ul className="nav nav-tabs mb-4 pb-2 border-0" style={{ cursor: 'pointer' }}>
                                    <li className="nav-item" onClick={() => setTraningtype('Individuals')} >
                                        <a className={`nav-link ${traningtype === 'Individuals' && "active"}`}>Individuals</a>
                                    </li>
                                    <li className="nav-item" onClick={() => setTraningtype('Corporate')}>
                                        <a className={`nav-link ${traningtype === 'Corporate' && "active"}`}>Corporate</a>
                                    </li>
                                </ul>

                                <div className="tab-content">
                                    <div className="tab-pane fade show active">
                                        <div className="row">
                                            {traningtype === "Individuals" && <Individuals />}
                                            {traningtype === "Corporate" && <Corporate />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Traning