import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

import Navbar from "../../Container/Navbar";
import Footer from "../../Container/Footer";
import ImageCard from "../../Components/Card/ImageCard";
import TextCard from "../../Components/Card/TextCard";
import Number from "../../Components/Number/Number";
import MiniCards from "../../Components/MiniCards/MiniCards";

import bulbLogo from "../../Assets/thebulb/bulblogoblue.svg";
import illustration1 from "../../Assets/img/worker.gif";
import illustration2 from "../../Assets/img/working-together.gif";
import illustration3 from "../../Assets/img/coworkers-make-break.gif";
import illustration4 from "../../Assets/img/colleagues-working-together.gif";
import fellows from "../../Assets/img/fellows.png";
import kids from "../../Assets/img/kids.png";
import explorer from "../../Assets/img/explorer.png";
import community from "../../Assets/img/a-community.png";

import "./Institute.css";

const Institute = () => {
  const miniCardsData = [
    {
      image: fellows,
      caption: "Join our Fellowship Program",
      inAppLink: false,
      destination: "https://thebulbafrica.institute/fellowship/",
    },
    {
      image: kids,
      caption: "Register your Kids",
      inAppLink: false,
      destination: "https://thebulbafrica.institute/next-gen/",
    },
    {
      image: explorer,
      caption: "Explore our Courses",
      inAppLink: true,
      destination: "/explore-courses",
    },
    {
      image: community,
      caption: "Join our Startup Community",
      inAppLink: true,
      destination: "/startup-academy",
    },
  ];

  const counterData = [
    {
      text: "Courses Offered",
      number: 27,
    },
    {
      text: "Trained",
      number: 127,
    },
    {
      text: "Facilitators",
      number: 18,
    },
  ];

  const sponsors = [
    "polaris",
    "access",
    "prunedge",
    "deloitte",
    "gtbank",
    "treepz",
    "chisco",
    "parkway",
    "webcoupers",
    "dotpay",
    "alat",
    "nestle",
    "herconomy",
    "money-africa",
    "lacasera",
    "uac",
    "itel",
    "techcabal",
    "awabah",
    "sterling",
  ];

  useEffect(() => {
    // document.getElementById("navTop").style.display = "none";
    // const items = document.getElementsByClassName("nav-link");
    // for (let i = 0; i < items.length; i++) {
    //   items[i].style.color = "#10152c";
    // }
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  });

  return (
    <>
      <Helmet>
        {/*TITLE TAGS & META TAGS*/}

        <title>Acquire In-Demand Tech Skills | The Bulb Africa </title>
        <meta
          name="title"
          content="Acquire In-Demand Tech Skills | The Bulb Africa "
        />
        <meta
          name="description"
          content="Accelerate your career in tech through our training programs for kids, unemployed youths, under-employed professionals & startup founders. "
        />

        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content="Acquire In-Demand Tech Skills | The Bulb Africa "
        />
        <meta property="og:url" content="https://thebulb.africa/institute" />
        <meta
          property="og:description"
          content="Accelerate your career in tech through our training programs for kids, unemployed youths, under-employed professionals & startup founders. "
        />
        <meta
          property="og:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta property="og:image:alt" content="The Bulb Africa- offical logo" />

        {/* twitter meta tags */}
        <meta
          name="twitter:title"
          content="Acquire In-Demand Tech Skills | The Bulb Africa"
        />
        <meta
          name="twitter:description"
          content="Accelerate your career in tech through our training programs for kids, unemployed youths, under-employed professionals & startup founders."
        />
        <meta
          name="twitter:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta name="twitter:image:alt" content="The Bulb- offical logo" />
        <meta name="twitter:site" content="@TheBulbAfrica" />
        <meta name="twitter:card" content="summary" />

        {/* JSON LD Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "EducationalOrganization",
              "name": "The Bulb",
              "url": "https://thebulb.africa/institute",
              "logo": "https://thebulb.africa/Images/homepage/logo.png"
            }
            {
              "@context": "https://schema.org/",
              "@type": "Article",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://thebulb.africa/institute"
              },
              "headline": "Acquire In-Demand Tech Skills | The Bulb Africa",
              "description": "Accelerate your career in tech through our training programs for kids, unemployed youths, under-employed professionals & startup founders.",
              "image": "https://thebulb.africa/Images/homepage/logo.png",
              "author": {
                "@type": "Organization",
                "name": "The Bulb Africa"
              },
              "publisher": {
                "@type": "Organization",
                "name": "",
                "logo": {
                  "@type": "ImageObject",
                  "url": ""
                }
              },
              "datePublished": "2020-01-20",
              "dateModified": "2022-09-21"
            }
          `}
        </script>
      </Helmet>

      <div className="fixed-nav">
        <Navbar navImage={bulbLogo} isLight={true} />
      </div>

      <header>
        <div className="institute-hero">
          <h1
            className="institute-hero-heading"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            Bridging the gap in Africa's tech ecosystem{" "}
          </h1>
          <p
            className="institute-hero-paragraph"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            We empower Africa’s tech talents by providing access to trainings
            for global opportunities and career growth.
          </p>
        </div>
      </header>

      <main className="institute-main">
        <div className="institute-card-container">
          <div className="institute-card-content reverse">
            <TextCard
              className="institute-text-card"
              headingClassName="institute-text-card-heading"
              descriptionClassName="institute-text-card-description"
              isHyperLink={true}
              heading="Become a tech expert in 4-6 months"
              description="Gain access to the skills and resources needed to launch your career in tech. Designed for Africans aged 18-35, our Fellowship provides practical experience to get you started. Pick a course, join a cohort, learn from vetted instructors without paying tuition until you’re hired."
              cta="Join Our Fellowship"
              location="https://thebulbafrica.institute/fellowship/"
            />
            <ImageCard
              source={illustration1}
              className="institute-image-card"
              animationDuration="1000"
            />
          </div>
          <div className="institute-card-content">
            <ImageCard
              source={illustration2}
              className="institute-image-card"
              animationDuration="1000"
            />
            <TextCard
              className="institute-text-card"
              headingClassName="institute-text-card-heading"
              descriptionClassName="institute-text-card-description"
              isHyperLink={true}
              heading="Unlock innovation in your kids and teenagers with NextGen"
              description="We are building the next generation of tech experts by giving kids the opportunity to learn and explore highly sought-after tech skills. NextGen is designed to provide a personalized and gamified learning experience for young learners aged 7-17. "
              cta="Register Your Kids"
              location="https://thebulbafrica.institute/next-gen/"
            />
          </div>
          <div className="institute-card-content reverse">
            <TextCard
              className="institute-text-card"
              headingClassName="institute-text-card-heading"
              descriptionClassName="institute-text-card-description"
              isAppLink={true}
              heading="Accelerate your career journey"
              description="Stay ahead of the competition by taking our Career Advance courses. Whether you’re preparing for an exam or simply wish to upskill, our courses have been curated with a standardized curriculum to take your career to new heights. "
              cta="Explore Our Courses"
              location="https://thebulbafrica.institute/career-advance/"
            />
            <ImageCard
              source={illustration3}
              className="institute-image-card"
              animationDuration="1000"
            />
          </div>
          <div className="institute-card-content">
            <ImageCard
              source={illustration4}
              className="institute-image-card"
              animationDuration="1000"
            />
            <TextCard
              className="institute-text-card"
              headingClassName="institute-text-card-heading"
              descriptionClassName="institute-text-card-description"
              isAppLink={true}
              heading="Equipping Startup Founders with courses tailored for Growth"
              description="We offer training programs geared towards founders and taught by industry experts. Our Academy provides access to the business knowledge, techniques, skilld, and resources needed to excel in Africas tech ecosystem."
              cta="Join Our Startup Academy"
              location="/startup-academy"
            />
          </div>
        </div>
        <div className="numbers-container">
          <div className="numbers">
            {counterData.map((item) => (
              <Number end={item.number} description={item.text} />
            ))}
          </div>
        </div>

        <div className="institute-sponsors-div">
          <h3 className="institute-sponsors-heading">
            Companies that trust us
          </h3>
          <div className="institute-sponsors">
            {sponsors.map((sponsor) => (
              <img
                src={require(`../../Assets/img/sponsor-logos/${sponsor}.png`)}
                alt={sponsor}
                className="institute-sponsor-logo"
                data-aos="zoom-in"
                data-aos-duration="500"
              />
            ))}
          </div>
        </div>

        <div className="empower">
          <div className="empower-text">
            <h2
              className="empower-heading"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Empowering Africa’s tech talents to unlock their potential
            </h2>
            <p
              className="empower-pickup"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Pick your preferred training, receive a world-class education,
              up-skill, and become an expert in your niche.
            </p>
          </div>
          <MiniCards data={miniCardsData} />
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Institute;
