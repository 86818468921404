import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import theBulbLogoDark from "../../../Assets/img/the-bulb-logo-dark.png";
import Navbar from "../../../Container/Navbar";
import Footer from "../../../Container/Footer";
import "./Incubator.css";
import IncubatorCards from "./components/IncubatorCards/IncubatorCards";
import magnet from "../../../Assets/img/magnet.png";
import mindMap from "../../../Assets/img/Mind-Map.png";
import bag from "../../../Assets/img/Business-bag.png";
import rodeo from "../../../Assets/img/Rodeo.png";
import spanner from "../../../Assets/img/spanner.png";
import pencil from "../../../Assets/img/crown-pencil.png";
import peexoo from "../../../Assets/img/peexoo.png";
import settlme from "../../../Assets/img/settlme.png";
import Button from "../../../Components/Button/Button";

const Incubator = () => {
  useEffect(() => {
    document.getElementById("navTop").style.display = "none";
    const items = document.getElementsByClassName("nav-link");
    for (let i = 0; i < items.length; i++) {
      items[i].style.color = "#10152c";
    }
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  });

  return (
    <>
      <Helmet>
        {/*TITLE TAGS & META TAGS*/}

        <title>
          Transform Your Tech Ideas into Business Realities | The Bulb{" "}
        </title>

        <meta
          name="title"
          content="Transform Your Tech Ideas into Business Realities | The Bulb "
        />
        <meta
          name="description"
          content="Got an innovative business idea? Join our incubator and gain access to a community of mentors, investors, and founders like you. "
        />

        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content="Transform Your Tech Ideas into Business Realities | The Bulb "
        />
        <meta property="og:url" content="https://thebulb.africa/incubator" />
        <meta
          property="og:description"
          content="Got an innovative business idea? Join our incubator and gain access to a community of mentors, investors, and founders like you."
        />
        <meta
          property="og:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta property="og:image:alt" content="The Bulb Africa- offical logo" />

        {/* twitter meta tags */}
        <meta
          name="twitter:title"
          content="Transform Your Tech Ideas into Business Realities | The Bulb "
        />
        <meta
          name="twitter:description"
          content="Got an innovative business idea? Join our incubator and gain access to a community of mentors, investors, and founders like you."
        />
        <meta
          name="twitter:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta name="twitter:image:alt" content="The Bulb- offical logo" />
        <meta name="twitter:site" content="@TheBulbAfrica" />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <div className="fixed-nav">
        <Navbar
          // closeAll={setState}
          navImage={theBulbLogoDark}
          about="/aboutus"
          alt="Careers"
          altLink="#"
          isLight={true}
        />
      </div>

      <div className="incubator">
        <div className="incubator-hero">
          <img
            src={magnet}
            data-aos="zoom-out"
            data-aos-duration="500"
            className="incubator-hero-img"
          />
          <div className="incubator-hero-body">
            <h1
              className="incubator-hero-head"
              data-aos="fade-in"
              data-aos-duration="1000"
            >
              We turn tech ideas into business realities
            </h1>
            <p className="incubator-hero-text">
              Our incubator finds the best ideas and transforms them from a plan
              to a product-market fit.
            </p>
            <Button
              className="get-started"
              data-aos="zoom-in"
              data-aos-duration="500"
              isHyperLink={true}
              cta="Apply for Incubator"
              location="https://forms.gle/kvg5WAW3eiDqdEA46"
              rel="noreferrer noopenner"
            />
          </div>
        </div>

        <div className="under-hero">
          <h2
            className="under-hero-head"
            data-aos="zoom-out"
            data-aos-duration="500"
          >
            We nurture revolutionary startup ideas that will solve Africa’s
            challenges
          </h2>
          <p
            className="under-hero-text"
            data-aos="zoom-out"
            data-aos-duration="500"
          >
            We provide an enabling environment for startups to thrive by
            offering nurturing and mentoring founders over 2-3 years. Our
            portfolio startups have launched successful companies, received
            funding from active invested and created job opportunities.
          </p>
        </div>

        <div className="incubator-list">
          <h2 className="incubator-list-head">How we make it happen </h2>
          <div className="incubator-list-text-1">
            <ul className="incubator-list-text-2">
              <li data-aos="fade-right" data-aos-duration="500">
                We source for high-potential early-stage startups led by
                dedicated founders.
              </li>
              <li data-aos="fade-right" data-aos-duration="500">
                Startups that yield desired results will be assimilated into our
                accelerator program and may consequently receive funding via our
                investment syndicate, Illuminate.
              </li>
              <li data-aos="fade-right" data-aos-duration="500">
                We provide help with day-to-day operations such as legal,
                co-working space, and business services.
              </li>
              <li data-aos="fade-right" data-aos-duration="500">
                We offer training and mentorship to help founders develop and
                launch their MVP.
              </li>
            </ul>
          </div>
        </div>

        <div className="look-out-for">
          <h2 style={{ marginLeft: "-1rem" }}>What we look out for</h2>
          <div className="look-out-for-body">
            <IncubatorCards
              icon={mindMap}
              text="Promising founders are yet to build their MVP"
            />
            <IncubatorCards
              icon={bag}
              text="Business ideas with a solid revenue potential"
            />
            <IncubatorCards
              icon={rodeo}
              text="Startups capable of outcompeting their competition"
            />
            <IncubatorCards
              icon={spanner}
              text="Startups that can solve real challenges in Africa "
            />
            <IncubatorCards
              icon={pencil}
              text="Startups that can gain significant brand traction "
            />
          </div>
        </div>
      </div>

      <div className="incubator-bottom">
        <h2
          className="incubator-bottom-head"
          style={{ textAlign: "center" }}
          data-aos="fade-right"
          data-aos-duration="500"
        >
          Meet our Startups
        </h2>
        <div className="incubator-bottom-imgss">
          <a
            href="https://peexoo.ai/"
            target="_blank"
            rel="noreferrer noopenner"
          >
            <img
              src={peexoo}
              data-aos="zoom-out"
              data-aos-duration="500"
              className="incubator-bottom-img"
            />
          </a>
          <a
            href="https://settl.me/"
            target="_blank"
            rel="noreferrer noopenner"
          >
            <img
              src={settlme}
              data-aos="zoom-out"
              data-aos-duration="500"
              className="incubator-bottom-img"
            />
          </a>
        </div>
      </div>

      <div className="incubator-final">
        <h3 data-aos="fade-up" data-aos-duration="1000">
          An incubator created to help you bring your dream startup to life
        </h3>
        <p data-aos="fade-up" data-aos-duration="1000">
          Join our incubator and gain access to a community of mentors,
          investors, and founders like you!
        </p>
        <Button
          isLight={true}
          c
          location="https://forms.gle/kvg5WAW3eiDqdEA46"
        />
      </div>
      <Footer />
    </>
  );
};

export default Incubator;
