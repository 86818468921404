import React from 'react';
import './Number.css';
import CountUp, { useCountUp } from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';

const Number = (props) => {
	return (
		<div className="number">
			<CountUp
				end={props.end}
				redraw={true}
			>
				{({ countUpRef, start }) => (
					<VisibilitySensor
						onChange={start}
						delayedCall
					>
						<span
							className='counter'
							ref={countUpRef}
						/>
					</VisibilitySensor>
				)}
			</CountUp>
			<h3 className='counter-text'>
				{props.description}
			</h3>
		</div>
	)
}

export default Number;