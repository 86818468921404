import React from "react";
import Back from "../../Components/Back/Back";
import "./index.css";

function Live() {
  return (
    <div className="live-bg-door">
      <div className="overley d-flex align-items-center justify-content-center flex-column">
        <Back top="40px"/>
        {/* <video width="100%" height="100%" controls>
          <source
            src={require("../../../Assets/img/tour-video.mp4")}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video> */}
        <iframe width="100%" height="100%" src="https://www.youtube.com/embed/live_stream?channel=UCdzS4YQ-NdpneHq68w4e3Cw" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  );
}

export default Live;
