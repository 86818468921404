import React, { useState } from "react";
import placeholder from "../../../Assets/img/conversation.png";
import "./Courses.css";
import Button from "../../Button/Button";
import frame1 from "../../../Assets/img/frame 1.png";
import frame2 from "../../../Assets/img/frame 2.png";
import frame3 from "../../../Assets/img/frame 3.png";
import frame4 from "../../../Assets/img/frame 4.png";
import frame5 from "../../../Assets/img/frame 5.png";
import Readmore from "../../Readmore/Readmore";

const Courses = (props) => {
  return (
    <div className="course" data-aos="zoom-in" data-aos-duration="800">
      <div className="img-cont">
        <img src={props.img} alt="" />
      </div>
      <h2 style={{ marginTop: "1rem" }}>{props.heading}</h2>
      <p>
        <Readmore>{props.text1}</Readmore>
      </p>
      {/* <p>
        <Readmore>{props.text2}</Readmore>
      </p> */}
      <div className="mini" style={{ marginTop: "1rem" }}>
        <img src={frame1} alt="" />
        <p>{props.mini1}</p>
      </div>
      <div className="mini">
        <img src={frame2} alt="" />
        <p>{props.mini2}</p>
      </div>
      <div className="mini">
        <img src={frame3} alt="" />
        <p>{props.mini3}</p>
      </div>
      <div className="mini">
        <img src={frame4} alt="" />
        <p>{props.mini4}</p>
      </div>
      <div className="mini" style={{ marginBottom: "2rem" }}>
        <img src={frame5} alt="" />
        <p>{props.mini5}</p>
      </div>
      <Button
        isHyperLink="true"
        location={props.link}
        cta="Register"
      />
    </div>
  );
};

export default Courses;
