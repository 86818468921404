import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Api } from '../../Const/Api';
import axios from 'axios';
import { TwitterShareButton, LinkedinShareButton, FacebookShareButton } from 'react-share';
import { Box, Text } from '@chakra-ui/react';

import './index.css';

function Share({ id, type, getSinglePost, title }) {
	const [likeData, setLikeData] = useState(0);
	const [Loading, setLoading] = useState(true);

	const base_url = window.location.origin;
	const { location } = useHistory();
	const path = location.pathname;

	const payload = {
		id: path.split('/')[2],
		section: path.split('/')[1]
	};

	useEffect(() => {
		axios.post(`${Api.BaseUrl}like/article`, payload).then(function (response) {
			setLoading(false);
			if (response.status === 200) {
				setLikeData(response.data.payload.message.total_likes);
			}
		});
	}, [payload]);

	const handleLikeClick = e => {
		setLoading(true);
		console.log({ payload });
		axios.post(`${Api.BaseUrl}like`, payload).then(function (response) {
			setLoading(false);
			if (response.status === 200) {
				setLikeData(response.data.payload.message.total_likes);
			}
		});
	};

	return (
		<Box
			position={{ base: 'relative', 'xl': 'fixed' }}
			top={{ base: '0px', 'xl': '300px' }}
			left={{ base: '0px', 'xl': '70px' }}
			mr={{ base: '10px', xl: 0 }}
			display="inline-flex"
			className="social-media "
		>
			<Text mb="15px">Share</Text>
			<div className="img  mx-auto">
				<TwitterShareButton url={`${base_url}${path}`} title={title}>
					<svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M23.6308 2.67289C22.752 3.05836 21.8156 3.31387 20.8394 3.43793C21.8437 2.8383 22.6102 1.89603 22.9706 0.760271C22.0342 1.31855 21.0004 1.71289 19.8986 1.93295C19.0095 0.986241 17.7423 0.399902 16.3599 0.399902C13.6778 0.399902 11.5185 2.57689 11.5185 5.24569C11.5185 5.62969 11.551 5.99892 11.6308 6.35042C7.6032 6.15399 4.03938 4.22366 1.64529 1.2831C1.22732 2.00827 0.982154 2.8383 0.982154 3.73184C0.982154 5.40963 1.84615 6.89689 3.13403 7.75793C2.35569 7.74316 1.59212 7.51719 0.945231 7.16126C0.945231 7.17602 0.945231 7.19522 0.945231 7.21442C0.945231 9.56864 2.62449 11.5241 4.82658 11.9745C4.43225 12.0824 4.00246 12.1341 3.55643 12.1341C3.24628 12.1341 2.93317 12.1163 2.63926 12.0513C3.26695 13.9699 5.04812 15.3803 7.16603 15.4261C5.51778 16.7155 3.42498 17.4923 1.15938 17.4923C0.762092 17.4923 0.381046 17.4746 0 17.4259C2.14597 18.8097 4.68923 19.5999 7.43188 19.5999C16.3466 19.5999 21.2204 12.2153 21.2204 5.8143C21.2204 5.60015 21.213 5.39338 21.2027 5.18809C22.1642 4.50575 22.9721 3.65356 23.6308 2.67289Z"
							fill="#10162B"
						/>
					</svg>
				</TwitterShareButton>
			</div>
			<div className="img  mx-auto  mt-4">
				<LinkedinShareButton url={`${base_url}${location.pathname}`} title={title}>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M23.9929 23.9998V23.9987H23.9989V14.8126C23.9989 10.3187 23.0768 6.85693 18.0696 6.85693C15.6624 6.85693 14.0471 8.24289 13.3876 9.55683H13.3179V7.27648H8.57031V23.9987H13.5139V15.7185C13.5139 13.5383 13.9078 11.4302 16.481 11.4302C19.0165 11.4302 19.0543 13.9182 19.0543 15.8583V23.9998H23.9929Z"
							fill="#10162B"
						/>
						<path d="M0 8.57129H5.14286V23.9999H0V8.57129Z" fill="#10162B" />
						<path
							d="M2.57143 0C1.15188 0 0 1.14651 0 2.55944C0 3.97237 1.15188 5.14286 2.57143 5.14286C3.99098 5.14286 5.14286 3.97237 5.14286 2.55944C5.14196 1.14651 3.99009 0 2.57143 0V0Z"
							fill="#10162B"
						/>
					</svg>
				</LinkedinShareButton>
			</div>
			<div className="img  mx-auto mt-4">
				<FacebookShareButton url={`${base_url}${location.pathname}`} title={title}>
					<svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M8.85941 24V13.0533H12.5323L13.0833 8.78588H8.85941V6.06176C8.85941 4.82664 9.20099 3.98492 10.9742 3.98492L13.232 3.98399V0.167076C12.8415 0.116334 11.5012 0 9.94123 0C6.68371 0 4.45356 1.98836 4.45356 5.63912V8.78588H0.769531V13.0533H4.45356V24H8.85941Z"
							fill="#10162B"
						/>
					</svg>
				</FacebookShareButton>
			</div>
			<div className="img  mx-auto mt-5 share" onClick={handleLikeClick}>
				<div className="like-circle">
					{Loading ? (
						<div className="spinner-grow sm bg-white " role="status">
							<span className="sr-only">Loading...</span>
						</div>
					) : (
						likeData
					)}
				</div>
				<svg width="31" height="49" viewBox="0 0 31 49" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M7.536 44V42.816H2.576V33.328H1.248V44H7.536ZM9.94825 35.168C10.3963 35.168 10.7643 34.816 10.7643 34.368C10.7643 33.92 10.3963 33.552 9.94825 33.552C9.51625 33.552 9.14825 33.92 9.14825 34.368C9.14825 34.816 9.51625 35.168 9.94825 35.168ZM10.5563 44V36.272H9.35625V44H10.5563ZM19.75 44L16.31 39.776L19.734 36.272H18.214L14.15 40.384V33.328H12.95V44H14.15V41.792L15.43 40.528L18.214 44H19.75ZM24.6099 44.192C25.8739 44.192 26.9139 43.776 27.6819 43.008L27.1059 42.224C26.4979 42.848 25.6019 43.2 24.7219 43.2C23.0579 43.2 22.0339 41.984 21.9379 40.528H28.1939V40.224C28.1939 37.904 26.8179 36.08 24.4819 36.08C22.2739 36.08 20.6739 37.888 20.6739 40.128C20.6739 42.544 22.3219 44.192 24.6099 44.192ZM27.0099 39.648H21.9379C22.0019 38.464 22.8179 37.072 24.4659 37.072C26.2259 37.072 26.9939 38.496 27.0099 39.648Z"
						fill="#28314F"
					/>
					<path
						d="M15 22.6648C14.6583 22.6648 14.3289 22.541 14.0722 22.3162C13.1026 21.4684 12.1679 20.6717 11.3432 19.9689L11.339 19.9653C8.92107 17.9048 6.83312 16.1254 5.38037 14.3725C3.75641 12.4129 3 10.5549 3 8.52521C3 6.55316 3.67621 4.73383 4.90393 3.4021C6.1463 2.05463 7.851 1.3125 9.70458 1.3125C11.09 1.3125 12.3587 1.75049 13.4754 2.6142C14.039 3.05017 14.5499 3.58374 15 4.20612C15.4502 3.58374 15.9609 3.05017 16.5247 2.6142C17.6415 1.75049 18.9102 1.3125 20.2956 1.3125C22.149 1.3125 23.8538 2.05463 25.0962 3.4021C26.3239 4.73383 27 6.55316 27 8.52521C27 10.5549 26.2437 12.4129 24.6198 14.3723C23.167 16.1254 21.0793 17.9046 18.6617 19.9649C17.8355 20.6688 16.8993 21.4667 15.9276 22.3165C15.6711 22.541 15.3415 22.6648 15 22.6648ZM9.70458 2.71838C8.24834 2.71838 6.91058 3.29956 5.93737 4.35498C4.9497 5.42633 4.4057 6.90729 4.4057 8.52521C4.4057 10.2323 5.04016 11.759 6.4627 13.4755C7.83764 15.1346 9.88274 16.8774 12.2507 18.8954L12.255 18.899C13.0829 19.6046 14.0213 20.4044 14.998 21.2584C15.9805 20.4027 16.9204 19.6016 17.7499 18.895C20.1176 16.877 22.1625 15.1346 23.5374 13.4755C24.9598 11.759 25.5943 10.2323 25.5943 8.52521C25.5943 6.90729 25.0503 5.42633 24.0626 4.35498C23.0896 3.29956 21.7516 2.71838 20.2956 2.71838C19.2288 2.71838 18.2494 3.0575 17.3846 3.7262C16.6139 4.32239 16.077 5.07605 15.7622 5.60339C15.6004 5.87457 15.3155 6.03644 15 6.03644C14.6845 6.03644 14.3996 5.87457 14.2377 5.60339C13.9231 5.07605 13.3863 4.32239 12.6154 3.7262C11.7506 3.0575 10.7712 2.71838 9.70458 2.71838Z"
						fill="black"
					/>
				</svg>
			</div>
		</Box>
	);
}

export default Share;
