import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../Container/Footer';
import './index.css';
import { SVGHeader } from '../../Components/SVG/header';
import { Box, Text, Link as ChakraLink } from '@chakra-ui/react';
import {Helmet} from 'react-helmet';

function PartnerWithUs() {
	useEffect(() => {
		document.getElementById('navTop').style.background = '#10152c';
		window.scrollTo(0, 0);
	}, []);

	return (
		<div style={{ position: 'relative', paddingTop: '3em' }} className="community">
			<Helmet>
					<title>  Join Our Community of Young Tech Talents | The Bulb Africa    </title>
					<meta name="title" content="Join Our Community of Young Tech Talents | The Bulb Africa   " />
					<meta name="description" content="Want to grow your professional network? Connect with tech experts. Our community of young tech talents is redefining the future. Find the right inspiration to turn your ideas into business realities. " />
			</Helmet>
			<SVGHeader />
			<div className="container">
				<div className="top d-flex flex-column-reverse flex-lg-row mt-5 mb-5 pb-5">
					<div className="img-section col-lg-5 mr-4">
						<img src={require('../../Assets/img/community.png')} alt="community" />
					</div>
					<div className="right-section col-lg-7">
						<Text
							fontSize={{ base: '30px', md: '34px' }}
							className="heading mb-4 mt-3 text-center text-lg-left"
							as="h1"
						>
							For tech talents, by tech talents
						</Text>
						<div className="text mb-5 text-center text-lg-left">
							The Bulb™ is an open community for young tech talents. Share knowledge with new tech recruits and professional programmers 
							and developers, recruit top tech talents in the industry, and find your next tech job. Expert answers to the questions you 
							can’t find everywhere are available in our community. 
						</div>
						<Link to="/community-all">
							<button className="btn button btn-dark d-none d-lg-block">Join a community</button>
						</Link>
					</div>
				</div>
				<Link to="/community-all">
					<button
						style={{
							marginBottom: '80px'
						}}
						className="btn button btn-dark  d-lg-none col-9 mx-auto mb-8 d-flex justify-content-center align-items-center"
					>
						Join a community
					</button>
				</Link>
				<Box mb="150px" mt="50px" className="possibilities  p-md-5">
					<Text pt="30px" fontSize="30px" px="10px" className="heading mb-5 text-center" as="h2">
						Unlock access to limitless tech possibilities with The Bulb™ community 
					</Text>
					<div className="possibilities-card-section d-flex flex-column flex-lg-row justify-content-between mx-auto align-items-stretch">
						<Box
							my="15px"
							mx={{ base: 'auto', lg: '10px' }}
							className="possibilities-card p-4 d-flex flex-column justify-content-center "
						>
							<div className="card-img d-flex  mx-auto">
								<img src={require('../../Assets/img/invest.png')} alt="invest" />
							</div>
							<Box className="heading text-center my-3">Invest in the future</Box>
							<div className="text text-center">
								Upskill through expert answers to your questions, whether you are a beginner, intermediate or a well-experienced software 
								developer. 
							</div>
						</Box>
						<Box
							my="15px"
							mx={{ base: 'md3', lg: '10px' }}
							className="possibilities-card p-4 d-flex flex-column justify-content-center align-self-stretch "
						>
							<div className="card-img d-flex  mx-auto">
								<img src={require('../../Assets/img/brife-case.png')} alt="invest" />
							</div>
							<Box className="heading text-center my-3">Gain fresh perspectives </Box>
							<div className="text text-center">
								Gain useful insights about various technologies, including yours, through a large audience of developers and programmers. 
							</div>
						</Box>
						<Box
							my="15px"
							mx={{ base: 'auto', lg: '10px' }}
							className="possibilities-card p-4 d-flex flex-column justify-content-center align-self-stretch "
						>
							<div className="card-img d-flex  mx-auto">
								<img src={require('../../Assets/img/brife-case.png')} alt="invest" />
							</div>
							<Box className="heading text-center my-3">Explore job opportunities</Box>
							<div className="text text-center">
								Create a professional profile to explore multiple job opportunities, land the one that is right for you, 
								and build a future suitable for your tech vision. 
							</div>
						</Box>
						<Box
							my="15px"
							mx={{ base: 'auto', lg: '10px' }}
							className="possibilities-card p-4 d-flex flex-column justify-content-center "
						>
							<div className="card-img d-flex  mx-auto">
								<img src={require('../../Assets/img/award.png')} alt="invest" />
							</div>
							<Box className="heading text-center my-3">Join and inspire new projects </Box>
							<div className="text text-center">
								Reach creators of top technologies and start projects of your own by connecting with members of our tech community. 
							</div>
						</Box>
						<Box
							my="15px"
							mx={{ base: 'auto', lg: '10px' }}
							className="possibilities-card p-4 d-flex flex-column justify-content-center "
						>
							<div className="card-img d-flex  mx-auto">
								<img src={require('../../Assets/img/award.png')} alt="invest" />
							</div>
							<Box className="heading text-center my-3">Recruit top tech talents</Box>
							<div className="text text-center">
								Find only top tech talents with highest level of performance for your company.
							</div>
						</Box>
					</div>
					<Box
						w="full"
						display={{ base: 'block', md: 'flex' }}
						justifyContent="center"
						alignItems="center"
						className="  mt-5 "
						pb="20px"
						textAlign="center"
					>
						<ChakraLink href="/community-all" mx="20px">
							<button
								style={{
									width: '185px'
								}}
								className="btn button my-3 btn-dark "
							>
								Join a community
							</button>
						</ChakraLink>
						<ChakraLink href="/hire-developer" mx="20px">
							<button
								style={{
									width: '185px'
								}}
								className="btn button my-3 btn-white "
							>
								Hire our talent
							</button>
						</ChakraLink>
					</Box>
				</Box>
			</div>

			<Footer />
		</div>
	);
}

export default PartnerWithUs;
