import React from 'react'
import './IncubatorCards.css'

const IncubatorCards = (props) => (
	<div data-aos="zoom-in" data-aos-duration="1000" className={`incubator-cards ${props.className}`}>
		<img src={props.icon} className='incubator-cards-img'/>
		{props.head && <h3 className='incubator-cards-head'>{props.head}</h3>}
		<p className={`incubator-cards-text ${props.className}`}>{props.text}</p>
	</div>
)
export default IncubatorCards;