import React, { useEffect, useState } from "react";

const CountUpAnimation = ({ initialValue, targetValue, text }) => {
  const [count, setCount] = useState(initialValue);
  const duration = 40000; // 4 seconds

  useEffect(() => {
    let startValue = initialValue;
    const interval = Math.floor(duration / (targetValue - initialValue));

    const counter = setInterval(() => {
      startValue += 1;
      setCount(startValue);
      if (startValue >= targetValue) {
        clearInterval(counter);
      }
    }, interval);

    return () => {
      clearInterval(counter);
    };
  }, [targetValue, initialValue]);

  const formatCount = (count) => {
    if (count >= 1000) {
      return count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "+";
    } else {
      return count;
    }
  };

  return (
    <div className="flex items-center md:gap-4 gap-4 flex-col pb-6">
      <h2 className="lg:text-6xl md:text-5xl text-3xl text-[#FDCE4E]">
        {formatCount(count)}
      </h2>
      <p className="lg:text-[24px] md:text-[20px] text-white text-xl">{text}</p>
    </div>
  );
};

export const Numbers = () => {
  return (
    <div className="lg:px-24  md:px-12 px-6 manrope md:py-24 py-12 bg-[#13162D]">
      <h2 className="text-[#E4E4E7] lg:text-6xl md:text-5xl text-2xl text-center">
        Our Strengths, Our Numbers
      </h2>
      <div className="flex justify-between md:flex-row  flex-col items-center px-6 md:pt-12 pt-6 w-full">
        <CountUpAnimation
          initialValue={200}
          targetValue={1100}
          text="Job Created"
        />
        <CountUpAnimation initialValue={0} targetValue={4} text="Programs" />
        <CountUpAnimation
          initialValue={300}
          targetValue={5000}
          text="Trained"
        />
        <CountUpAnimation
          initialValue={500}
          targetValue={10000}
          text="Community members"
        />
      </div>
    </div>
  );
};
