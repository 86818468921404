import React, { useEffect } from 'react'
import Footer from '../../../Container/Footer'
import AlertDialog from '../../../Components/Madal'
import Loader from '../../../Components/Loader'
import { Schedulevisit } from '../../../common/function'

let errormsg = "This field is required.";
class Schedulewithus extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: "",
            fname: "",
            lname: "",
            phoneno: "",
            email: "",
            organization: "",
            groupOrganization: "",
            GroupSize: "",
            ParticipantNames: "",
            Reasontour: "",
            Additionalinfo: "",
            titleError: "",
            fnameError: "",
            lnameError: "",
            phonennoError: "",
            emailError: "",
            organationError: "",
            groupsizeError: "",
            participateError: "",
            reasonsError: "",
            additionalError: "",
            value: '1000',
            loader: false,
            status: null,
            setOpen: false,
            message: ""
        }
    }
    // componentDidMount() {
    //     document.getElementById("navTop").style.background = "#10152c";
    // }


    HandleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    validate = () => {

        let titleError = "";
        let fnameError = "";
        let lnameError = "";
        let phonennoError = "";
        let emailError = "";
        let organationError = "";
        let groupsizeError = "";
        let participateError = "";
        let reasonsError = "";
        let additionalError = "";

        if (!this.state.title) {
            titleError = errormsg;
        }
        else {
            titleError = "";
        }

        if (!this.state.fname) {
            fnameError = errormsg;
        }
        else {
            fnameError = "";
        }

        if (!this.state.lname) {
            lnameError = errormsg;
        }
        else {
            lnameError = "";
        }

        if (!this.state.phoneno) {
            phonennoError = errormsg;
        }
        else {
            phonennoError = "";
        }

        if (!this.state.email.includes("@")) {
            emailError = errormsg;
        }
        else {
            emailError = "";
        }
        if (!this.state.organization) {
            organationError = errormsg;
        }
        else {
            organationError = "";
        }
        if (!this.state.GroupSize) {
            groupsizeError = errormsg;
        }
        else {
            groupsizeError = "";
        }
        if (!this.state.ParticipantNames) {
            participateError = errormsg;
        }
        else {
            participateError = "";
        }

        if (!this.state.Reasontour) {
            reasonsError = errormsg;
        }
        else {
            reasonsError = "";
        }
        if (!this.state.Additionalinfo) {
            additionalError = errormsg;
        }
        else {
            additionalError = "";
        }

        if (titleError || fnameError || lnameError || phonennoError || emailError || organationError || groupsizeError || participateError || reasonsError || additionalError
        ) {
            this.setState({
                titleError, fnameError, lnameError, phonennoError, emailError, organationError, groupsizeError, participateError, reasonsError, additionalError,
            });
            return false;
        }
        return true;
    };
    handleClose = () => {
        this.setState({
            setOpen: false
        })
    };

    clearState() {
        console.log("hit")
        this.setState({
            title: "",
            fname: "",
            lname: "",
            phoneno: "",
            email: "",
            organization: "",
            groupOrganization: "",
            GroupSize: "",
            ParticipantNames: "",
            Reasontour: "",
            Additionalinfo: "",
            titleError: "",
            fnameError: "",
            lnameError: "",
            phonennoError: "",
            emailError: "",
            organationError: "",
            groupsizeError: "",
            participateError: "",
            reasonsError: "",
            additionalError: "",
        })
    }

    SubmitHandleForm = async (e) => {
        e.preventDefault()
        const isValid = this.validate()
        if (isValid) {
            this.setState({
                loader: true
            })

            let data = {
                title: this.state.title,
                first_name: this.state.fname,
                last_name: this.state.lname,
                mobile: this.state.phoneno,
                email: this.state.email,
                organization: this.state.organization,
                group_organization: this.state.groupOrganization,
                group_size: this.state.GroupSize,
                participant_names: this.state.ParticipantNames,
                reason: this.state.Reasontour,
                info: this.state.Additionalinfo
            }
            await Schedulevisit(data)
                .then(response => {
                    console.log("response", response)
                    this.setState({
                        message: response.message,
                        loader: false,
                        setOpen: true,
                        status: response.status
                    })
                }).catch(err => {
                    console.log("error for the submit form", err)
                    this.setState({
                        loader: false,
                        message: err,
                        status: 0,
                        setOpen: true,
                    })
                })
            this.clearState()
        }
    }

    render() {
        return (
            <>
                <div className="mt-73 submit-idea">
                    <div className="submit-idea-title headingTop py-5">
                        <div className="container">
                            <div className="heading-inner">
                                <h1>Schedule a visit</h1>
                            </div>
                        </div>
                    </div>
                    <div className="reqest-tour submit-ideaForm mt-md-3 py-3 py-sm-5 mb-5">
                        <div className="container">
                            <form onSubmit={this.SubmitHandleForm}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="request-heading">
                                            <h3>Individual Information</h3>
                                            <p className="d-none d-md-block">Please provide us with you information</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="title">Title {this.state.titleError == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                    <input type="text" onChange={this.HandleChange} className="form-control" name="title" value={this.state.title} />
                                                    <div className="errorMsg">
                                                        {this.state.titleError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="fname">First Name {this.state.fnameError == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                    <input type="text" onChange={this.HandleChange} className="form-control" name="fname" value={this.state.fname} />
                                                    <div className="errorMsg">
                                                        {this.state.fnameError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="lname">Last Name {this.state.lnameError == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                    <input type="text" onChange={this.HandleChange} className="form-control" name="lname" value={this.state.lname} />
                                                    <div className="errorMsg">
                                                        {this.state.lnameError}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="phoneno">Phone Number {this.state.phonennoError == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                    <input type="number" onChange={this.HandleChange} className="form-control" name="phoneno" value={this.state.phoneno} />
                                                    <div className="errorMsg">
                                                        {this.state.phonennoError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="email">Email Address {this.state.emailError == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                    <input type="email" onChange={this.HandleChange} className="form-control" name="email" value={this.state.email} />
                                                    <div className="errorMsg">
                                                        {this.state.emailError}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="organization">Organization {this.state.organationError == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                    <input type="text" onChange={this.HandleChange} className="form-control" name="organization" value={this.state.organization} />
                                                    <div className="errorMsg">
                                                        {this.state.organationError}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="request-heading">
                                            <h3>Group Information</h3>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="groupOrganization">Organization {this.state.emailError == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                    <input type="text" onChange={this.HandleChange} className="form-control" name="groupOrganization" value={this.state.groupOrganization} />
                                                    <div className="errorMsg">
                                                        {this.state.emailError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="GroupSize">Group Size {this.state.groupsizeError == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                    <input type="text" onChange={this.HandleChange} className="form-control" name="GroupSize" value={this.state.GroupSize} />
                                                    <div className="errorMsg">
                                                        {this.state.groupsizeError}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="ParticipantNames">Participant Names {this.state.participateError == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                    <textarea onChange={this.HandleChange} className="form-control" rows="7" name="ParticipantNames" value={this.state.ParticipantNames}></textarea>
                                                    <div className="errorMsg">
                                                        {this.state.participateError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="Reasontour">Reason for the tour {this.state.reasonsError == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                    <textarea onChange={this.HandleChange} className="form-control" rows="7" name="Reasontour" value={this.state.Reasontour}></textarea>
                                                    <div className="errorMsg">
                                                        {this.state.reasonsError}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="Additionalinfo">Additional information {this.state.additionalError == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                    <textarea onChange={this.HandleChange} className="form-control" rows="7" name="Additionalinfo" value={this.state.Additionalinfo}></textarea>
                                                    <div className="errorMsg">
                                                        {this.state.additionalError}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group text-center mt-4">
                                                    <button className="btn btn-warning" type="submit">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {this.state.loader && this.state.loader &&
                                <Loader />
                            }
                        </div>
                    </div>
                </div>
                <AlertDialog
                    open={this.state.setOpen}
                    close={this.handleClose}
                    status={this.state.status}
                    message={this.state.message}
                />
                <Footer />

            </>
        )
    }
}

export default Schedulewithus