import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import theBulbLogoDark from "../../Assets/img/the-bulb-logo-dark.png";
import database from "../../Assets/img/database.png";
import userGroup from "../../Assets/img/user-group.png";
import ladies from "../../Assets/img/ladies-2.png";
import mvp from "../../Assets/img/mvp.png";
import Navbar from "../../Container/Navbar";
import Footer from "../../Container/Footer";
import "./StartupAcademy.css";
import CourseCard from "../../Components/Card/Cards/CourseCard";
import BgImg from "../../Assets/img/hat.png";
import Button from "../../Components/Button/Button";

const StartupAcademy = () => {
  useEffect(() => {
    // document.getElementById("navTop").style.display = "none";
    // const items = document.getElementsByClassName("nav-link");
    // for (let i = 0; i < items.length; i++) {
    //   items[i].style.color = "#10152c";
    // }
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  });

  return (
    <>
    <Helmet>
      <title>Academy for Startup Founders | The Bulb Africa </title>

      <meta name="title" content="Academy for Startup Founders | The Bulb Africa " />
      <meta name="description" content="Enroll in our training programs geared toward founders and taught by industry experts. Data analytics for startups, MVP, & building the right culture & team. " />
    </Helmet>
      <div className="fixed-top">
        <Navbar navImage={theBulbLogoDark} isLight={true} />
      </div>
      <div className="startup-hero">
        <div className="startup-hero-text">
          <h2
            data-aos="fade-right"
            data-aos-duration="500"
            className="startup-heading"
          >
            Build the Startup of your Dreams
          </h2>
          <p
            data-aos="fade-right"
            data-aos-duration="800"
            className="startup-para"
          >
            Get trained by expert facilitators and get equipped with the right
            knowledge, skills, and resources to overcome challenges and scale
            your Startup
          </p>
          <Button
            data-aos="zoom-in"
            data-aos-duration="1000"
            isHyperLink="true"
            location="https://forms.gle/Kt9xGiiW371ziz4k7"
            cta="Get started"
          />
        </div>
        <div className="startup-img">
          <img
            data-aos="fade-left"
            data-aos-duration="500"
            className="ladies"
            src={ladies}
            alt=""
          />
        </div>
      </div>
      <div id="strtaca" className="courses-details">
        <h2 className="course-heading">
          Specially curated courses designed to help you navigate&nbsp;
          <span className="africa" data-aos="fade-in" data-aos-duration="1000">
            Africa’s tech
          </span>
          &nbsp; ecosystem.
        </h2>
        <CourseCard
          logo={database}
          courseName="Data Analytics for Startups"
          courseInfo="Thanks to the ever-changing technology, data has become essential to the growth and sustainability of startups. In this course, you will learn how to use data to make critical business decisions that will set your startup on the right course. Discover ways to spot trends, improve customer experience, determine market viability, reveal hidden opportunities, and solve problems. You will also learn how to leverage data to generate revenue and improve brand awareness."
          duration={3}
        />
        <CourseCard
          logo={userGroup}
          courseName="Building the Right Team & Culture"
          courseInfo="Company culture changes in the shift from startup to scale up – and as the company moves and incorporates different people profiles and roles, culture becomes increasingly vital in ensuring that everyone understands the company´s mission, values, strategy, and objectives. This course will help you get that crucial culture piece in your startup. You will also discover the role and relationship between the company founder and the company culture to ensure a successful transition to scale up and gain insights on how and why to build and foster diverse teams."
          duration={2}
        />
        <CourseCard
          logo={mvp}
          courseName="Developing an MVP"
          courseInfo="Learn how to take ideas, validate them, and build the first version. This course shows you how to turn your idea into an MVP which is critical for every successful startup. Launching a new product is always tough, and you can never truly know if you are delivering the right thing at the right time. With this course, you will be able to effectively build a prototype that will allow you to test the reaction of your potential customers."
          duration={3}
        />
      </div>
      <div className="provide">
        <div className="background">
          <h3
            className="what-we-provide"
            data-aos="zoom-in"
            data-aos-duration="500"
          >
            We provide market-driven training guaranteed to accelerate growth
          </h3>
        </div>
        <p className="about-provide" data-aos="zoom-in" data-aos-duration="500">
          Our Academy is designed to support and empower founders through
          courses developed by some of Africa most successful entrepreneurs
        </p>
      </div>
      <div className="academy-main">
        <h2 className="academy-head">Our Academy Offers</h2>
        <ul className="academy-list">
          <li data-aos="fade-right" data-aos-duration="500">
            Exclusive well-researched courses{" "}
          </li>
          <li data-aos="fade-right" data-aos-duration="600">
            Expert facilitators who have founded or built startups{" "}
          </li>
          <li data-aos="fade-right" data-aos-duration="700">
            Flexible learning model{" "}
          </li>
          <li data-aos="fade-right" data-aos-duration="800">
            Fully virtual classes{" "}
          </li>
        </ul>
      </div>
      <div className="academy-bottom">
        <img src={BgImg} className="bg-img bg-img-1" />
        <h1
          className="academy-bottom-head"
          data-aos="fade-in"
          data-aos-duration="500"
        >
          Immersive learning experience to boost your startup
        </h1>
        <p
          className="academy-bottom-text"
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          Get trained by expert facilitators and get equipped with the right
          knowledge, skills, and resources to overcome challenges and scale your
          Startup
        </p>
        <Button
          rel="noreferrer noopenner"
          className="get-started"
          data-aos="zoom-in"
          data-aos-duration="500"
          isHyperLink="true"
          location="https://forms.gle/GvQ7hfrfSWMKmApr6"
          cta="Apply to our Academy"
        />
        <img src={BgImg} className="bg-img bg-img-2 " />
      </div>
      <Footer />
    </>
  );
};

export default StartupAcademy;
