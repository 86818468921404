import React from 'react';
import { Text, Box, Image} from "@chakra-ui/react"
import PlaceHolder from '../../Assets/img/placeholder.png';
import photo from '../../Assets/img/Adeyemi.png';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import './index.css';
import {useLocation} from 'react-router-dom';

function RelatedArticles({ articles }) {
	let path = "";
	let location = useLocation().pathname;
	console.log(location);
	if(location.includes("press")){
		path = "press"
	}
	else{
		path = "blog"
	}

	const renderArticles = () => {
		
		let article = articles.map((article,key) => {
			// console.log(article);
			return(
				<Link
					to={`/${path}/${article.id}`}
					className="mb-5"
				>
					<Box
						alignItems="center"
						height={{ base: '224px', md: '358px' }}
						justifyContent="center"
						display="flex"
						p={{ base: '5', md: '50px' }}
						width={{ sm: '90vw' }}
						className="recent-card"
						key={key}
					>
						<Box width="50%" px="10px" h="full" display="flex" flexDir="column" justifyContent="space-around">
							<Box
								mr="auto"
								maxW="255px"
								display="flex"
								alignItems="center"
								justifyContent={{ base: 'space-around', md: 'start' }}
							>
								{
									JSON.parse(article.tag).split(",").map((tag,key) => (
										<Text fontSize={{ base: '12px', md: '18px' }} mr={{ base: '5px', md: '50px' }} key={key}>
											{tag}
										</Text>
									))
								}
							</Box>
							<Box
								textAlign="left"
								min H={{ base: '60px', md: '80px' }}
								fontSize={{ base: '26px', md: '36px' }}
								color="#10162B"
								className="head-blog"
								dangerouslySetInnerHTML={{ __html: article?.heading }}
								lineHeight={{ base: '32px', md: '44px' }}
							>
							</Box>
							<Text textAlign="left" color="#9EA3B4" fontSize={{ base: '12px', md: '18px' }}>
								{format(new Date(article?.updated_at), 'do MMMM  yyyy')}
							</Text>
						</Box>
						<Box height="full" width="50%" borderRadius="4px">
							<Image
								fallbackSrc={PlaceHolder}
								borderRadius="20px"
								w="full"
								height="full"
								src={article?.picture?.split('"')?.[1]}
								alt="blog"
								objectFit="cover"
							/>
						</Box>
					</Box>
				</Link>
			)
		})
		return article;
	}

	return (
		
		// 	<Box w="100%" margin="10px 0 0 0">
		// 		<Box bg="#FFFCF4" borderRadius="20px" p="36px 48px">
		// 			<HStack
		// 				color="#28314F"
		// 				fontSize={{ base: '12px', md: '18px' }}
		// 				lineHeight={{ base: '15px', md: '22px' }}
		// 				spacing="55px"
		// 			>
		// 				<Text>Data</Text>
		// 				<Text>Tech</Text>
		// 				<Text>Design</Text>
		// 			</HStack>
		// 		</Box>
		// 	</Box>
		// </Box>
		<Box pos="relative" maxW="5xl" px="10px" mx="auto">
			<Text
				color="#10162B"
				fontWeight="bold"
				fontSize={{ base: '20px', md: '24px' }}
				lineHeight={{ base: '24px', md: '29px' }}
				mb="50px"
			> Related Articles </Text>
			{renderArticles()}
		</Box>
	);
}

export default RelatedArticles;
