import React, { useEffect, useState } from 'react'
// import story from '../../Assets/img/'
import quotation from '../../Assets/img/quotation.svg'
// import Steven from '../../Assets/img/steven.png'
import Footer from '../../Container/Footer'
// import { upcomingevent, eventsname } from '../../Const/data'
import Upcoming from '../../Components/Upcoming'
import Ourevent from '../../Components/Ourevents'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import ImageGallery from 'react-image-gallery';
import { connect } from 'react-redux'
// import { TheHubEvent } from '../../Action/thehub'
// import out from '../../Assets/img/thehub/out.png'
// import out1 from '../../Assets/img/thehub/out1.png'
// import out2 from '../../Assets/img/thehub/out2.png'
import { Helmet } from 'react-helmet';


const eventsname = [
    { id: 1, image: 'https://thebulb.africa/Images/thehub/Start%20ups.jpg', eventname: 'Start ups', eventdetails: 'The Startup Engine at The Bulb™ finds, builds and nurture startups like Peexoo, Avale and MySpec. Find out how to be next!', path: '/startups' },
    { id: 2, image: 'https://thebulb.africa/Images/thehub/Trainings.jpg', eventname: 'Trainings', eventdetails: 'We create experiential learning opportunities for incubatees, students and businesses with transferable and transportable skill sets for tomorrow’s leaders', path: '/training' },
    { id: 3, image: 'https://thebulb.africa/Images/thehub/resourses.jpg', eventname: 'Resources', eventdetails: 'We carefully select the very best resources from around the web. We’ve taken care to ensure the majority of these resources are free.', path: '/resources' },
    { id: 4, image: 'https://thebulb.africa/Images/thehub/Workspace.jpg', eventname: 'Workspace', eventdetails: 'Dedicated Desks,Fiber-Optic WiFi, Private Meeting Rooms and an Outdoor Lounge just a few reason to fall in love with “Desk @ The Bulb', path: '/coworkspace' },
    { id: 5, image: 'https://thebulb.africa/Images/thehub/Community.jpg', eventname: 'Community', eventdetails: 'Our developers have been trained tested and certified to deliver outstanding work on any IT project.', path: '/community' },
    { id: 6, image: 'https://thebulb.africa/Images/thehub/Partner%20with%20us.jpg', eventname: 'Partner with us', eventdetails: 'The Bulb works with a diverse group of partners who share our the success of entrepreneurs.', path: '/partner-with-us' },
]

const images = [
    {
        original: 'https://thebulb.africa/Images/thehub/out.webp',
        description:
            <div className="storyContent d-flex align-items-center justify-content-center flex-column text-center">
                <img src={quotation} alt="quotation" className="img-fluid" />
                <h4>Tara Bada</h4>
                <p className="desgnation">CEO, MySpec</p>
                <p>MySpec is a first of its kind dating application that blends online and offline events to provide used the best chance at finding “their spec”. Tara Bada has been working with the Tech since she moved to Nigeria to build the application.</p>
            </div>
    },
    {
        original: 'https://thebulb.africa/Images/thehub/out1.webp',
        description:
            <div className="storyContent d-flex align-items-center justify-content-center flex-column text-center">
                <img src={quotation} alt="quotation" className="img-fluid" />
                <h4>eVisit VMS</h4>
                <p className="desgnation">Enov8 Solutions Product</p>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet</p>
            </div>
    },
    {
        original: 'https://thebulb.africa/Images/thehub/out2.webp',
        description:
            <div className="storyContent d-flex align-items-center justify-content-center flex-column text-center">
                <img src={quotation} alt="quotation" className="img-fluid" />
                <h4>Lorem ipsum dolor sit amet</h4>
                <p className="desgnation">CEO, Peexoo</p>
                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet</p>
            </div>
    },
]
class Thehub extends React.Component {
    constructor() {
        super()
        this.state = {
            currenttab: 'whatdoHub'
        }
    }
    setCurrenttab = (name) => {
        this.setState({
            currenttab: name
        })
    }

    // componentDidMount() {
    //     document.getElementById("navTop").style.background = "#10152c";
    //     document.getElementById("navTop").style.display = "flex";
    //     window.addEventListener('scroll', this.handleScroll, true);
    //     const items = document.getElementsByClassName("nav-link");
    //     for (let i = 0; i < items.length; i++) {
    //         items[i].style.color = "#fff";
    //     }
    //     window.scrollTo(0, 0)
    //     // this.props.theHubevent()
    //     // console.log("this.props.theHubevent---", this.props.theHubevent)
    // }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    nav = React.createRef();

    handleScroll = () => {
        var lastScrollY = window.scrollY;
        if (lastScrollY > 1500) {
            this.setState({
                currenttab: 'ourStory'
            })
        }
        if (lastScrollY > 2080) {
            this.setState({
                currenttab: 'UpcomingEvents'
            })
        }
        if (lastScrollY < 1150) {
            this.setState({
                currenttab: 'whatdoHub'
            })
        }
    };

    render() {
        // const { theHubeventpost } = this.props
        // let loader = theHubeventpost.loading
        // console.log("theHubeventpost",theHubeventpost)
        // console.log("loader----------",loader)

        // let thehubpost = []
        // theHubeventpost.resp.length > 0 && theHubeventpost.resp.map((event) => {
        //     if (event.event_post == "HUB" || event.event_post == "ALL") {
        //         let data = {
        //             image: event.image_url,
        //             date: event.date,
        //             title: event.title,
        //             place: event.venue
        //         }
        //         thehubpost.push(data)
        //     }
        // })

        return (
            <>
                <Helmet>
                    <title>  The Hub – Find Out What We Do | The Bulb Africa    </title>
                    <meta name="title" content=" The Hub – Find Out What We Do | The Bulb Africa  " />
                    <meta name="description" content="Find out what we do. Start-up accelerator, tech education, co-workspace, useful resources, community, and partnership. Get involved today!  " />
                </Helmet>
                <div className="thehub" ref={this.nav}>
                    {/* <div className="fixed-tab position-fixed">
                        <div onClick={() => this.setCurrenttab('whatdoHub')} className={`fix-tab ${this.state.currenttab === 'whatdoHub' && "active-tab"}`} >
                            <AnchorLink offset='80' href='#whatdoHub'></AnchorLink>
                            <span className="tabtitle">What we do</span>
                        </div>
                        <div onClick={() => this.setCurrenttab('ourStory')} className={`fix-tab ${this.state.currenttab === 'ourStory' && "active-tab"}`}>
                            <AnchorLink offset='80' href='#ourStory'></AnchorLink>
                            <span className="tabtitle">Our Story</span>
                        </div>
                        <div onClick={() => this.setCurrenttab('UpcomingEvents')} className={`fix-tab ${this.state.currenttab === 'UpcomingEvents' && "active-tab"}`} >
                            <AnchorLink offset='80' href='#UpcomingEvents'></AnchorLink>
                            <span className="tabtitle">Upcoming Events</span>
                        </div>
                    </div> */}
                    <div className="thehub-inner">
                        <div className="thehub-bgslide">
                            <div className="hub-title d-flex align-items-center text-center justify-content-center flex-column">
                                <h1>Get Involved<br className="d-none d-md-block" /></h1>
                                <AnchorLink offset='80' href="#whatdoHub" className="text-decoration-none"><button className="btn btn-warning">Find out more <i className="fa fa-chevron-circle-down"></i></button></AnchorLink>
                            </div>
                        </div>
                        <div className="hub-whatdo pt-5 pb-3" id="whatdoHub">
                            <div className="container">
                                <div className="heading-hub">
                                    <h4>What we do</h4>
                                </div>
                                <div className="row">
                                    <Ourevent list={eventsname} />
                                </div>
                            </div>
                        </div>
                        {/* <div className="our-stories pb-3 pt-3" id="ourStory">
                            <div className="container">
                                <div className="heading-hub">
                                    <h4>Our stories</h4>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="ourStroyslider">
                                            <ImageGallery items={images}
                                                showBullets={true}
                                                showNav={false}
                                                autoPlay={true}
                                                showFullscreenButton={false}
                                                showPlayButton={false}
                                                showThumbnails={false}
                                                thumbnailPosition="bottom"
                                                showIndex={false}
                                                slideInterval={8000}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}


                        {/* <div className="upcoming-events pb-5 pt-5" id="UpcomingEvents">
                            <div className="container">
                                <div className="heading-hub">
                                    <h4>Join our upcoming events</h4>
                                </div>
                                <div className="row">
                                    <Upcoming list={thehubpost} />
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
                <Footer />

            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        // theHubeventpost: state.TheHubEvent
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // theHubevent: () => { dispatch(TheHubEvent()) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Thehub)


