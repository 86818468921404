import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/stable';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const colors = {
	bulbYellow: {
		50: '#fbbf19',
		100: '#fbc530',
		200: '#fccc47',
		300: '#fcd25e',
		400: '#fdd975',
		500: '#fddf8c',
		600: '#fbbf19',
		700: '#c99914',
		800: '#b08612',
		900: '#fde5a3'
	}
};
const theme = extendTheme({ colors });


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<ChakraProvider theme={theme}>
	<App />
	</ChakraProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
