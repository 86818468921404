import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../Container/Footer';
import Modal from '../../Components/Modal';
import { Api } from '../../Const/Api';
import { industries } from '../../Const/function';
import axios from 'axios';
import './index.css';
import { Box, Checkbox } from '@chakra-ui/react';

import { SVGHeader } from '../../Components/SVG/header';
import {Helmet} from 'react-helmet';

function Idea({ history }) {
	const [selectedCountry, setSelectedCountry] = useState();
	const [attachedFile, setAttachedFile] = useState({
		name: 'No file chosen',
		file: ' '
	});
	const [developerData, setDeveloperData] = useState({});
	const [Loading, setLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [modelItems, setModelItems] = useState({
		text: '',
		btnText: '',
		imgUrl: require('../../Assets/img/fail.png'),
		handleModal: ''
	});
	const [locations, setLocations] = useState({});

	useEffect(() => {
		document.getElementById('navTop').style.background = '#10152c';
		window.scrollTo(0, 0);
		axios.get(`${Api.paceBaseUrl}locations`).then(function (response) {
			if (response.status === 200) {
				setLoading(false);
				const result = response.data.data;
				const locations = result.reduce((c, v) => {
					c[v.country] = { states: v.states, countryCode: v.countryCode };
					return c;
				}, {});
				setLocations(locations);
			}
		});
	}, []);
	const handleModal = () => {
		setShowModal(false);
		history.push('/startups');
	};
	const closeModal = () => {
		setShowModal(false);
	};

	//handle country change
	const handleCountryChange = event => {
		setSelectedCountry({ selectedCountry: event.target.value });
	};

	const handleInputChange = e => {
		setDeveloperData({
			...developerData,
			[e.target.name]: e.target.value
		});
	};

	const handleFileSelect = e => {
		let file = e.target.files[0];
		setAttachedFile({
			name: file.name,
			file: e.target.files[0]
		});
	};

	//handle country select option
	const countries = Object.keys(locations).map((country, index) => (
		<option key={index} value={country}>
			{country}
		</option>
	));

	// //handle state select option
	const states = selectedCountry
		? locations[selectedCountry.selectedCountry].states.map(stateObj => (
				<option key={stateObj.id} value={stateObj.state}>
					{stateObj.state}
				</option>
		  ))
		: '';
	const industry = industries.map((industry, index) => (
		<option key={index} value={industry}>
			{industry}
		</option>
	));
	const handleSubmit = e => {
		setLoading(true);
		e.preventDefault();
		const payload = {
			...developerData,
			country: selectedCountry.selectedCountry,
			city: developerData.city,
			myFiles: attachedFile.file
		};

		const formData = new FormData();

		for (const field in payload) {
			formData.append(`${field}`, payload[field]);
		}
		axios
			.post(`${Api.BaseUrl}idea/save`, formData)
			.then(function (response) {
				console.log({ response });
				setLoading(false);
				setShowModal(true);
				if (response.status === 200) {
					return setModelItems({
						text: 'Your request to submit an idea has been successfully submitted, you will be contacted after a careful review of your application.',
						btnText: 'Home',
						imgUrl: require('../../Assets/img/success.svg'),
						handleModal
					});
				}
				return setModelItems({
					text: 'Hey, something went wrong with your submission. Please return to the form page and make sure all fields are completed and resubmit',
					btnText: 'Close',
					imgUrl: require('../../Assets/img/fail.png'),
					handleModal: closeModal
				});
			})
			.catch(error => {
				console.log({ error });
				setLoading(false);
				setShowModal(true);
				return setModelItems({
					text: 'Hey, something went wrong with your submission. Please return to the form page and make sure all fields are completed and resubmit',
					btnText: 'Close',
					imgUrl: require('../../Assets/img/fail.png'),
					handleModal: closeModal
				});
			});
	};

	return (
		<Box style={{ position: 'relative', paddingTop: '3em' }}>
			<Helmet>
					<title>  Submit an Idea |The Bulb Africa  </title>
					<meta name="title" content="Submit an Idea |The Bulb Africa " />
					<meta name="description" content="Got an idea? Let us help you transform your ideas into sustainable business realities, through our incubation and acceleration programs. " />
			</Helmet>
			<SVGHeader />
			<Box maxW="6xl" px={{ base: 0, md: '20px' }} mx="auto" style={{ position: 'relative' }} className="idea">
				<Box h="10px" mb="50px" cursor="pointer" textAlign="right" ml="auto" pos="relative">
					<svg
						style={{
							marginRight: '20px'
						}}
						onClick={history.goBack}
						className=" close"
						width="20"
						height="20"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M8.42358 7.82637L13.7071 13.1117C14.0979 13.5027 14.0976 14.1364 13.7064 14.527C13.3152 14.9177 12.6812 14.9174 12.2903 14.5264L7.0064 9.24065L1.70888 14.5273C1.31754 14.9179 0.683523 14.9174 0.292762 14.5263C-0.0979982 14.1352 -0.0975269 13.5015 0.293815 13.111L5.59092 7.82468L0.296044 2.52796C-0.0948298 2.13695 -0.094543 1.50329 0.296685 1.11263C0.687912 0.721973 1.32193 0.722259 1.71281 1.11327L7.0081 6.4104L12.2905 1.13879C12.6818 0.748246 13.3159 0.748717 13.7066 1.13984C14.0974 1.53096 14.0969 2.16463 13.7056 2.55517L8.42358 7.82637Z"
							fill="#050038"
						/>
					</svg>
				</Box>

				<div className="heading text-center mt-5 mb-5">Submit an idea</div>
				{showModal && (
					<Modal
						text={modelItems.text}
						btnText={modelItems.btnText}
						imgUrl={modelItems.imgUrl}
						handleModal={modelItems.handleModal}
					/>
				)}
				<div className="container bg-img py-5">
					<form className="p-md-5 p-3  mx-lg-5 mx-md-3" onSubmit={handleSubmit}>
						<div className="row  flex-column flex-lg-row">
							<div className="form-group mt-3 col-lg-6">
								<div className="required">*</div>
								<label htmlFor="title" className="pl-1 pr-2">
									Title
								</label>
								<input
									type="text"
									className="form-control"
									id="title"
									name="title"
									required
									onChange={handleInputChange}
								/>
							</div>
							<div className="form-group mt-3 col-lg-6">
								<div className="required">*</div>
								<label htmlFor="first_name" className="pl-1 pr-2">
									First Name
								</label>
								<input
									type="text"
									className="form-control"
									name="first_name"
									id="first_name"
									required
									onChange={handleInputChange}
								/>
							</div>
						</div>
						<div className="row  flex-column flex-lg-row">
							<div className="form-group mt-3 col-lg-6">
								<div className="required">*</div>
								<label htmlFor="last_name" id="last_name" className="pl-1 pr-2">
									Last Name
								</label>
								<input
									type="text"
									className="form-control"
									name="last_name"
									id="last_name"
									required
									onChange={handleInputChange}
								/>
							</div>
							<div className="form-group mt-3 col-lg-6">
								<div className="required">*</div>
								<label htmlFor="phone" className="pl-1 pr-2">
									Phone number
								</label>
								<input
									type="tel"
									pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
									maxLength={14}
									className="form-control"
									name="phone"
									id="phone"
									required
									onChange={handleInputChange}
								/>
							</div>
						</div>
						<div className="row  flex-column flex-lg-row">
							<div className="form-group mt-3 col-lg-6">
								<div className="required">*</div>
								<label htmlFor="email" className="pl-1 pr-2">
									Email address
								</label>
								<input
									type="email"
									className="form-control"
									name="email"
									id="email"
									required
									onChange={handleInputChange}
								/>
							</div>
							<div className="form-group mt-3 col-lg-6">
								<div className="required">*</div>
								<label htmlFor="country" className="pl-1 pr-2">
									Country
								</label>
								<select
									name="country"
									className="form-control"
									id="country"
									required
									onChange={handleCountryChange}
								>
									<option>Select a Country</option>

									{countries}
								</select>
							</div>
						</div>
						<div className="row  flex-column flex-lg-row">
							<div className="form-group mt-3 col-lg-6">
								<div className="required">*</div>
								<label htmlFor="city" className="pl-1 pr-2">
									State
								</label>
								<select
									name="city"
									className="form-control"
									id="city"
									required
									onChange={handleInputChange}
								>
									<option>Select a State</option>
									{states}
								</select>
							</div>
							<div className="form-group mt-3 col-lg-6">
								<div className="required">*</div>
								<label htmlFor="industry" className="pl-1 pr-2">
									Industry
								</label>
								<select
									name="industry"
									className="form-control"
									id="industry"
									required
									onChange={handleInputChange}
								>
									<option value=""></option>
									{industry}
								</select>
							</div>
						</div>
						<div className="row  flex-column flex-lg-row">
							<div className="form-group mt-3 col-lg-6">
								<div className="required-non">*</div>
								<label htmlFor="company_url" className="pl-1 pr-2">
									Company URL
								</label>
								<input
									type="url"
									className="form-control"
									name="company_url"
									id="company_url"
									onChange={handleInputChange}
								/>
							</div>
							<div className="form-group mt-3 col-lg-6">
								<div className="required-non">*</div>
								<label htmlFor="linkedin_url" className="pl-1 pr-2">
									LinkedIn URL
								</label>
								<input
									type="url"
									className="form-control"
									name="linkedin_url"
									id="linkedin_url"
									onChange={handleInputChange}
								/>
							</div>
						</div>
						<div className="row  flex-column flex-lg-row">
							<div className="form-group mt-3 col-lg-6">
								<div className="required-non">*</div>
								<label htmlFor="twitter_url" className="pl-1 pr-2">
									Twitter URL
								</label>
								<input
									type="url"
									className="form-control"
									name="twitter_url"
									id="twitter_url"
									onChange={handleInputChange}
								/>
							</div>
							<div className="form-group mt-3 col-lg-6">
								<div className="required">*</div>
								<label htmlFor="business_stage" className="pl-1 pr-2">
									Business stage
								</label>
								<select
									name="business_stage"
									className="form-control"
									id="business_stage"
									required
									onChange={handleInputChange}
								>
									<option value=""></option>
									<option value="Budding">Budding</option>
									<option value="MVP/Early">MVP/Early</option>
									<option value="Established">Established</option>
								</select>
							</div>
						</div>
						<div className="form-group mt-3 ">
							<div className="required-non">*</div>
							<label htmlFor="product_idea" className="pl-1 pr-2 " id="reason">
								Tell us about your Product/Idea
							</label>
							<textarea
								name="product_idea"
								id="product_idea"
								maxLength={2000}
								className="form-control "
								onChange={handleInputChange}
							></textarea>
							<div className="ml-3 mt-1 text-xs">(2000 characters max)</div>
						</div>
						<div style={{ background: 'white' }} className="row align-items-end">
							<div className="file-upload form-group mt-3 mb-5 pb-5">
								<div className="text mb-4 mt-2 ml-4 ">Attach a presentation (Pdf format)</div>
								<input
									style={{
										position: 'absolute',
										zIndex: '-9999'
									}}
									type="file"
									id="actual-btn"
									// className="d-none"
									accept="application/pdf"
									name="attachment"
									required
									onChange={handleFileSelect}
								/>
								<label for="actual-btn">
									<img
										src={require('../../Assets/img/file-upload.png')}
										alt=" file upload"
										className="mr-1"
									/>{' '}
									Upload file
								</label>
							</div>
							<div className="pl-4 mb-">{attachedFile.name}</div>
						</div>
						<Box className="  pt-5 ">
							{/* <input type="checkbox" name="accept" id="accept" required className="mt-1 mr-2" /> */}
							<Checkbox
								w="full"
								name="accept"
								id="accept"
								required
								className="mt-1"
								isRequired
								defaultIsChecked
								alignItems="start"
							>
								<Box
									style={{
										lineHeight: '186%',
										marginTop: '-11px'
									}}
								>
									{' '}
									I/We hereby confirm that I/We have read{' '}
									<Link
										style={{
											color: '#106ebe'
										}}
										to="/terms-and-condition"
									>
										the Terms and Conditions{' '}
									</Link>{' '}
									of The Bulb Africa and agree
								</Box>
							</Checkbox>
						</Box>
						<button className="btn button  mb-5  p-2" disabled={Loading}>
							{' '}
							Submit
							{Loading && (
								<div className="spinner-grow sm bg-white ml-1 " role="status">
									<span className="sr-only">Loading...</span>
								</div>
							)}
						</button>
					</form>
				</div>
			</Box>
			<Footer />
		</Box>
	);
}

export default Idea;
