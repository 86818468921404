import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import Slider from "react-slick";

import Navbar from "../../Container/Navbar";
import Footer from "../../Container/Footer";
import bulbLogo from "../../Assets/img/the-bulb-logo-dark.png";
import gears from "../../Assets/img/gears.png";
import card1 from "../../Assets/img/image 111.png";
import card2 from "../../Assets/img/image 112.png";
import card3 from "../../Assets/img/image 113.png";
import card4 from "../../Assets/img/image 114.png";
import smallerImg from "../../Assets/img/p-d-mobile.png";
import widerImg from "../../Assets/img/p-d-desktop.png";
import planning from "../../Assets/img/coffee-laptop.png";
import requirement from "../../Assets/img/flowchart.png";
import design from "../../Assets/img/mac-iphone.png";
import development from "../../Assets/img/developer.png";
import testing from "../../Assets/img/testing.png";
import devops from "../../Assets/img/devops.png";
import pace from "../../Assets/img/pace.png";
import maintenance from "../../Assets/img/maintenance.png";
import comercio from "../../Assets/img/comercio.png";
import evisit from "../../Assets/img/evisit.png";
import cscs from "../../Assets/img/cscs.png";
import compass from "../../Assets/img/compass.png";
import tradefi from "../../Assets/img/tradefi.png";
import settlme from "../../Assets/img/settl-me.png";
import bridgepay from "../../Assets/img/bridgepay.png";
import propty from "../../Assets/img/propty.png";
import "./ProductDevelopment.css";
import Button from "../../Components/Button/Button";

const ProductDevelopment = () => {
  const { innerWidth } = window
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // document.getElementById("navTop").style.display = "none";
    // const items = document.getElementsByClassName("nav-link");
    // for (let i = 0; i < items.length; i++) {
    //   items[i].style.color = "#10152c";
    // }

    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();

    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", () => {
        setWindowWidth(window.innerWidth);
      });
    };
  }, []);

  // Carousel Arrow Functions
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#606060",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "#606060",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  };
  const images = [
    { img: "pd-1" },
    { img: "pd-2" },
    { img: "pd-4" },
    { img: "pd-5" },
    { img: "pd-6" },
  ];
  const settings = {
    dots: true,
    className: "center",
    centerMode: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    infinite: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    easing: "linear",
    pauseOnHover: true,
    initialSlide: 0,
    focusOnSelect: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          nextArrow: false,
          prevArrow: false,
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: false,
          prevArrow: false,
          // fade: true,
        },
      },
    ],
  };
  return (
    <>
      <Helmet>
        {/*TITLE TAGS & META TAGS*/}

        <title>
          Build, Design and Launch Your Digital Products | The Bulb{" "}
        </title>

        <meta
          name="title"
          content="Build, Design and Launch Your Digital Products | The Bulb "
        />
        <meta
          name="description"
          content="Build best-in-class products with the top engineering talents in Africa. Build with us today. "
        />

        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content="Build, Design and Launch Your Digital Products | The Bulb "
        />
        <meta
          property="og:url"
          content="https://thebulb.africa/product-development"
        />
        <meta
          property="og:description"
          content="Build best-in-class products with the top engineering talents in Africa. Build with us today."
        />
        <meta
          property="og:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta property="og:image:alt" content="The Bulb Africa- offical logo" />

        {/* twitter meta tags */}
        <meta
          name="twitter:title"
          content="Build, Design and Launch Your Digital Products | The Bulb "
        />
        <meta
          name="twitter:description"
          content="Build best-in-class products with the top engineering talents in Africa. Build with us today."
        />
        <meta
          name="twitter:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta name="twitter:image:alt" content="The Bulb- offical logo" />
        <meta name="twitter:site" content="@TheBulbAfrica" />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <div className="fixed-nav">
        <Navbar navImage={bulbLogo} isLight={true} />
      </div>
      <div className="pro-dev-hero">
        <h1
          data-aos="fade-up"
          data-aos-duration="1000"
          className="pro-dev-hero-heading"
        >
          Build best-in-class products with the top engineering talents in
          Africa{" "}
        </h1>
        <p
          data-aos="fade-up"
          data-aos-duration="1000"
          className="pro-dev-hero-para"
        >
          We transform your fascinating ideas into leading-edge web and mobile
          applications.
        </p>
        <Button
          isHyperLink={true}
          data-aos-duration="1000"
          data-aos="zoom-in"
          location="https://docs.google.com/forms/d/e/1FAIpQLSdsQ6FNpFr85HH7QVc3IqYHdBqxXQcCZ_EvBSOVaXOYm3KRpw/viewform"
          isLight={true}
          // className="pro-dev-hero-cta"
          cta="Lets Build with you"
        />
      </div>
      <div className="pro-dev-sec-2">
        <div className="sec-2-text-box">
          <h2
            data-aos="fade-up"
            data-aos-duration="1000"
            className="sec-2-head"
          >
            We design, build, and launch effective digital solutions
          </h2>
          <p data-aos="fade-up" data-aos-duration="1000" className="sec-2-para">
            Get efficient software built on cutting-edge technology by elite
            talents on the continent. From strategy to deployment and support,
            we provide everything you need to own the best digital product in
            the market.
          </p>
        </div>
        <div className="sec-2-img-div">
          <img data-aos="fade-up" data-aos-duration="1000" src={gears} alt="" />
        </div>
      </div>
      <div className="pro-dev-sec-3">
        <div className="sec-3-text-box">
          <h2
            data-aos="fade-up"
            data-aos-duration="1000"
            className="sec-3-head"
          >
            End-to-end solutions so you never have to touch a line of code
          </h2>
          {/* <p data-aos='fade-up' data-aos-duration='1000' className="sec-3-para">End-to-end solutions so you never have to touch a line of code</p> */}
        </div>
        <div className="sec-3-card-section">
          <Cards
            source={card2}
            altText="human staring at screen"
            head="Software & Web Development"
            text="Using full-stack technology, we develop compelling and functional websites built to convert users to paying customers."
          />
          <Cards
            source={card4}
            altText="boxes stacked on each other"
            head="Product & Project Management"
            text="We provide thorough product and project management, ensuring timely delivery of your projects and providing support throughout its lifecycle."
          />
          <Cards
            source={card1}
            altText="hand prototyping"
            head="Mobile App Development"
            text="Engineering teams at The Bulb are versed in mobile development and build robust iOS and Android applications designed to provide the best user experiences."
          />
          <Cards
            source={card3}
            altText="Lady with tablet"
            head="App Maintenance & Support"
            text="From basic troubleshooting to bug management and training, we offer comprehensive support at multiple levels using ticket generation."
          />
        </div>
        <Button
          data-aos="fade-up"
          data-aos-duration="1000"
          className="sec-3-cta"
          location="https://docs.google.com/forms/d/e/1FAIpQLSdsQ6FNpFr85HH7QVc3IqYHdBqxXQcCZ_EvBSOVaXOYm3KRpw/viewform"
          cta="Build with us"
          isHyperLink={true}
        />
      </div>
      <div className="pro-dev-sec-4">
        <div className="sec-4-text-box">
          <h2
            data-aos="fade-up"
            data-aos-duration="1000"
            className="sec-4-head"
          >
            What we do
          </h2>
          <p data-aos="fade-up" data-aos-duration="1000" className="sec-4-para">
            Leveraging the power of agile methodology, we develop solutions
            through the collaborative effort of self-organizing and
            cross-functional teams.
          </p>
        </div>
        <img
          data-aos="fade-up"
          data-aos-duration="1000"
          style={{ marginBottom: 50 }}
          src={windowWidth <= 768 ? smallerImg : widerImg}
          alt=""
        />
        <Button
          data-aos="fade-up"
          data-aos-duration="1000"
          className="sec-3-cta"
          location="https://docs.google.com/forms/d/e/1FAIpQLSdsQ6FNpFr85HH7QVc3IqYHdBqxXQcCZ_EvBSOVaXOYm3KRpw/viewform"
          cta="Build with us"
          isHyperLink={true}
          style={{ marginBottom: 50 }}
        />
      </div>
      <div
        className="our-process"
        style={{ gap: window.innerWidth >= 805 ? "0" : "68px" }}
      >
        <h2 className="process-head">Our process</h2>
        <div className="process-cards">
          <Cards
            topMargin={window.innerWidth >= 805 && "68px"}
            height="693px"
            source={planning}
            altText="Laptop & mug"
            head="Initiation & Planning"
            text="We collaborate with you to learn more about your organization, target audience, and project goals. Then, we work closely with experts in sales, marketing, and other relevant fields to determine/confirm the user and market feasibility of the project and identify the associated risks. We ensure your confidentiality is not compromised by signing a Non-Disclosure Agreement document with you."
          />
          <Cards
            topMargin={window.innerWidth >= 805 && "68px"}
            height="693px"
            source={requirement}
            altText="flowchart on a notebook"
            head="Requirements Gathering"
            text="Using our standard Product Requirement Document (PRD), we thoroughly and clearly document the product requirements defining every aspect of the product to be designed and developed. This document is then shared with you for your review and approval to ensure that it matches your expectations."
          />
          <Cards
            topMargin={window.innerWidth >= 805 && "68px"}
            height="693px"
            marginTop="4rem"
            source={design}
            altText="Laptop & Mobile Phone"
            head="Design"
            text="Based on the approved product requirements document, our designers develop user flows, information architectures, and high-fidelity screens to create a usable product which is documented in a Design Document Specification (DSS) and presented to our clients for review and acceptance."
          />
          <Cards
            topMargin={window.innerWidth >= 805 && "68px"}
            height="693px"
            source={development}
            altText="Human writing code"
            head="Development"
            text="We put together a team of highly skilled software engineers tailored to the specific needs of your project. Our engineers translate your chosen design to source code and build a start-of-the-art product while strictly adhering to the specifications in the DDS document."
          />
          <Cards
            topMargin={window.innerWidth >= 805 && "68px"}
            height="693px"
            source={testing}
            altText="Human testing code"
            head="Testing"
            text="After your product has been developed, our quality assurance testers write a test script using the acceptance criteria document. They examine the product in the test environment to report and ensure fixes to any defects, certifying every function works correctly until the product reaches the standards defined in the SRS document."
          />
          <Cards
            topMargin={window.innerWidth >= 805 && "68px"}
            height="693px"
            source={devops}
            altText="devops illustration"
            head="Deployment"
            text="Once your product is tested, a User Acceptance Testing (UAT) is conducted. If the product matches your expectation and you sign off on it, our skilled DevOps deploys to the production environment, and formally the product is deployed to the market for users."
          />
          <Cards
            topMargin={window.innerWidth >= 805 && "68px"}
            height="693px"
            source={maintenance}
            altText="code on screen"
            head="Ongoing Maintenance & support"
            text="Even after your products are in your user’s hands, we will continue to provide support, offering bug fixes and other forms of development maintenance to ensure your product is seamless and sustainable."
          />
        </div>
      </div>
      <div className="portfolio">
        <div className="portfolio-text-box">
          <h2
            data-aos="fade-up"
            data-aos-duration="1000"
            className="portfolio-head"
          >
            Our Portfolio
          </h2>
          <p
            data-aos="fade-up"
            data-aos-duration="1000"
            className="portfolio-para"
          >
            We’ve represented everything from start-ups to large corporate
            organizations with that same partnership approach and dedication at
            the core of every engagement.
          </p>
        </div>
        <div className="portfolio-logos">
          <img
            data-aos="fade-in"
            data-aos-duration="2000"
            src={pace}
            alt="pace logo"
          />
          <img
            data-aos="fade-in"
            data-aos-duration="2000"
            src={comercio}
            alt="comercio logo"
          />
          <img
            data-aos="fade-in"
            data-aos-duration="2000"
            src={evisit}
            alt="evisit logo"
          />
          <img
            data-aos="fade-in"
            data-aos-duration="2000"
            src={cscs}
            alt="cscs logo"
          />
          <img
            data-aos="fade-in"
            data-aos-duration="2000"
            src={compass}
            alt="compass logo"
          />
          <img
            data-aos="fade-in"
            data-aos-duration="2000"
            src={tradefi}
            alt="tradefi logo"
          />
          <img
            data-aos="fade-in"
            data-aos-duration="2000"
            src={settlme}
            alt="settle.me logo"
          />
          <img
            data-aos="fade-in"
            data-aos-duration="2000"
            src={bridgepay}
            alt="bridgepay logo"
          />
          <img
            data-aos="fade-in"
            data-aos-duration="2000"
            src={propty}
            alt="propty logo"
          />
        </div>
      </div>

      <div
        style={{
          margin: " 0 auto ",
          maxWidth: "1250px",
        }}
      >
        <Slider
        style={{margin:'0 40px'}}
         {...settings}>

          {images.map((x, i) => {
            return (
              <div key={i} id="img-card">
                <img
                  id="img"
                  src={require(`../../Assets/img/carousel-${x.img}.jpg`)}
                />
              </div>
            );
          })}
        </Slider>
      </div>
      <div id="cbtn">
        <Button
          data-aos="fade-up"
          data-aos-duration="1000"
          className="sec-3-cta cbtn"
          location="https://dribbble.com/TheBulbAfrica"
          cta="View our Portfolio"
          isHyperLink={true}
        />
      </div>

      <div className="goodbye">
        <div className="goodbye-text">
          <h3
            data-aos="fade-up"
            data-aos-duration="1000"
            className="goodbye-head"
          >
            Your product is our priority
          </h3>
          <p
            data-aos="fade-up"
            data-aos-duration="1000"
            className="goodbye-para"
          >
            Whether you need a new product from scratch or require our expertise
            mid-stage, we are committed to creating top-class products for you.
          </p>
          <a
            data-aos="fade-in"
            data-aos-duration="1000"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdsQ6FNpFr85HH7QVc3IqYHdBqxXQcCZ_EvBSOVaXOYm3KRpw/viewform"
            target="_blank"
            className="pro-dev-hero-cta"
          >
            Tell us about your project
          </a>
        </div>
      </div>
      <Footer />
    </>
  );
};

const Cards = (props) => (
  <>
    <div
      data-aos="fade-up"
      data-aos-duration="1000"
      style={{
        marginTop: props.topMargin || 0,
        maxWidth: "377px",
        color: "#050038",
        height: props.height || "486px",
        borderRadius: "12px",
        backgroundColor: "#fff",
      }}
    >
      <img
        style={{ borderRadius: "12px 12px 0 0" }}
        src={props.source}
        alt={props.altText}
      />
      <div
        style={{
          margin: `${props.marginTop || "39px"} 20px 0`,
          display: "flex",
          gap: "22px",
          flexDirection: "column",
        }}
      >
        <h3 style={{ fontSize: "22px", fontWeight: "700" }}>{props.head}</h3>
        <p style={{ lineHeight: "32px" }}>{props.text}</p>
      </div>
    </div>
  </>
);

export default ProductDevelopment;
