import React from 'react'
const Traningcard = ({ list }) => {
    return (
        <>
            {
                list.map((user) => {
                    return (
                        <div key={user.id} className="col-md-4 col-sm-6">
                            <div className="course-inner position-relative">
                                <div className="course-image">
                                    <img src={user.image} alt="Front-end development" className="img-fluid w-100" />
                                </div>
                                <div className="course-headings position-absolute">
                                    <h4>{user.coursename}</h4>
                                    <p>{user.duration}</p>
                                    <ul>
                                        {user.coursecontent.length > 0 && user.coursecontent.map((coursecontent) => {
                                            return <li>{coursecontent}</li>
                                        })}
                                    </ul>
                                    <button className="btn btn-warning">Comming soon</button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default Traningcard