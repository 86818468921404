import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Footer from "../../Container/Footer";
import NoJob from "../../Components/NoContent";
import { Api } from "../../Const/Api";
import "./index.css";
import { Box, Flex } from "@chakra-ui/react";
import { SVGHeader } from "../../Components/SVG/header";
import { Helmet } from "react-helmet";
import Button from "../../Components/Button/Button";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Container/Navbar";
import bulbLogo from "../../Assets/img/the-bulb-logo-dark.png";
import IncubatorCards from "../StartupEngine/Incubator/components/IncubatorCards/IncubatorCards";
import training from "../../Assets/img/training.png";
import account from "../../Assets/img/account.png";
import project from "../../Assets/img/project.png";
import operations from "../../Assets/img/operations.png";
import questionMark from "../../Assets/img/question-mark.png";
import contact from "../../Assets/img/contact-details.png";
import videoland from "../../Assets/img/videoland.png";
import people from "../../Assets/img/people.png";

function Career() {
  const [jobs, setJobs] = useState([]);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    // document.getElementById("navTop").style.background = "#10152c";
    // document.getElementById("navTop").style.display = "none";
    // const items = document.getElementsByClassName("nav-link");
    // for (let i = 0; i < items.length; i++) {
    //   items[i].style.color = "#10152c";
    // }
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
    axios.get(`${Api.paceBaseUrl}jobs`, {}).then(function (response) {
      setLoading(false);
      if (response.status === 200) {
        setJobs(response.data.data.jobs);
      }
    });
  }, []);
  const theBulbJobs = jobs.map((job, index) => {
    if (
      job.companyName === "The Bulb Africa" ||
      job?.company?.name === "The Bulb Africa"
    ) {
      return (
        <Link to={`/jobs/${job.slug}`} key={index}>
          <div className="job-card col-12 mt-5 p-lg-5 pt-5 pb-5 pl-3">
            <div className="role text-left">{job.title}</div>
            <Box
              display={{ base: "block", lg: "flex" }}
              className="detail-section text-left  mt-3 "
              px="20px"
            >
              <Flex
                mx={{ base: 0, lg: "20px" }}
                my="10px"
                justifyContent={{ base: "start", lg: "center" }}
                align="center"
                className="location"
              >
                <svg
                  style={{ marginRight: "5px" }}
                  width="14"
                  height="16"
                  viewBox="0 0 14 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0)">
                    <path
                      d="M11.1456 2.73992C10.296 1.06417 8.78682 0.0403906 7.10859 0.00126563C7.03695 -0.000421875 6.96485 -0.000421875 6.89318 0.00126563C5.21498 0.0403906 3.70583 1.06417 2.85615 2.73992C1.98766 4.4528 1.96389 6.5103 2.79257 8.24376L6.26422 15.5059C6.26578 15.5091 6.26733 15.5123 6.26895 15.5155C6.42169 15.8189 6.69532 16 7.00094 16C7.30653 16 7.58016 15.8189 7.73288 15.5155C7.73449 15.5123 7.73605 15.5091 7.73761 15.5059L11.2093 8.24376C12.0379 6.5103 12.0141 4.4528 11.1456 2.73992ZM7.00089 7.25002C5.91531 7.25002 5.03214 6.24067 5.03214 5.00002C5.03214 3.75936 5.91531 2.75002 7.00089 2.75002C8.08646 2.75002 8.96964 3.75936 8.96964 5.00002C8.96964 6.24067 8.08649 7.25002 7.00089 7.25002Z"
                      fill="#050038"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0">
                      <rect width="14" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Location: <span> &nbsp; {job.location} </span>
              </Flex>
              <Flex
                mx={{ base: 0, lg: "20px" }}
                my="10px"
                justifyContent={{ base: "start", lg: "center" }}
                align="center"
                className="location"
              >
                <svg
                  style={{ marginRight: "5px" }}
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.41797 9.05078H10.582V10.0527H7.41797V9.05078Z"
                    fill="#050038"
                  />
                  <path
                    d="M14.6032 10.0533H11.6367V10.5543C11.6367 10.8311 11.4008 11.0552 11.1094 11.0552H6.89062C6.59921 11.0552 6.36328 10.8311 6.36328 10.5543V10.0533H3.39684C2.715 10.0533 2.11198 9.64038 1.8961 9.0259L0 3.62109V14.5621C0 15.3908 0.709717 16.065 1.58203 16.065H16.418C17.2903 16.065 18 15.3908 18 14.5621V3.62149L16.1038 9.0259C15.888 9.64038 15.285 10.0533 14.6032 10.0533Z"
                    fill="#050038"
                  />
                  <path
                    d="M11.1102 1.03516H6.89149C6.01918 1.03516 5.30946 1.70939 5.30946 2.53809V3.03906H0.908203L2.89714 8.70832C2.96924 8.91327 3.17056 9.05078 3.39771 9.05078H6.36415V8.5498C6.36415 8.27296 6.60008 8.04883 6.89149 8.04883H11.1102C11.4017 8.04883 11.6376 8.27296 11.6376 8.5498V9.05078H14.604C14.8312 9.05078 15.0325 8.91327 15.1046 8.70832L17.0937 3.03906H12.6923V2.53809C12.6923 1.70939 11.9826 1.03516 11.1102 1.03516ZM6.36415 3.03906V2.53809C6.36415 2.26164 6.60049 2.03711 6.89149 2.03711H11.1102C11.4012 2.03711 11.6376 2.26164 11.6376 2.53809V3.03906H6.36415Z"
                    fill="#050038"
                  />
                </svg>
                Job Type: <span> &nbsp;{job.jobType} </span>
              </Flex>
              <Flex
                mx={{ base: 0, lg: "20px" }}
                my="10px"
                justifyContent={{ base: "start", lg: "center" }}
                align="center"
                className="location"
              >
                <svg
                  style={{ marginRight: "5px" }}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.125 1.25H13.375V0H12.125V1.25H3.875V0H2.625V1.25H1.875C0.841125 1.25 0 2.09113 0 3.125V14.125C0 15.1589 0.841125 16 1.875 16H14.125C15.1589 16 16 15.1589 16 14.125V3.125C16 2.09113 15.1589 1.25 14.125 1.25ZM14.75 14.125C14.75 14.4696 14.4696 14.75 14.125 14.75H1.875C1.53038 14.75 1.25 14.4696 1.25 14.125V5.875H14.75V14.125ZM14.75 4.625H1.25V3.125C1.25 2.78038 1.53038 2.5 1.875 2.5H2.625V3.75H3.875V2.5H12.125V3.75H13.375V2.5H14.125C14.4696 2.5 14.75 2.78038 14.75 3.125V4.625Z"
                    fill="#050038"
                  />
                  <path
                    d="M3.625 7.1875H2.375V8.4375H3.625V7.1875Z"
                    fill="#050038"
                  />
                  <path
                    d="M6.125 7.1875H4.875V8.4375H6.125V7.1875Z"
                    fill="#050038"
                  />
                  <path
                    d="M8.625 7.1875H7.375V8.4375H8.625V7.1875Z"
                    fill="#050038"
                  />
                  <path
                    d="M11.125 7.1875H9.875V8.4375H11.125V7.1875Z"
                    fill="#050038"
                  />
                  <path
                    d="M13.625 7.1875H12.375V8.4375H13.625V7.1875Z"
                    fill="#050038"
                  />
                  <path
                    d="M3.625 9.6875H2.375V10.9375H3.625V9.6875Z"
                    fill="#050038"
                  />
                  <path
                    d="M6.125 9.6875H4.875V10.9375H6.125V9.6875Z"
                    fill="#050038"
                  />
                  <path
                    d="M8.625 9.6875H7.375V10.9375H8.625V9.6875Z"
                    fill="#050038"
                  />
                  <path
                    d="M11.125 9.6875H9.875V10.9375H11.125V9.6875Z"
                    fill="#050038"
                  />
                  <path
                    d="M3.625 12.1875H2.375V13.4375H3.625V12.1875Z"
                    fill="#050038"
                  />
                  <path
                    d="M6.125 12.1875H4.875V13.4375H6.125V12.1875Z"
                    fill="#050038"
                  />
                  <path
                    d="M8.625 12.1875H7.375V13.4375H8.625V12.1875Z"
                    fill="#050038"
                  />
                  <path
                    d="M11.125 12.1875H9.875V13.4375H11.125V12.1875Z"
                    fill="#050038"
                  />
                  <path
                    d="M13.625 9.6875H12.375V10.9375H13.625V9.6875Z"
                    fill="#050038"
                  />
                </svg>
                Application deadline: <span> &nbsp;{job.closeDate} </span>
              </Flex>
            </Box>
            <p className="text-justify mt-4">{job.short_desc}</p>
          </div>
        </Link>
      );
    }
  });

  return (
    <>
      <div className="fixed-nav">
        <Navbar navImage={bulbLogo} isLight={true} />
      </div>
      <Helmet>
        {/*TITLE TAGS & META TAGS*/}
        <title>
          {" "}
          The Bulb Careers | Discover High Paying Tech Jobs in Africa{" "}
        </title>
        <meta
          name="title"
          content="The Bulb Careers | Discover High Paying Tech Jobs in Africa "
        />
        <meta
          name="description"
          content=" Join us as we revolutionize the African tech space. Take your next big career step. Explore The Bulb careers and apply today. "
        />
        {/* Open Graph meta tags */}

        <meta
          property="og:title"
          content="The Bulb Careers | Discover High Paying Tech Jobs in Africa "
        />
        <meta property="og:url" content="https://thebulb.africa/careers" />
        <meta
          property="og:description"
          content="Join us as we revolutionize the African tech space. Take your next big career step. Explore The Bulb careers and apply today."
        />
        <meta
          property="og:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta property="og:image:alt" content="The Bulb Africa- offical logo" />
        <meta property="og:type" content="website." />
        {/* twitter meta tags */}

        <meta
          name="twitter:title"
          content="The Bulb Careers | Discover High Paying Tech Jobs in Africa "
        />
        <meta
          name="twitter:description"
          content="Join us as we revolutionize the African tech space. Take your next big career step. Explore The Bulb careers and apply today."
        />
        <meta
          name="twitter:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta name="twitter:image:alt" content="The Bulb- offical logo" />
        <meta name="twitter:site" content="@TheBulbAfrica" />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <div className="career-hero">
        <h1
          className="career-hero-heading"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Make a global impact
        </h1>
        <h2
          className="career-hero-para"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Join us at The Bulb Africa to help train and equip the next generation
          of tech talents in Africa!
        </h2>
        <Button
          isHyperLink={true}
          location="#linked"
          cta="View Openings"
          isLight={false}
          self={true}
        />
      </div>
      <div className="t-a-l-main">
        <h2 data-aos="fade-up" data-aos-duration="500">
          Take a look
        </h2>
        <div className="t-a-l-body">
          <IncubatorCards
            icon={training}
            head="Training and professional growth"
            text="Help tech talents discover their true potential  "
          />
          <IncubatorCards
            icon={account}
            head="Entrepreneurial growth  "
            text="Incubate to accelerate businesses globally "
          />
          <IncubatorCards
            icon={project}
            head="Projects"
            text="Make an impact by working on projects designed to accelerate industries  "
          />
          <IncubatorCards
            icon={operations}
            head="Operations and Finance"
            text="Be the backbone for daily strategic and financial decisions  "
          />
          <IncubatorCards
            icon={questionMark}
            head="Community and Networking"
            text="Join, empower and build fast-paced tech communities "
          />
          <IncubatorCards
            icon={contact}
            head="Business Development"
            text="Be the reason for daily business growth and expansion.  "
          />
          <IncubatorCards
            icon={videoland}
            head="Media and Marketing"
            text="Build the brand. Have fun converting generating leads for the business."
          />
          <IncubatorCards
            icon={people}
            head="People and Co-working"
            text="Get more creative with industry experts. Revolutionize co-working spaces.  "
          />
        </div>
      </div>
      <div className="benefits">
        <h2
          className="benefits-head"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Our benefits
        </h2>
        <div>
          <ul className="benefits-main">
            <div className="benefits-text-1">
              <li data-aos="fade-up" data-aos-duration="1000">
                Competitive Salaries
              </li>
              <li data-aos="fade-up" data-aos-duration="1000">
                Health Insurance
              </li>
              <li data-aos="fade-up" data-aos-duration="1000">
                Paid Leave
              </li>
              <li data-aos="fade-up" data-aos-duration="1000">
                Parental Leave
              </li>
            </div>
            <div className="benefits-text-2">
              <li data-aos="fade-up" data-aos-duration="1000">
                Learning and Development
              </li>
              <li data-aos="fade-up" data-aos-duration="1000">
                Flexible Working Model
              </li>
              <li data-aos="fade-up" data-aos-duration="1000">
                Company Retreats
              </li>
            </div>
          </ul>
        </div>
      </div>
      <div className="c-footer">
        <p>
          We are constantly running programs to train tech enthusiasts and
          incubate entrepreneurs to grow and sustain their businesses.
        </p>
      </div>

      <Box id="linked" style={{ position: "relative", paddingTop: "3em" }}>
        <SVGHeader />
        <Box mb="150px" className="container text-center">
          {Loading ? (
            <div className="spinner-grow mt-5 mb-5" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div className="career  mt-5">
              <div
                className="heading mb-5 pb-5"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                See Openings
              </div>
              {jobs.length < 1 ? (
                <NoJob
                  fontSize="16px"
                  text="Oops, sorry there’s no job update up yet"
                />
              ) : (
                <>{theBulbJobs}</>
              )}
            </div>
          )}
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default Career;
