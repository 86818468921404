import React, { Component } from 'react'
import ArrowRight from '../../Assets/img/chevron-arrow.svg'

export default class Nojobfound extends Component {
    render() {
        return (
            <>
                <div className="notjobfound parallax">
                    <div className="notfound-inner min-vh-100 py-4 d-flex justify-content-center text-center align-items-center flex-column">
                        <h1 className="font-weight-bold large" style={{marginTop: "317px"}}>This job is no longer available</h1>
                        <p className="pb-3">You can check out other listings from our careers page.</p>
                        <a href="/careers" className="text-decoration-none"><button className="btn btn-warning text-white d-flex align-items-center px-5 py-2">Check Careers &nbsp; <img src={ArrowRight} alt="arrow-right" className="img-fluid" /></button></a>
                    </div>
                </div>
            </>
        )
    }
}