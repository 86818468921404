import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import "./CoWorkspace.css";
import Navbar from "../../Container/Navbar";
import bulbLogo from "../../Assets/img/the-bulb-logo-dark.png";
import Footer from "../../Container/Footer";

import Button from "../../Components/Button/Button";

const CoWorkspace = () => {
  useEffect(() => {
    // document.getElementById("navTop").style.display = "none";
    // const items = document.getElementsByClassName("nav-link");
    // for (let i = 0; i < items.length; i++) {
    //   items[i].style.color = "#10152c";
    // }
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  });

  const sponsors = [
    {
      source: "hugo",
      // link: 'https://hugotech.co/'
    },
    {
      source: "mkopa",
      // link: 'https://m-kopa.com/',
      ext: "jpg",
    },
    {
      source: "intelytics",
      // link: 'https://www.intelyticslimited.com/'
    },
  ];

  return (
    <>
      <Helmet>
        {/*TITLE TAGS & META TAGS*/}

        <title>Coworking Space in Lagos – Workstation for Rent|The Bulb </title>

        <meta
          name="title"
          content="Coworking Space in Lagos – Workstation for Rent|The Bulb "
        />
        <meta
          name="description"
          content="A workspace designed to help you grow ideas. A desk dedicated to helping your business grow and connect with others. Book a space at The Desk today! "
        />

        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content="Coworking Space in Lagos – Workstation for Rent|The Bulb "
        />
        <meta property="og:url" content="https://thebulb.africa/co-workspace" />
        <meta
          property="og:description"
          content="A workspace designed to help you grow ideas. A desk dedicated to helping your business grow and connect with others. Book a space at The Desk today! "
        />
        <meta
          property="og:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta property="og:image:alt" content="The Bulb Africa- offical logo" />

        {/* twitter meta tags */}
        <meta
          name="twitter:title"
          content="Coworking Space in Lagos – Workstation for Rent|The Bulb "
        />
        <meta
          name="twitter:description"
          content="A workspace designed to help you grow ideas. A desk dedicated to helping your business grow and connect with others. Book a space at The Desk today! "
        />
        <meta
          name="twitter:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta name="twitter:image:alt" content="The Bulb- offical logo" />
        <meta name="twitter:site" content="@TheBulbAfrica" />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <div className="fixed-nav">
        <Navbar navImage={bulbLogo} isLight={true} />
      </div>
      <div className="cw-hero">
        <h1
          className="cw-hero-heading"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Your space to build, innovate and collaborate
        </h1>
        <p className="cw-hero-para" data-aos="fade-up" data-aos-duration="500">
          The Desk is a functional shared office designed to inspire solutions
          and boost productivity
        </p>
        <Button
          isHyperLink={true}
          location="https://desk.africa/"
          cta="Book a space"
          isLight={false}
          self={false}
        />
      </div>
      <div className="cw-creative" data-aos="fade-up" data-aos-duration="500">
        <h2 className="cw-creative-heading">
          Creative workspaces for individuals and organizations
        </h2>
        <p className="cw-creative-para">
          Situated in the hearts of Lagos Island and Mainland, our flexible
          workspaces help you get more work done. Explore the new world of work
          and access our spaces when and how you need it per day, month, or
          year!
        </p>
      </div>
      <div className="cw-list-main">
        <h2
          className="cw-list-heading"
          data-aos="fade-up"
          data-aos-duration="500"
        >
          Why choose The Desk?
        </h2>
        <ul className="cw-list-text">
          <div className="cw-list-text1">
            <li data-aos="fade-right" data-aos-duration="500">
              Fiber-optic Wi-Fi
            </li>
            <li data-aos="fade-right" data-aos-duration="500">
              Uninterrupted power supply
            </li>
            <li data-aos="fade-right" data-aos-duration="500">
              Complimentary coffee
            </li>
            <li data-aos="fade-right" data-aos-duration="500">
              Board rooms
            </li>
            <li data-aos="fade-right" data-aos-duration="500">
              Meeting rooms
            </li>
          </div>
          <div className="cw-list-text2">
            <li data-aos="fade-left" data-aos-duration="500">
              Event spaces
            </li>
            <li data-aos="fade-left" data-aos-duration="500">
              Mail management
            </li>
            <li data-aos="fade-left" data-aos-duration="500">
              Kitchen space
            </li>
            <li data-aos="fade-left" data-aos-duration="500">
              Lounge areas
            </li>
            <li data-aos="fade-left" data-aos-duration="500">
              Convenient locations
            </li>
          </div>
        </ul>
        <Button
          isHyperLink={true}
          location="https://desk.africa/"
          cta="Apply for your desk"
          isLight={false}
          self={false}
        />
      </div>
      <div className="cw-contemporary">
        <div className="cw-contemporary-background">
          <h2
            className="cw-contemporary-heading"
            data-aos="zoom-in"
            data-aos-duration="500"
          >
            Contemporary architecture developed with you in mind
          </h2>
        </div>
        <p
          className="cw-contemporary-para"
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          Carefully furnished with timeless art pieces, The Desk was
          intentionally built to provide an atmosphere that enhances creativity
          and innovation.
        </p>
      </div>
      <div className="trusted">
        <h2
          className="trusted-heading"
          data-aos="fade-right"
          data-aos-duration="500"
        >
          Trusted by companies you trust
        </h2>
        <div className="trusted-imgss">
          {sponsors.map((image) => (
            <a href={image.link} target="_blank" rel="noreferrer noopenner">
              <img
                src={require(`../../Assets/img/${image.source}.${
                  image.ext ? image.ext : "png"
                }`)}
                alt={image.source}
                className="trusted-img"
                data-aos="zoom-in"
                data-aos-duration="1500"
              />
            </a>
          ))}
        </div>
      </div>
      <div className="cw-footer">
        <h2
          className="cw-footer-heading"
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          More than just a workspace
        </h2>
        <p
          className="cw-footer-para"
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          The Desk goes beyond shared offices. It is a community of
          entrepreneurs, founders, and creatives designed to ignite ideas and
          collaboration.
        </p>
        <a href="https://desk.africa/" className="cw-footer-button">
          Work at desk
        </a>
      </div>
      <Footer />
    </>
  );
};

export default CoWorkspace;
