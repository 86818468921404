import React from 'react'

const Individuals = () => {
    let traningcard = [
        { image: 'https://thebulb.africa/Images/Traning/block.jpg', coursename: 'Blockchain', duration: 'Coming soon....', content: ["HTML/CSS", "Javascript", "Haskell", "Solidity", "C++"] },
        { image: 'https://thebulb.africa/Images/Traning/machine.jpg', coursename: 'Machine Learning', duration: 'Coming soon....', content: ["Python/C++/R/Java", "Probability and Statistics", "Data Modeling & Evaluation", "ML Algorithms", "Distributed Computing"] },
        { image: 'https://thebulb.africa/Images/Traning/comp.jpg', coursename: 'CompTIA', duration: 'Coming soon....', content: ["Security", "Virtualization and cloud computing", "Operating systems", "Hardware and network troubleshooting", "Software troubleshooting"] },
        {
            image: 'https://thebulb.africa/Images/Traning/ethical.jpg', coursename: 'Ethical Hacking', duration: 'Coming soon....', content: ["Networking",
                "Linux",
                "Virtualization",
                "Security Concepts",
                "Wireless Technologies"]
        },
        {
            image: 'https://thebulb.africa/Images/Traning/businees.jpg', coursename: 'Business Analytics', duration: 'Coming soon....', content: ["SQL ",
                "Agile/Scrum",
                "Six Sigma",
                "Predictive analytics",
                "Prescriptive analytics"]
        },
        {
            image: 'https://thebulb.africa/Images/Traning/global.jpg', coursename: 'Global Business', duration: 'Coming soon....', content: ["Content Management Systems (CMS)",
                "IP Rights",
                "Networking",
                "Advanced problem-solving",
                "Public speaking"]
        },
    ]
    return (
        <div className="row">
            {
                traningcard.map((user) => {
                    return (
                        <div key={user.id} className="col-md-4 col-sm-6">
                            <div className="course-inner position-relative">
                                <div className="course-image">
                                    <img src={user.image} alt="Front-end development" className="img-fluid w-100" />
                                </div>
                                <div className="course-headings position-absolute">
                                    <h4>{user.coursename}</h4>
                                    <p>{user.duration}</p>
                                    <ul>
                                        {user.content.length > 0 && user.content.map((content) => {
                                            return <li>{content}</li>
                                        })}
                                    </ul>
                                    <button className="btn btn-warning">Comming soon</button>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}
export default Individuals
