import React, { useState } from "react";
import Axios from "axios";
import { Api } from "../Const/Api";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

export const Newsletter = () => {
  const [emailInput, setemailInput] = useState("");
  const toast = useToast();

  const [input, setInput] = useState({});
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  // const [showModal, setShowModal] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [Loading, setLoading] = useState(false);

  const handleCheck = (e) => {
    setInput({
      ...input,
      [e.target.name]: e.target.value,
    });
  };
//   const HandleSubmit = (e) => {
//     e.preventDefault();
//     setLoading(true);
//     const payload = {
//       section: "general",
//       username: input.username,
//       email: emailInput,
//       status: "subscribed",
//       questions: [
//         {
//           question1:
//             "Are you looking for tech opportunities as an individual or organisation?",
//           answer1: input.individual || "no",
//         },
//         {
//           question2: "Do you live in Nigeria?",
//           answer: input.nigerian || "no",
//         },
//         {
//           question3:
//             "Would you like to receive updates about our programs?  We currently have programs that help you upskill and accelerate your business",
//           answer: input.update || "no",
//         },
//       ],
//     };
//     Axios.post(`${Api.BaseUrl}newsletter/subscribe`, payload).then(function (
//       response
//     ) {
//       setLoading(false);

//       if (response.status === 200 || response.status === 201) {
//         setemailInput("");
//         setInput({});
//         onClose();

//         return toast({
//           position: "top",
//           duration: 1000,
//           isClosable: true,
//           render: (props) => (
//             <Box
//               {...props}
//               color="black"
//               border="0.2px solid #4774d53d"
//               mx="auto"
//               display={{ base: "block", md: "flex" }}
//               justifyContent="space-around"
//               py={3}
//               bg="white"
//               px="30px"
//             >
//               <Box
//                 mx={{ base: "auto", md: "30px" }}
//                 as="svg"
//                 width="34px"
//                 height="34px"
//                 viewBox="0 0 34 34"
//                 fill="none"
//               >
//                 <circle
//                   cx="17"
//                   cy="17"
//                   r="17"
//                   fill="#9AD191"
//                   fill-opacity="0.2"
//                 />
//                 <path
//                   d="M15.1267 22.7688C14.974 22.9172 14.7656 23 14.5492 23C14.3327 23 14.1243 22.9172 13.9716 22.7688L9.35901 18.3129C8.88033 17.8505 8.88033 17.1008 9.35901 16.6393L9.93657 16.0813C10.4154 15.6189 11.1907 15.6189 11.6694 16.0813L14.5492 18.8629L22.3306 11.3468C22.8094 10.8844 23.5855 10.8844 24.0634 11.3468L24.641 11.9048C25.1197 12.3671 25.1197 13.1167 24.641 13.5784L15.1267 22.7688Z"
//                   fill="#57B348"
//                   fill-opacity="0.6"
//                 />
//               </Box>
//               You’ve successfully subscribed to get updates on Blog stories
//               posted by The Bulb Africa
//             </Box>
//           ),
//         });
//       }
//     });
//   };
const HandleSubmit = async (e) => {
	e.preventDefault();
	setLoading(true);
	const payload = {
	  section: "general",
	  username: input.username,
	  email: emailInput,
	  status: "subscribed",
	  questions: [
		{
		  question1: "Are you looking for tech opportunities as an individual or organisation?",
		  answer1: input.individual || "no",
		},
		{
		  question2: "Do you live in Nigeria?",
		  answer: input.nigerian || "no",
		},
		{
		  question3: "Would you like to receive updates about our programs? We currently have programs that help you upskill and accelerate your business",
		  answer: input.update || "no",
		},
	  ],
	};
  
	try {
	  const response = await Axios.post(`${Api.BaseUrl}newsletter/subscribe`, payload);
  
	  setLoading(false);
  
	  if (response.status === 200 || response.status === 201) {
		setemailInput("");
		setInput({});
		onClose();
		toast({
		  position: "top",
		  duration: 1000,
		  isClosable: true,
		  render: (props) => (
			<Box
			  {...props}
			  color="black"
			  border="0.2px solid #4774d53d"
			  mx="auto"
			  display={{ base: "block", md: "flex" }}
			  justifyContent="space-around"
			  py={3}
			  bg="white"
			  px="30px"
			>
			  <Box
				mx={{ base: "auto", md: "30px" }}
				as="svg"
				width="34px"
				height="34px"
				viewBox="0 0 34 34"
				fill="none"
			  >
				<circle
				  cx="17"
				  cy="17"
				  r="17"
				  fill="#9AD191"
				  fillOpacity="0.2"
				/>
				<path
				  d="M15.1267 22.7688C14.974 22.9172 14.7656 23 14.5492 23C14.3327 23 14.1243 22.9172 13.9716 22.7688L9.35901 18.3129C8.88033 17.8505 8.88033 17.1008 9.35901 16.6393L9.93657 16.0813C10.4154 15.6189 11.1907 15.6189 11.6694 16.0813L14.5492 18.8629L22.3306 11.3468C22.8094 10.8844 23.5855 10.8844 24.0634 11.3468L24.641 11.9048C25.1197 12.3671 25.1197 13.1167 24.641 13.5784L15.1267 22.7688Z"
				  fill="#57B348"
				  fillOpacity="0.6"
				/>
			  </Box>
			  You’ve successfully subscribed to get updates on Blog stories posted by The Bulb Africa
			</Box>
		  ),
		});
	  }
	} catch (error) {
	  setLoading(false);
	  console.error("There was an error!", error);
	  if (error.response) {
		// Request made and server responded
		console.error("Error data:", error.response.data);
		console.error("Error status:", error.response.status);
		console.error("Error headers:", error.response.headers);
	  } else if (error.request) {
		// Request made but no response received
		console.error("Error request:", error.request);
	  } else {
		// Something happened in setting up the request
		console.error("Error message:", error.message);
	  }
	  toast({
		title: "An error occurred.",
		description: "Unable to subscribe. Please try again later.",
		status: "error",
		duration: 9000,
		isClosable: true,
	  });
	}
  };
  

  const handleShowModal = (e) => {
    console.log("here");
    e.preventDefault();
    // setShowModal(true);
    onOpen();
  };

  return (
    <>
    <div className="flex py-12 flex-col justify-center gap-6 items-center ">
      <h1 className="md:text-4xl text-xl font-bold ">
        Stay in the loop!
      </h1>
      <p className="text-[#E4E4E7] md:text-[18px] md:items-start text-center text-[12px]">
        Sign up for our newsletter to receive updates on exciting new features
        and product releases.
      </p>
      <form
        onSubmit={handleShowModal}
        className="flex md:flex-row flex-col justify-center gap-4 w-full "
      >
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Enter Email here.."
          onChange={(e) => setemailInput(e.target.value)}
          className="md:w-[400px] w-full py-[14px] rounded-full px-[40px] bg-white text-[#636262]"
        />
        <button
          type="submit"
          className="bg-[#FDBF17] text-center  md:w-[173px] w-full  px-[40px] py-[14px] rounded-full text-[#13162D]"
        >
          Subscribe
        </button>
      </form>
      <p className="text-[#E4E4E7] md:text-[18px] md:items-start text-center text-[12px]">
        Psst... We take your privacy seriously. Check out our
        <a href="#" className="underline px-2">
          Privacy Policy
        </a>
        to learn more. You can unsubscribe at any time
      </p>
    </div>
    <Modal
				isCentered
				initialFocusRef={initialRef}
				finalFocusRef={finalRef}
				isOpen={isOpen}
				onClose={onClose}
				size={'xl'}
				scrollBehavior="inside"
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Subscribe</ModalHeader>
					<ModalCloseButton />
					<ModalBody p={{ base: '20px', md: '50px' }}>
						<div className="bg-white  d-flex flex-column mx-auto">
							<div className="heading mb-3 pr-5">
								Thank you for subscribing!{' '}
								<span role="img" aria-label="emoji">
									🎉
								</span>
								<div className="mt-3 mb-10">
									There are just a few more details we need to ensure we tailor our newsletters to
									suit your interest
								</div>
							</div>

							<form className="pr-5" onSubmit={HandleSubmit}>
								<div className="form-group pb-3">
									<label htmlFor="full_name" className="pl-1 pr-2">
										Your full name
									</label>
									<input
										ref={initialRef}
										type="text"
										className="form-control"
										id="full_name"
										name="username"
										required
										onChange={handleCheck}
									/>
								</div>

								<div className="form-group pb-3">
									1. Are you looking for tech opportunities as an individual or organization?
									<div className="row justify-content-start col-lg-8 pt-2">
										<label htmlFor="individual" className="pl-1 pr-2 mr-3">
											<input
												type="checkbox"
												className="mr-2"
												id="individual"
												name="individual"
												checked={input.individual === 'yes'}
												value="yes"
												onChange={handleCheck}
											/>
											Individual
										</label>
										<label htmlFor="group" className="pl-1 pr-2">
											<input
												type="checkbox"
												className="mr-2"
												id="group"
												name="individual"
												checked={input.individual === 'no'}
												value="no"
												onChange={handleCheck}
											/>
											Organisation
										</label>
									</div>
								</div>

								<div className="form-group pb-3">
									2. Do you live in Nigeria?
									<div className="row justify-content-start col-lg-4 pt-2">
										<label htmlFor="nigerian" className="pl-1 pr-2 mr-3">
											<input
												type="checkbox"
												className="mr-2"
												id="nigerian"
												name="nigerian"
												checked={input.nigerian === 'yes'}
												value="yes"
												onChange={handleCheck}
											/>
											Yes
										</label>
										<label htmlFor="nigerian2" className="pl-1 pr-2">
											<input
												type="checkbox"
												className="mr-2"
												name="nigerian"
												id="nigerian2"
												checked={input.nigerian === 'no'}
												value="no"
												onChange={handleCheck}
											/>
											No
										</label>
									</div>
								</div>

								<div className="form-group pb-3">
									3. Would you like to receive updates about our programs? We currently have programs
									that help you upskill and accelerate your business
									<div className="row justify-content-start col-lg-4 pt-2">
										<label htmlFor="update" className="pl-1 pr-2 mr-3">
											<input
												type="checkbox"
												className="mr-2"
												id="update"
												name="update"
												checked={input.update === 'yes'}
												value="yes"
												onChange={handleCheck}
											/>
											Yes
										</label>
										<label htmlFor="updateNo" className="pl-1 pr-2">
											<input
												type="checkbox"
												className="mr-2"
												id="updateNo"
												name="update"
												checked={input.update === 'no'}
												value="no"
												onChange={handleCheck}
											/>
											No
										</label>
									</div>
								</div>

								<Button
									mb="50px"
									fontWeight="bold"
									lineHeight="22px"
									mx="auto"
									color="white"
									width="170px"
									h="56px"
									borderRadius="6px"
									fontSize="18px"
									style={{
										background: '#050038'
									}}
									type="submit"
									disabled={Loading}
								>
									Done
									{Loading && (
										<div className="spinner-grow ml-2" role="status">
											<span className="sr-only">Loading...</span>
										</div>
									)}
								</Button>
							</form>
						</div>
					</ModalBody>
				</ModalContent>
			</Modal>
    </>
  );
};
