export const footerData = [
  {
    label: "For Businesses",
    link: "#",
    children: [
      {
        label: "Corporate Tech Training",

        link: " https://thebulbafrica.institute/career-advance/",
        children: [
          { label: "Cybersecurity Awareness", link: "#" },
          { label: "AI for Everyone", link: "#" },
          { label: "Blockchain for Executives",link:"#" },
          { label: "Data Protection: GDPR/NDPR Employee Awareness",link:"#" },
          { label: "Agile and Scrum Fundamentals", link: "https://thebulbafrica.institute/scrum-master/" },
          { label: "Startup Academy", link: "https://thebulbafrica.institute/startup-academy/" }
        ],
      },
      {
        label: "Hire Our Talents",
        link: "https://forms.office.com/pages/responsepage.aspx?id=wg7I4WtJUkelNn433SW-piERclaYcBlNr7eo1ebdbtlURE9FVDFMMU5SMVdBTDU4TDIyR0k0VlFFSi4u",
      },
      {
        label: "Office Space",
        link: "/co-workspace",
      },
      {
        label: "Product Development",
        link: "/product-development",
      },
      {
        label: "Application Support and Maintenance",
        link: "#",
      },
      {
        label: "Products",
        link: "#",
        children: [
          { label: "Settl.me", link: "#" },
          { label: "Vecul", link: "#" },
          { label: "Odoo", link: "#" },
          { label: "Whitelabeled Solutions", link: "#" }
        ],
      },
    ],
  },
  {
    label: "For Talent",
    link: "#",
    children: [
      {
        label: "Tech training (Fellowship)",
      
        link: "#",
        children: [
          { label: "Fellowship", link: "#" },
          { label: "Career Advance", link: "#" },
          { label: "NextGen (Coding for Kids", link: "https://thebulbafrica.institute/next-gen/" },
          { label: "3MTT", link: "#" }
        ],
      },
      {
        label: "Find Opportunities",
        link: "/careers",
      },
      {
        label: "Develop for The Bulb",
      
        link: "#",
        children: [
          { label: "Join our Developer Community", link: "/our-communities" },
        ],
      },
      {
        label: "Co-Working Space",
        link: "https://desk.africa/",
      },
    ],
  },
  {
    label: "Events, Communities and Resources",
    link: "#",
    children: [
      {
        label: "Women in Tech",
        link: "https://t.me/+mHzrmmoAx9RkY2Q0",
      },
      {
        label: "Leverage at The Bulb",
        link: "https://leverageatthebulb.substack.com/",
      },
      {
        label: "Alumni Network",
        link: "https://discord.com/invite/v7Uzp9xvjg",
      },
      {
        label: "Int'l Women's Day Event",
        link: "/co-workspace",
      },
      {
        label: "Int'l Children's Day Event",
        link: "#",
      },
      {
        label: "Ebooks",
        link: "https://blog.thebulb.africa/resources/",
      },
      {
        label: "Substack",
        link: "https://leverageatthebulb.substack.com/",
      },
      {
        label: "Blog",
        link: "https://blog.thebulb.africa",
      },
      {
        label: "Newsletter",
        link: "https://blog.thebulb.africa/newsletter",
      },
      {
        label: "Success Stories",
        link: "https://www.youtube.com/playlist?list=PLKkWT_StFvTd7JC163elkl1F1OKdM1BuW",
      }
    ],
  },
  {
    label: "Why The Bulb",
    link: "#",
    children: [
      {
        label: "About Us",
        link: "/about-us",
      },
      {
        label: "Success Stories",
        link: "https://www.youtube.com/playlist?list=PLKkWT_StFvTd7JC163elkl1F1OKdM1BuW",
      },
      {
        label: "Life at The Bulb",
        link: "https://www.youtube.com/playlist?list=PLKkWT_StFvTdNzYrbFVAr2cmo5yD7TyrP",
      },
      {
        label: "Careers",
        link: "/careers",
      },
      {
        label: "Contact us",
        link: "/contact",
      },
    ],
  },
  {
    label: "Logins",
    link: "#",
    children: [
      {
        label: "For Students",
        link: "https://app.thebulbafrica.institute/login",
      },
      {
        label: "For Employees",
        link: "https://thebulb.seamlesshrms.com/",
      },
      {
        label: "For DSA",
        link: "https://forms.gle/XG7mAKev4YqvUkUR6",
      },
    ],
  },
];
