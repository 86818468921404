import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Commingsoon from "../../Container/CommingSoon";
import Formcontact from "../../Container/Formcontact";
import Nojobfound from "../../Container/Nojobfound";
import Recentpost from "../../Container/Recent";
import Aboutpage from "../../Pages/About";
// import ApplyIncubator from '../../Pages/ApplyForIncubation';
import { Home } from "../../Pages/Home/Home";
import Becomeadeveloper from "../../Pages/Commonpages/BecomeAdeveloper";
// import Faqs from '../../Pages/Footerpage/Faq'
import CareerExpended from "../../Pages/Commonpages/CareerExpended";
// import Community from '../../Pages/Commonpages/Community'
import Career from "../../Pages/Career";
import Job from "../../Pages/Career/SingleJob";
import Live from "../../Pages/Live";
import Hireadeveloper from "../../Pages/HireDeveloper";
import HireadeveloperForm from "../../Pages/HireDeveloper/Form";
import NotFound from "../../Pages/Commonpages/NotFound";
import Partnerwithus from "../../Pages/PartnerWithUs";
import PartnerWithUsForm from "../../Pages/PartnerWithUs/PartnerWithUsForm";
import Schedulewithus from "../../Pages/Commonpages/Schedulewithus";
import BlogPost from "../../Pages/SingleBlogPosts";
import PressPost from "../../Pages/PressPost";
import Startups from "../../Pages/Commonpages/Startups";
import SubmitIdea from "../../Pages/ApplyForIdea";
// import Testing from '../../Container/Testing'
import Traning from "../../Pages/Commonpages/Trainings";
import Contactpage from "../../Pages/Contact";
// import Resourses from '../../Pages/Footerpage/Resourses'
import Termsandcondition from "../../Pages/Footerpage/Termsandcondions";
// import Newsfeed from '../../Pages/Newsfeed';
import Pressdesciption from "../../Pages/Newsfeedpage/Pressdescription";
import Tagdescription from "../../Pages/Newsfeedpage/Tagdescription";
import Tour from "../../Pages/Tour";
import PhysicalVisit from "../../Pages/ScheduleAVisit";
import VirtualTour from "../../Pages/Tour/Virtual";
import Thehub from "../../Pages/Thehub";
import Dashboard from "../Dashboard";
import Header from "../Header";
import Navbar from "../../Container/Navbar";
import Community from "../../Pages/Community";
import AllCommunity from "../../Pages/Community/AllComuunity";
import { Helmet } from "react-helmet";
import Institute from "../../Pages/Institute/Institute";
import OurCommunities from "../../Pages/OurCommunities/OurCommunities";
import ExploreCourses from "../../Pages/ExploreCourses/ExploreCourses";
import StartupAcademy from "../../Pages/StartupAcademy/StartupAcademy";
import StartupEngine from "../../Pages/StartupEngine/StartupEngine";
import Incubator from "../../Pages/StartupEngine/Incubator/Incubator";
import HireOurTalents from "../../Pages/HireOurTalents/HireOurTalents";
import CoWorkspace from "../../Pages/CoWorkspace/CoWorkspace";
import ProductDevelopment from "../../Pages/ProductDevelopment/ProductDevelopment";
import PrivacyPolicy from "../../Pages/PrivacyPolicy/PrivacyPolicy";
// import { Hero } from "../../Pages/Home/hero/Hero";
const CHANNEL_ID = "UCY7cQnufm6qasQfXnSr5muw";
const API_KEY = "AIzaSyAeTdTso1twtLdgtRYiGHl_z4XqkbCqVnc";

const Navigation = () => {
  const [state, setstate] = useState(false);
  const [changetoggle, setChangetoggle] = useState(false);
  const [calenderchange, setCalenderchange] = useState(false);
  const [newsTab, setNewsTab] = useState("recent");
  const [isYouTubeLive, setIsYouTubeLive] = useState(false);

  useEffect(() => {
    const checkIfLive = async () => {
      const url = `https://www.googleapis.com/youtube/v3/search?part=snippet&channelId=${CHANNEL_ID}&type=video&eventType=live&key=${API_KEY}`;

      try {
        const response = await (
          await fetch(url, {
            method: "GET",
          })
        ).json();
        console.log(response);
        const isLive = response.items.length > 0;
        console.log(isLive);
        setIsYouTubeLive(isLive);
      } catch (error) {
        console.log(error);
      }
    };
    void checkIfLive();
  }, []);

  return (
    <Router>
      <div className="blue-fixed-nav">
        <Navbar
          isYouTubeLive={isYouTubeLive}
          closeAll={setstate}
          headerv={state}
          callcalenderselect={setCalenderchange}
          navImage={require("../../Assets/img/the-bulb-logo-white.png")}
          about="/about-us"
          showServices={true}
        />
      </div>
      <Switch>
        <Route
          exact
          path="/"
          component={Home}
          // component={() => (
          //   <>
          //     {/* <Helmet>
          //       <meta
          //         name="title"
          //         content="Africa's Leading Tech Talent Incubator | The Bulb"
          //       />
          //       <meta
          //         property="og:title"
          //         content="Africa's Leading Tech Talent Incubator | The Bulb"
          //       />
          //       <meta property="og:url" content="https://thebulb.africa" />
          //       <meta
          //         property="og:description"
          //         content="The Bulb™ is a platform for African creatives to turn tech ideas into business realities. We run accelerator programs, incubate entrepreneurs and provide tech training."
          //       />
          //       <meta property="og:type" content="website." />
          //       <meta
          //         property="og:image"
          //         content="https://thebulb.africa/Images/homepage/logo.png"
          //       />
          //       <meta property="og:image:alt" content="The Bulb Africa logo" />
          //       <meta
          //         name="twitter:title"
          //         content="Africa's Leading Tech Talent Incubator | The Bulb"
          //       />
          //       <meta
          //         name="twitter:description"
          //         content="The Bulb™ is a platform for African creatives to turn tech ideas into business realities. We run accelerator programs, incubate entrepreneurs and provide tech training."
          //       />
          //       <meta
          //         name="twitter:image"
          //         content="https://thebulb.africa/Images/homepage/logo.png"
          //       />
          //       <meta name="twitter:image:alt" content="The Bulb Africa logo" />
          //       <meta name="twitter:site" content="@TheBulbAfrica" />
          //       <meta name="twitter:card" content="summary" />
          //     </Helmet> */}
          //     {/* <Dashboard
          //       headerv={state}
          //       // changetype={changetoggle}
          //       traningselect={setChangetoggle}
          //       // calenderselect={calenderchange}
          //       callcalenderselect={setCalenderchange}
          //     /> */}
          //     <Home />
          //   </>
          // )}
        />
        {/* <Route exact path={`/:id`} component={CareerExpended} /> */}
        <Route path="/thehub" component={Thehub} />
        <Route path="/nav" component={Navbar} />
        {/* <Route
					path="/newsfeed"
					component={() => (
						<Newsfeed isYouTubeLive={isYouTubeLive} setNewsTab={setNewsTab} newsTab={newsTab} />
					)}
				/> */}
        <Route path="/about-us" component={Aboutpage} />
        <Route path={`/press-description/:id`} component={Pressdesciption} />
        <Route path={`/tag-description/:id`} component={Tagdescription} />
        <Route path="/contact" component={Contactpage} />
        <Route path="/tour" component={Tour} />
        <Route path="/live" component={Live} />
        <Route path="/virtual-tour" component={VirtualTour} />
        <Route path="/blog/:id" component={BlogPost} />

        <Route path="/press/:id" component={PressPost} />
        <Route path="/request-a-tour" component={Formcontact} />
        <Route path="/recent" component={Recentpost} />
        <Route path="/startups" component={Startups} />
        <Route path="/become-developer" component={Becomeadeveloper} />
        <Route path="/community" component={Community} />
        <Route path="/community-all" component={AllCommunity} />
        <Route
          path="/training"
          component={() => <Traning changetype={changetoggle} />}
        />
        <Route path="/careers" component={Career} />
        <Route path="/hire-developer/form" component={HireadeveloperForm} />
        <Route path="/hire-developer" component={Hireadeveloper} />
        <Route path="/partner-with-us/form" component={PartnerWithUsForm} />
        <Route path="/partner-with-us" component={Partnerwithus} />
        <Route path="/schedule-a-visit" component={PhysicalVisit} />
        <Route path="/resources" component={Commingsoon} />
        <Route path="/terms-and-condition" component={Termsandcondition} />
        {/* <Route path="/faqs" component={Commingsoon} /> */}
        <Route path={`/jobs/:id`} component={Job} />
        <Route path="/submit-an-idea" component={SubmitIdea} />
        <Route path="/apply-incubator" component={Incubator} />
        <Route path="/no-job-found" component={Nojobfound} />
        <Route path="/institute" component={Institute} />
        <Route path="/explore-courses" component={ExploreCourses} />
        <Route path="/startup-academy" component={StartupAcademy} />
        <Route path="/startup-engine" component={StartupEngine} />
        <Route path="/incubator" component={Incubator} />
        <Route path="/our-communities" component={OurCommunities} />
        <Route path="/hire-talents" component={HireOurTalents} />
        <Route path="/co-workspace" component={CoWorkspace} />
        <Route path="/product-development" component={ProductDevelopment} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="*" component={NotFound} />
        {/* <Route exact path="/hero" component={Hero} /> */}
      </Switch>
    </Router>
  );
};

export default Navigation;
