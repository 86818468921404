import React from 'react'
import { FacebookShareButton, InstapaperShareButton, TwitterShareButton, } from "react-share";


const Socialshare = (props) => {
    return (
        <ul className="share-post d-flex flex-wrap d-md-block mt-4 mt-md-0 list-inline mx-auto text-md-center">
            <p className="w-100">Share</p>
            <li className="mr-3 mr-md-0"> <FacebookShareButton url={props.url} title="Facebook">
                <i className="fab fa-lg fa-facebook-f"></i>
            </FacebookShareButton>
            </li>
            <li className="mr-3 mr-md-0"> <TwitterShareButton url={props.url} title="Twitter">
                <i className="fab fa-lg fa-twitter"></i>
            </TwitterShareButton>
            </li>
            <li className="mr-3 mr-md-0"> <InstapaperShareButton url={props.url} title="Instagram">
                <i className="fab fa-lg fa-instagram"></i>                                    </InstapaperShareButton>
            </li>
        </ul>
    )
}
export default Socialshare