import * as types from "../Action/actiontype";

const initialState = {
    resp: [],
    loading: true,
    error: '',
    pagination: []
};

export const CurrentJobReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.CURRENT_JOB_VACANCY_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.CURRENT_JOB_VACANCY_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.CURRENT_JOB_VACANCY_LOADING:
            return {
                ...state,
                loading: true
            };
        case types.CURRENT_JOB_VACANCY_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                pagination: payload
            };
        default:
            return state;
    }
}

export const JobFormReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.JOB_FORM_SUBMIT_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.JOB_FORM_SUBMIT_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.JOB_FORM_SUBMIT_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}