import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from '../../Container/Footer';
import Back from '../../Components/Back/Back';
import { Api } from '../../Const/Api';
import './index.css';
import { Box, Flex } from '@chakra-ui/react';
import { SVGHeader } from '../../Components/SVG/header';

function Job(props) {
	const [job, setJobs] = useState([]);
	const [Loading, setLoading] = useState(true);
	const id = props.match.params.id;
	const screen = window.screen.width;
	const left = window.screen.width > 900 ? '120px' : '20px';

	useEffect(() => {
		document.getElementById('navTop').style.background = '#10152c';
		window.scrollTo(0, 0);
		axios
			.get(`${Api.paceBaseUrl}jobs/${id}`, {
				params: {
					ID: 12345
				}
			})
			.then(function (response) {
				setLoading(false);
				if (response.status === 200) {
					setJobs(response.data.data.job);
				}
			});
	}, [id]);

	return (
		<>
			<Box style={{ position: 'relative', paddingTop: '3em' }}>
				<SVGHeader />
				<div className="container text-center mb-5">
					{Loading ? (
						<div className="spinner-grow mt-5 mb-5" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					) : (
						<div className="career  mt-5">
							<Box pos="relative" maxW="5xl" px="10px" mx="auto">
								<Box mx="10px" w="full" h="30px">
									<Back left="10px" top="0px" />
									<Box pt={{ base: '40px', md: 0 }} className="heading  mb-5">
										Job Opportunities
									</Box>
								</Box>
							</Box>
							{/* <Back top={110} left={left} /> */}

							<div className="job-card col-12 mt-5 p-lg-5 pt-5 pb-5 pl-3">
								<div className="role text-left">{job.title}</div>
								<Box
									display={{ base: 'block', lg: 'flex' }}
									className="detail-section text-left  mt-3 "
									px={{ base: '20px', lg: 0 }}
								>
									<Flex
										mx={{ base: 0, lg: '20px' }}
										my="10px"
										justifyContent={{ base: 'start', lg: 'center' }}
										align="center"
										className="location"
									>
										<svg
											style={{ marginRight: '5px' }}
											width="14"
											height="16"
											viewBox="0 0 14 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<g clip-path="url(#clip0)">
												<path
													d="M11.1456 2.73992C10.296 1.06417 8.78682 0.0403906 7.10859 0.00126563C7.03695 -0.000421875 6.96485 -0.000421875 6.89318 0.00126563C5.21498 0.0403906 3.70583 1.06417 2.85615 2.73992C1.98766 4.4528 1.96389 6.5103 2.79257 8.24376L6.26422 15.5059C6.26578 15.5091 6.26733 15.5123 6.26895 15.5155C6.42169 15.8189 6.69532 16 7.00094 16C7.30653 16 7.58016 15.8189 7.73288 15.5155C7.73449 15.5123 7.73605 15.5091 7.73761 15.5059L11.2093 8.24376C12.0379 6.5103 12.0141 4.4528 11.1456 2.73992ZM7.00089 7.25002C5.91531 7.25002 5.03214 6.24067 5.03214 5.00002C5.03214 3.75936 5.91531 2.75002 7.00089 2.75002C8.08646 2.75002 8.96964 3.75936 8.96964 5.00002C8.96964 6.24067 8.08649 7.25002 7.00089 7.25002Z"
													fill="#050038"
												/>
											</g>
											<defs>
												<clipPath id="clip0">
													<rect width="14" height="16" fill="white" />
												</clipPath>
											</defs>
										</svg>
										Location: <span>{job.location}</span>
									</Flex>
									<Flex
										mx={{ base: 0, lg: '20px' }}
										my="10px"
										justifyContent={{ base: 'start', lg: 'center' }}
										align="center"
										className="location"
									>
										<svg
											style={{ marginRight: '5px' }}
											width="18"
											height="18"
											viewBox="0 0 18 18"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M7.41797 9.05078H10.582V10.0527H7.41797V9.05078Z" fill="#050038" />
											<path
												d="M14.6032 10.0533H11.6367V10.5543C11.6367 10.8311 11.4008 11.0552 11.1094 11.0552H6.89062C6.59921 11.0552 6.36328 10.8311 6.36328 10.5543V10.0533H3.39684C2.715 10.0533 2.11198 9.64038 1.8961 9.0259L0 3.62109V14.5621C0 15.3908 0.709717 16.065 1.58203 16.065H16.418C17.2903 16.065 18 15.3908 18 14.5621V3.62149L16.1038 9.0259C15.888 9.64038 15.285 10.0533 14.6032 10.0533Z"
												fill="#050038"
											/>
											<path
												d="M11.1102 1.03516H6.89149C6.01918 1.03516 5.30946 1.70939 5.30946 2.53809V3.03906H0.908203L2.89714 8.70832C2.96924 8.91327 3.17056 9.05078 3.39771 9.05078H6.36415V8.5498C6.36415 8.27296 6.60008 8.04883 6.89149 8.04883H11.1102C11.4017 8.04883 11.6376 8.27296 11.6376 8.5498V9.05078H14.604C14.8312 9.05078 15.0325 8.91327 15.1046 8.70832L17.0937 3.03906H12.6923V2.53809C12.6923 1.70939 11.9826 1.03516 11.1102 1.03516ZM6.36415 3.03906V2.53809C6.36415 2.26164 6.60049 2.03711 6.89149 2.03711H11.1102C11.4012 2.03711 11.6376 2.26164 11.6376 2.53809V3.03906H6.36415Z"
												fill="#050038"
											/>
										</svg>
										Job Type: <span>{job.jobType}</span>
									</Flex>
									<Flex
										mx={{ base: 0, lg: '20px' }}
										my="10px"
										justifyContent={{ base: 'start', lg: 'center' }}
										align="center"
										className="location"
									>
										<svg
											style={{ marginRight: '5px' }}
											width="16"
											height="16"
											viewBox="0 0 16 16"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M14.125 1.25H13.375V0H12.125V1.25H3.875V0H2.625V1.25H1.875C0.841125 1.25 0 2.09113 0 3.125V14.125C0 15.1589 0.841125 16 1.875 16H14.125C15.1589 16 16 15.1589 16 14.125V3.125C16 2.09113 15.1589 1.25 14.125 1.25ZM14.75 14.125C14.75 14.4696 14.4696 14.75 14.125 14.75H1.875C1.53038 14.75 1.25 14.4696 1.25 14.125V5.875H14.75V14.125ZM14.75 4.625H1.25V3.125C1.25 2.78038 1.53038 2.5 1.875 2.5H2.625V3.75H3.875V2.5H12.125V3.75H13.375V2.5H14.125C14.4696 2.5 14.75 2.78038 14.75 3.125V4.625Z"
												fill="#050038"
											/>
											<path d="M3.625 7.1875H2.375V8.4375H3.625V7.1875Z" fill="#050038" />
											<path d="M6.125 7.1875H4.875V8.4375H6.125V7.1875Z" fill="#050038" />
											<path d="M8.625 7.1875H7.375V8.4375H8.625V7.1875Z" fill="#050038" />
											<path d="M11.125 7.1875H9.875V8.4375H11.125V7.1875Z" fill="#050038" />
											<path d="M13.625 7.1875H12.375V8.4375H13.625V7.1875Z" fill="#050038" />
											<path d="M3.625 9.6875H2.375V10.9375H3.625V9.6875Z" fill="#050038" />
											<path d="M6.125 9.6875H4.875V10.9375H6.125V9.6875Z" fill="#050038" />
											<path d="M8.625 9.6875H7.375V10.9375H8.625V9.6875Z" fill="#050038" />
											<path d="M11.125 9.6875H9.875V10.9375H11.125V9.6875Z" fill="#050038" />
											<path d="M3.625 12.1875H2.375V13.4375H3.625V12.1875Z" fill="#050038" />
											<path d="M6.125 12.1875H4.875V13.4375H6.125V12.1875Z" fill="#050038" />
											<path d="M8.625 12.1875H7.375V13.4375H8.625V12.1875Z" fill="#050038" />
											<path d="M11.125 12.1875H9.875V13.4375H11.125V12.1875Z" fill="#050038" />
											<path d="M13.625 9.6875H12.375V10.9375H13.625V9.6875Z" fill="#050038" />
										</svg>
										Application deadline: <span>{job.closeDate}</span>
									</Flex>
								</Box>
								<p
									className="text-justify mt-4 mx-2"
									dangerouslySetInnerHTML={{ __html: `${job.description}` }}
								></p>
								<a
									href={`https://pace.africa/job/${id}`}
									target="_blank"
									rel="noopener noreferrer"
									className="btn  d-flex align-items-center justify-content-center mt-5"
								>
									Apply
								</a>
							</div>
						</div>
					)}
				</div>
			</Box>
			<Footer />
		</>
	);
}

export default Job;
