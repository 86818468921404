import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Back from '../../Components/Back/Back';
import './index.css';
import {Helmet} from 'react-helmet';

function Tour() {
	// useEffect(() => {
	// 	document.getElementById('navTop').style.background = '#10152c';
	// 	window.scrollTo(0, 0);
	// }, []);
	console.log('screen', window.screen.width);
	const screen = window.screen.width;
	const left = window.screen.width > 900 ? '60px' : '20px';
	return (
		<>
			<Helmet>
					<title>  Book a Physical Tour | The Bulb Africa    </title>
					<meta name="title" content=" Book a Physical Tour | The Bulb Africa  " />
					<meta name="description" content="Book a physical tour. Experience innovation in real time.  " />
			</Helmet>
			<div className="tour-bg-door">
				<div className="overley ">
					<div className="container d-flex  align-items-center justify-content-center flex-column">
						<Back top={120} left={left} />
						<h1 className="text-white mb-5 text-wrap" >Welcome to our workspace. Let’s show you around.</h1>

						<Link
							to="/schedule-a-visit"
							className="physical col-md-4 d-flex align-items-center justify-content-center mt-4 "
						>
							Book physical tour
						</Link>
					</div>
				</div>
			</div>
		</>
	);
}

export default Tour;
