import React from 'react'
import './CareerAdvance.css'

const CareerAdvance = (props) => (
	<div data-aos="zoom-in" data-aos-duration="1000" className='c-a-main'>
		<img src={props.icon} />
		<h3>{props.head}</h3>
		<p>{props.text}</p>
	</div>
)
export default CareerAdvance;