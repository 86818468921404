// import whatwedohub from '../Assets/img/whatwedo-hub.jpg'
import dummy from '../Assets/img/dummy.jpg'
// import upcoming from '../Assets/img/upcoming-events.png'
// import tech from '../Assets/img/tech.png'
// import press from '../Assets/img/press.png'
// import aiimg from '../Assets/img/ai.png'
// import media from '../Assets/img/media.png'
// import tech1 from '../Assets/img/tech-1.png'
// import tech2 from '../Assets/img/tech-2.png'
// import eventimage from '../Assets/img/ai.png'

// import frontEnd from '../Assets/img/front-end.png'
// import mobiledevelopment from '../Assets/img/mobile-development.png'
// import a from '../Assets/img/thehub/Community.webp'
// import b from '../Assets/img/thehub/Partner with us.webp'
// import c from '../Assets/img/thehub/Resources.webp'
// import d from '../Assets/img/thehub/Start ups.webp'
// import e from '../Assets/img/thehub/Trainings.webp'
// import f from '../Assets/img/thehub/Workspace.webp'


// export const recentpost = [
//     { image: tech, title: 'Lorem ipsum dolor sit amet,', description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt', date: 'October 22, 2019', type: 'Tech', url: '1', category: 'Design and UI' },
//     { image: press, title: 'Lorem ipsum dolor sit amet,', description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt', date: 'October 22, 2019', type: 'Press', url: '2', category: 'Web Developemt' },
//     { image: aiimg, title: 'Lorem ipsum dolor sit amet,', description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt', date: 'October 22, 2019', type: 'AI', url: '3', category: 'Programming' },
//     { image: media, title: 'Lorem ipsum dolor sit amet,', description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt', date: 'October 22, 2019', type: 'Media', url: '4', category: 'Web Developemt' },
//     { image: tech1, title: 'Lorem ipsum dolor sit amet,', description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt', date: 'October 22, 2019', type: 'Event', url: '5', category: 'Design and UI' },
//     { image: tech2, title: 'Lorem ipsum dolor sit amet,', description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt', date: 'October 22, 2019', type: 'Tech', url: '6', category: 'Web Developemt' },
// ]

export const team = [
    { img: dummy, position: "Coming soon..." },
    { img: dummy, position: "Coming soon..." },
    { img: dummy, position: "Coming soon..." },
    { img: dummy, position: "Coming soon..." },
    { img: dummy, position: "Coming soon..." }
]

// export const traningcard = [
//     { image: block, coursename: 'Blockchain', duration: 'Coming soon....', content: ["HTML/CSS", "Javascript", "Haskell", "Solidity", "C++"] },
//     { image: machine, coursename: 'Machine Learning', duration: 'Coming soon....', content: ["Python/C++/R/Java", "Probability and Statistics", "Data Modeling & Evaluation", "ML Algorithms", "Distributed Computing"] },
//     { image: comp, coursename: 'CompTIA', duration: 'Coming soon....', content: ["Security", "Virtualization and cloud computing", "Operating systems", "Hardware and network troubleshooting", "Software troubleshooting"] },
//     {
//         image: ethical, coursename: 'Ethical Hacking', duration: 'Coming soon....', content: ["Networking",
//             "Linux",
//             "Virtualization",
//             "Security Concepts",
//             "Wireless Technologies"]
//     },
//     { image: businees, coursename: 'Business Analytics', duration: 'Coming soon....', content: ["SQL ",
//         "Agile/Scrum",
//         "Six Sigma",
//         "Predictive analytics",
//         "Prescriptive analytics"] },
//     { image: global, coursename: 'Global Business', duration: 'Coming soon....', content: ["Content Management Systems (CMS)",
//         "IP Rights",
//         "Networking",
//         "Advanced problem-solving",
//         "Public speaking"] },
// ]

export const advisoryteam = [
    { img: dummy, position: "Tosin Osunkoya", name: 'Title goes here' },
    { img: dummy, position: "Haruna Jalo-Waziri", name: 'Title goes here' },
    { img: dummy, position: "Seyi Tinubu", name: 'Title goes here' },
    { img: dummy, position: "Peter Njonjo", name: 'Title goes here' },
    { img: dummy, position: "Ife Oyedele", name: 'Title goes here' },
]

// export const upcomingevent = [
//     { image: upcoming, date: "October 22, 2019", title: 'Title of the event goes here', place: "Yaba, Lagos" },
//     { image: upcoming, date: "October 22, 2019", title: 'Title of the event goes here', place: "Yaba, Lagos" },
//     { image: upcoming, date: "October 22, 2019", title: 'Title of the event goes here', place: "Yaba, Lagos" },
// ]

export const becomeadeveloperdata = [
    { id: 1, image: 'https://thebulb.africa/Images/individual/front-end.jpg', coursename: "Front-end Development", duration: "Coming soon....", content: ["HTML", "CSS", "Javascript", "React", "Angular"] },
    { id: 2, image: 'https://thebulb.africa/Images/individual/mobile-development.jpg', coursename: "Mobile Development", duration: "Coming soon....", content: ["Java", "CSS and HTML5", "UI / UX", "Angular", "React"] },
    { id: 3, image: 'https://thebulb.africa/Images/individual/front-end.jpg', coursename: "Backend Development", duration: "Coming soon....", content: ["PHP", "Ruby", "MySQL", "Python", ".Net"] }
]

// export const eventsname = [
//     { image: d, eventname: 'Start ups', eventdetails: 'The Startup Engine at The Bulb™ finds, builds and nurture startups like Peexoo, Avale and MySpec. Find out how to be next!', path: '/startups' },
//     { image: e, eventname: 'Trainings', eventdetails: 'We create experiential learning opportunities for incubatees, students and businesses with transferable and transportable skill sets for tomorrow’s leaders', path: '/training' },
//     { image: c, eventname: 'Resources', eventdetails: 'We carefully select the very best resources from around the web. We’ve taken care to ensure the majority of these resources are free.', path: '/resources' },
//     { image: f, eventname: 'Workspace', eventdetails: 'Dedicated Desks,Fiber-Optic WiFi, Private Meeting Rooms and an Outdoor Lounge just a few reason to fall in love with “Desk @ The Bulb', path: '/coworkspace' },
//     { image: a, eventname: 'Community', eventdetails: 'Our developers have been trained tested and certified to deliver outstanding work on any IT project.', path: '/community' },
//     { image: b, eventname: 'Partner with us', eventdetails: 'The Bulb works with a diverse group of partners who share our the success of entrepreneurs.', path: '/partner-with-us' },
// ]


// export const calenderdata = [
//     { image: eventimage, title: 'Dinner: Community meetup Lagos', des: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, sed diam nonumy eirmod tempor invidunt', date: 'Tuesday, October 10', time: '6:00 - 7:30PM', address: '61 Queen Street Alagomeji Yaba, Yaba, Lagos, Nigeria', type: 'Conference' },
//     { image: eventimage, title: 'Dinner: Community meetup Lagos', des: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, sed diam nonumy eirmod tempor invidunt', date: 'Tuesday, October 10', time: '6:00 - 7:30PM', address: '61 Queen Street Alagomeji Yaba, Yaba, Lagos, Nigeria', type: 'Hackathons' },
//     { image: eventimage, title: 'Dinner: Community meetup Lagos', des: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, sed diam nonumy eirmod tempor invidunt', date: 'Tuesday, October 10', time: '6:00 - 7:30PM', address: '61 Queen Street Alagomeji Yaba, Yaba, Lagos, Nigeria', type: 'Conference' },
//     { image: eventimage, title: 'Dinner: Community meetup Lagos', des: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, sed diam nonumy eirmod tempor invidunt', date: 'Tuesday, October 10', time: '6:00 - 7:30PM', address: '61 Queen Street Alagomeji Yaba, Yaba, Lagos, Nigeria', type: 'Contest' },
//     { image: eventimage, title: 'Dinner: Community meetup Lagos', des: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt, sed diam nonumy eirmod tempor invidunt', date: 'Tuesday, October 10', time: '6:00 - 7:30PM', address: '61 Queen Street Alagomeji Yaba, Yaba, Lagos, Nigeria', type: 'Workshop' }
// ]

export const aboutdetails = "The Bulb™ is a platform for African creatives to turn tech ideas into business realities. We run accelerator programs, incubate entrepreneurs and provide training to help them launch, grow and sustain businesses."

export const aboutheading = "Africa’s largest network of tech incubation"

export const vision = "Our Vision is to be Africa’s largest network of tech incubation, empowering the next generation of innovatives and startups."

export const mission = "Our Mission is to create experiential learning opportunities for incubatees, students and businesses with transferable and transportable skill sets for tomorrow’s workforce and next generation startups."

export const Objective = "To build an empowering platform that trains and certifies programmers to serve our nation and the continent's growing needs."

var months = new Array(12);
months[0] = "January";
months[1] = "February";
months[2] = "March";
months[3] = "April";
months[4] = "May";
months[5] = "June";
months[6] = "July";
months[7] = "August";
months[8] = "September";
months[9] = "October";
months[10] = "November";
months[11] = "December";

export function convertDatePickerTime(str) {
    var month, day, year
    var date = new Date(str),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    year = date.getFullYear()
    var mySQLDate = [day, month, year].join("-");
    // var mySQLDate = months[month]
    return mySQLDate
}

export function DatePickerTime(str) {
    var month, day, year, hours, minutes, seconds;
    var date = new Date(str),
        month = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    hours = ("0" + date.getHours()).slice(-2);
    minutes = ("0" + date.getMinutes()).slice(-2);

    var mySQLDate = [day, month, date.getFullYear()].join("-");
    var mySQLTime = [hours, minutes].join(":");
    return [mySQLDate, mySQLTime].join(", ");
}

// myFunction("2020-03-03T11:00:00Z",1) ; // 24-12-2019, 04:30AM
// myFunction("2020-03-03T11:00:00Z",2) ; // 03  Mar, 2020 at 16:30 pm
// myFunction("2020-03-03T11:00:00Z",3) ; // Tuesday, March 03 2020
// myFunction("2020-03-03T11:00:00Z",4) ; // Mar 03, 2020

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
const shortMonthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov]", "Dec"
];
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function DayName(str) {
    var d = new Date(str);
    var dayName = days[d.getDay()];
    return dayName;
}

function MonthName(str) {
    var date = new Date(str)
    return monthNames[date.getMonth()]
}
function shortMonthName(str) {
    var date = new Date(str)
    return shortMonthNames[date.getMonth()]
}

function formatAMPM(hours) {
    var ampm = hours >= 12 ? 'pm' : 'am';
    return ampm;
}

export function Dateconvert(dateString, type) {
    var response = "";
    var month, day, year, hours, minutes, seconds;
    var date = new Date(dateString),

        month = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    hours = ("0" + date.getHours()).slice(-2);
    minutes = ("0" + date.getMinutes()).slice(-2);

    var ShorMonthName = shortMonthName(dateString)
    var monthName = MonthName(dateString)
    var AMPM = formatAMPM(hours);
    var dayName = DayName(dateString);

    if (type === 1) {
        response = day + "-" + month + "-" + date.getFullYear() + ", " + hours + ":" + minutes + AMPM.toUpperCase();
    } else if (type === 2) {
        response = day + "  " + ShorMonthName + ", " + date.getFullYear() + " at " + hours + ":" + minutes + " " + AMPM;
    } else if (type === 3) {
        response = dayName + ", " + monthName + " " + day + " " + date.getFullYear();
    } else if (type === 4) {
        response = monthName + " " + day + ", " + date.getFullYear();
    }
    return response;
}

export const COURSES_REGISTRATION_LINK = 'https://thebulbafrica.vzy.io/';


