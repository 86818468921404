import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from '../../Container/Footer';
import Back from '../../Components/Back/Back';
import NoContent from '../../Components/NoContent';
import { Api } from '../../Const/Api';
import './index.css';
import { Box } from '@chakra-ui/react';
import { SVGHeader } from '../../Components/SVG/header';
import {Helmet} from 'react-helmet';

function Community(props) {
	const [CommunityData, setCommunityData] = useState([]);
	const [Loading, setLoading] = useState(true);
	const id = props.match.params.id;
	const screen = window.screen.width;
	// const left = window.screen.width > 900 ? '120px' : '20px';

	useEffect(() => {
		// document.getElementById('navTop').style.background = '#10152c';
		window.scrollTo(0, 0);
		axios.get(`${Api.BaseUrl}community/all`).then(function (response) {
			setLoading(false);
			setLoading(false);
			if (response.status === 200) {
				console.log({ response });
				setCommunityData(response.data.payload.message);
			}
		});
	}, []);

	return (
		<>
			<Box style={{ position: 'relative', paddingTop: '3em' }}>
				<Helmet>
						<title>  Join Our Community of Young Tech Talents | The Bulb Africa    </title>
						<meta name="title" content="Join Our Community of Young Tech Talents | The Bulb Africa   " />
						<meta name="description" content="Want to grow your professional network? Connect with tech experts. Our community of young tech talents is redefining the future. Find the right inspiration to turn your ideas into business realities. " />
				</Helmet>
				<SVGHeader />
				<div className="container text-center mb-5">
					{Loading ? (
						<div className="spinner-grow mt-5 mb-5" role="status">
							<span className="sr-only">Loading...</span>
						</div>
					) : (
						<div className="career  mt-5">
							<Back left="100px" top="90px" />
							<Box pos="relative" maxW="5xl" px="10px" mx="auto">
								<Box mx="10px" w="full" h="30px">
									
									<Box pt={{ base: '40px', md: 0 }} className="heading  mb-5">
										Join a Community
									</Box>
								</Box>
							</Box>
							{/* <Back top={110} left={left} /> */}
							<Box className="join">
								{CommunityData.length < 1 ? (
									<div className="mt-5">
										<NoContent text="Oops, sorry no Community up yet" />
									</div>
								) : (
									<div className="d-flex justify-content-between flex-wrap " style={{position:"relative"}}>
										{CommunityData.map((company, index) => (
											<div
												className=" join-card  mt-5 px-lg-3 pt-5 pb-5 pl-3 mx-auto"
												key={index}
												style={{position:"relative"}}
											>
												<div className="company-logo">
													<img
														src={company?.logo ? company?.logo?.split('"')?.[1] : ''}
														alt=" company logo"
													/>
												</div>
												<div className="heading mt-4"> {company?.heading}</div>
												<p className="text">{company?.description}</p>
												<a
													href={company.link ?? ''}
													target="_blank"
													rel="noopener noreferrer"
													className="btn  d-flex align-items-center justify-content-center mt-5"
												>
													Join
												</a>
											</div>
										))}
									</div>
								)}
							</Box>
						</div>
					)}
				</div>
			</Box>
			<Footer />
		</>
	);
}

export default Community;
