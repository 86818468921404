import React from 'react';
import { withRouter } from 'react-router-dom';
import './back.css';

function Back({ top = '113px', left = '60px', history }) {
	return (
		<div
			className="back"
			onClick={history.goBack}
			style={{
				position: 'absolute',
				top,
				left,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				color: '#fbbf19',
				fontWeight: 'bold',
				fontSize: '20px',
				cursor: 'pointer',
				zIndex: 100
			}}
		>
			<img src={require('../../Assets/img/arrow-left.svg')} alt="back arrow" />
			Back
		</div>
	);
}

export default withRouter(Back);
