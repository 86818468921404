import React from 'react'

const Contactblock = ({ position, position1, contact, address, mail, name, heading, headingname, content, mailing }) => {
    return (
        <div className="address">
            {heading && heading && <h4>{headingname}</h4>}
            <div className="full-address d-flex flex-wrap mb-3">
                <div className="address-contact mr-sm-4 mr-md-5">
                    {/* {
                        content && content ? '' :
                            <>
                                <h5><strong>{name}</strong></h5>
                                <p>{position}</p>
                            </>
                    } */}
                    {
                        mailing && mailing ? <p>
                            {position1}    <br />
                            {address}
                        </p> :
                            <>
                                <p className="d-flex mb-1">
                                    {contact && (
                                        <>
                                            <strong className="mr-2">Phone</strong>
                                            <br />
                                            <span><a href={`tel:${contact}`}>0700THEBULB</a></span>
                                        </>
                                    )}
                                </p>
                                <p className="d-flex">
                                    <strong className="mr-2">Email</strong> <br />
                                    <span><a href={`mailto:${mail}`}>{mail}</a></span>
                                </p>
                            </>
                    }
                </div>
            </div>
        </div>
    )
}
export default Contactblock


