import React from "react";

export const Marquee = () => {
  return (
    <div class="marquee-container bg-[#FDBF17] z-[9999] fixed flex justify-center  transition-all  ">
      <div class="bounce-text  text-[#13162D] md:font-bold underline text-sm ">
        <a href="https://app.thebulbafrica.institute/">
          {/* Break into tech! ₦ 25 Million worth of scholarships available for the
          Fellowship Program. Apply Now! */}
          
Get 20% discount on tuition upon registration for the Fellowship Program
 
        </a>
      </div>
    </div>
  );
};
