import React, { useState } from "react";
import { Header } from "../../Components/homeHeader/Header";
import { Hero } from "./hero/Hero";
// import Hero from "./hero/Hero";
import { GetStarted } from "../../Components/GetStarted/GetStarted";

export const NewHome = () => {
  const [showSlides, setShowSlides] = useState(false);
  const showDrop = () => {
    setShowSlides(true);
  };
  const hideDrop = () => {
    setShowSlides(false);
  };
  return (
    <div className="">
      <div className="  ">
        <Header showDrops={showDrop} />
      </div>
      <section className="bg-[#e2e5f3] lg:px-24 md:px-12 px-4">
        {/* <Hero /> */}
        <Hero />
        {showSlides && <GetStarted hideDrops={hideDrop} />}
      </section>
    </div>
  );
};
