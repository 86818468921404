import React, { useEffect } from "react";
import "./HireOurTalents.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

import theBulbLogoDark from "../../Assets/img/the-bulb-logo-dark.png";
import Navbar from "../../Container/Navbar";
import Footer from "../../Container/Footer";
import TextCard from "../../Components/Card/TextCard";
import ImageCard from "../../Components/Card/ImageCard";
import happyPeople from "../../Assets/img/happy-people.png";
import peopleLaughing from "../../Assets/img/people-laughing.png";
import twoLadies from "../../Assets/img/two-ladies.png";
import fellows from "../../Assets/img/hire-fellows.png";
import accessLogo from "../../Assets/img/access-bank.png";
import polarisLogo from "../../Assets/img/polaris-bank.png";
import recruit from "../../Assets/img/guy-with-pad.png";
import outsource from "../../Assets/img/a-bulb-image.png";
import hire from "../../Assets/img/handshake.png";
import MiniCards from "../../Components/MiniCards/MiniCards";

const HireOurTalents = () => {
  useEffect(() => {
    // document.getElementById("navTop").style.display = "none";
    // const items = document.getElementsByClassName("nav-link");
    // for (let i = 0; i < items.length; i++) {
    //   items[i].style.color = "#10152c";
    // }
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  });

  const miniCardsData = [
    {
      image: recruit,
      caption: "Recruit tech talents",
      inAppLink: false,
      destination: "https://forms.gle/Q68fbmsbFVpnwWEJ6",
    },
    {
      image: outsource,
      caption: "Outsource our talents",
      inAppLink: false,
      destination: "https://forms.gle/Q68fbmsbFVpnwWEJ6",
    },
    {
      image: hire,
      caption: "Hire our fellows",
      inAppLink: false,
      destination: "https://forms.gle/sU9qZLKoPtBq25kF9",
    },
  ];

  const sponsors = [
    {
      source: "lifebank",
    },
    {
      source: "titan",
    },
    {
      source: "identity-pass",
    },
    {
      source: "renmoney",
    },
    {
      source: "polaris",
    },
    {
      source: "oze",
    },
    {
      source: "access",
    },
    {
      source: "prunedge",
    },
    {
      source: "first-bank",
    },
    {
      source: "wema",
    },
    {
      source: "awabah",
    },
    {
      source: "parallex",
    },
    {
      source: "softcom",
    },
    {
      source: "paga",
    },
    {
      source: "minimie",
    },
    {
      source: "suntrust",
    },
    {
      source: "sterling",
    },
    {
      source: "cloudcation",
    },
    {
      source: "arm",
    },
    {
      source: "shuttlers",
    },
  ];

  return (
    <>
      <Helmet>
        {/*TITLE TAGS & META TAGS*/}
        <title>Hire Vetted & Experienced Tech Talents | The Bulb Africa </title>

        <meta
          name="title"
          content="Hire Vetted & Experienced Tech Talents | The Bulb Africa "
        />
        <meta
          name="description"
          content="We understand your needs! Hire top engineering talents. Recruit our tech talents. Outsource our tech specialists. "
        />
        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content="Hire Vetted & Experienced Tech Talents | The Bulb Africa"
        />
        <meta property="og:url" content="https://thebulb.africa/hire-talents" />
        <meta
          property="og:description"
          content="We understand your needs! Hire top engineering talents. Recruit our tech talents. Outsource our tech specialists. "
        />
        <meta
          property="og:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta property="og:image:alt" content="The Bulb Africa- offical logo" />
        {/* twitter meta tags */}
        <meta
          name="twitter:title"
          content="Hire Vetted & Experienced Tech Talents | The Bulb Africa"
        />
        <meta
          name="twitter:description"
          content="We understand your needs! Hire top engineering talents. Recruit our tech talents. Outsource our tech specialists. "
        />
        <meta
          name="twitter:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta name="twitter:image:alt" content="The Bulb- offical logo" />
        <meta name="twitter:site" content="@TheBulbAfrica" />
        <meta name="twitter:card" content="summary" />
      </Helmet>

      <div className="fixed-nav">
        <Navbar navImage={theBulbLogoDark} isLight={true} />
      </div>

      <div className="hire">
        <div className="hire-hero">
          <h1 data-aos="fade-up" data-aos-duration="1000">
            Hire the best in-demand tech talents
          </h1>
          <p data-aos="fade-up" data-aos-duration="1000">
            Get access to well-trained and experienced software engineers,
            designers, and other tech experts to meet your business needs.
          </p>
        </div>

        <div className="hire-card-container">
          <div className="hire-card-content reverse">
            <TextCard
              className="hire-text-card"
              headingClassName="hire-text-card-heading"
              descriptionClassName="hire-text-card-description"
              heading="Matching organizations with skilled talents "
              description="We take on the work of attracting quality talents and connecting you with the finest tech specialists that best fit your company culture and its demands. Each solution proffered is tailored specifically to fit and bridge the gap between your business and its customers."
            />
            <ImageCard
              source={happyPeople}
              className="hire-image-card"
              animationDuration="1000"
            />
          </div>
          <div className="hire-card-content">
            <ImageCard
              source={peopleLaughing}
              className="hire-image-card"
              animationDuration="1000"
            />
            <TextCard
              className="hire-text-card"
              headingClassName="hire-text-card-heading"
              descriptionClassName="hire-text-card-description"
              isHyperLink={true}
              heading="In the new world of work, choose The Bulb Africa"
              description="Focus on running your business while we provide highly skilled talents from across Africa. We recruit only top tech talents with the highest level of performance for your company. With our personality assessment, we screen and recruit experts who blend your company culture and values."
              cta="Recruit tech talents"
              location="https://forms.gle/Q68fbmsbFVpnwWEJ6"
            />
          </div>
          <div className="hire-card-content reverse">
            <TextCard
              className="hire-text-card"
              headingClassName="hire-text-card-heading"
              descriptionClassName="hire-text-card-description"
              isHyperLink={true}
              heading="Bridge the gap within your tech team by outsourcing our in-house talents"
              description="Our tech specialists are IT experts with world-class standards in vocational aptitude, and our in-house training keeps them ahead of the curve. We’ll equip you with professionals trained in fields such as iOS, Android, JavaScript, React, Angular, Node, PHP, Python, Ruby, and Java."
              cta="Outsource our talents"
              location="https://forms.gle/Q68fbmsbFVpnwWEJ6"
            />
            <ImageCard
              source={twoLadies}
              className="hire-image-card"
              animationDuration="1000"
            />
          </div>
          <div className="hire-card-content">
            <ImageCard
              source={fellows}
              className="hire-image-card"
              animationDuration="1000"
            />
            <TextCard
              className="hire-text-card"
              headingClassName="hire-text-card-heading"
              descriptionClassName="hire-text-card-description"
              isHyperLink={true}
              heading="Hire top talents from our fellowship program"
              description="Gain access to a 90-day free internship with graduates of our fellowship program. Our fellows will add value to your organization understand your company’s culture while reducing the work of your recruitment team."
              cta="Hire our fellows"
              location="https://forms.gle/sU9qZLKoPtBq25kF9"
            />
          </div>
        </div>
        <div className="sponsors">
          <h3
            className="sponsors-heading"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Companies that trust us
          </h3>
          <div className="sponsors-logo">
            {sponsors.map((image) => (
              <img
                data-aos="zoom-in"
                data-aos-duration="1000"
                src={require(`../../Assets/img/sponsor-logos/${image.source}.png`)}
                alt=""
              />
            ))}
          </div>
        </div>

        <div className="hire-mini-cards">
          <div className="hire-mini-text">
            <h3
              data-aos="fade-up"
              data-aos-duration="1000"
              className="hire-mini-heading"
            >
              Ready for that boost?
            </h3>
            <p
              data-aos="fade-up"
              data-aos-duration="1000"
              className="hire-mini-para"
            >
              Our goal is to positively influence the African continent with
              technology. With our commitment and strategy towards bridging
              Africa’s tech talent gap, you’re assured of the best at all times.
            </p>
          </div>
          <MiniCards data={miniCardsData} />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default HireOurTalents;
