import { combineReducers } from 'redux'
import classesadd from './Addclass'
import Forminfo from './Form'
// import {pressaction} from '../Action/newsfeedRequestAction'
// import { TheHubEvent } from './thehub'
import { NewsFeedEvent, NewsFeedTag, NewsFeedPress, NewsFeedGallery,NewsFeedEventCalender } from './newsfeed'
import { StateReducer, CityReducer, FileuploadReducer } from './common'
import { SubmitIdeaReducer, IncubatorReducer, HireDeveloperReducer, PartnershipReducer, RequestatourReducer, ScheduleVisitReducer } from './applicationRequest'
import { CurrentJobReducer, JobFormReducer } from './CareerReducer'
import { WorkspaceReducer } from './workspace'

export default combineReducers({
  classesadd: classesadd,
  Form: Forminfo,
  // pressaction: pressaction,
  // TheHubEvent: TheHubEvent,
  NewsFeedEvent: NewsFeedEvent,
  NewsFeedTag: NewsFeedTag,
  NewsFeedPress: NewsFeedPress,
  NewsFeedGallery: NewsFeedGallery,
  CurrentJobReducer: CurrentJobReducer,
  StateReducer: StateReducer,
  CityReducer: CityReducer,
  FileuploadReducer: FileuploadReducer,
  SubmitIdeaReducer: SubmitIdeaReducer,
  IncubatorReducer: IncubatorReducer,
  HireDeveloperReducer: HireDeveloperReducer,
  JobFormReducer: JobFormReducer,
  PartnershipReducer: PartnershipReducer,
  RequestatourReducer: RequestatourReducer,
  ScheduleVisitReducer: ScheduleVisitReducer,
  WorkspaceReducer: WorkspaceReducer,
  NewsFeedEventCalender : NewsFeedEventCalender
})