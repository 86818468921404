import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

const AlertDialog = ({ open, close, showok, status, message, error }) => {
    return (
        <div>
            <Dialog
                className="thankyou"
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p className="mb-1"><strong>{status === "1" ? "Thank you!" : "Sorry"}</strong></p>
                        {/* <p className="mb-0">We have received your request and we will <br className="d-none d-md-block" /> get back to you</p> */}
                        <p className="mb-0">{message || error}</p>

                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ cursor: 'pointer' }}>
                    {
                        showok && showok ? '' :
                            <Button onClick={close} color="primary">
                                Ok
                    </Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
}
export default AlertDialog

