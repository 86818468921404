import * as types from "../Action/actiontype";

const initialState = {
    resp: [],
    loading: true,
    error: ''
};

export const WorkspaceReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.WORK_SPACE_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.WORK_SPACE_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.WORK_SPACE_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}