import React from 'react';

import './index.css';
function NewLetter({ fontSize = '30px', text = 'Oops, sorry no recent news up yet', }) {
	return (
		<>
			<div className="no-content col-11 col-lg-6 ">
				<div
					className="heading"
					style={{
						fontSize,
						lineHeight: '41px',
						color: '#050038',
						fontWeight: 'normal'
					}}
				>
					{text}
				</div>
				<div className="img-section d-flex align-items-center mt-4 mb-5 col-12 ml-md-3">
					<img src={require('../../Assets/img/no-content.png')} alt=" Subscribe bell" data-aos="fade-up"
            data-aos-duration="500"/>
				</div>
			</div>
		</>
	);
}

export default NewLetter;
