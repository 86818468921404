import React, { Component } from 'react'
import Footer from '../../../Container/Footer'
import Axios from 'axios'
import AlertDialog from '../../../Components/Madal'
import { Api } from '../../../Const/Api'
import CircularProgress from '@material-ui/core/CircularProgress';
import Inputcomponents from '../../../Components/Inputarea'
import Loader from '../../../Components/Loader'
import { Fileupload } from '../../../common/commonfunction'
import { Careerpost } from '../../../common/function'
import { Redirect } from 'react-router-dom'
import ArrowRight from '../../../Assets/img/chevron-arrow.svg'

let errormsg = "This field is required."

class CareerExpended extends Component {
	constructor(props) {
		super(props)
		this.state = {
			fields: {},
			errors: {},
			selectedFile: null,
			joblogo: "",
			title: '',
			jobtype: '',
			joblocation: '',
			closedate: '',
			description: '',
			fileuploadloader: false,
			fileuploadloadermsg: '',
			imageurl: '',
			loader: false,
			status: null,
			setOpen: false,
			message: "",
			file: null,
			uploadstatus: null,
			redirect: false,
			open: false,
			show : true
		}
	}


	componentDidMount() {
		// document.getElementById("navTop").style.background = "#10152c";
		this.setState({ fetchData: this.props.jobListData })
		let jobid = this.props.match.params.id
		console.log("jobid", jobid)
		Axios.get(Api.BaseUrl + "/api/carrerpost/" + jobid)
			.then(res => {
				if (res.data.data.list.length === 0) {
					this.setState({
						redirect: true,
					})
				}
				else {
					this.setState({
						show : false,
						joblogo: res.data.data.list.logo_url,
						title: res.data.data.list.title,
						jobtype: res.data.data.list.job_type,
						joblocation: res.data.data.list.location,
						closedate: res.data.data.list.entry_closure,
						description: res.data.data.list.description,
					})
				}
			})
	}

	handleClose = () => {
		this.setState({
			setOpen: false,
		})
	};



	HandleChange = e => {
		let fields = this.state.fields;
		fields[e.target.name] = e.target.value;
		this.setState({ fields });
	}

	clearState() {
		let fields = {};
		fields['title'] = " ";
		fields['fname'] = " ";
		fields['lname'] = " ";
		fields['phoneno'] = " ";
		fields['email'] = " ";
		fields['Github'] = " ";
		fields['joinTeam'] = " ";
		fields['Website'] = " ";
		fields['LinkedIn'] = " ";
		fields['Twitter'] = " ";
		fields['Behance'] = " ";
		fields['Dribbble'] = " ";
		this.setState({
			fields: fields,
			file: '',
			imageurl: ''
		})
	}

	Submitform = async (e) => {
		e.preventDefault();
		if (this.validateForm()) {
			this.setState({
				loader: true
			})
			let data = {
				title: this.state.fields.title,
				first_name: this.state.fields.fname,
				last_name: this.state.fields.lname,
				mobile: this.state.fields.phoneno,
				email: this.state.fields.email,
				github: this.state.fields.Github,
				cv_url: this.state.imageurl,
				info: this.state.fields.joinTeam,
				Website: this.state.fields.Website,
				linkedin: this.state.fields.LinkedIn,
				twitter: this.state.fields.Twitter,
				behance: this.state.fields.Behance,
				dribbble: this.state.fields.Dribbble,
				jobpost_name: this.state.title
			}
			await Careerpost(data)
				.then(response => {
					this.setState({
						message: response.message,
						loader: false,
						setOpen: true,
						status: response.status
					})
				}).catch(err => {
					this.setState({
						loader: false,
						message: err,
						status: 0,
						setOpen: true,
					})
				})
			this.clearState()
		}
	}


	validateForm() {
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;

		if (!fields['title']) {
			formIsValid = false;
			errors["title"] = errormsg
		}
		if (typeof fields['title'] !== "undefined") {
			if (!fields['title'].match(/^[a-z A-Z.]*$/)) {
				formIsValid = false;
				errors['title'] = errormsg
			}
		}

		if (!fields['fname']) {
			formIsValid = false;
			errors["fname"] = errormsg
		}
		if (typeof fields['fname'] !== 'undefined') {
			if (!fields['fname'].match(/^[a-zA-Z.]*$/)) {
				formIsValid = false;
				errors['fname'] = errormsg
			}
		}

		if (!fields['lname']) {
			formIsValid = false;
			errors["lname"] = errormsg
		}
		if (typeof fields['lname'] !== 'undefined') {
			if (!fields['lname'].match(/^[a-zA-Z]*$/)) {
				formIsValid = false;
				errors['lname'] = errormsg
			}
		}

		if (!fields['phoneno']) {
			formIsValid = false;
			errors["phoneno"] = errormsg
		}
		if (typeof fields['phoneno'] !== 'undefined') {
			if (!fields['phoneno'].match(/^[0-9]*$/)) {
				formIsValid = false;
				errors['phoneno'] = errormsg
			}
		}

		if (!fields['email']) {
			formIsValid = false;
			errors["email"] = errormsg
		}
		if (typeof fields['email'] !== "undefined") {
			var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
			if (!pattern.test(fields['email'])) {
				formIsValid = false;
				errors["email"] = errormsg
			}
		}

		if (this.state.imageurl == null) {
			formIsValid = false;
			errors["file"] = errormsg
		}
		if (this.state.imageurl == '') {
			formIsValid = false;
			errors["file"] = errormsg
		}
		this.setState({ errors: errors });
		return formIsValid;
	}


	handleUploadFile = async (e) => {
		this.setState({
			fileuploadloader: true,
			fileuploadloadermsg: ""
		})
		let filesize = e.target.files[0].size
		if (5242880 >= filesize) {
			await Fileupload(e.target.files[0])
				.then(response => {
					this.setState({
						imageurl: response.data.image_url,
						fileuploadloader: false,
						uploadstatus: response.status
					})
				})
				.catch(error => {
					this.setState({
						fileuploadloadermsg: error,
						fileuploadloader: false
					})
				})
		}
		else {
			this.setState({
				fileuploadloadermsg: "please select the file than less than 5 mb",
				fileuploadloader: false
			})
		}
	}

	render() {
		const { redirect , show } = this.state
		console.log("show",show)
		// console.log("!show",!show)

		

		return (
			<>
				{redirect ?
					<div className="notjobfound parallax">
						<div className="notfound-inner min-vh-100 py-4 d-flex justify-content-center text-center align-items-center flex-column">
							<h1 className="font-weight-bold large" style={{ marginTop: "317px" }}>This job is no longer available</h1>
							<p className="pb-3">You can check out other listings from our careers page.</p>
							<a href="/careers" className="text-decoration-none"><button className="btn btn-warning text-white d-flex align-items-center px-5 py-2">Check Careers &nbsp; <img src={ArrowRight} alt="arrow-right" className="img-fluid" /></button></a>
						</div>
					</div> :
					show ? <p>loading</p> :
					<>
						<div className="mt-73 careers-expended">
							<div className="careers-inner bg-careers parallax py-5 min-h-350"></div>
							<div className="careers-expended-inner py-5">
								<div className="container">
									<div className="row">
										<div className="col-md-4 sticky-top-200">
											<div className="careers-expended-left bg-white sticky-top">
												<div className="job-image">
													<img src={this.state.joblogo} alt="Job" className="img-fluid" />
												</div>
												<h4 className="job-title">{this.state.title}</h4>
												<p className="job-shift">
													<i className="fas fa-briefcase"></i> {this.state.jobtype}
												</p>
												<p className="job-location">
													<i className="fas fa-map-marker"></i> {this.state.joblocation}
												</p>
												<p className="job-end-date">
													Entry closes : {this.state.closedate}
												</p>
												<div className="apply-btn mt-4 text-center">
													<button className="btn btn-warning text-white px-5" data-toggle="modal" data-target="#applyjob">Apply</button>
												</div>
											</div>
										</div>
										<div className="col-md-8">
											<div className="careers-expended-desc bg-white">
												<h4 className="job-desc-title">JOB DESCRIPTION</h4>

												<div dangerouslySetInnerHTML={{ __html: this.state.description }} />

												<div className="apply-btn mt-4 text-center">
													<button className="btn btn-warning px-5 text-white" data-toggle="modal" data-target="#applyjob">Apply</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="modal applyjobform fade" id="applyjob" tabindex="-1" role="dialog" aria-labelledby="applyjobTitle" aria-hidden="true">
							<div className="modal-dialog modal-dialog-centered" role="document">
								<div className="modal-content">
									<button type="button" className="close position-absolute" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true">&times;</span>
									</button>
									<div className="modal-body">
										<form onSubmit={this.Submitform}>
											<div className="applyjobform-inner">
												<h2>Application form</h2>
												<div className="request-heading">
													<h3>About yourself</h3>
													<p className="d-none d-md-block">Please provide us with you information</p>
												</div>
												<div className="row">
													<Inputcomponents
														divclassname={"col-md-4"}
														label={"Title"}
														Errorname={this.state.errors.title}
														type={"text"}
														HandleChange={this.HandleChange}
														value={this.state.fields.title}
														name={"title"}
														errormsg={errormsg}
													/>
													<Inputcomponents
														divclassname={"col-md-4"}
														label={"First Name"}
														Errorname={this.state.errors.fname}
														type={"text"}
														HandleChange={this.HandleChange}
														value={this.state.fields.fname}
														name={"fname"}
														errormsg={errormsg}

													/>
													<Inputcomponents
														divclassname={"col-md-4"}
														label={"Last Name"}
														Errorname={this.state.errors.lname}
														type={"text"}
														HandleChange={this.HandleChange}
														value={this.state.fields.lname}
														name={"lname"}
														errormsg={errormsg}

													/>
												</div>

												<div className="row">
													<Inputcomponents
														divclassname={"col-md-6"}
														label={"Phone Number"}
														Errorname={this.state.errors.phoneno}
														type={"text"}
														HandleChange={this.HandleChange}
														value={this.state.fields.phoneno}
														name={"phoneno"}
														errormsg={errormsg}

													/>
													<Inputcomponents
														divclassname={"col-md-6"}
														label={"Email Address"}
														Errorname={this.state.errors.email}
														type={"text"}
														HandleChange={this.HandleChange}
														value={this.state.fields.email}
														name={"email"}
														errormsg={errormsg}

													/>
												</div>

												<div className="request-heading">
													<h3>Cover Letter</h3>
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="form-group">
															<label for="joinTeam">Tell us why you want to join our team! </label>
															<textarea
																className="form-control"
																rows="7"
																name="joinTeam"
																onChange={this.HandleChange}
																value={this.state.fields.joinTeam}
															/>
															<div className="errorMsg">
																{this.state.errors.joinTeam}
															</div>
														</div>
													</div>
												</div>
												<div className="request-heading">
													<h3>Your Profile</h3>
												</div>
												<div className="row profile">
													<div className="col-md-2">
														<label for="resumecv">Resume/CV {this.state.errors.file == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
													</div>
													<div className="col-md-10 pl-md-0">
														<div className="form-group">
															<input
																type="file"
																className="d-block mb-2"
																name="file"
																onChange={this.handleUploadFile}
																value={this.state.file}
																accept=".xlsx,.xls,,.doc, .docx,.ppt, .pptx,.txt,.pdf"
															/>
															{this.state.fileuploadloader && this.state.fileuploadloader && <CircularProgress />}

															<div className="errorMsg">
																{this.state.errors.file}
																{this.state.fileuploadloadermsg}
															</div>
															<div className="succesMsg">
																{this.state.uploadstatus === "1" && "File upload succesfully"}
															</div>
															<small className="d-block pt-2">Required format: PDF. Maximum size: 5 MB</small>
														</div>
													</div>
												</div>
												<div className="request-heading">
													<h3>Your Web Presence</h3>
												</div>
												<div className="webpresence">
													<div className="row align-items-center">
														<div className="col-md-2">
															<label className="text-md-right d-block" for="Website">Website</label>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<input
																	type="text"
																	name="Website"
																	className="form-control"
																	onChange={this.HandleChange}
																	value={this.state.fields.Website}
																/>
															</div>
														</div>
													</div>
													<div className="row align-items-center">
														<div className="col-md-2">
															<label className="text-md-right d-block" for="LinkedIn">LinkedIn</label>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<input
																	type="text"
																	name="LinkedIn"
																	className="form-control"
																	onChange={this.HandleChange}
																	value={this.state.fields.LinkedIn}
																/>
															</div>
														</div>
													</div>
													<div className="row align-items-center">
														<div className="col-md-2">
															<label className="text-md-right d-block" for="Twitter">Twitter</label>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<input
																	type="text"
																	name="Twitter"
																	className="form-control"
																	onChange={this.HandleChange}
																	value={this.state.fields.Twitter}
																/>
															</div>
														</div>
													</div>
													<div className="row align-items-center">
														<div className="col-md-2">
															<label className="text-md-right d-block" for="Github">Github </label>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<input
																	type="text"
																	name="Github"
																	className="form-control"
																	onChange={this.HandleChange}
																	value={this.state.fields.Github}
																/>
																<div className="errorMsg">
																	{this.state.errors.Github}
																</div>
															</div>
														</div>
													</div>
													<div className="row align-items-center">
														<div className="col-md-2">
															<label className="text-md-right d-block" for="Behance">Behance</label>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<input
																	type="text"
																	name="Behance"
																	className="form-control"
																	onChange={this.HandleChange}
																	value={this.state.fields.Behance}
																/>
															</div>
														</div>
													</div>
													<div className="row align-items-center">
														<div className="col-md-2">
															<label className="text-md-right d-block" for="Dribbble">Dribbble</label>
														</div>
														<div className="col-md-6">
															<div className="form-group">
																<input
																	type="text"
																	name="Dribbble"
																	className="form-control"
																	onChange={this.HandleChange}
																	value={this.state.fields.Dribbble}
																/>
															</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="col-md-12">
														<div className="form-group text-center mt-4">
															<button type="submit" className="btn btn-warning px-5">Apply</button>
														</div>
													</div>
												</div>

											</div>
										</form>
										{this.state.loader && this.state.loader &&
											<Loader />
										}
									</div>
								</div>
							</div>
						</div>
						<Footer />
						<AlertDialog
							open={this.state.setOpen}
							close={this.handleClose}
							status={this.state.status}
							message={this.state.message}
						/>

					</>
					}
			</>
		)
	}
}

export default CareerExpended
