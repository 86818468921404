import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./PrivacyPolicy.css";
import Navbar from "../../Container/Navbar";
import Footer from "../../Container/Footer";
import bulbLogo from "../../Assets/img/the-bulb-logo-dark.png";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.getElementById("navTop").style.display = "none";
    const items = document.getElementsByClassName("nav-link");
    for (let i = 0; i < items.length; i++) {
      items[i].style.color = "#10152c";
    }

    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  });

  return (
    <>
      <Navbar navImage={bulbLogo} isLight={true} />
      <div className="privacy-hero">
        <h1 className="privacy-hero-head">PrivacyPolicy</h1>
        <p className="privacy-text">
          At The Bulb Africa we recognize that privacy is important. This
          Privacy Policy applies to all products and services provided by us.
        </p>
      </div>

      <div className="privacy-main">
        <h2 className="privacy-head">Collection of Information</h2>
        <p className="privacy-text">
          We collect various information when you register with the Company,
          during your usage of our products and services. When you sign in to
          our services, you are not anonymous to us. We automatically receive
          and record information from your computer and browser, including your
          IP address, our cookie information, software and hardware attributes,
          the page you request and other standard browser parameters.
          <br /> When you use our services, we send one or more cookies – a
          small file containing a string of characters – to your device that
          uniquely identifies your browser. We use cookies to improve the
          quality of our service, including for storing user preferences,
          tracking user trends, and providing you with a better experience. We
          may set one or more cookies in your browser when you use our services
          or one of our partner’s services. When you send messages, publish
          content, post material, transmit information or email through our
          services, we may retain the same in order to process your inquiries,
          respond to your requests and improve our services.
        </p>

        <h2 className="privacy-head">Use of Information</h2>
        <p className="privacy-text">
          We only use and process personal information for the purposes
          described in this Privacy Policy. In addition to the above, such
          purposes include:
          <ul className="privacy-list">
            <li>Communicating with you</li>
            <li>
              Making the sites or services easier to use by eliminating the need
              for you to repeatedly enter the same information
            </li>
            <li>Providing our services</li>
            <li>
              Auditing, research and analysis in order to maintain, protect and
              improve our services
            </li>
            <li>Ensuring the technical functioning of our network</li>
            <li>Protecting our rights or property and that of our users</li>
            <li>Developing new services</li>
            <li>
              As described in the respective terms of service of each of our
              services
            </li>
          </ul>
        </p>

        <h2 className="privacy-head">No-Spam Policy</h2>
        <p className="privacy-text">
          We have a strict No-Spam Policy prohibiting the use of any data
          collected to send spam. We will not sell any contact information to
          third parties.
        </p>

        <h2 className="privacy-head">Choices for Personal Information</h2>
        <p className="privacy-text">
          When you sign up for a particular service that requires registration,
          we ask you to provide personal information. If we use this information
          in a manner different than the purpose defined herein and in the terms
          of the services offered then we will ask for your consent prior to
          such use. Most browsers are initially set up to accept cookies, but
          you can reset your browser to refuse all cookies or to indicate when a
          cookie is being sent. However, some of our features and services may
          not function properly if your cookies are disabled. You can decline to
          submit personal information to any of our services, in which case we
          may not be able to provide those services to you
        </p>

        <h2 className="privacy-head"> Information Sharing</h2>
        <p className="privacy-text">
          We only share personal information with other companies or individuals
          in the following limited circumstances:
          <ul className="privacy-list">
            <li>We have your consent</li>
            <li>
              Sharing the information with our affiliated companies or other
              trusted businesses or persons for the purpose of processing
              personal information on our behalf. We require that these parties
              agree to process such information based on our instructions and in
              compliance with this Privacy Policy and any other appropriate
              confidentiality and security measures.
            </li>
            <li>
              We have a good faith belief that access, use, preservation or
              disclosure of such information is reasonably necessary to:
              <ol className="alpha">
                <li>
                  satisfy any applicable law, regulation, legal process or
                  enforceable governmental request,
                </li>
                <li>
                  enforce applicable Terms of Service, including investigation
                  of potential violations thereof,
                </li>
                <li>
                  detect, prevent, or otherwise address fraud, security or
                  technical issues, or
                </li>
                <li>
                  protect against harm to rights, property or safety, of our
                  users, us or the public as required or permitted by law.
                </li>
              </ol>
            </li>
            <li>
              We may share with third parties certain pieces of aggregated,
              nonpersonal information, such as the number of users by
              demographic. Such information does not identify you individually.
            </li>
            <li>
              As permitted and described in the respective terms of service of
              each of our services
            </li>
          </ul>
        </p>

        <h2 className="privacy-head">Information Security</h2>
        <p className="privacy-text">
          We take appropriate security measures to protect against unauthorized
          access to or unauthorized alteration, disclosure or destruction of
          data. These include internal reviews of our data collection, storage
          and processing practices and security measures, as well as physical
          security measures to guard against unauthorized access to systems
          where we store personal data.
          <br />
          We restrict access to personal information to our employees,
          contractors and agents who need to know that information in order to
          operate, develop or improve our services. These individuals are bound
          by confidentiality obligations and may be subject to discipline,
          including termination and criminal prosecution, if they fail to meet
          these obligations.
        </p>

        <h2 className="privacy-head">
          Accessing and Updating Personal Information
        </h2>
        <p className="privacy-text">
          When you use our services, we make good faith efforts to provide you
          with access to your personal information and to correct this data if
          it is inaccurate.
        </p>

        <h2 className="privacy-head">Changes to this Privacy Policy</h2>
        <p className="privacy-text">
          Please note that this Privacy Policy may change from time to time.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
