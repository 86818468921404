import React, { useEffect, useState } from 'react'
import Footer from '../../../Container/Footer'
import Axios from 'axios'
import { Api } from '../../../Const/Api'
import { useParams } from 'react-router-dom';
import { Dateconvert } from '../../../Const/data'
import Socialshare from '../../../Components/Socialshare'

const Pressdesciption = () => {
    const [image, setImage] = useState('')
    const [title, settitle] = useState('')
    const [date, setDate] = useState('')
    const [description, setDescription] = useState('')
    let { id } = useParams();

    useEffect(() => {
        document.getElementById("navTop").style.background = "#10152c";
        Axios.get(Api.BaseUrl + '/api/press/' + id)
            .then((res) => {
                setImage(res.data.data.list.image_url)
                settitle(res.data.data.list.title)
                setDate(res.data.data.list.date)
                setDescription(res.data.data.list.description)
            })
    }, [])

    return (
        <>
            <div className="single-post mt-73 py-5">
                <div className="singlepost-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="singlepost-banner">
                                    <img src={image} alt="Post Banner" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 py-4">
                                <h1 className="post-title">{title}</h1>
                                <p className="post-date mb-2">{Dateconvert(date, 4)}
                                    {/* <span className="post-category">Tech</span> */}
                                </p>
                            </div>
                            <div className="col-md-9">
                                <div className="post-content mb-md-5">
                                    <span dangerouslySetInnerHTML={{ __html: description }}></span>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <Socialshare url={window.location.href} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Pressdesciption
