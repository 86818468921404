const data = {
    btnclassexpand: false,
    newclass: '',
    btnclassadd : false
}

const classesadd = (state = data, { type, payload }) => {
    switch (type) {
        case 'BTN_CLASS_ADD':
            return {
                ...state,
                btnclassexpand: payload
            }
        case 'DIV_CLASS_ADD':
            return {
                ...state,
                newclass: payload
            }
        case 'BTN_EXPAND_ADD':
            return {
                ...state,
                btnclassadd: payload
            }
        default:
            return state;
    }
}

export default classesadd