import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./OurCommunities.css";
import { Helmet } from "react-helmet";

import Navbar from "../../Container/Navbar";
import bulbLogo from "../../Assets/img/the-bulb-logo-dark.png";
import Footer from "../../Container/Footer";

import ImageCard from "../../Components/Card/ImageCard";
import TextCard from "../../Components/Card/TextCard";
import IncubatorCards from "../StartupEngine/Incubator/components/IncubatorCards/IncubatorCards";
import Button from "../../Components/Button/Button";

import heroImg from "../../Assets/img/communities-hero-img.png";
import space from "../../Assets/img/communities-space.png";
import standingLady from "../../Assets/img/standing-lady.png";
import standingLady2 from "../../Assets/img/standing-lady-2.png";
import alumni from "../../Assets/img/alumni-img.png";
import cloudLink from "../../Assets/img/cloud-link.png";
import grandMasterKey from "../../Assets/img/grand-master-key.png";
import womanStretch from "../../Assets/img/woman-stretching.png";
import folder from "../../Assets/img/folder.png";
import businessWoman from "../../Assets/img/business-woman.png";
import training from "../../Assets/img/training.png";
import talentsImg from "../../Assets/img/talents-img.png";
import talentsImg1 from "../../Assets/img/talents-img1.png";
import { Link } from "react-router-dom";

const OurCommunities = () => {
  const miniCardsData = [
    {
      image: "ladies-drinking.png",
      caption: "Come hangout with us",
      hyperLink: true,
      destination: "https://discord.gg/v7Uzp9xvjg",
    },
    {
      image: "lady-yellow-bg.png",
      caption: "Join Tech Jewel",
      hyperLink: true,
      destination: "https://t.me/+mHzrmmoAx9RkY2Q0",
    },
    {
      image: "brain.png",
      caption: "Discover what they are up to",
      hyperLink: false,
      destination: "#",
    },
    {
      image: "the-desk.png",
      caption: "Learn about the desk",
      hyperLink: false,
      destination: "/co-workspace",
    },
    {
      image: "coffee.png",
      caption: "Join the conversation",
      hyperLink: true,
      destination: "https://leverage.thebulb.africa",
    },
    {
      image: "boy.png",
      caption: "Join the club of future leaders",
      hyperLink: true,
      destination: "https://nextgen.africa/",
    },
  ];

  useEffect(() => {
    // document.getElementById("navTop").style.display = "none";
    // const items = document.getElementsByClassName("nav-link");
    // for (let i = 0; i < items.length; i++) {
    //   items[i].style.color = "#10152c";
    // }
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Helmet>
        {/*TITLE TAGS & META TAGS*/}

        <title>Join our Community for Tech Talents | The Bulb Africa</title>

        <meta
          name="title"
          content="Join our Community for Tech Talents | The Bulb Africa "
        />
        <meta
          name="description"
          content="Want to grow your professional network? Connect with tech experts. We’ve got a place for you; tech jewel, co-workspace, leverage and the alumni community."
        />
        {/* Open Graph meta tags */}

        <meta
          property="og:title"
          content="Join our Community for Tech Talents | The Bulb Africa"
        />
        <meta
          property="og:url"
          content="https://thebulb.africa/our-communities"
        />
        <meta
          property="og:description"
          content="Want to grow your professional network? Connect with tech experts. We’ve got a place for you; tech jewel, co-workspace, leverage and the alumni community. "
        />
        <meta
          property="og:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta property="og:image:alt" content="The Bulb Africa- offical logo" />
        {/* twitter meta tags */}

        <meta
          name="twitter:title"
          content="Join our Community for Tech Talents | The Bulb Africa"
        />
        <meta
          name="twitter:description"
          content="Want to grow your professional network? Connect with tech experts. We’ve got a place for you; tech jewel, co-workspace, leverage and the alumni community. "
        />
        <meta
          name="twitter:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta name="twitter:image:alt" content="The Bulb- offical logo" />
        <meta name="twitter:site" content="@TheBulbAfrica" />
        <meta name="twitter:card" content="summary" />
        {/* JSON LD Structured Data */}

        <script type="application/ld+json">
          {`
            {
            "@context": "https://schema.org/",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://thebulb.africa/our-communities"
            },
            "headline": "Join our Community for Tech Talents | The Bulb Africa",
            "description": "Want to grow your professional network? Connect with tech experts. We’ve got a place for you; tech jewel, co-workspace, leverage and the alumni community.",
            "image": "https://thebulb.africa/Images/homepage/logo.png",
            "author": {
              "@type": "Organization",
              "name": "The Bulb Africa"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Daniel",
              "logo": {
                "@type": "ImageObject",
                "url": "https://thebulb.africa/static/media/talents-img1.57f8b05f.png"
              }
            },
            "datePublished": "2020-01-20",
            "dateModified": "2022-09-21"
            }
          `}
        </script>
      </Helmet>
      <div className="fixed-nav zee">
        <Navbar navImage={bulbLogo} alt="Careers" altLink="#" isLight={true} />
      </div>
      <div className="hero">
        <div className="hero-text">
          <div className="hero-text-emoji">
            <div className="whats-new">What’s new?</div>
            <span>
              You belong here
              <img
                src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/apple/325/two-hearts_1f495.png"
                alt="communities-hero-img.png"
                className="heart"
              />
            </span>
          </div>

          <div>
            <h1 className="hero-text-heading">
              Open communities for young tech talents
            </h1>
            <p className="hero-text-para">
              From founders to professionals and newbie tech talents, The
              Community has something for everyone. Connect, learn, and grow
              with technology-inclined individuals across Africa.
            </p>
          </div>
          <div>
            <Button
              isHyperLink={true}
              cta="Come hangout with us"
              location="https://discord.gg/v7Uzp9xvjg"
              isLight={false}
            />
          </div>
        </div>

        <div className="hero-img">
          <div className="hero-img-box-top"></div>
          <img
            src={heroImg}
            alt="communities-hero-img.png"
            className="hero-img-box"
          />
          <div className="hero-img-box-bottom"></div>
        </div>
      </div>

      <div className="talents" data-aos="fade-up" data-aos-duration="500">
        <div className="talents-img">
          <img
            src={talentsImg1}
            data-aos="zoom-in"
            data-aos-duration="1000"
            alt=""
          />
        </div>
        <div className="talents-text">
          <h2>For tech talents, by tech talents </h2>
          <p>
            Share knowledge and upskill with new tech recruits, professional
            programmers, and developers. Receive expert answers to the questions
            you can’t find everywhere else in our community.
          </p>
        </div>
      </div>
      <div>
        <div className="communities-card-container">
          <div className="communities-card-content reverse">
            <TextCard
              className="communities-text-card"
              headingClassName="communities-text-card-heading"
              descriptionClassName="communities-text-card-description"
              isHyperLink={true}
              heading="Tech Jewel empowers African women in tech to upskill and foster impactful innovations"
              description="A non-profit community for women in tech by women in tech. With a growing community of about 500 women, we inspire and help women develop new skillsets, excel in their careers, and facilitate access to capital for their business. "
              cta="Join Tech Jewel "
              location="https://t.me/+mHzrmmoAx9RkY2Q0"
            />
            <ImageCard
              source={standingLady}
              className="communities-image-card color1"
              animationDuration="1000"
            />
          </div>
          <div className="communities-card-content">
            <ImageCard
              source={space}
              className="communities-image-card color2"
              animationDuration="1000"
            />
            <TextCard
              className="communities-text-card"
              headingClassName="communities-text-card-heading"
              descriptionClassName="communities-text-card-description"
              isAppLink={true}
              heading="Need a space to call your own?"
              description="Dedicated desks, fiber-optic Wi-Fi, private meeting rooms, an outdoor lounge, and a community focused on your needs and success are just a few  reasons to fall in love with our co-working space!"
              description2="Desk at The Bulb is a multi-location functional shared office for tech talents and startups to build the next big thing."
              cta="Learn about The Desk "
              location="/co-workspace"
            />
          </div>
        </div>

        <div className="we-offer">
          <div className="we-offer-main">
            <h2>Opportunities we offer</h2>
            <div className="we-offer-body">
              <IncubatorCards
                icon={cloudLink}
                text="Connection to the best jobs and opportunities within the tech ecosystem."
                className="ic-width"
              />
              <IncubatorCards
                icon={grandMasterKey}
                text="Exclusive access to verified grants for female-owned businesses."
                className="ic-width"
              />
              <IncubatorCards
                icon={womanStretch}
                text="A dedicated desk in a technology inclined community for individuals and businesses."
                className="ic-width"
              />
              <IncubatorCards
                icon={folder}
                text="Tech Jewel Pitch Day for female founders to present their ideas to investors and receive funding. "
                className="ic-width"
              />
              <IncubatorCards
                icon={businessWoman}
                text="A thought leadership platform for tech professionals and CEOs to discuss relevant technological ideas."
                className="ic-width"
              />
              <IncubatorCards
                icon={training}
                text="Room2Grow, a year-long fellowship training program that equips indigent African women with in-demand tech skills. "
                className="ic-width"
              />
            </div>
          </div>
        </div>

        <div className="communities-card-container">
          <div className="communities-card-content reverse">
            <TextCard
              className="communities-text-card"
              headingClassName="communities-text-card-heading"
              descriptionClassName="communities-text-card-description"
              isHyperLink={true}
              heading="Learn from renowned speakers and discover revolutionary ideas that can transform Africa "
              description="Leverage is a community for tech experts and startup owners to pitch innovative ideas and discuss relevant technological issues that could lead to more fascinating discoveries, driving further growth and development in the continent. "
              cta="Join the conversation "
              location="https://leverage.thebulb.africa"
            />
            <ImageCard
              source={standingLady2}
              className="communities-image-card color1"
              animationDuration="1000"
            />
          </div>
          <div className="communities-card-content">
            <ImageCard
              source={alumni}
              className="communities-image-card color2"
              animationDuration="1000"
            />
            <TextCard
              className="communities-text-card"
              headingClassName="communities-text-card-heading"
              descriptionClassName="communities-text-card-description"
              isAppLink={true}
              self={false}
              heading="Our fellowship doesn’t end at graduation "
              description="The Alumni is a community of tech experts who have scaled through our fellowship program. They are among the best and brightest engineers and designers carving their path in Africa’s tech ecosystem. "
              cta="Discover what they are up to	"
              location="#"
            />
          </div>
        </div>
      </div>

      <div className="unlock">
        <h2>
          Unlock access to limitless tech possibilities with the Community
        </h2>
        <p>
          Share knowledge and up-skill with new tech recruits, professional
          programmers, and developers. Receive expert answers to the questions
          you can’t find everywhere else in our community.
        </p>
      </div>
      {/* <MiniCards data={miniCardsData} /> */}

      <div className="communities-mini-cards">
        <div
          className="long mini-card"
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          {miniCardsData[0].hyperLink ? (
            <a
              href={miniCardsData[0].destination}
              className="link"
              target="_blank"
              rel="noreferrer noopenner"
            >
              <img
                src={require(`../../Assets/img/bottom-cards/${miniCardsData[0].image}`)}
                alt={miniCardsData[0].image}
                className="img-radius mini-card-img"
              />
              <h3 style={{ padding: "20px", textAlign: "center" }}>
                {miniCardsData[0].caption}
              </h3>
            </a>
          ) : (
            <Link to={miniCardsData[0].destination} className="link">
              <img
                src={require(`../../Assets/img/bottom-cards/${miniCardsData[0].image}`)}
                alt={miniCardsData[0].image}
                className="img-radius mini-card-img"
              />
              <h3 style={{ padding: "20px", textAlign: "center" }}>
                {miniCardsData[0].caption}
              </h3>
            </Link>
          )}
        </div>
        <div className="group">
          <div
            className="bigger mini-card"
            data-aos="zoom-in"
            data-aos-duration="500"
          >
            {miniCardsData[1].hyperLink ? (
              <a
                href={miniCardsData[1].destination}
                className="link"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img
                  src={require(`../../Assets/img/bottom-cards/${miniCardsData[1].image}`)}
                  alt={miniCardsData[1].image}
                  className="img-radius mini-card-img"
                />
                <h3 style={{ padding: "20px", textAlign: "center" }}>
                  {miniCardsData[1].caption}
                </h3>
              </a>
            ) : (
              <Link to={miniCardsData[1].destination} className="link">
                <img
                  src={require(`../../Assets/img/bottom-cards/${miniCardsData[1].image}`)}
                  alt={miniCardsData[1].image}
                  className="img-radius mini-card-img"
                />
                <h3 style={{ padding: "20px", textAlign: "center" }}>
                  {miniCardsData[1].caption}
                </h3>
              </Link>
            )}
          </div>
          <div
            className="smaller mini-card"
            data-aos="zoom-in"
            data-aos-duration="500"
          >
            {miniCardsData[2].hyperLink ? (
              <a
                href={miniCardsData[2].destination}
                className="link"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img
                  src={require(`../../Assets/img/bottom-cards/${miniCardsData[2].image}`)}
                  alt={miniCardsData[2].image}
                  className="img-radius mini-card-img"
                />
                <h3 style={{ padding: "20px", textAlign: "center" }}>
                  {miniCardsData[2].caption}
                </h3>
              </a>
            ) : (
              <Link to={miniCardsData[2].destination} className="link">
                <img
                  src={require(`../../Assets/img/bottom-cards/${miniCardsData[2].image}`)}
                  alt={miniCardsData[2].image}
                  className="img-radius mini-card-img"
                />
                <h3 style={{ padding: "20px", textAlign: "center" }}>
                  {miniCardsData[2].caption}
                </h3>
              </Link>
            )}
          </div>
        </div>
        <div
          className="long mini-card"
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          {miniCardsData[3].hyperLink ? (
            <a
              href={miniCardsData[3].destination}
              className="link"
              target="_blank"
              rel="noreferrer noopenner"
            >
              <img
                src={require(`../../Assets/img/bottom-cards/${miniCardsData[3].image}`)}
                alt={miniCardsData[3].image}
                className="img-radius mini-card-img"
              />
              <h3 style={{ padding: "20px", textAlign: "center" }}>
                {miniCardsData[3].caption}
              </h3>
            </a>
          ) : (
            <Link to={miniCardsData[3].destination} className="link">
              <img
                src={require(`../../Assets/img/bottom-cards/${miniCardsData[3].image}`)}
                alt={miniCardsData[3].image}
                className="img-radius mini-card-img"
              />
              <h3 style={{ padding: "20px", textAlign: "center" }}>
                {miniCardsData[3].caption}
              </h3>
            </Link>
          )}
        </div>
        <div className="group end-group">
          <div
            className="smaller mini-card"
            data-aos="zoom-in"
            data-aos-duration="500"
          >
            {miniCardsData[4].hyperLink ? (
              <a
                href={miniCardsData[4].destination}
                className="link"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img
                  src={require(`../../Assets/img/bottom-cards/${miniCardsData[4].image}`)}
                  alt={miniCardsData[4].image}
                  className="img-radius mini-card-img"
                />
                <h3 style={{ padding: "20px", textAlign: "center" }}>
                  {miniCardsData[4].caption}
                </h3>
              </a>
            ) : (
              <Link to={miniCardsData[4].destination} className="link">
                <img
                  src={require(`../../Assets/img/bottom-cards/${miniCardsData[4].image}`)}
                  alt={miniCardsData[4].image}
                  className="img-radius mini-card-img"
                />
                <h3 style={{ padding: "20px", textAlign: "center" }}>
                  {miniCardsData[4].caption}
                </h3>
              </Link>
            )}
          </div>
          <div
            className="bigger mini-card end-card"
            data-aos="zoom-in"
            data-aos-duration="500"
          >
            {miniCardsData[5].hyperLink ? (
              <a
                href={miniCardsData[5].destination}
                className="link"
                target="_blank"
                rel="noreferrer noopenner"
              >
                <img
                  src={require(`../../Assets/img/bottom-cards/${miniCardsData[5].image}`)}
                  alt={miniCardsData[5].image}
                  className="img-radius mini-card-img"
                />
                <h3 style={{ padding: "20px", textAlign: "center" }}>
                  {miniCardsData[5].caption}
                </h3>
              </a>
            ) : (
              <Link to={miniCardsData[5].destination} className="link">
                <img
                  src={require(`../../Assets/img/bottom-cards/${miniCardsData[5].image}`)}
                  alt={miniCardsData[5].image}
                  className="img-radius mini-card-img"
                />
                <h3 style={{ padding: "20px", textAlign: "center" }}>
                  {miniCardsData[5].caption}
                </h3>
              </Link>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default OurCommunities;
