import React, { useEffect } from 'react'
import Footer from '../../Container/Footer'
import Contactblock from '../../Components/Contactarea'
import Contactmap from '../../Components/Contactmap'
import { Helmet } from 'react-helmet';

const Contact = () => {
	// useEffect(() => {
	// 	document.getElementById("navTop").style.background = "#10152c";
	// 	document.getElementById("navTop").style.display = "flex";
	// 	const items = document.getElementsByClassName("nav-link");
	// 	for (let i = 0; i < items.length; i++) {
	// 		items[i].style.color = "#fff";
	// 	}
	// }, [])

	return (
		<>
			<Helmet>
				{/*TITLE TAGS & META TAGS*/}

				<title>  Get In Touch start| The Bulb Africa | Tech Hub  </title>

				<meta name="title" content="Get In Touch | The Bulb Africa | Tech Hub " />
				<meta name="description" content="Contact Us. Your feedback and questions help us to serve you better. We'd love to hear from you. 39 Ikorodu Road, Jibowu, Yaba, Lagos. The Bulb Africa.  " />
				{/* Open Graph meta tags */}

				<meta property="og:title" content="Get In Touch|The Bulb Africa | Tech Hub " />
				<meta property="og:url" content="https://thebulb.africa/contact" />
				<meta property="og:description" content="Contact Us. Your feedback and questions help us to serve you better. We'd love to hear from you. 39 Ikorodu Road, Jibowu, Yaba, Lagos. The Bulb Africa." />
				<meta property="og:image" content="https://thebulb.africa/Images/homepage/logo.png" />
				<meta property="og:image:alt" content="The Bulb Africa- offical logo" />

				{/* twitter meta tags */}
				<meta name="twitter:title" content="Get In Touch|The Bulb Africa | Tech Hub " />
				<meta name="twitter:description" content="Contact Us. Your feedback and questions help us to serve you better. We'd love to hear from you. 39 Ikorodu Road, Jibowu, Yaba, Lagos. The Bulb Africa. " />
				<meta name="twitter:image" content="https://thebulb.africa/Images/homepage/logo.png" />
				<meta name="twitter:image:alt" content="The Bulb- offical logo" />
				<meta name="twitter:site" content="@TheBulbAfrica" />
				<meta name="twitter:card" content="summary" />

				{/* JSON LD Structured Data */}
				<script type="application/ld+json">
					{
						`{
							"@context": "https://schema.org",
							"@type": "LocalBusiness",
							"name": "The Bulb",
							"image": "https://thebulb.africa/Images/homepage/logo.png",
							"@id": "https://thebulb.africa/#localbusiness",
							"url": "https://thebulb.africa/contact",
							"telephone": "+234700843 2852",
							"address": {
								"@type": "PostalAddress",
								"streetAddress": "39 Ikorodu Road",
								"addressLocality": "Lagos",
								"postalCode": "102215",
								"addressCountry": "NG",
								"addressRegion": "Lagos"
							},
							"priceRange": "The Bulb Africa",
							"geo": {
								"@type": "GeoCoordinates",
								"latitude": "6.521701389386185",
								"longitude": "3.3674550683252056"
							},
							"sameAs": [
								"https://twitter.com/TheBulbAfrica",
								"https://www.facebook.com/TheBulbAfrica",
								"https://www.linkedin.com/company/the-bulb-africa",
								"https://www.youtube.com/channel/UCY7cQnufm6qasQfXnSr5muw"
							],
							"openingHoursSpecification": [
								{
								"@type": "OpeningHoursSpecification",
								"dayOfWeek": [
									"Monday",
									"Tuesday",
									"Wednesday",
									"Thursday",
									"Friday"
								],
								"opens": "08:00",
								"closes": "19:00"
								}
							]
						}`
					}
				</script>
			</Helmet>
			{/* <Navbar
				// closeAll={setState}
				navImage={theBulbLogoDark}
				about="/aboutus"
				alt="Careers"
				altLink="#"
			/> */}
			<div className="contactus-Main">
				<div className="contact-inner">
					<div className="contact-first bg-light-gray py-5">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-md-6">
									<Contactmap
										addressheading={"Our address"}
										houseno={"39 Ikorodu Road,"}
										address={"Jibowu, Yaba, Lagos"}
										country={"Nigeria"}
										operation={"Hours of operation"}
										timing={"7 a.m. - 9 p.m."}
										day={"Monday - Sunday"}
										button={true}
									/>
								</div>
								<div className="col-md-6">
									<div className="googlemap">
										<iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15856.003339599858!2d3.3673051!3d6.5215752!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x61e31411b1bc213b!2sThe%20Bulb%20Africa!5e0!3m2!1sen!2sng!4v1580788765203!5m2!1sen!2sng" width="100%" height="350" frameBorder="0" allowFullScreen=""></iframe>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="contact-third bg-light-gray py-5">
						<div className="container pt-3">
							<div className="row">
								<div className="col-md-4">
									<Contactblock
										name={"Name goes here"}
										position={"Position goes here"}
										contact={"+2347008432852"}
										mail={"info@thebulb.africa"}
										heading={true}
										headingname={"General contact info"}
										content={true}
									/>
								</div>
								<div className="col-md-4">
									<Contactblock
										heading={true}
										headingname={"Mailing address"}
										mailing={true}
										position1={"The Bulb Africa"}
										address={"39 Ikorodu Road, Jibowu, Yaba,Lagos, Nigeria 100252"}
									/>
								</div>
								<div className="col-md-4">
									<Contactblock
										// name={"The Bulb Africa"}
										// position={"Human Resources Team"}
										// contact={"+234 906 200 0305"}
										mail={"careers@thebulb.africa"}
										headingname={"Careers contact"}
										heading={true}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
export default Contact

