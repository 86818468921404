import React, { useState } from 'react';
import Modal from '../../Components/Modal';
import { withRouter } from 'react-router-dom';
import { Api } from '../../Const/Api';
import axios from 'axios';

export default withRouter(function IndividualForm({ history }) {
	const [developerData, setDeveloperData] = useState({});
	const [Loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const [modelItems, setModelItems] = useState({
		text: '',
		btnText: '',
		imgUrl: require('../../Assets/img/fail.png'),
		handleModal: ''
	});

	const handleModal = () => {
		setShowModal(false);
		history.push('/tour');
	};
	const closeModal = () => {
		setShowModal(false);
	};

	const handleInputChange = e => {
		setDeveloperData({
			...developerData,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = e => {
		setLoading(true);
		e.preventDefault();

		axios
			.post(`${Api.BaseUrl}tour/saveIndividualRequest`, developerData)
			.then(function (response) {
				console.log({ response });
				setLoading(false);
				setShowModal(true);
				if (response.status === 200) {
					return setModelItems({
						text:
							'Your request to take a tour has been successfully submitted, you will be contacted after a careful review of your application.',
						btnText: 'Home',
						imgUrl: '',
						handleModal
					});
				}
				return setModelItems({
					text:
						'Hey, something went wrong with your submission. Please return to the form page and make sure all fields are completed and resubmit',
					btnText: 'Close',
					imgUrl: require('../../Assets/img/fail.png'),
					handleModal: closeModal
				});
			})
			.catch(error => {
				console.log({ error });
				setLoading(false);
				setShowModal(true);
				return setModelItems({
					text:
						'Hey, something went wrong with your submission. Please return to the form page and make sure all fields are completed and resubmit',
					btnText: 'Close',
					imgUrl: require('../../Assets/img/fail.png'),
					handleModal: closeModal
				});
			});
	};

	return (
		<>
			{showModal && (
				<Modal
					text={modelItems.text}
					btnText={modelItems.btnText}
					imgUrl={modelItems.imgUrl}
					handleModal={modelItems.handleModal}
				/>
			)}
			<form className="px-5 pb-5 pt-3 " onSubmit={handleSubmit}>
				<div className="row flex-column flex-lg-row">
					<div className="form-group mt-3 col-lg-4">
						<div className="required">*</div>
						<label htmlFor="title" className="pl-1 pr-2">
							Title
						</label>
						<input
							type="text"
							className="form-control"
							id="title"
							name="title"
							required
							onChange={handleInputChange}
						/>
					</div>
					<div className="form-group mt-3 col-lg-4">
						<div className="required">*</div>
						<label htmlFor="first_name" className="pl-1 pr-2">
							First Name
						</label>
						<input
							type="text"
							className="form-control"
							name="first_name"
							id="first_name"
							required
							onChange={handleInputChange}
						/>
					</div>
					<div className="form-group mt-3 col-lg-4">
						<div className="required">*</div>
						<label htmlFor="last_name" id="last_name" className="pl-1 pr-2">
							Last Name
						</label>
						<input
							type="text"
							className="form-control"
							name="last_name"
							id="last_name"
							required
							onChange={handleInputChange}
						/>
					</div>
				</div>
				<div className="row flex-column flex-lg-row">
					<div className="form-group mt-3 col-lg-4">
						<div className="required">*</div>
						<label htmlFor="phone" className="pl-1 pr-2">
							Phone number
						</label>

						<input
							type="tel"
							pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
							maxLength={14}
							className="form-control"
							name="phone"
							id="phone"
							required
							onChange={handleInputChange}
						/>
					</div>
					<div className="form-group mt-3 col-lg-4">
						<div className="required">*</div>
						<label htmlFor="email" className="pl-1 pr-2">
							Email address
						</label>
						<input
							type="email"
							className="form-control"
							name="email"
							id="email"
							required
							onChange={handleInputChange}
						/>
					</div>
					<div className="form-group mt-3 col-lg-4">
						<div className="required-non">*</div>
						<label htmlFor="company_url" className="pl-1 pr-2">
							Company URL
						</label>
						<input
							type="url"
							className="form-control"
							name="company_url"
							id="company_url"
							onChange={handleInputChange}
						/>
					</div>
				</div>

				<div className="form-group mt-3">
					<div className="required">*</div>
					<label htmlFor="reasonfor" className="pl-1 pr-2" id="reason">
						Reason for the visit
					</label>

					<textarea
						name="reason"
						id="reasonfor"
						className="form-control px-4 "
						onChange={handleInputChange}
						required
					></textarea>
				</div>

				<div className="form-group mt-3 ">
					<div className="required-non">*</div>
					<label htmlFor="additional_info" className="pl-1 pr-2" id="reason">
						Additional Information
					</label>

					<textarea
						name="additional_info"
						id="additional_info"
						className="form-control "
						onChange={handleInputChange}
					></textarea>
				</div>

				<button className="btn button  mb-5  p-2" disabled={Loading}>
					{' '}
					Submit
					{Loading && (
						<div className="spinner-grow sm bg-white ml-1 " role="status">
							<span className="sr-only">Loading...</span>
						</div>
					)}
				</button>
			</form>
		</>
	);
});
