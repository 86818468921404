import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Back from '../../Components/Back/Back';
import axios from 'axios';
import { Api } from '../../Const/Api';
import moment from 'moment';
import Comment from '../../Components/Comment';
import Share from '../../Components/Share';
import { minRead } from '../../Const/function';
import './index.css';
import sanitizeHtml from 'sanitize-html-react';
import PlaceHolder from '../../Assets/img/placeholder.png';

import { Box, HStack, Text, Image } from '@chakra-ui/react';

import { SVGHeader } from '../../Components/SVG/header';
import RelatedArticles from '../../Components/RelatedArticles';

function BlogPost({ location }) {
	const [blogPosts, setBlogPosts] = useState([]);
	const [Loading, setLoading] = useState(true);
	const id = location.pathname.split('/')[2];
	const blog_id = id;

	const getSinglePost = React.useCallback(() => {
		axios.get(`${Api.BaseUrl}/press/show/${id}`, {}).then(function (response) {
			if (response.status === 200) {
				setLoading(false);
				console.log(response.data.payload.message);
				setBlogPosts(response.data.payload.message);
			}
		});
	}, [id]);

	useEffect(() => {
		document.getElementById('navTop').style.background = '#10152c';
		window.scrollTo(0, 0);
		getSinglePost();
	}, [getSinglePost]);
	const photo = blogPosts?.picture ? blogPosts?.picture?.split('"')?.[1] : '';
	const avgReadPerMin = minRead(blogPosts?.content);
	const commentLength = blogPosts?.Comments?.length;
	return (
		<Box pos="relative" py="50px">
			<SVGHeader />
			<Box pos="relative" maxW="5xl" px="10px" mx="auto">
				<Box mx="10px" w="full" h="30px">
					<Back left="10px" top="0px" />
				</Box>

				{Loading ? (
					<div className="spinner-grow mt-5 mb-5 mx-auto" role="status">
						<span className="sr-only">Loading...</span>
					</div>
				) : (
					<Box mt="40px">
						<Box className="">
							<HStack
								color="#28314F"
								fontSize={{ base: '12px', md: '16px' }}
								lineHeight={{ base: '15px', md: '19px' }}
								spacing="24px"
							>
								<Text>Data</Text>
								<Text>Tech</Text>
								<Text>Design</Text>
							</HStack>

							<Box
								color="#10162B"
								fontSize={{ base: '26px', md: '26px' }}
								lineHeight={{ base: '32px', md: '44px' }}
								className="title mt-3"
								dangerouslySetInnerHTML={{ __html: sanitizeHtml(blogPosts?.heading) }}
							/>

							<HStack
								color="#9EA3B4"
								fontSize={{ base: '12px', md: '16px' }}
								lineHeight={{ base: '15px', md: '19px' }}
								spacing={{ base: '2px', md: '14px' }}
								mt="20px"
							>
								<Text>{moment(blogPosts?.updated_at).format('Do MMMM  YYYY')}</Text>
								<svg
									width="21"
									height="2"
									viewBox="0 0 21 2"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M21 1L6.55651e-07 1" stroke="#9EA3B4" />
								</svg>

								<Text>
									{avgReadPerMin} {avgReadPerMin > 1 ? 'mins' : 'min'} read
								</Text>
								<svg
									width="21"
									height="2"
									viewBox="0 0 21 2"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="M21 1L6.55651e-07 1" stroke="#9EA3B4" />
								</svg>

								<Text>By {blogPosts?.User?.[0]?.username}</Text>
							</HStack>

							<Box display="flex" mt="50px" maxH="502px" h={{ base: '350px', md: '502px' }} w="100%">
								<Share title={blogPosts?.heading} />
								<Image
									flex="1"
									maxW="80%"
									maxH="100%"
									fallbackSrc={PlaceHolder}
									src={photo}
									alt={blogPosts.heading ?? 'Blog post'}
									style={{ objectFit: 'cover' }}
								/>
							</Box>
							<Box mt="50px" className="text-section ">
								<p dangerouslySetInnerHTML={{ __html: blogPosts?.content }} />
							</Box>
							<Box className="my-5">
								{commentLength > 0 && (
									<Text
										color="#10162B"
										fontWeight="bold"
										fontSize={{ base: '20px', md: '24px' }}
										lineHeight={{ base: '24px', md: '29px' }}
										className="number-of-comment mt-3 mb-5"
									>
										{commentLength} {commentLength > 1 ? 'Comments' : 'Comment'}
									</Text>
								)}

								<Comment id={id} blogPosts={blogPosts} type="press_id" getSinglePost={getSinglePost} />
							</Box>
						</Box>
					</Box>
				)}
			</Box>
			{
				(blogPosts.related_articles !== undefined) ?
				<RelatedArticles articles={blogPosts.related_articles}/> : ''
			}
		</Box>
	);
}

export default BlogPost;
