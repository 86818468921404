import Axios from 'axios'
import { Api } from '../Const/Api'
// https://melodymantra.com/api/submitidea

export const Submitidea = (data) => {
    // console.log("data", data)
    return new Promise((resolve, reject) => {
        Axios.post('https://melodymantra.com/api/submitidea', data)
            .then(resp => {
                // console.log("resp=== of function",resp)
                if (resp.status === 200) {
                    resolve(resp.data)
                }
                else {
                    // console.log("catch sumit idea", resp)
                    reject(resp.message)
                }
            })
            .catch(err => {
                reject("Something Went Wrong Please Try Again latter")
            })
    })
}

export const Applyincubator = (data) => {
    return new Promise((resolve, reject) => {
        Axios.post(Api.BaseUrl + "/api/applyforincubator", data)
            .then(resp => {
                if (resp.status === 200) {
                    resolve(resp.data)
                }
                else {
                    reject("please try again")
                }
            }).catch(err => {
                reject("Something Went Wrong Please Try Again latter")
            })
    })
}

export const Hiredeveloper = (data) => {
    return new Promise((resolve, reject) => {
        Axios.post(Api.BaseUrl + "/api/hire", data)
            .then(resp => {
                if (resp.status === 200) {
                    resolve(resp.data)
                }
                else {
                    reject("please try again")
                }
            }).catch(err => {
                reject("Something Went Wrong Please Try Again latter")
            })
    })
}

export const Partnership = (data) => {
    return new Promise((resolve, reject) => {
        Axios.post(Api.BaseUrl + "/api/partnership", data)
            .then(resp => {
                if (resp.status === 200) {
                    resolve(resp.data)
                }
                else {
                    reject("please try again")
                }
            }).catch(err => {
                reject("Something Went Wrong Please Try Again latter")
            })
    })
}

export const Schedulevisit = (data) => {
    return new Promise((resolve, reject) => {
        Axios.post(Api.BaseUrl + "/api/schedulevisit", data)
            .then(resp => {
                // console.log("res submit idea", resp)
                if (resp.status === 200) {
                    resolve(resp.data)
                }
                else {
                    reject("please try again")
                }
            }).catch(err => {
                reject("Something Went Wrong Please Try Again latter")
            })
    })
}


export const Requestatour = (data) => {
    return new Promise((resolve, reject) => {
        Axios.post(Api.BaseUrl + "/api/requesttour", data)
            .then(resp => {
                if (resp.status === 200) {
                    resolve(resp.data)
                }
                else {
                    reject("please try again")
                }
            })
            .catch(err => {
                reject("Something Went Wrong Please Try Again latter")
            })
    })
}

export const Careerpost = (data) => {
    return new Promise((resolve, reject) => {
        Axios.post(Api.BaseUrl + "/api/career", data)
            .then(resp => {
                if (resp.status === 200) {
                    resolve(resp.data)
                }
                else {
                    reject("please try again")
                }
            })
            .catch(err => {
                reject("Something Went Wrong Please Try Again latter")
            })
    })
}