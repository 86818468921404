import React, { useState, useEffect } from 'react'
// import bulb from '../../Assets/img/bulb_light.svg'
import { connect } from 'react-redux'

const Commingsoon = ({ headerv, clasname, clasnamebtn, btnclassadd, changetype, traningselect, calenderselect, callcalenderselect }) => {

  

    useEffect(() => {
        const script = document.createElement("script");
            script.src = "./js/animate-bg.js";
        script.async = true;
        // document.body.appendChild(script);
        document.head.appendChild(script);

    })

    useEffect(() => {
        headerv && closeAll()
    }, [headerv])

    useEffect(() => {
        var TxtType = function (el, toRotate, period) {
            this.toRotate = toRotate;
            this.el = el;
            this.loopNum = 0;
            this.period = parseInt(period, 10) || 2000;
            this.txt = '';
            this.tick();
            this.isDeleting = false;
        };

        TxtType.prototype.tick = function () {
            var i = this.loopNum % this.toRotate.length;
            var fullTxt = this.toRotate[i];

            if (this.isDeleting) {
                this.txt = fullTxt.substring(0, this.txt.length - 1);
            } else {
                this.txt = fullTxt.substring(0, this.txt.length + 1);
            }

            this.el.innerHTML = '<span class="wrap">' + this.txt + '</span>';

            var that = this;
            var delta = 200 - Math.random() * 100;

            if (this.isDeleting) { delta /= 2; }

            if (!this.isDeleting && this.txt === fullTxt) {
                delta = this.period;
                this.isDeleting = true;
            } else if (this.isDeleting && this.txt === '') {
                this.isDeleting = false;
                this.loopNum++;
                delta = 500;
            }

            setTimeout(function () {
                that.tick();
            }, delta);
        };

        window.onload = function () {
            var elements = document.getElementsByClassName('typewrite');
            for (var i = 0; i < elements.length; i++) {
                var toRotate = elements[i].getAttribute('data-type');
                var period = elements[i].getAttribute('data-period');
                if (toRotate) {
                    new TxtType(elements[i], JSON.parse(toRotate), period);
                }
            }
            // INJECT CSS
            var css = document.createElement("style");
            css.type = "text/css";
            css.innerHTML = ".typewrite > .wrap {}";
            document.body.appendChild(css);
        };

    }, [])

    const [setindidual, setSetindidual] = useState(false)
    const [corporate, setCorporate] = useState(false)
    const [ngo, setNgo] = useState(false)
    const [closemenubar, setClosemenubar] = useState(true)

    function addClass() {
        clasname(closemenubar)
        clasnamebtn(closemenubar)
        btnclassadd(closemenubar)
    }

    function closeAll() {
        setSetindidual(false)
        setCorporate(false)
        setNgo(false)
    }

    function inover() {
        setSetindidual(false)
        setCorporate(false)
        setNgo(false)
    }

    function onmouseiver() {
        setSetindidual(true)
        setCorporate(false)
        setNgo(false)
    }

    function corporatehover() {
        setCorporate(false)
        setSetindidual(false)
        setNgo(false)
        setCorporate(true)
    }

    function ngomousehover() {
        setNgo(true)
        setCorporate(false)
        setSetindidual(false)
    }

    let indi = ''
    let indiarrow = ''
    if (setindidual === true) {
        indi = " individual-bg-slide";
        indiarrow = " indiarrow"
    }
    else {
        indi = "";
        indiarrow = ""
    }

    let corporateclass = ''
    let coparrow = ''
    if (corporate === true) {
        corporateclass = " corporation-bg-slide";
        coparrow = " copratearrow"
    }
    else {
        corporateclass = "";
        coparrow = ""
    }

    let ngoclass = ''
    let ngoarrow = ''
    if (ngo === true) {
        ngoclass = " ngo-bg-slide";
        ngoarrow = " ngoratearrow";

    }
    else {
        ngoclass = "";
        ngoarrow = ""
    }
    return (
        <>
        <div id="particles-js" className="uk-position-fixed"></div>
        <div className="slider position-relative" onClick={() => addClass(setClosemenubar(!closemenubar))}>
            {/* {alert(showview)} */}

            <div className="slider_inner">
                <div className="container">
                    <div   className="row align-items-center">
                        <div className="col-md-7">
                            <div className="slider-content">
                                <h1 className="pb-2">This Page Will Be Live  <br className="d-none d-md-block" /> <span className="text-warning">Soon...</span></h1>
                                {/* <h4 className="text-white text-center text-md-left">We are building something amazing and can't <br className="d-none d-md-block"/> wait to show you. We would be live soon.</h4> */}
                            </div>
                        </div>
                        <div className="col-md-5">
                            <img src='https://thebulb.africa/Images/homepage/bulb_light.svg' alt="bulb" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        navnarclassaddremove: state.classesadd
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        clasname: d => dispatch({ type: "DIV_CLASS_ADD", payload: d }),
        clasnamebtn: d => dispatch({ type: "BTN_EXPAND_ADD", payload: d }),
        btnclassadd: d => dispatch({ type: "BTN_CLASS_ADD", payload: d })
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Commingsoon);
