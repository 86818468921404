import React, { useEffect } from 'react'
import Footer from '../../../Container/Footer'
// import becomeDeveloper from '../../../Assets/img/become-developer-slide.svg'
// import frontEnd from '../../../Assets/img/front-end.png'
// import mobiledevelopment from '../../../Assets/img/mobile-development.png'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { becomeadeveloperdata } from '../../../Const/data'
import Traningcard from '../../../Components/Traningcard'

const Becomeadeveloper = () => {

    let listcourse = []
    {
        becomeadeveloperdata.map((user)=>{
            let data = {
                image : user.image,
                coursename : user.coursename,
                duration : user.duration,
                coursecontent : user.content,
                id : user.id
            }
            listcourse.push(data)
        })
    }

    // useEffect(() => {
    //     document.getElementById("navTop").style.background = "#10152c";
    // }, [])
    return (
        <>
            <div className="become-developer mt-73">
                <div className="become-developer-slide parallax py-5">
                    <div className="container-fluid">
                        <div className="row flex-column-reverse flex-md-row align-items-center px-md-5">
                            <div className="col-md-6">
                                <h1 className="text-white font-weight-bold">Become a developer</h1>
                                <p className="py-3 text-white">We make available suitable and qualified mentors for developers who require them in order to take their product development to the highest level attainable. To push them through their careers, equipping them with the right tools and resources.</p>
                                <AnchorLink offset='100' href="#ourcourse"><button className="btn text-white btn-warning px-5">Explore courses</button></AnchorLink>
                            </div>
                            <div className="col-md-6">
                                <img src='https://thebulb.africa/Images/individual/become-developer-slide.svg' alt="Become A Developer" className="img-fluid ml-auto d-table" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ourcourse py-5" id="ourcourse">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="heading text-center mb-5">
                                    <h3 className="font-weight-bold">Our Courses</h3>
                                </div>
                            </div>

                            <Traningcard list={listcourse} />

                            {/* <div className="col-md-4 col-sm-6">
                                <div className="course-inner position-relative">
                                    <div className="course-image">
                                        <img src={frontEnd} alt="Front-end development" className="img-fluid" />
                                    </div>
                                    <div className="course-headings position-absolute">
                                        <h4>Front-end development</h4>
                                        <p>Coming soon....</p>
                                        <ul>
                                            <li>HTML</li>
                                            <li>CSS</li>
                                            <li>Javascript</li>
                                            <li>React</li>
                                            <li>Angular</li>
                                        </ul>
                                        <button className="btn btn-warning">Coming soon</button>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-md-4 col-sm-6">
                                <div className="course-inner position-relative">
                                    <div className="course-image">
                                        <img src={mobiledevelopment} alt="Mobile development" className="img-fluid" />
                                    </div>
                                    <div className="course-headings position-absolute">
                                        <h4>Mobile <br className="d-none d-md-block" />development</h4>
                                        <p>6 Weeks course</p>
                                        <ul>
                                            <li>HTML</li>
                                            <li>CSS</li>
                                            <li>Javascript</li>
                                            <li>React</li>
                                            <li>Angular</li>
                                        </ul>
                                        <button className="btn btn-warning">Coming soon</button>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="col-md-4 col-sm-6">
                                <div className="course-inner position-relative">
                                    <div className="course-image">
                                        <img src={frontEnd} alt="Front-end development" className="img-fluid" />
                                    </div>
                                    <div className="course-headings position-absolute">
                                        <h4>Front-end <br className="d-none d-md-block" />development</h4>
                                        <p>6 Weeks course</p>
                                        <ul>
                                            <li>HTML</li>
                                            <li>CSS</li>
                                            <li>Javascript</li>
                                            <li>React</li>
                                            <li>Angular</li>
                                        </ul>
                                        <button className="btn btn-warning">Coming soon</button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Becomeadeveloper
