import React, { useState } from 'react';
import { Api } from '../../Const/Api';
import axios from 'axios';
import './index.css';
import moment from 'moment';
import { Box, HStack, Text, VStack, Textarea, Input, SimpleGrid, Button } from '@chakra-ui/react';

function Comment({ id, type, getSinglePost, blogPosts }) {
	const [commentData, setCommentData] = useState({
		reply: '',
		email: '',
		name: ''
	});
	const [Loading, setLoading] = useState(false);

	const handleInputChange = e => {
		setCommentData({
			...commentData,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = e => {
		setLoading(true);
		e.preventDefault();
		const payload = {};
		commentData[type] = id;
		axios.post(`${Api.BaseUrl}comment`, commentData).then(function (response) {
			setLoading(false);
			if (response.status === 200) {
				getSinglePost();
				window.scrollTo(0, 700);
				setCommentData({
					reply: '',
					email: '',
					name: ''
				});
			}
		});
	};

	return (
		<>
			<Box mb="120px">
				{blogPosts?.Comments?.map((comment, index) => (
					<HStack
						mb="80px"
						alignItems="start"
						spacing={{ base: '10px', md: '24px' }}
						className="mt-5"
						key={index}
					>
						<Box
							flexShrink="0"
							w={{ base: '50px', md: '70px' }}
							h={{ base: '50px', md: '70px' }}
							borderRadius="full"
							bg="#FFEDBC"
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<Text>
								{comment?.name
									.match(/(\b\S)?/g)
									.join('')
									.match(/(^\S|\S$)?/g)
									.join('')
									.toUpperCase()}
							</Text>
						</Box>
						<VStack align="start" spacing={{ base: '10px', md: '24px' }}>
							<Text
								fontSize={{ base: '18px', md: '22px' }}
								lineHeight={{ base: '186%', md: '27px' }}
								color="#10162B"
							>
								{comment?.name}
							</Text>
							<Text
								color="#9EA3B4"
								fontSize={{ base: '12px', md: '14px' }}
								lineHeight={{ base: '15px', md: '17px' }}
							>
								{moment(comment?.updated_at).format('Do MMMM  YYYY')}
							</Text>
							<Text
								maxW="841px"
								color="#28314F"
								fontSize={{ base: '16px', md: '18px' }}
								lineHeight={{ base: '34px', md: '172%' }}
								className="text"
							>
								{comment?.reply}
							</Text>
						</VStack>
					</HStack>
				))}
			</Box>
			<Box>
				<Text
					color="#10162B"
					fontWeight="bold"
					fontSize={{ base: '20px', md: '24px' }}
					lineHeight={{ base: '24px', md: '29px' }}
					mb="50px"
				>
					Leave a reply{' '}
					<Text
						as="span"
						fontWeight="normal"
						color="#28314F"
						fontSize={{ base: '12px', md: '20px' }}
						lineHeight={{ base: '172%', md: '172%' }}
					>
						(All fields are required)
					</Text>
				</Text>
				<form onSubmit={handleSubmit}>
					<Textarea
						_focus={{
							borderBottom: '1px solid  #E5E5E5'
						}}
						border="0 solid white"
						borderRadius="0px"
						borderBottom="1px solid  #E5E5E5"
						rows="1"
						id="reply"
						name="reply"
						placeholder="Enter comment here"
						value={commentData.reply}
						onChange={handleInputChange}
						required
					/>

					<SimpleGrid gap={'10px'} columns={{ base: 1, md: 2 }}>
						<Input
							_focus={{
								borderBottom: '1px solid  #E5E5E5'
							}}
							mt="30px"
							type="text"
							placeholder="Name"
							id="name"
							name="name"
							value={commentData.name}
							border="0 solid white"
							borderRadius="0px"
							borderBottom="1px solid  #E5E5E5"
							required
							onChange={handleInputChange}
						/>
						<Input
							_focus={{
								borderBottom: '1px solid  #E5E5E5'
							}}
							mt="30px"
							type="email"
							placeholder="Email Address"
							id="email"
							name="email"
							value={commentData.email}
							border="0 solid white"
							borderRadius="0px"
							borderBottom="1px solid  #E5E5E5"
							required
							onChange={handleInputChange}
						/>
					</SimpleGrid>
					<Button
						px="30px"
						py="30px"
						type="submit"
						bg="#FBBF19"
						borderRadius="4px"
						fontWeight="bold"
						fontSize="18px"
						mt="50px"
						_focus={{
							bg: '#e0aa13'
						}}
						_hover={{
							bg: '#e0aa13'
						}}
					>
						Post comment
						{Loading && (
							<div className="spinner-grow ml-2" role="status">
								<span className="sr-only">Loading...</span>
							</div>
						)}
					</Button>
				</form>
			</Box>
		</>
	);
}

export default Comment;
