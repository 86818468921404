import React from 'react';
import ProfileOverlay from '../../Components/ProfileOverlay';
import { Image, Box } from '@chakra-ui/react';
import placeholder from '../../Assets/img/placeholder.png';

const StaffProfile = ({ name, role, setHoveredPicture, hoveredPicture, useStaticImage, type, picture, linkedIn_url }) => {
	const [firstname] = name.split(' ');
	let staffImage = picture;
	if (!useStaticImage || useStaticImage === undefined) {
		staffImage = picture?.split('"')?.[1];
	}


	return (
		<Box style={{
			display: "flex",
			flexDirection: 'column',
			// height:'100%',
			justifyContent: 'center',
			alignItems: 'center',
			alignContent: 'center',
		}} >
			<div
				style={{
					maxHeight: "380px",
					maxWidth: "280px",
					position: 'relative',
					display: "flex",
					flexWrap: "wrap",
					alignSelf: "center",
					margin: "0 auto"
				}}
				onMouseEnter={() => setHoveredPicture(firstname.toLowerCase())}
				onMouseLeave={() => setHoveredPicture('')}
			>
				{hoveredPicture === firstname.toLowerCase() && (
					<ProfileOverlay linkedIn_url={linkedIn_url} type={type} />
				)}

				<Image fallbackSrc={placeholder} mx="auto" src={staffImage} style={{
					borderRadius: "12px",
					// objectFit: "contain",
					width: "280px",
					height: "310px",
					display: "flex"
				}} />
			</div>
			<div style={{ textAlign: 'center', marginTop: 15 }}>
				<p style={{ fontSize: 22, marginBottom: 0, color: '#28314F' }}>{name}</p>
				<p style={{ fontSize: 16, marginBottom: 32, color: '#707070' }}>{role}</p>

			</div>
		</Box>
	);
};

export default StaffProfile;
