import React, { useState } from "react";

import Logo from "../../Assets/thebulb/bulblogo.svg";
import addressIcon from "../../Assets/thebulb/addressicon.svg";
import phoneIcon from "../../Assets/thebulb/contacticon.svg";
import emailIcon from "../../Assets/thebulb/mailicon.svg";
import { Link } from "react-router-dom";
import { footerData } from "./FooterData";
import {Newsletter} from "../../Components/Newsletter"
import facebook from "../../Assets/footerLogo/facebook.png";

import twiter from "../../Assets/footerLogo/twitter.png";
import instagram from "../../Assets/footerLogo/instagram.png";
import discord from "../../Assets/footerLogo/discord.png";
import tiktok from "../../Assets/footerLogo/tiktok.png";
import linkdin from "../../Assets/footerLogo/linkidin.png";

 const Footer = ({ initialRef }) => {
  return (
    <footer className="text-[#ffff] bg-primaryColor  md:py-10 py-8">
      <main className="flex md:flex-row lg:px-24  px-6 md:px-12 flex-col md:gap-0 gap-8 justify-between w-full ">
        <div className="flex flex-col md:gap-6 gap-4 lg:w-[20%] md:w-[30%] w-full text-[14px] md:text-[16px]">
          <img src={Logo} alt="" width="148px" height="44px" />
          <div className="flex gap-4 items-center ">
            <img src={addressIcon} alt="" />
            <span>39 Ikorodu-Ososun Rd. Jibowu.Lagos</span>
          </div>
          <div className="flex gap-4 items-center ">
            <img src={phoneIcon} alt="" />
            <a href="">+234 700 834 2852</a>
          </div>
          <div className="flex gap-4 items-center ">
            <img src={emailIcon} alt="" />
            <a href="info@thebulb.africa">info@thebulb.africa</a>
          </div>
        </div>

        <div className="lg:w-[80%] md:w-[70%]  w-full flex  justify-end">
          <div className="grid lg:grid-cols-5 md:grid-col-3 grid-cols-2 gap-6 w-full  ">
          {footerData.map((data, id) => (
              <div className="flex flex-col gap-4" key={id}>
                <h1 className="font-bold md:text-[18px] text-[14px] ">
                  {data.label}
                </h1>
                <ul className="flex gap-2 flex-col text-[#E4E4E7] font-normal md:text-[14px] text-[12px]">
                  {data.children &&
                    data.children.map((child, id) => (
                  <>
                      <li className="" key={id}>
                        <a
                          href={child.link}
                          className="no-underline hover:underline"
                        >
                          {child.label}
                        </a>
                      </li>
                   <ul className="pl-4 ">
                    {
                      child.children && child.children.map((child, id)=>(
                        <li className="show-style py-1 cursor-pointer " key={id}>
                        <a>{child.label}</a>
                      </li>
                      ))
                    }
                   </ul>
                  </>
                      
                    ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </main>
      <div className="lg:px-24  px-6 md:px-12">
        <Newsletter />
      </div>
      <div className="w-full h-[1px] bg-[#FDCE4E] "></div>
      <section className="flex  px-6 lg:flex-row flex-col-reverse lg:gap-0 md:gap-4 gap-6 md:items-center items-center  justify-between pt-6  ">
        <div className="text-[#E4E4E7] lg:text-[16px] text-[12px] md:hidden block">
          <p>Copyright @ 2024 The Bulb All Rights Reserved</p>
        </div>

        <div className="flex  items-center no-underline gap-2 text-[#E4E4E7] md:text-[14px] text-[12px]">
          <Link to="/partner-with-us">Our Partners</Link>
          <span className="w-[1px] h-4 bg-[#FDBF17]"></span>
          <Link to="/terms-and-condition">Terms of Use</Link>
          <span className="w-[1px] h-4 bg-[#FDBF17]"></span>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <span className="w-[1px] h-4 bg-[#FDBF17] md:block hidden"></span>
          <Link
            to="https://thebulbafrica.medium.com/"
            className="md:block hidden"
          >
            Resources
          </Link>
        </div>

        <div className="text-[#E4E4E7] md:text-[14px] text-[12px] md:block hidden">
          <p>Copyright @ 2024 The Bulb All Rights Reserved</p>
        </div>

        <div className="text-[#E4E4E7] md:text-[14px] flex items-center md:flex-row flex-col text-[12px]">
          <p className="">Connect With Us</p>
          <ul className="flex gap-2 items-center">
            <li>
              <a href="https://www.facebook.com/TheBulbAfrica/">
                <img src={facebook} alt="" width="40px" height="40px" />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/thebulbafrica">
                <img src={twiter} alt="" width="40px" height="40px" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/thebulb.africa/">
                <img src={instagram} alt="" width="40px" height="40px" />
              </a>
            </li>
            <li>
              <a href="https://discord.gg/v7Uzp9xvjg">
                <img src={discord} alt="" width="40px" height="40px" />
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@thebulbafrica?_t=8VICx62j0x4&_r=1">
                <img src={tiktok} alt="" width="40px" height="40px" />
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/64547766/">
                <img src={linkdin} alt="" width="40px" height="40px" />
              </a>
            </li>
          </ul>
        </div>
      </section>
    </footer>
  );
};
export default Footer
// import axios from 'axios';
// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { Api } from '../../Const/Api';
// import {
// 	Box,
// 	Button,
// 	Modal,
// 	ModalOverlay,
// 	ModalContent,
// 	ModalHeader,
// 	ModalBody,
// 	ModalCloseButton,
// 	useDisclosure,
// 	useToast
// } from '@chakra-ui/react';
// import './style.css';

// const Footer = ({ style }, props) => {
// 	const [emailInput, setEmailInput] = useState('');
// 	const toast = useToast();

// 	const [input, setInput] = useState({});
// 	const initialRef = React.useRef();
// 	const finalRef = React.useRef();

// 	// const [showModal, setShowModal] = useState(false);
// 	const { isOpen, onOpen, onClose } = useDisclosure();
// 	const [Loading, setLoading] = useState(false);

// 	const handleCheck = e => {
// 		setInput({
// 			...input,
// 			[e.target.name]: e.target.value
// 		});
// 	};
// 	const HandleSubmit = e => {
// 		e.preventDefault();
// 		setLoading(true);
// 		const payload = {
// 			section: 'general',
// 			username: input.username,
// 			email: emailInput,
// 			status: 'subscribed',
// 			questions: [
// 				{
// 					question1: 'Are you looking for tech opportunities as an individual or organisation?',
// 					answer1: input.individual || 'no'
// 				},
// 				{
// 					question2: 'Do you live in Nigeria?',
// 					answer: input.nigerian || 'no'
// 				},
// 				{
// 					question3:
// 						'Would you like to receive updates about our programs?  We currently have programs that help you upskill and accelerate your business',
// 					answer: input.update || 'no'
// 				}
// 			]
// 		};
// 		axios.post(`${Api.BaseUrl}newsletter/subscribe`, payload).then(function (response) {
// 			setLoading(false);

// 			if (response.status === 200 || response.status === 201) {
// 				setEmailInput('');
// 				setInput({});
// 				onClose();

// 				return toast({
// 					position: 'top',
// 					duration: 1000,
// 					isClosable: true,
// 					render: props => (
// 						<Box
// 							{...props}
// 							color="black"
// 							border="0.2px solid #4774d53d"
// 							mx="auto"
// 							display={{ base: 'block', md: 'flex' }}
// 							justifyContent="space-around"
// 							py={3}
// 							bg="white"
// 							px="30px"
// 						>
// 							<Box
// 								mx={{ base: 'auto', md: '30px' }}
// 								as="svg"
// 								width="34px"
// 								height="34px"
// 								viewBox="0 0 34 34"
// 								fill="none"
// 							>
// 								<circle cx="17" cy="17" r="17" fill="#9AD191" fill-opacity="0.2" />
// 								<path
// 									d="M15.1267 22.7688C14.974 22.9172 14.7656 23 14.5492 23C14.3327 23 14.1243 22.9172 13.9716 22.7688L9.35901 18.3129C8.88033 17.8505 8.88033 17.1008 9.35901 16.6393L9.93657 16.0813C10.4154 15.6189 11.1907 15.6189 11.6694 16.0813L14.5492 18.8629L22.3306 11.3468C22.8094 10.8844 23.5855 10.8844 24.0634 11.3468L24.641 11.9048C25.1197 12.3671 25.1197 13.1167 24.641 13.5784L15.1267 22.7688Z"
// 									fill="#57B348"
// 									fill-opacity="0.6"
// 								/>
// 							</Box>
// 							You’ve successfully subscribed to get updates on Blog stories posted by The Bulb Africa
// 						</Box>
// 					)
// 				});
// 			}
// 		});
// 	};

// 	const handleShowModal = e => {
// 		console.log('here');
// 		e.preventDefault();
// 		// setShowModal(true);
// 		onOpen();
// 	};

// 	return (
// 		<footer
// 			style={{
// 				// marginTop: '100px',
// 				...style
// 			}}
// 			className='footer'
// 		>
// 			<div className="container">
// 				<div className="row">
// 					<div className="col-md-3 col-6">
// 						<div className="footer-links">
// 							<h5>
// 								<b>Explore</b>
// 							</h5>
// 							<ul className="list-inline">
// 								<li>
// 									<Link to="/about-us">About</Link>
// 								</li>
// 								<li>
// 									<Link to='/co-workspace'>Co-workspace</Link>
// 								</li>
// 								<li>
// 									<Link to="/hire-talents">Hire our talents</Link>
// 								</li>
// 								<li>
// 									<Link to="/careers">Careers</Link>
// 								</li>
// 								{/* <li>
// 									<Link to="/newsfeed">Newsfeed</Link>
// 								</li> */}
// 								<li>
// 									<a href='https://forms.gle/7aKXVgD9eGhFAhnt7' target='_blank' rel='noreferrer noopenner'>Schedule a visit (Individuals)</a>
// 								</li>
// 								<li>
// 									<a href="https://forms.gle/GL86YeVy4oKafXpJ9">Schedule a visit (for Groups)</a>
// 								</li>
// 								<li>
// 									<Link to="/partner-with-us">Partner with us</Link>
// 								</li>
// 							</ul>
// 						</div>
// 					</div>
// 					<div className="col-md-3 col-6">
// 						<div className="footer-links">
// 							<h5>
// 								<b>Help</b>
// 							</h5>
// 							<ul className="list-inline">
// 								{/* <li>
// 									<Link to="/faqs">FAQs</Link>
// 								</li> */}
// 								{/* <li>
// 									<Link to="/resources">Resources</Link>
// 								</li> */}
// 								<li><Link to="/privacy-policy">Privacy policy</Link></li>
// 								<li>
// 									<Link to="/terms-and-condition">Terms &amp; Conditions</Link>
// 								</li>
// 							</ul>
// 						</div>
// 					</div>
// 					<div className="col-md-3 col-6">
// 						<div className="footer-links address">
// 							<h5>
// 								<b>Contact us</b>
// 							</h5>
// 							<ul className="list-inline">
// 								<li>39 Ikorodu Road, Jibowu, Yaba, Lagos </li>
// 								<li>
// 									<a href="tel:+2347008432852">0700THEBULB</a>
// 								</li>
// 								<li>
// 									<a href="mailto:info@thebulb.com">info@thebulb.africa</a>
// 								</li>
// 							</ul>
// 						</div>
// 					</div>
// 					<div className="col-md-3 col-12">
// 						<div className="footer-links address">
// 							<h5>
// 								<b>Connect</b>
// 							</h5>
// 							<div className="subscribe-form">
// 								<form onSubmit={handleShowModal}>
// 									<label htmlFor="">Sign up for our newsletters</label>
// 									<div className="input-group">
// 										<input
// 											type="email"
// 											className="form-control"
// 											placeholder="Email address"
// 											onChange={e => setEmailInput(e.target.value)}
// 											aria-label="Email address"
// 											aria-describedby="basic-addon2"
// 										/>
// 										<div className="input-group-append">
// 											<button className="btn btn-outline-secondary" type="submit">
// 												Subscribe
// 											</button>
// 										</div>
// 									</div>
// 								</form>
// 							</div>
// 						</div>
// 						{/* <i className="fab fa-linkedin"></i> */}
// 						<div className="footer-social" style={{ marginLeft: '-2px', display: 'flex', flexWrap: "wrap" }}>
// 							<a
// 								href="https://www.facebook.com/TheBulbAfrica/?_rdc=1&_rdr"
// 								target="_blank"
// 								rel="noopener noreferrer"
// 								className="text-white p-2"
// 							>
// 								<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="22" height="22" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="white" d="M9.5 3H12V0H9.5C7.57 0 6 1.57 6 3.5V5H4v3h2v8h3V8h2.5l.5-3H9V3.5c0-.271.229-.5.5-.5z" /></svg>
// 							</a>
// 							<a
// 								href="https://twitter.com/thebulbafrica"
// 								target="_blank"
// 								rel="noopener noreferrer"
// 								className="text-white p-2"
// 							>
// 								<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="22" height="22" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="white" d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z" /></svg>
// 							</a>
// 							<a
// 								href="https://www.instagram.com/thebulb.africa/"
// 								target="_blank"
// 								rel="noopener noreferrer"
// 								className="text-white p-2"
// 							>
// 								<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="22" height="22" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="white" d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3S645.3 585.4 645.3 512S585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165c-3.1-64-17.7-120.8-64.5-167.6c-46.9-46.9-103.6-61.4-167.6-64.5c-55.2-3.1-109.9-2.6-165-2.6c-55.2 0-109.9-.5-165 2.6c-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6c46.9 46.9 103.6 61.4 167.6 64.5c55.2 3.1 109.9 2.6 165 2.6c55.2 0 109.9.5 165-2.6c64-3.1 120.8-17.7 167.6-64.5c46.9-46.9 61.4-103.6 64.5-167.6c3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9S717.1 398.5 717.1 512S625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9s47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z" /></svg>
// 							</a>
// 							<a
// 								href="https://www.linkedin.com/company/64547766/"
// 								target="_blank"
// 								rel="noopener noreferrer"
// 								className="text-white p-2"
// 							>
// 								<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="22" height="22" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1000 1000"><path fill="white" d="M196.064.25C88.347.25.187 88.408.187 196.127v607.841c0 107.717 88.158 195.845 195.877 195.845h607.841c107.718 0 195.845-88.127 195.845-195.845V196.127C999.75 88.41 911.623.25 803.905.25H196.064zm49.266 164.948c51.648 0 83.461 33.906 84.443 78.475c0 43.585-32.797 78.444-85.442 78.444h-.969c-50.665 0-83.412-34.857-83.412-78.444c0-44.568 33.738-78.475 85.379-78.475zm445.08 208.31c99.329 0 173.79 64.922 173.79 204.436v260.449H713.247V595.406c0-61.06-21.847-102.718-76.476-102.718c-41.704 0-66.562 28.078-77.476 55.202c-3.987 9.704-4.967 23.257-4.967 36.832v253.671H403.375s1.981-411.613 0-454.233h150.984v64.324c20.06-30.95 55.942-74.977 136.051-74.977zm-521.556 10.685h150.953v454.202H168.854V384.193z" /></svg>
// 							</a>
// 							<a
// 								href="https://discord.gg/v7Uzp9xvjg"
// 								target="_blank"
// 								rel="noopener noreferrer"
// 								className="text-white p-2"
// 							>
// 								<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="22" height="22" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="white" d="M20.317 4.492c-1.53-.69-3.17-1.2-4.885-1.49a.075.075 0 0 0-.079.036c-.21.369-.444.85-.608 1.23a18.566 18.566 0 0 0-5.487 0a12.36 12.36 0 0 0-.617-1.23A.077.077 0 0 0 8.562 3c-1.714.29-3.354.8-4.885 1.491a.07.07 0 0 0-.032.027C.533 9.093-.32 13.555.099 17.961a.08.08 0 0 0 .031.055a20.03 20.03 0 0 0 5.993 2.98a.078.078 0 0 0 .084-.026a13.83 13.83 0 0 0 1.226-1.963a.074.074 0 0 0-.041-.104a13.201 13.201 0 0 1-1.872-.878a.075.075 0 0 1-.008-.125c.126-.093.252-.19.372-.287a.075.075 0 0 1 .078-.01c3.927 1.764 8.18 1.764 12.061 0a.075.075 0 0 1 .079.009c.12.098.245.195.372.288a.075.075 0 0 1-.006.125c-.598.344-1.22.635-1.873.877a.075.075 0 0 0-.041.105c.36.687.772 1.341 1.225 1.962a.077.077 0 0 0 .084.028a19.963 19.963 0 0 0 6.002-2.981a.076.076 0 0 0 .032-.054c.5-5.094-.838-9.52-3.549-13.442a.06.06 0 0 0-.031-.028ZM8.02 15.278c-1.182 0-2.157-1.069-2.157-2.38c0-1.312.956-2.38 2.157-2.38c1.21 0 2.176 1.077 2.157 2.38c0 1.312-.956 2.38-2.157 2.38Zm7.975 0c-1.183 0-2.157-1.069-2.157-2.38c0-1.312.955-2.38 2.157-2.38c1.21 0 2.176 1.077 2.157 2.38c0 1.312-.946 2.38-2.157 2.38Z" /></svg>
// 							</a>
// 							<a
// 								href="https://www.tiktok.com/@thebulbafrica?_t=8VICx62j0x4&_r=1"
// 								target="_blank"

// 								rel="noopener noreferrer"
// 								className="text-white p-2"
// 							>
// 								<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
// 									width="28" height="28"
// 									preserveAspectRatio="xMidYMid meet"
// 									viewBox="0 0 30 30">    <path fill='white' d="M24,4H6C4.895,4,4,4.895,4,6v18c0,1.105,0.895,2,2,2h18c1.105,0,2-0.895,2-2V6C26,4.895,25.104,4,24,4z M22.689,13.474 c-0.13,0.012-0.261,0.02-0.393,0.02c-1.495,0-2.809-0.768-3.574-1.931c0,3.049,0,6.519,0,6.577c0,2.685-2.177,4.861-4.861,4.861 C11.177,23,9,20.823,9,18.139c0-2.685,2.177-4.861,4.861-4.861c0.102,0,0.201,0.009,0.3,0.015v2.396c-0.1-0.012-0.197-0.03-0.3-0.03 c-1.37,0-2.481,1.111-2.481,2.481s1.11,2.481,2.481,2.481c1.371,0,2.581-1.08,2.581-2.45c0-0.055,0.024-11.17,0.024-11.17h2.289 c0.215,2.047,1.868,3.663,3.934,3.811V13.474z"></path></svg>
// 							</a>
// 						</div>
// 					</div>
// 					<div className="col-12 text-center">
// 						<p className="copyright">Copyright © 2022 The Bulb - All rights reserved</p>
// 					</div>
// 				</div>
// 			</div>
// 			<Modal
// 				isCentered
// 				initialFocusRef={initialRef}
// 				finalFocusRef={finalRef}
// 				isOpen={isOpen}
// 				onClose={onClose}
// 				size={'xl'}
// 				scrollBehavior="inside"
// 			>
// 				<ModalOverlay />
// 				<ModalContent>
// 					<ModalHeader>Subscribe</ModalHeader>
// 					<ModalCloseButton />
// 					<ModalBody p={{ base: '20px', md: '50px' }}>
// 						<div className="bg-white  d-flex flex-column mx-auto">
// 							<div className="heading mb-3 pr-5">
// 								Thank you for subscribing!{' '}
// 								<span role="img" aria-label="emoji">
// 									🎉
// 								</span>
// 								<div className="mt-3 mb-10">
// 									There are just a few more details we need to ensure we tailor our newsletters to
// 									suit your interest
// 								</div>
// 							</div>

// 							<form className="pr-5" onSubmit={HandleSubmit}>
// 								<div className="form-group pb-3">
// 									<label htmlFor="full_name" className="pl-1 pr-2">
// 										Your full name
// 									</label>
// 									<input
// 										ref={initialRef}
// 										type="text"
// 										className="form-control"
// 										id="full_name"
// 										name="username"
// 										required
// 										onChange={handleCheck}
// 									/>
// 								</div>

// 								<div className="form-group pb-3">
// 									1. Are you looking for tech opportunities as an individual or organization?
// 									<div className="row justify-content-start col-lg-8 pt-2">
// 										<label htmlFor="individual" className="pl-1 pr-2 mr-3">
// 											<input
// 												type="checkbox"
// 												className="mr-2"
// 												id="individual"
// 												name="individual"
// 												checked={input.individual === 'yes'}
// 												value="yes"
// 												onChange={handleCheck}
// 											/>
// 											Individual
// 										</label>
// 										<label htmlFor="group" className="pl-1 pr-2">
// 											<input
// 												type="checkbox"
// 												className="mr-2"
// 												id="group"
// 												name="individual"
// 												checked={input.individual === 'no'}
// 												value="no"
// 												onChange={handleCheck}
// 											/>
// 											Organisation
// 										</label>
// 									</div>
// 								</div>

// 								<div className="form-group pb-3">
// 									2. Do you live in Nigeria?
// 									<div className="row justify-content-start col-lg-4 pt-2">
// 										<label htmlFor="nigerian" className="pl-1 pr-2 mr-3">
// 											<input
// 												type="checkbox"
// 												className="mr-2"
// 												id="nigerian"
// 												name="nigerian"
// 												checked={input.nigerian === 'yes'}
// 												value="yes"
// 												onChange={handleCheck}
// 											/>
// 											Yes
// 										</label>
// 										<label htmlFor="nigerian2" className="pl-1 pr-2">
// 											<input
// 												type="checkbox"
// 												className="mr-2"
// 												name="nigerian"
// 												id="nigerian2"
// 												checked={input.nigerian === 'no'}
// 												value="no"
// 												onChange={handleCheck}
// 											/>
// 											No
// 										</label>
// 									</div>
// 								</div>

// 								<div className="form-group pb-3">
// 									3. Would you like to receive updates about our programs? We currently have programs
// 									that help you upskill and accelerate your business
// 									<div className="row justify-content-start col-lg-4 pt-2">
// 										<label htmlFor="update" className="pl-1 pr-2 mr-3">
// 											<input
// 												type="checkbox"
// 												className="mr-2"
// 												id="update"
// 												name="update"
// 												checked={input.update === 'yes'}
// 												value="yes"
// 												onChange={handleCheck}
// 											/>
// 											Yes
// 										</label>
// 										<label htmlFor="updateNo" className="pl-1 pr-2">
// 											<input
// 												type="checkbox"
// 												className="mr-2"
// 												id="updateNo"
// 												name="update"
// 												checked={input.update === 'no'}
// 												value="no"
// 												onChange={handleCheck}
// 											/>
// 											No
// 										</label>
// 									</div>
// 								</div>

// 								<Button
// 									mb="50px"
// 									fontWeight="bold"
// 									lineHeight="22px"
// 									mx="auto"
// 									color="white"
// 									width="170px"
// 									h="56px"
// 									borderRadius="6px"
// 									fontSize="18px"
// 									style={{
// 										background: '#050038'
// 									}}
// 									type="submit"
// 									disabled={Loading}
// 								>
// 									Done
// 									{Loading && (
// 										<div className="spinner-grow ml-2" role="status">
// 											<span className="sr-only">Loading...</span>
// 										</div>
// 									)}
// 								</Button>
// 							</form>
// 						</div>
// 					</ModalBody>
// 				</ModalContent>
// 			</Modal>
// 		</footer>
// 	);
// };
// export default Footer;
