import React, { useState, useEffect } from "react";
import Footer from "../../Container/Footer";
import { Box } from "@chakra-ui/react";
import "./index.css";
import { SVGHeader } from "../../Components/SVG/header";
import { Helmet } from "react-helmet";
import Navbar from "../../Container/Navbar";
import theBulbLogoDark from "../../Assets/img/the-bulb-logo-dark.png";

function PartnerWithUs() {
  useEffect(() => {
    // document.getElementById("navTop").style.display = "none";
    // const items = document.getElementsByClassName("nav-link");
    // for (let i = 0; i < items.length; i++) {
    //   items[i].style.color = "#10152c";
    // }
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ position: "relative" }} className="partner">
      <SVGHeader />
      <Helmet>
							{/*TITLE TAGS & META TAGS*/}

        <title>Partner With Us | The Bulb Africa </title>
        <meta name="title" content="Partner With Us | The Bulb Africa " />
        <meta
          name="description"
          content="The Bulb works with a diverse group of partners who share our core values and the success of entrepreneurs. Find out how partnering with us works for you. "
		  />

		  {/* Open Graph meta tags */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Partner With Us | The Bulb Africa "
        />
        <meta
          property="og:url"
          content="https://thebulb.africa/partner-with-us"
        />
        <meta
          property="og:description"
          content="The Bulb works with a diverse group of partners who share our core values and the success of entrepreneurs. Find out how partnering with us works for you."
        />
        <meta
          property="og:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta property="og:image:alt" content="The Bulb Africa- offical logo" />

		{/* twitter meta tags */}
        <meta
          name="twitter:title"
          content="Partner With Us | The Bulb Africa"
        />
        <meta
          name="twitter:description"
          content="The Bulb works with a diverse group of partners who share our core values and the success of entrepreneurs. Find out how partnering with us works for you."
        />
        <meta
          name="twitter:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta name="twitter:image:alt" content="The Bulb- offical logo" />
        <meta name="twitter:site" content="@TheBulbAfrica" />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <div className="fixed-nav">
        <Navbar navImage={theBulbLogoDark} isLight={true} />
      </div>
      <div className="container">
        <div className="top pt-5 pb-5">
          <h2
            className="heading text-center mt-5"
            style={{ paddingTop: "5rem" }}
          >
            Be a catalyst for innovation
          </h2>
          <div className="text  col-lg-9 text-center ml-auto mr-auto my-5">
            The Bulb™ works with young tech talents, entrepreneurs, startup
            companies, government and nongovernmental businesses, and charitable
            organisations to build transferable and transportable skills set for
            the fast-changing global workspace. We bring partners with aligned
            goals together as catalysts that drive the growth of innovation
            across the globe.
          </div>
          <a
            href="https://forms.gle/QxBywUjSJiMYK39EA"
            target="_blank"
            rel="noreferrer noopenner"
          >
            <div className="btn button  mb-5  p-2"> Partner with us </div>
          </a>
        </div>
      </div>
      <div className="how-partnering">
        <div className="container ">
          <h1 className="heading text-center pt-5" as="h2">
            How partnering with us works for you
          </h1>
          <div className="img-section d-flex flex-column-reverse flex-lg-row mt-4">
            <div className="col-lg-6 big-img mt-5 mt-lg-0">
              <img src={require("../../Assets/img/woman.png")} alt="" />
            </div>
            <ul className="ml-lg-5 col-lg-6 pb-5">
              <li className="d-flex mt-5">
                <div className="star-img mr-3">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="15" cy="15" r="15" fill="#DFE1F4" />
                    <path
                      d="M15 5.99927L17.0206 12.2181H23.5595L18.2694 16.0616L20.2901 22.2804L15 18.437L9.70993 22.2804L11.7306 16.0616L6.44049 12.2181H12.9794L15 5.99927Z"
                      fill="#9A95D2"
                    />
                  </svg>
                </div>
                <div className="text-section">
                  <div className="heading">Make Impact</div>
                  <div className="text">
                    Work with us to co-develop programs that support young tech
                    talents and entrepreneurs, locally and internationally. Our
                    programs align with our partners’ organisational values and
                    priorities.
                  </div>
                </div>
              </li>
              <li className="d-flex mt-5 ">
                <div className="star-img mr-3">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="15" cy="15" r="15" fill="#DFE1F4" />
                    <path
                      d="M15 5.99927L17.0206 12.2181H23.5595L18.2694 16.0616L20.2901 22.2804L15 18.437L9.70993 22.2804L11.7306 16.0616L6.44049 12.2181H12.9794L15 5.99927Z"
                      fill="#9A95D2"
                    />
                  </svg>
                </div>
                <div className="text-section">
                  <div className="heading">Engage innovative minds</div>
                  <div className="text">
                    Broaden your perspective about innovation in tech by
                    networking with leading experts and supporters in software
                    development and youth entrepreneurial fields.
                  </div>
                </div>
              </li>
              <li className="d-flex mt-5">
                <div className="star-img mr-3">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="15" cy="15" r="15" fill="#DFE1F4" />
                    <path
                      d="M15 5.99927L17.0206 12.2181H23.5595L18.2694 16.0616L20.2901 22.2804L15 18.437L9.70993 22.2804L11.7306 16.0616L6.44049 12.2181H12.9794L15 5.99927Z"
                      fill="#9A95D2"
                    />
                  </svg>
                </div>
                <div className="text-section">
                  <div className="heading">
                    Promote employee growth and engagement
                  </div>
                  <div className="text">
                    Create limitless opportunities for your employees to upskill
                    and share their expertise by supporting our team and
                    directly engaging young, innovative tech communities.
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="brands mb-5 pb-5">
          <div className="heading text-center mt-5 pt-5 mb-4">
            Brands we’ve partnered with
          </div>
          <Box maxW="713px" className="text text-center  m-auto">
            We work with a number of reputable brands and companies across
            different industries and sectors.
          </Box>
          <div className="brand-ing mt-5 d-flex flex-wrap justify-content-center  mb-5">
            <div className="brand-logo mr-4 mt-3">
              <img src={require("../../Assets/img/tradefi.png")} alt="star" />
            </div>
            <div className="brand-logo mr-4 mt-3">
              <img
                src={require("../../Assets/img/Flutterwave.png")}
                alt="star"
              />
            </div>
            <div className="brand-logo mr-4 mt-3">
              <img src={require("../../Assets/img/lifebank.png")} alt="star" />
            </div>
            <div className="brand-logo mr-4 mt-3">
              <img src={require("../../Assets/img/aws.png")} alt="star" />
            </div>
            <div className="brand-logo mr-4 mt-3">
              <img src={require("../../Assets/img/compass.png")} alt="star" />
            </div>
          </div>
          <a
            href="https://forms.gle/QxBywUjSJiMYK39EA"
            target="_blank"
            rel="noreferrer noopenner"
          >
            <div className="btn button  mb-5  p-2"> Partner with us </div>
          </a>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default PartnerWithUs;
