import React from 'react'
import { Link } from 'react-router-dom'

const Ourevent = ({ list }) => {
    return list.map((user) => {
        return (
            <div key={user.id} className="col-md-4 col-sm-6">
                <div className="hub-whatdo-inner position-relative">
                    <div className="hub-whatdoImg">
                        <img src={user.image} alt="Start ups" className="img-fluid" />
                    </div>
                    <div className="hub-whatdoContent position-absolute">
                        <h5>{user.eventname}</h5>
                        <p>{user.eventdetails}</p>
                        <Link to={user.path}>Learn more <i className="fa fa-chevron-circle-right"></i></Link>
                    </div>
                </div>
            </div>
        )
    })
}
export default Ourevent