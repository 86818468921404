import React, { useEffect, useState } from 'react'
import Footer from '../../Container/Footer'
// import postBanner from '../../Assets/img/post-banner.jpg'
// import { recentpost } from '../../Const/data'

const Recentpost = () => {
    // alert('dsd')
    // console.log('alokalokaloka', match)

    // const [post, setPost] = useState([])

    useEffect(() => {
        // document.getElementById("particles-js").style.display = "none";
        document.getElementById("navTop").style.background = "#10152c";
        // list()
    }, [])


    // function list() {
    //     const data = recentpost
    //     setPost(data)
    // }


    // console.log('aloakakkakka', post)


    // let friends = post.filter(function (user) {
    //     return user.url === true
    // });

    //   let nonFriends = this.props.list.filter( function (user) {
    //     return user.friend !== true
    //   });


    return (
        <>
            <div className="single-post mt-73 py-5">
                <div className="singlepost-inner">
                    <div className="container">
                        <div className="row">
                            {/* user info */}
                                 {/* {
                                post.filter((user => user.url === match.params.id)){
                                   
                                })
                            } */}

                            {/* {friends.map(function (user) {
                                return <li key={user.url}>{user.url}</li>
                            })} */}

                            {/* {pathsd.params.id} */}
                            <div className="col-12">
                                <div className="singlepost-banner">
                                    {/* <img src={postBanner} alt="Post Banner" className="img-fluid" /> */}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 py-4">
                                <h1 className="post-title">Lorem ipsum dolor sit amet,</h1>
                                <p className="post-date mb-2">October 22, 2019 <span className="post-category">Tech</span></p>
                            </div>
                            <div className="col-md-9">
                                <div className="post-content mb-md-5">
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea</p>
                                    <p><strong>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,</strong> sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores</p>
                                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <ul className="share-post d-flex flex-wrap d-md-block mt-4 mt-md-0 list-inline mx-auto text-md-center">
                                    <p className="w-100">Share</p>
                                    <li className="mr-3 mr-md-0"><a href="https://www.facebook.com/" className="text-decoration-none mb-sm-3 d-inline-block"><i className="fab fa-lg fa-facebook-f"></i></a></li>
                                    <li className="mr-3 mr-md-0"><a href="https://www.facebook.com/" className="text-decoration-none mb-sm-3 d-inline-block"><i className="fab fa-lg fa-twitter"></i></a></li>
                                    <li><a href="https://www.facebook.com/" className="text-decoration-none d-inline-block"><i className="fab fa-lg fa-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    )

}
export default Recentpost
