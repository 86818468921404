export const LoginItems = [
  {
    label: "Login",
    link: "#",
    children: [
      {
        label: "For Students",
        link: "https://app.thebulbafrica.institute/login",
      },
      {
        label: "For Employee",
        link: "https://thebulb.seamlesshrms.com/",
      },

      {
        label: "For DSA",
        link: "https://forms.gle/XG7mAKev4YqvUkUR6",
      },

    ],
  },
];

export const navItems = [
  {
    label: "For Businesses",
    link: "#",
    children: [
      {
        label: "Corporate Training",
        link: "https://thebulbafrica.institute/career-advance/",
      },
      {
        label: "Hire Our Talents",
        link: "https://bit.ly/fellowship-placement",
      },

      {
        label: "Office Space",
        link: "/co-workspace",
      },
    ],
  },
  {
    label: "For Talent",
    link: "#",
    children: [
      // {
      //   label: "Fellowship Programs",
      //   link: "https://thebulbafrica.institute/fellowship/",
      // },
      {
        label: "Tech Trainings",
        link: "https://thebulbafrica.institute/courses/",
      },
      {
        label: "Coworking Space",
        link: "https://desk.africa/",
      },
      // {
      //   label: "Career Advance",
      //   link: "https://thebulbafrica.institute/career-advance/",
      // },
      // {
      //   label: "All Courses",
      //   link: "#",
      // },
      {
        label: "Communities",
        link: "/Our-communities",
      },
    ],
  },
  {
    label: "Talent-as-a-Service",
    link: "#",
    children: [
      {
        label: "Recruitment",
        // link: "https://www.thebulb.africa/hire-talents",
        link: "/hire-talents",
      },
      {
        label: "Product Development",
        link: "/product-development",
      },
    ],
  },
  {
    label: "Why The Bulb",
    link: "#",
    children: [
      {
        label: "About Us",
        // link: "https://www.thebulb.africa/about-us",
        link: "/about-us",
      },
      {
        label: "Blog",
        link: "https://blog.thebulb.africa/",
      },
      {
        label: "Newsletter",
        link: "https://blog.thebulb.africa/newsletter",
      },
      {
        label: "Success Stories",
        link: "https://www.youtube.com/playlist?list=PLKkWT_StFvTd7JC163elkl1F1OKdM1BuW",
      },
    ],
  },
];
