import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Navbar from "../../Container/Navbar";
import Footer from "../../Container/Footer";
import theBulbLogoDark from "../../Assets/img/the-bulb-logo-dark.png";
import "./ExploreCourses.css";
import { Helmet } from "react-helmet";

import icon1 from "../../Assets/img/idea.gif";
import icon2 from "../../Assets/img/rocket.gif";
import icon3 from "../../Assets/img/networking.gif";
import icon4 from "../../Assets/img/books.gif";
import icon5 from "../../Assets/img/target.gif";
import scrum from "../../Assets/img/scrum.png";
import sql from "../../Assets/img/sql.png";
import dataScience from "../../Assets/img/data-science.png";
import blockchain from "../../Assets/img/blockchain.png";
import sap from "../../Assets/img/sap.png";
import cHeadImg1 from "../../Assets/img/cHeadImg1.jpg";
import cHeadImg2 from "../../Assets/img/cHeadImg2.jpg";
import cHeadImg3 from "../../Assets/img/cHeadImg3.jpg";
import cHeadImg4 from "../../Assets/img/cHeadImg4.jpg";
import cHeadImg5 from "../../Assets/img/c-h-img-5.png";
import cHeadImg6 from "../../Assets/img/cHeadImg6.jpg";
import Facilitators from "./Facilitators";

import beecube from "../../Assets/img/beecube.png";

import Button from "../../Components/Button/Button";
import CareerAdvance from "../../Components/CareerAdvance/CareerAdvance";
import Courses from "../../Components/Card/Courses/Courses";
import { COURSES_REGISTRATION_LINK } from "../../Const/data";

const ExploreCourses = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = (value) => setIsOpen(value);

  useEffect(() => {
    // document.getElementById("navTop").style.display = "none";
    // const items = document.getElementsByClassName("nav-link");
    // for (let i = 0; i < items.length; i++) {
    //   items[i].style.color = "#10152c";
    // }
    // window.scrollTo(0, 0);
    AOS.init();
    // AOS.refresh();
  });

  console.log(isOpen);

  return (
    <>
      <Helmet>
        {/*TITLE TAGS & META TAGS*/}

        <title>Upskill Your Career in Tech – Career Advance | The Bulb </title>
        <meta
          name="title"
          content="Upskill Your Career in Tech – Career Advance | The Bulb"
        />
        <meta
          name="description"
          content="Upskill with Career Advance. Our curriculum offers practical experience in Data Science, SQL, Scrum Master, Blockchain, & Product design. Select the right track!"
        />
        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content="Upskill Your Career in Tech – Career Advance | The Bulb "
        />
        <meta
          property="og:url"
          content="https://thebulb.africa/explore-courses"
        />
        <meta
          property="og:description"
          content="Upskill with Career Advance. Our curriculum offers practical experience in Data Science, SQL, Scrum Master, Blockchain, & Product design. Select the right track! "
        />
        <meta
          property="og:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta property="og:image:alt" content="The Bulb Africa- offical logo" />

        {/* twitter meta tags */}
        <meta
          name="twitter:title"
          content="Upskill Your Career in Tech – Career Advance | The Bulb "
        />
        <meta
          name="twitter:description"
          content="Upskill with Career Advance. Our curriculum offers practical experience in Data Science, SQL, Scrum Master, Blockchain, & Product design. Select the right track! "
        />
        <meta
          name="twitter:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta name="twitter:image:alt" content="The Bulb- offical logo" />
        <meta name="twitter:site" content="@TheBulbAfrica" />
        <meta name="twitter:card" content="summary" />
      </Helmet>
      <div className="fixed-nav">
        <Navbar navImage={theBulbLogoDark} isLight={true} />
      </div>
      <div className="full-content">
        <div className="explore-hero">
          <h1
            className="explore-hero-heading"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            Take your career to the next level{" "}
          </h1>
          <p
            className="explore-hero-paragraph"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            Our Career Advance courses are strategically designed to proffer the
            knowledge needed to elevate your career. We work closely with
            industry leaders to curate standard curriculums that align with
            industry trends and the job market.
          </p>
          <Button
            isHyperLink="true"
            location={COURSES_REGISTRATION_LINK}
            cta="Get started"
          />
        </div>
        <div className="hero-imgs">
          <div>
            <img src={dataScience} alt="" />
            <p>Data Science</p>
          </div>
          <div>
            <img src={sql} alt="" />
            <p>SQL</p>
          </div>
          <div>
            <img src={blockchain} alt="" />
            <p>Blockchain</p>
          </div>
          <div>
            <img src={scrum} alt="" />
            <p>Scrum</p>
          </div>
          <div>
            <img src={sap} alt="" />
            {/* style={{ marginTop: "25px" }} */}
            <p>SAP</p>
          </div>
        </div>

        <div className="courses-full">
          <Courses
            img={cHeadImg1}
            heading="Data Science"
            text1="Data plays an essential role in the sustainability of financial institutions & Fintechs. In this course, you will get familiar with critical data science tools, learn R programming language, discover ways to obtain & cleanse data, and learn industry-standard data analysis techniques. At the end of the course, participants will know how to utilize data to prevent fraud, conduct risk analysis, customer behaviour analysis, personalized marketing, etc This course is designed for data scientists, data engineers and organizations in the finance and fintech industry. Data Scientists are one of the most sought-after professionals globally, with a projected 27.6% increase in jobs in the United States. These professionals have been reported to earn an average of $122,338 annually."
            mini1="Fintechs"
            mini2="$550"
            mini3="3 month"
            mini4="Virtual"
            mini5="Intermediate to Expert"
            link={COURSES_REGISTRATION_LINK}
          />

          <Courses
            img={cHeadImg2}
            heading="SQL"
            text1="The SQL course offers a complete curriculum that caters to beginners and more experienced professionals. Participants will learn the fundamentals of databases, database languages, and database management systems. The course will also cover SQL and MySQL queries and data structures, equipping you with all the skills you need to make queries and more. The SQL course has been curated for database administrators, data analysts, data engineers, server management specialists, software developers, and more. As a robust language, SQL is the most in-demand technical skill globally, with the SQL experts earning an average annual salary of $84,450. It is the most popular language for data and is used by top companies worldwide."
            mini1="Fintechs"
            mini2="$100"
            mini3="1 month"
            mini4="Hybrid"
            mini5="Novice to Expert"
            link={COURSES_REGISTRATION_LINK}
          />

          <Courses
            img={cHeadImg3}
            heading="Blockchain"
            text1="Businesses across the globe are now using blockchain to record transactions and track assets. The blockchain for executives course provides an in-depth understanding of blockchain and how it can be implemented in businesses. You will learn about the fundamentals of blockchain, blockchain use cases, design & execute smart contracts, create decentralized applications, and more. With about 41% of senior executives adopting blockchain into production, the career outlook for blockchain professionals is outstanding. Blockchain is often described as the career of the future, and it is projected that by 2023, it will add $3.1 trillion in business value. With over a 500% increase in demand for blockchain skills in recent years, as reported by Hired, seasoned blockchain specialists earn a median annual salary of over $96,000!"
            mini1="Fintechs"
            mini2="To be determined"
            mini3="1 month"
            mini4="On-site"
            mini5="Novice to intermediate"
            link={COURSES_REGISTRATION_LINK}
          />

          <Courses
            img={cHeadImg4}
            heading="Scrum"
            text1="Become a Scrum Master with our interactive and hands-on Scrum course. This course covers the basic principles and theories of Scrum and its framework. You will learn about product delivery, product backlog, Agile, identifying common problems, etc. At the end of the course, participants would have developed an Agile mindset and be able to build excellent software by using modern Agile engineering practices.   Scrum Masters play a crucial role in tech companies, particularly those using Scrum and agile. Earning an average annual salary of $99,400, the demand for Scrum Masters is projected to grow exponentially by 37.9% over the next decade, as reported by Burning Glass Technologies. This course is targeted at project Managers, Leaders and operations managers in the IT space to provide a thorough understanding of the Scrum framework and elevate their careers."
            mini1="Fintechs"
            mini2="$120"
            mini3="1 month"
            mini4="Virtual"
            mini5="Novice to intermediate"
            link={COURSES_REGISTRATION_LINK}
          />

          <Courses
            img={cHeadImg5}
            heading="SAP"
            text1="SAP, ‘Systems Applications and Products’ is one of the world's most popular Enterprise Resource Planning (ERP) solutions. This course offers rigorous and broad teachings on the fundamentals of the SAP system. Participants will learn to understand the SAP interface, work with master data and post transactions, and how SAP organizes information via organizational data. At the end of this course, you will understand basic and advanced SAP strategies, production planning, material requirements, data design, and the role SAP plays as a strategic and financial reporting tool. SAP specialists and consultants are in demand due to the widespread use of SAP software by businesses across the globe, including tech giants like Microsoft and IBM. While in demand, there’s a low supply of SAP professionals, making it one of the highest-paid professions at an annual median income of $89,635. This course has been curated for prospective financial consultants and anyone who would love to upskill in the field."
            mini1="Fintechs"
            mini2="To be determined"
            mini3="2 month"
            mini4="Hybrid"
            mini5="Novice to intermediate"
            link={COURSES_REGISTRATION_LINK}
          />

          <Courses
            img={cHeadImg6}
            heading="Product Design"
            text1="Acquire the proficient use of design tools that help you build valuable, efficient and cost-effective products for various global markets. In this course, you will learn about products, product design and the role of a product designer. The curriculum covers design thinking, human-centred design, user research, User Experience (UX) design, Information Architecture (IA), User Interface (UI) design, prototyping and usability testing and stakeholder engagement. Participants will learn how to work with essential design tools as well as how to create and manage a design portfolio. Product Designers are one of the most sought-after professionals in the world today, and the demand is projected to grow by 6% over the next decade. Product Designers are hired in a variety of industries and are needed in startups, software companies and large product-based corporations. Product Designers earn an average annual salary of $112,610. This course is the perfect blend of theory and practice to help participants materialize ideas into excellent products."
            mini1="Fintechs"
            mini2="₦1 Million (Virtual) "
            mini3="4 month"
            mini4="Virtual/Physical"
            mini5="Beginner to Advance"
            link={COURSES_REGISTRATION_LINK}
          />
        </div>

        <section className="c-a-body">
          <div className="c-a-head">
            <h2 data-aos="fade-up" data-aos-duration="500">
              Why learn with Career Advance
            </h2>
          </div>
          <div className="career-props">
            <CareerAdvance
              icon={icon1}
              head="Accelerate Your career"
              text="Learn from seasoned instructors armed with standardized curriculums needed to transform your career and increase your employability."
            />
            <CareerAdvance
              icon={icon2}
              head="Boost your earning potential"
              text="Gain valuable skills that make you more valuable to employers and give you greater bargaining power during salary negotiations. "
            />
            <CareerAdvance
              icon={icon3}
              head="Networking"
              text="Learn and grow with people in and outside your industry. Connect with learned professionals and expand your network."
            />
            <CareerAdvance
              icon={icon4}
              head="Enhance your creativity"
              text="Getting a better understanding of certain topics in your courses will broaden your mindset and improve the way you think. Career Advance improves innovation and aids in brainstorming, setting you apart from your peers."
            />
            <CareerAdvance
              icon={icon5}
              head="Develop new professional interests"
              text="Exploring courses outside your field can expose you to new interests and innate skills you never knew existed. These skills can be incorporated into your current career or used to forge a new path."
            />
          </div>
        </section>
        <div className="bottom-x">
          <h2 data-aos="fade-up" data-aos-duration="500">
            Learn&nbsp;&nbsp;.&nbsp;&nbsp;Apply&nbsp;&nbsp;.&nbsp;&nbsp;Grow
          </h2>
          <p data-aos="fade-up" data-aos-duration="500">
            Get immersed in the most sought-after skills your future will thank
            you for.
          </p>
          <Button
            isHyperLink="true"
            location={COURSES_REGISTRATION_LINK}
            cta="Sign up for our courses"
          />
        </div>

        <div className="facilitators">
          <h2>Meet the Facilitators</h2>
          <div
            className="facilitators-main"
            style={{ flexDirection: isOpen ? "column" : "row" }}
          >
            <Facilitators
              toggleIsOpen={toggleIsOpen}
              img={require("../../Assets/img/philip.png")}
              instagram="https://instagram.com/nkwamphilip?igshid=YmMyMTA2M2Y="
              linkedIn="https://www.linkedin.com/in/nkwam-philip"
              twitter="https://twitter.com/philip_nkwam"
              name="Philip Nkwam"
              title="AI, Data Analytics & Cloud Architectures"
              description1="Philip Nkwam is a Technical Trainer at The Bulb Africa, Jibowu -
            Yaba, a Top Software Engineering and Incubator Company in Lagos. He
            started facilitating Core training programs at age 19 after working
            as a software engineer at several companies. He was a Data Scientist
            at Ajobox Inc, a Fintech Startup."
              description2="He has trained and mentored several folks within and across the
            continent under Coretal.io, MentorColor, PyData Global Impact
            Mentor, Sidehustle Internship, NextGen @ The Bulb Africa and is
            currently a Big Data Technical Trainer at Fellowship @ The Bulb
            Africa, where he trains fellows on Data Analytics, Big Data
            Architectures and Machine Learning."
              description3="He currently leads the validation of an MIT Research Project on
            Breast Cancer risk assessment AI Algorithm in Sub-Saharan Africa in
            partnership with St Cyril Cancer Treatment Foundation."
            />
            <Facilitators
              toggleIsOpen={toggleIsOpen}
              img={require("../../Assets/img/idris.png")}
              instagram={false}
              linkedIn="https://www.linkedin.com/in/idris-olatunji-badru-98b83217"
              twitter={false}
              name="Idris Olatunji Badru"
              title="Technology Advisor, Consultant, Business Agility Enthusiast and Digital Transformation Champion"
              description1="Idris is a Technology Advisor, Consultant, Business Agility Enthusiast and Digital Transformation Champion. He is a Certified Scrum Master (CSM), SAFe Program Consultant (SPC), Project Management Professional (PMP) and expert in Business and System Integration. 
            
            "
              description2="
            He has expertise in agile and digital transformation, lean agile portfolio and program management, requirements management, business process re-engineering, complex project execution, change coordination, systems integration, business transformation and project management.  
            "
              description3="
            Idris worked with 2 of the largest technology consulting companies in world where he helped delivered products and complex projects in both agile and waterfall work environment for mostly large, global financial services companies. "
              description4="He currently leads a global Agile Community of Practice which consists of over 600 Agile practitioners in United States, Canada, and United Kingdom."
            />
          </div>
        </div>
        <div className="partners" data-aos="fade-up" data-aos-duration="1000">
          <h2>Our Partners</h2>
          <div>
            <img src={beecube} alt="" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ExploreCourses;
