import React from "react";
import "./talent.css";
export const Talent = () => {
  return (
    <div className="lg:px-24 md:px-12 px-6 md:py-28 manrope py-12">
      <h1 className="text-[#13162D] lg:text-6xl md:text-5xl text-2xl font-bold text-center ">
        The Bulb Tech Talent Model
      </h1>
      <div className="flex w-full justify-between items-center md:text-5xl text-xl text-[#4D4C4C] font-Gotic font-normal md:pt-12 pt-6 pb-0">
        <h1 className="">For Talents</h1>
        <h1>For Business</h1>
      </div>
      <div>
        <div className="timeline-container  ">
          {timelineData.map((data) => (
            <TimelineItem tags={data.tags} key={data.id} talent={data.talent} />
          ))}
        </div>
      </div>
    </div>
  );
};

// export const Talent = () =>
//   timelineData.length > 0 && (
//     <div className="timeline-container  ">
//       {timelineData.map((data) => (
//         <TimelineItem tags={data.tags} key={data.id} talent={data.talent} />
//       ))}
//     </div>
//   );
export const TimelineItem = ({ tags, talent }) => (
  <div className="timeline-item">
    <div className="timeline-item-content">
      <h1 className="md:text-[16px] text-[12px]  font-bold font-Gotic py-2 text-[#13162D]">
        {tags}
      </h1>
      <p className="md:text-[16px] text-[8px] text-[#4D4C4C] md:leading-[30px] leading-[10px] font-medium">
        {talent}
      </p>

      <span className="circle" />
    </div>
  </div>
);

const timelineData = [
  {
    id: 1,
    tags: "StartUp Academy",
    talent:
      "We educate and offer training and mentorship to founders who are at the beginning of their startup journey.",
  },
  {
    id: 2,
    tags: "Education",
    talent:
      "We believe in the power of education. We empower Africa's tech talents by providing access to cutting-edge tech education for local & global opportunities and career growth. ",
  },
  {
    id: 3,
    tags: "StartUp Engine",
    talent:
      "Our Startup Engine is geared towards incubating fascinating ideas, accelerating early-stage startups, and co-investing in startups that can drive growth and innovation in Africa",
  },
  {
    id: 4,
    tags: "Talent Placement",
    talent:
      "We help our trained talents get job placements through our recruitment and talent sourcing programs.",
  },
  {
    id: 5,
    tags: "Talent-as-a Service",
    talent:
      "Get access to well-trained and experienced software engineers, designers, and other tech experts to meet your business needs,    Also, build best-in-class products with the top engineering talents in Africa",
  },
  {
    id: 6,
    tags: "Community For You",
    talent:
      "From professionals and newbie tech talents, Our Community has something for everyone. Connect, learn, and grow with technology-inclined individuals across Africa.",
  },
  {
    id: 7,

    tags: "Office Space/Virtual Office",
    talent:
      "We provide you a space to build, innovate and collaborate. A multi-location functional virtual and physical office for businesses to build the next big thing.",
  },
  {
    id: 8,

    tags: "Co-working Space",
    talent:
      "We provide you a space to innovate and collaborate. A multi-location functional shared office for tech talents to build the next big thing.  ",
  },
  {
    id: 9,
    tags: "A Community for You (Leverage)",
    talent:
      "A community for tech founders & entrepreneurs to pitch innovative ideas and discuss relevant technological issues that could lead to more fascinating discoveries, driving further growth and development in the continent.",
  },
];
