import Axios from 'axios';
import { Api } from '../Const/Api';

export const uploadfile = async file => {
	alert('api called');
	let formData = new FormData();
	formData.append('file', file);
	return Axios.post(Api.BaseUrl + '/api/file-upload', formData).then(resp => {
		console.log('uploadfile', resp);
	});
};

export const minRead = sentence => {
	const avgRead = sentence?.split(' ').length / 300;
	if (avgRead < 1) {
		return 1;
	}
	return Math.round(avgRead);
};

export const industries = [
	'Agriculture',

	'Construction/ Real Estate',

	'Consumer Goods',

	'Financial Services',

	'Healthcare',

	'Industrial Goods',

	'Information & Communications Technology ',

	'Natural Resources ',

	'Oil & Gas',

	'Services',

	'Utilities',

	'Conglomerates'
];

// export const Uploadfile = (file) => {
//     let formData = new FormData()
//     formData.append(
//         'file',
//         file
//     )
//     return dispatch => {
//         Axios.post(Api.BaseUrl + '/api/file-upload', formData)
//             .then(resp => {
//                 console.log("resp======file", resp)
//                 dispatch(StateLoading());
//                 if (resp.status === 200) {
//                     if (resp.data.status === "1") {
//                         dispatch(UploadfileSuccess(resp.data.data.image_url));
//                     }
//                     if (resp.data.status === "0") {
//                         dispatch(UploadfileError(resp.data.message));
//                     }
//                 } else {
//                     dispatch(UploadfileError("we are unable to fetch your data Please check your internet connection or try again"));
//                 }
//             })
//             .catch(err => {
//                 dispatch(UploadfileError(err.message));
//             });
//     };
// };
