import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import logo1 from "../../../Assets/BrandLogo/logo1.png";
import logo2 from "../../../Assets/BrandLogo/logo2.png";
import logo3 from "../../../Assets/BrandLogo/logo3.png";
import logo4 from "../../../Assets/BrandLogo/logo4.png";
import logo5 from "../../../Assets/BrandLogo/logo5.png";
import logo6 from "../../../Assets/BrandLogo/logo6.png";
import logo7 from "../../../Assets/BrandLogo/logo7.png";
import logo8 from "../../../Assets/BrandLogo/logo8.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";

export const LogoSlide = () => {
  return (
    <div className="md:mt-12 mt-6  ">
      <h2 className="text-xl sm:text-3xl lg:text-4xl font-bold flex items-center flex-col pt-6 md:pb-12 pb-6 text-[#13162D] ">
        Meet Some of Our Partners
      </h2>
      <Swiper
        spaceBetween={60}
        slidesPerView={4}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        modules={[Autoplay]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        className="flex   items-center w-full"
      >
        <SwiperSlide>
          <img src={logo1} alt="" width="160px" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo2} alt="" width="160px" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo3} alt="" width="160px" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo4} alt="" width="160px" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo5} alt="" width="160px" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo6} alt="" width="160px" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo7} alt="" width="160px" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={logo8} alt="" width="160px" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
