import * as types from "../Action/actiontype";

const initialState = {
    resp: [],
    loading: true,
    error: '',
    paging: []
};

export const NewsFeedEvent = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.NEWSFEED_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.NEWSFEED_EVENT_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                paging: payload
            };

        case types.NEWSFEED_EVENT_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.NEWSFEED_EVENT_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}

export const NewsFeedEventCalender = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.NEWSFEED_EVENT_CALENDER_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.NEWSFEED_EVENT_CALENDER_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.NEWSFEED_EVENT_CALENDER_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}

export const NewsFeedTag = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.NEWSFEED_TAG_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.NEWSFEED_TAG_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.NEWSFEED_TAG_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                paging: payload
            };
        case types.NEWSFEED_TAG_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}

export const NewsFeedPress = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.NEWSFEED_PRESS_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.NEWSFEED_PRESS_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                paging: payload
            };
        case types.NEWSFEED_PRESS_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.NEWSFEED_PRESS_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
export const NewsFeedGallery = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.NEWSFEED_GALLERY_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.NEWSFEED_GALLERY_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                paging: payload
            };
        case types.NEWSFEED_GALLERY_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.NEWSFEED_GALLERY_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}