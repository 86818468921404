import React from 'react';
import StaffProfile from '../../Components/StaffProfile';
import Footer from '../../Container/Footer';
import { Box, Text, SimpleGrid, Image, Link } from '@chakra-ui/react';

import managingPartners from './managingPartners';
import Header from '../../Container/Header';
import placeholder from '../../Assets/img/placeholder.png';
import Babatunde from '../../Assets/img/Babatunde.png';
import Ishaku from '../../Assets/img/Ishaku.png';
import Femi from '../../Assets/img/Femi.png';
import tosin from '../../Assets/img/tosin-new.png';
import tosin2 from '../../Assets/img/tosin2.png';
import wande from '../../Assets/img/wande.png';
import wande2 from '../../Assets/img/wande2.png';
import peace from '../../Assets/img/peace.png';
import daniel from '../../Assets/img/daniel.jpg';
// import sunkanmi from '../../Assets/img/sunkanmi.jpg';
// import bukola1 from '../../Assets/img/bukola1.jpg';
// import Adeyemi2 from '../../Assets/img/Adeyemi2.png';
// import Collin from '../../Assets/img/Collin.png';
// import Olumide from '../../Assets/img/Olumide.png';
// import Patrick from '../../Assets/img/Patrick.png';
// import Korede from '../../Assets/img/Korede.png';
// import blankProfile from '../../Assets/img/blankProfile.png';
// import ReactSelect from '../../Components/Select';
import Esther from "../../Assets/thebulb/ESTHER.jpg"
import Bosun from "../../Assets/thebulb/BOSUN.jpg"
import Gabriel from "../../Assets/thebulb/GABRIEL.jpg"
import Lilian from "../../Assets/thebulb/LILIAN.jpg"
import Damilola from "../../Assets/thebulb/DAMILOLA.jpg"
import Muyiwa from "../../Assets/thebulb/MUYIWA.jpg"
import Steven from "../../Assets/thebulb/STEVEN.jpg"
import Samuel from "../../Assets/thebulb/SAMUEL.jpg"
import Oyindamola from "../../Assets/thebulb/OYINDAMOLA.jpg"


import Picture from '../../Components/StaffPicture/index';
import { Api } from '../../Const/Api';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const whereTalentsWork = [
	{
		source: 'dotpay'
		// link: 'https://www.dotpay.africa/#/'
	},
	{
		source: 'gtbank'
		// link: 'https://www.gtbank.com/'
	},
	{
		source: 'treepz'
		// link: 'https://ng.treepz.com/'
	},
	{
		source: 'webcoupers'
		// link: 'https://www.webcoupers.com/'
	},
	{
		source: 'sterling'
		// link: 'https://sterling.ng/'
	},
	{
		source: 'chisco'
		// link: 'https://chiscotransport.com.ng/'
	},
	{
		source: 'deloitte'
		// link: 'https://www2.deloitte.com/za/en/nigeria.html'
	},
	{
		source: 'access'
		// link: 'https://www.accessbankplc.com/'
	},
	{
		source: 'polaris',
		// link: 'https://www.polarisbanklimited.com/'
	},
	{
		source: 'prunedge',
		// link: 'https://prunedge.com/'
	},
	{
		source: 'parkway',
		// link: 'https://parkway.ng/'
	},
	{
		source: 'first-bank',
		// link: 'https://parkway.ng/'
	},
	{
		source: 'suntrust',
		// link: 'https://parkway.ng/'
	},
]

const ourPartners1 = [
	{
		source: 'dotpay',
		// link: 'https://www.dotpay.africa/#/'
	},
	{
		source: 'gtbank',
		// link: 'https://www.gtbank.com/'
	},
	{
		source: 'parkway',
		// link: 'https://parkway.ng/'
	},
	{
		source: 'polaris',
		// link: 'https://www.polarisbanklimited.com/'
	},
	{
		source: 'chisco',
		// link: 'https://chiscotransport.com.ng/'
	},
	{
		source: 'herconomy',
		// link: 'https://herconomy.com/'
	},
	{
		source: 'access',
		// link: 'https://www.accessbankplc.com/'
	},
	{
		source: 'prunedge',
		// link: 'https://prunedge.com/'
	},
	{
		source: 'peexoo',
		// link: 'https://www.peexoo.ai/'
	},
	{
		source: 'deloitte',
		// link: 'https://www2.deloitte.com/za/en/nigeria.html'
	},
	{
		source: 'commercio',
		// link: 'https://www.comerciopartners.com/'
	},
	{
		source: 'lsetf',
		// link: 'https://lsetf.ng/'
	},
	{
		source: 'act',
		// link: 'https://actrustfoundation.org/'
	},
	{
		source: 'tech-jewel',
		// link: '#',
		self: '_self'
	},
	{
		source: 'sterling',
		// link: 'https://sterling.ng/'
	},
	{
		source: 'treepz',
		// link: 'https://ng.treepz.com/'
	},
	{
		source: 'afdb',
		// link: 'https://www.afdb.org/en'
	},
	{
		source: 'webcoupers',
		// link: 'https://www.webcoupers.com/'
	},
	{
		source: 'olaniwun-ajayi',
		// link: 'https://www.olaniwunajayi.net/'
	}
]

const Tick = () => (
	<svg
		style={{
			display: 'inline',
			marginRight: '10px'
		}}
		width="34"
		height="34"
		viewBox="0 0 34 34"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="34" height="34" rx="2" fill="#F3F6FC" />
		<path
			d="M15.1267 22.9759C14.974 23.1296 14.7656 23.2153 14.5491 23.2153C14.3327 23.2153 14.1243 23.1296 13.9716 22.9759L9.35901 18.3626C8.88033 17.8839 8.88033 17.1077 9.35901 16.6299L9.93657 16.0522C10.4154 15.5735 11.1907 15.5735 11.6694 16.0522L14.5491 18.9321L22.3306 11.1505C22.8094 10.6718 23.5855 10.6718 24.0634 11.1505L24.641 11.7282C25.1196 12.2069 25.1196 12.983 24.641 13.4609L15.1267 22.9759Z"
			fill="#3B6BD3"
		/>
	</svg>
);

class Aboutpage extends React.Component {
	state = {
		hoveredPicture: '',
		businessUnit: 'operations',
		departments: [],
		id: 8,
		teams: [],
		management: [],
		managementID: ''
	};
	// async componentDidMount() {
	// 	await this.getDepartment();
	// 	this.getManagement();
	// }

	// componentDidMount = () => {
	// 	document.getElementById("navTop").style.display = "flex";
	// 	document.getElementById("navTop").style.backgroundColor = "#10152c";
	// 	const items = document.getElementsByClassName("nav-link");
	// 	for (let i = 0; i < items.length; i++) {
	// 		items[i].style.color = "#fff";
	// 	}
	// }

	// componentDidUpdate = () => {
	//   document.getElementById("navTop").style.display = "flex";
	// 	document.getElementById("navTop").style.backgroundColor = "#10152c";
	//   const items = document.getElementsByClassName("nav-link");
	//   for (let i = 0; i < items.length; i++) {
	//     items[i].style.color = "#fff";
	//   }
	// }

	getDepartment = async () => {
		let result = [];
		await axios.get(`${Api.BaseUrl}department/all`).then(function (response) {
			console.log("jfjgj", response);
			if (response.status === 200) {
				result = response.data.payload.message;
			}
		});
		this.getTeam(result?.[0]?.id);
		result.map((result) => {
			if (result.name === "Management") {
				this.setState({ managementID: result.id })
			}
		})
		this.setState({ departments: result });
	};

	// http: //35.178.225.103/backend/api/department/team/2

	getManagement = async () => {
		let result = [];
		await axios.get(`${Api.BaseUrl}department/team/${this.state.managementID}`).then(function (response) {
			if (response.status === 200) {
				result = response.data.payload.message;
			}
		});
		this.setState({ management: result });
	};

	getTeam = async id => {
		let teams = [];
		await axios.get(`${Api.BaseUrl}department/team/${id}`).then(function (response) {
			if (response.status === 200) {
				teams = response.data.payload.message;
			}
		});
		this.setState({ teams });
	};
	setHoveredPicture = pictureOwner => {
		this.setState(() => ({ ...this.state, hoveredPicture: pictureOwner }));
	};

	setDepartment = e => {
		this.getTeam(e.target.value);
	};

	setBusinessUnit = unit => {
		this.setState(() => ({ ...this.state, businessUnit: unit }));
	};

	render() {
		const { departments, teams, management } = this.state;
		// console.log('yes oo', teams, departments);

		const businessUnit = departments
			? departments.map(stateObj => (
				<option key={stateObj.id} value={stateObj.id}>
					{stateObj.name}
				</option>
			))
			: '';

		const businessUnitData = departments
			? departments.map(stateObj => ({ value: stateObj.id, label: stateObj.name }))
			: null;
		return (
			<div
				className="aboutus"
				style={{
					position: 'absolute',
					top: '0',
					left: '0',
					right: '0',
					zIndex: '-1'
				}}
			>
				<Helmet>

					{/*TITLE TAGS & META TAGS*/}
					<title>About The Bulb| Africa’s Leading Tech Talent Incubator</title>
					<meta name="title" content="About The Bulb| Africa’s Leading Tech Talent Incubator " />
					<meta name="description" content="About Us. The Bulb Africa connects innovation from Africa to global opportunities. Find out why you should partner with us. " />
					{/* Open Graph meta tags */}
					<meta property="og:title" content="About The Bulb | Africa’s Leading Tech Talent Incubator " />
					<meta property="og:url" content="https://thebulb.africa/about-us" />
					<meta property="og:description" content="About Us. We’re a tech talent incubator strategically designed to close the talent shortage gap in Africa. " />
					<meta property="og:type" content="website" />
					<meta property="og:image" content="https://thebulb.africa/Images/homepage/logo.png" />
					<meta property="og:image:alt" content="The Bulb Africa- offical logo" />
					{/* twitter meta tags */}

					<meta name="twitter:title" content="About The Bulb | Africa’s Leading Tech Talent Incubator" />
					<meta name="twitter:description" content="About Us. We’re a tech talent incubator strategically designed to close the talent shortage gap in Africa." />
					<meta name="twitter:image" content="https://thebulb.africa/Images/homepage/logo.png" />
					<meta name="twitter:image:alt" content="The Bulb- offical logo" />
					<meta name="twitter:site" content="@TheBulbAfrica" />
					<meta name="twitter:card" content="summary" />
					{/* JSON LD Structured Data */}
					<script type="application/ld+json">
						{`	{
							"@context": "https://schema.org/",
						"@type": "Article",
						"mainEntityOfPage": {
							"@type": "WebPage",
						"@id": "https://thebulb.africa/about-us"
							},
						"headline": "About The Bulb |Africa’s Leading Tech Talent Incubator",
						"description": "About Us. We’re a tech talent incubator strategically designed to close the talent shortage gap in Africa.",
						"image": "https://thebulb.africa/Images/homepage/logo.png",
						"author": {
							"@type": "Organization",
						"name": "The Bulb Africa"
							},
						"publisher": {
							"@type": "Organization",
						"name": "",
						"logo": {
							"@type": "ImageObject",
						"url": ""
							}
							},
						"datePublished": "2020-01-20",
						"dateModified": "2022-09-21"
							}`}
					</script>
				</Helmet>
				<Header />
				<Box ref={this.nav} id="about-us">
					<Box
						maxW="954px"
						px="15px"
						mx='auto'
						style={{
							marginTop: 90,
							color: '#28314F',
							textAlign: 'center',
							// margin: "0 auto"
						}}
					>
						<h2 style={{ fontSize: 42, color: '#10162B', textAlign: 'center', marginBottom: 24 }}>What we do</h2>
						<div style={{ fontSize: 18, color: '#050038', textAlign: 'center', marginBottom: 26 }}>
							<Text lineHeight="172%">
								We are a platform for African tech talents leveraging technology. We create employment opportunities, help startups scale, mold young Africans into tech experts and provide communities to foster growth and career advancements.
							</Text>
						</div>
					</Box>
					<Box
						px="15px"
						// mx={{ base: 0, md: '50px' }}
						mx='auto'
						maxW="954px"
						align="center"
						style={{
							marginTop: 65,
							color: '#28314F'
						}}
					>
						<div style={{ fontSize: 42, color: '#10162B', marginBottom: 24 }}>We are driven by impact</div>
						<div style={{ fontSize: 18, color: '#050038', marginBottom: 26 }}>
							<Text lineHeight="172%">
								Our goal is to provide exceptional learning experiences to equip African tech creatives with the skills required to compete in the fast-changing world of technology.
								We incubate, accelerate, and co-invest in startups that can proffer efficient solutions capable of transforming the continent.
							</Text>
						</div>
					</Box>
					<Box
						px="15px"
						pb="70px"
						maxW="1250px"
						mx='auto'
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							marginTop: '140px '
						}}
					>
						<Box
							mt={{ base: '32px', md: '80px', lg: '132px' }}
							width="33.33%"
							px={{ base: '10px', md: '20px' }}
						>
							<Box
								w="full"
								h={{ base: '127px', md: '225px', lg: '432px' }}
								py={{ base: '10px', md: '20px' }}
							>
								<Image
									objectFit="cover"
									w="full"
									h="full"
									alt="Fellows at The Bulb Coliseum"
									fallbackSrc={placeholder}
									style={{ marginBottom: 32 }}
									src={require('../../Assets/img/new/IMG_1423.jpeg')}
								/>
							</Box>

							<Box
								w="full"
								h={{ base: '127px', md: '225px', lg: '432px' }}
								py={{ base: '10px', md: '20px' }}
							>
								<Image
									objectFit="cover"
									w="full"
									h="full"
									alt="Tosin Osunkoya, Founder of The Bulb Africa "
									fallbackSrc={placeholder}
									style={{ marginBottom: 32 }}
									src={require('../../Assets/img/about1.png')}
								/>
							</Box>

							<Box
								w="full"
								h={{ base: '127px', md: '225px', lg: '432px' }}
								py={{ base: '10px', md: '20px' }}
							>
								<Image
									objectFit="cover"
									w="full"
									h="full"
									alt="Modupe Durosinmi-Etti celebrating success at The Bulb Africa "
									fallbackSrc={placeholder}
									style={{ marginBottom: 32 }}
									src={require('../../Assets/img/about2.png')}
								/>
							</Box>
						</Box>

						<Box width="33.33%" px={{ base: '10px', md: '20px' }}>
							<Box
								w="full"
								h={{ base: '127px', md: '225px', lg: '432px' }}
								py={{ base: '10px', md: '20px' }}
							>
								<Image
									objectFit="cover"
									w="full"
									h="full"
									alt="Software developers at The Bulb Africa"
									fallbackSrc={placeholder}
									style={{ marginBottom: 32 }}
									src={require('../../Assets/img/new/SWT_1948.jpg')}
								/>
							</Box>
							<Box
								w="full"
								h={{ base: '127px', md: '225px', lg: '432px' }}
								py={{ base: '10px', md: '20px' }}
							>
								<Image
									objectFit="cover"
									w="full"
									h="full"
									alt="Staff at The Bulb Africa "
									fallbackSrc={placeholder}
									style={{ marginBottom: 32 }}
									src={require('../../Assets/img/about3.png')}
								/>
							</Box>

							<Box
								w="full"
								h={{ base: '127px', md: '225px', lg: '432px' }}
								py={{ base: '10px', md: '20px' }}
							>
								<Image
									objectFit="cover"
									w="full"
									h="full"
									alt="Fellows learning software engineering at The Bulb Africa"
									fallbackSrc={placeholder}
									style={{ marginBottom: 32 }}
									src={require('../../Assets/img/new/SWT_1923.jpg')}
								/>
							</Box>
						</Box>

						<Box
							mt={{ base: '32px', md: '80px', lg: '132px' }}
							width="33.33%"
							px={{ base: '10px', md: '20px' }}
						>
							<Box
								w="full"
								h={{ base: '127px', md: '225px', lg: '432px' }}
								py={{ base: '10px', md: '20px' }}
							>
								<Image
									objectFit="cover"
									w="full"
									h="full"
									alt="Fellows at The Bulb Africa "
									fallbackSrc={placeholder}
									style={{ marginBottom: 32 }}
									src={require('../../Assets/img/new/SWT_1874.jpg')}
								/>
							</Box>

							<Box
								w="full"
								h={{ base: '127px', md: '225px', lg: '432px' }}
								py={{ base: '10px', md: '20px' }}
							>
								<Image
									objectFit="cover"
									w="full"
									h="full"
									alt="Managing Director and the Programs Director at The Bulb "
									fallbackSrc={placeholder}
									style={{ marginBottom: 32 }}
									src={require('../../Assets/img/about5.png')}
								/>
							</Box>

							<Box
								w="full"
								h={{ base: '127px', md: '225px', lg: '432px' }}
								py={{ base: '10px', md: '20px' }}
							>
								<Image
									objectFit="cover"
									w="full"
									h="full"
									alt="African creatives at The Bulb Africa, a world class tech hub "
									fallbackSrc={placeholder}
									style={{ marginBottom: 32 }}
									src={require('../../Assets/img/new/SWT_1910.jpg')}
								/>
							</Box>
						</Box>
					</Box>

					<Box

						style={{
							backgroundColor: '#10162B',
							width: '100%',
							paddingTop: '147px'
						}}
					>
						<Box
							// maxW="100rem"
							maxW="1250px"
							mx="auto"
							px="15px"
							display={{ base: 'block', md: 'flex' }}
							justifyContent="center"
							gridGap="5rem"
						>
							<Box
								mx="auto"
								style={{ textAlign: 'center', color: '#fff', flexBasis: "30%", maxWidth: 320, marginBottom: '30px' }}
							>
								<Image
									mx="auto"
									style={{ marginBottom: 35 }}
									src={require('../../Assets/img/eye 1.svg')}
								/>
								<p style={{ fontSize: 30, marginBottom: 20 }}>Our Vision</p>
								<Text as="p" fontSize="18px">
									Our vision is to be Africa’s leading tech platform that connects innovation to global opportunities.
								</Text>
							</Box>
							<Box
								mx="auto"
								style={{ textAlign: 'center', color: '#fff', flexBasis: "30%", maxWidth: 320, marginBottom: '30px' }}
							>
								<Image
									mx="auto"
									style={{ marginBottom: 35 }}
									src={require('../../Assets/img/target 1.svg')}
								/>
								<p style={{ fontSize: 30, marginBottom: 20 }}>Our Mision</p>
								<Text as="p" fontSize="18px">
									Our mission is to inspire and empower the next generation of tech experts and innovators to build transferable and
									transportable skills set for the fast-changing global workspace.
								</Text>
							</Box>
							<Box
								mx="auto"
								style={{ textAlign: 'center', color: '#fff', flexBasis: "30%", maxWidth: 320, marginBottom: '30px' }}
							>
								<Image
									mx="auto"
									style={{ marginBottom: 35 }}
									src={require('../../Assets/img/target-2 1.svg')}
								/>
								<p style={{ fontSize: 30, marginBottom: 20 }}>Our Objective</p>
								<Text as="p" fontSize="18px">
									Our corporate objective is to empower 200 startups in 5 years, 50 women led startups in the same period and
									create 20,000 employment opportunities across Africa through our fellowship program.
								</Text>
							</Box>
						</Box>
						<Box
							pb={{ base: '350px', md: '400px' }}
							px="15px"
							maxW="100rem"
							mx="auto"
							style={{ marginTop: 60 }}
						>
							<p style={{ color: '#fbbf19', fontSize: 36, marginBottom: 0, textAlign: "center", marginTop: "5rem" }}>Meet the Board</p>
						</Box>
					</Box>
					<Box
						maxW="80rem"
						mx="auto"
						px="1rem"
						mt={{ base: '-300px', md: '-300px', lg: '-320px' }}
						mb="3rem"
						display={{ base: 'block', md: 'flex' }}
						justifyContent="center"
						gridGap="4rem"
						gao='4rem'
						flexWrap="wrap"
						style={{ display: 'flex', flexDirection: 'row' }}
					>
						<Box>
							<StaffProfile
								name={`Babatunde Durosinmi-Etti`}
								linkedIn_url={`https://www.linkedin.com/in/babatunde-durosinmi-etti-6b28a943/`}
								role={`Chairman`}
								useStaticImage={true}
								picture={Babatunde}
								setHoveredPicture={this.setHoveredPicture}
								hoveredPicture={this.state.hoveredPicture}
							/>
						</Box>
						<Box>
							<StaffProfile
								name={`Wande Adams`}
								linkedIn_url={`https://www.linkedin.com/in/wandeadams/`}
								role={`Co-Founder / CEO`}
								useStaticImage={true}
								picture={wande}
								setHoveredPicture={this.setHoveredPicture}
								hoveredPicture={this.state.hoveredPicture}
							/>
						</Box>
						<Box>
							<StaffProfile
								name={`Tosin Osunkoya`}
								linkedIn_url={`https://www.linkedin.com/in/tosin-osunkoya-fca-435a0a89/`}
								role={`Co-Founder`}
								useStaticImage={true}
								picture={tosin}
								setHoveredPicture={this.setHoveredPicture}
								hoveredPicture={this.state.hoveredPicture}
							/>
						</Box>
						<Box>
							<StaffProfile
								name={`Ishaku Yusufu`}
								linkedIn_url={`https://www.linkedin.com/in/yusufu-ishaku-9a446b65/`}
								role={`Non-executive Board Member`}
								useStaticImage={true}
								picture={Ishaku}
								setHoveredPicture={this.setHoveredPicture}
								hoveredPicture={this.state.hoveredPicture}
							/>
						</Box>
						<Box>
							<StaffProfile
								name={`Femi Okegbenro`}
								linkedIn_url={`https://www.linkedin.com/in/femi-okegbenro-604115184/`}
								role={`Non-executive Board Member`}
								useStaticImage={true}
								picture={Femi}
								setHoveredPicture={this.setHoveredPicture}
								hoveredPicture={this.state.hoveredPicture}
							/>
						</Box>
						{console.log(management)}
						{management.Teams?.length > 0 &&
							management.Teams?.map(({ name, role, picture, linkedIn_url }, index) => {
								if (index >= 2) return null;
								return (
									<Box mb="100px" px="15px">
										{console.log(name, role, picture)}
										<StaffProfile
											name={name}
											linkedIn_url={linkedIn_url}
											role={role}
											picture={picture}
											setHoveredPicture={this.setHoveredPicture}
											hoveredPicture={this.state.hoveredPicture}
										/>
									</Box>
								);
							})}
					</Box>
					<Box
						mx="auto"
						px="1rem"
						mb="3rem"
						display={{ base: 'block', md: 'flex' }}
						justifyContent="center"
						gridGap="4rem"
						flexWrap="wrap"
						style={{ display: 'flex', flexDirection: 'column' }}
					>
						<p style={{ color: '#10162B', fontSize: 36, marginBottom: 0, textAlign: "center", marginTop: "5rem" }}>Meet the Founders</p>

						<Box
							style={{ display: 'flex', flexDirection: 'row' }}
							display={{ base: 'block', md: 'flex' }}
							justifyContent="center"
							gridGap="4rem"
							flexWrap="wrap"
						>
							<Box>
								<StaffProfile
									name={`Wande Adams`}
									linkedIn_url={`https://www.linkedin.com/in/wandeadams/`}
									role={`Co-Founder / CEO`}
									useStaticImage={true}
									picture={wande2}
									setHoveredPicture={this.setHoveredPicture}
									hoveredPicture={this.state.hoveredPicture}
								/>
							</Box>
							<Box>
								<StaffProfile
									name={`Tosin Osunkoya`}
									linkedIn_url={`https://www.linkedin.com/in/tosin-osunkoya-fca-435a0a89/`}
									role={`Co-Founder`}
									useStaticImage={true}
									picture={tosin2}
									setHoveredPicture={this.setHoveredPicture}
									hoveredPicture={this.state.hoveredPicture}
								/>
							</Box>
						</Box>
					</Box>

					<Box
						maxW="100rem"
						mx="auto"
						px="1rem"
						// mt={{ base: '-300px', md: '-300px', lg: '-320px' }}
						mb="3rem"
						display={{ base: 'block', md: 'flex' }}
						justifyContent="center"
						style={{ flexDirection: 'column' }}
					>

						<p style={{ color: '#10162B', fontSize: 36, marginBottom: '4rem', textAlign: "center", marginTop: "5rem" }}>Meet the Executors</p>
						<Box
							style={{ display: 'flex' }}
							gridGap="4rem"
							flexWrap="wrap"
							justifyContent="center"
							mx='auto'

						>

							<Box>
								<StaffProfile
									name={`Damilola Emuze`}
									linkedIn_url={``}
									role={`Operations Manager`}
									useStaticImage={true}
									picture={Damilola}
									setHoveredPicture={this.setHoveredPicture}
									hoveredPicture={this.state.hoveredPicture}
								/>
							</Box>
							<Box>
								<StaffProfile
									name={`Daniel Nnechi`}
									linkedIn_url={`https://www.linkedin.com/in/ekene-nnechi-5b5800175`}
									role={`Head, media & Marketing`}
									useStaticImage={true}
									picture={daniel}
									setHoveredPicture={this.setHoveredPicture}
									hoveredPicture={this.state.hoveredPicture}
								/>
							</Box>
							<Box>

								<StaffProfile
									name={`Lilian Ugoh`}
									linkedIn_url={``}
									role={`Head,People & Culture`}
									useStaticImage={true}
									picture={Lilian}
									setHoveredPicture={this.setHoveredPicture}
									hoveredPicture={this.state.hoveredPicture}
								/>
							</Box>
							 <Box>
							<StaffProfile
								name={`Muyiwa Adetunji`}
								linkedIn_url={``}
								role={`Project Manager`}
								useStaticImage={true}
								picture={Muyiwa}
								setHoveredPicture={this.setHoveredPicture}
							    hoveredPicture={this.state.hoveredPicture}
							/>
						</Box> 
							{/* <Box>
							<StaffProfile
								name={`Vacant`}
								linkedIn_url={`https://www.linkedin.com/in/`}
								role={`Program Manager - Startup Engine`}
								useStaticImage={true}
								picture={blankProfile}
								setHoveredPicture={this.setHoveredPicture}
							// hoveredPicture={this.state.hoveredPicture}
							/>
						</Box> */}
							<Box>
								<StaffProfile
									name={`Esther Abang`}
									linkedIn_url={``}
									role={`Finance Manager`}
									useStaticImage={true}
									picture={Esther}
									setHoveredPicture={this.setHoveredPicture}
									hoveredPicture={this.state.hoveredPicture}
								/>
							</Box>
							<Box>
								<StaffProfile
									name={`Steven Orokpo`}
									linkedIn_url={``}
									role={`People Operations Lead`}
									useStaticImage={true}
									picture={Steven}
									setHoveredPicture={this.setHoveredPicture}
									hoveredPicture={this.state.hoveredPicture}
								/>
							</Box>
							<Box>
								<StaffProfile
									name={`Samuel Adegbite`}
									linkedIn_url={``}
									role={`IT Manager`}
									useStaticImage={true}
									picture={Samuel}
									setHoveredPicture={this.setHoveredPicture}
									hoveredPicture={this.state.hoveredPicture}
								/> 
							</Box>
							<Box>
								<StaffProfile
									name={`Gabriel Osho`}
									linkedIn_url={``}
									role={`Programs Operators Manager`}
									useStaticImage={true}
									picture={Gabriel}
									setHoveredPicture={this.setHoveredPicture}
									hoveredPicture={this.state.hoveredPicture}
								/>
							</Box>
							<Box>
								<StaffProfile
									name={`Bosun Abiodun`}
									linkedIn_url={``}
									role={`Hub Manager`}
									useStaticImage={true}
									picture={Bosun}
									setHoveredPicture={this.setHoveredPicture}
									hoveredPicture={this.state.hoveredPicture}
								/>
							</Box>
							<Box>
								<StaffProfile
									name={`Oyindamola Olawuyi`}
									linkedIn_url={`https://www.linkedin.com/in/olukorede-samuel-766b52162/`}
									role={`Community Manager`}
									useStaticImage={true}
									picture={Oyindamola}
									setHoveredPicture={this.setHoveredPicture}
									hoveredPicture={this.state.hoveredPicture}
								/>
							</Box>
						</Box>
					</Box>


					<Box maxW="1300px" mx='auto' >
						<div className='where-talents-work'>
							<h3
								data-aos='fade-up'
								data-aos-duration='500'
							>Where our talents work  </h3>
							<div className='sponsor-images'>
								{whereTalentsWork.map(image =>
									<a
										href={image.link || null}
										target="_blank" rel="noreferrer"

									>
										<img
											src={require(`../../Assets/img/sponsor-logos/${image.source}.png`)}
											alt=""
											data-aos='zoom-in'
											data-aos-duration='500'
										/>
									</a>
								)}
							</div>
						</div>
						<div className='our-partners'>
							<h3
								data-aos='fade-up'
								data-aos-duration='500'
							>Our Partners</h3>
							<p
								data-aos='fade-up'
								data-aos-duration='500'
							>We are supported by fantastic organizations that believe in our goals and objectives  </p>
							<div className='sponsor-images'>
								{ourPartners1.map(image =>
									<a
										href={image.link || null}
										target={image.self || "_blank"}
									>
										<img
											src={require(`../../Assets/img/sponsor-logos/${image.source}.png`)}
											alt=""
											data-aos='zoom-in'
											data-aos-duration='500'
										/>
									</a>
								)}
							</div>
						</div>
					</Box>

					<Box mt="100px">
						{/* <Box
							style={{
								maxWidth: '300px',
								paddingLeft: 15,
								paddingRight: 20,
								marginTop: -100,
								marginBottom: 100
							}}
						>
							{businessUnitData.length > 0 && (
								<ReactSelect data={businessUnitData} onChange={this.getTeam} />
							)}
						</Box>

						<SimpleGrid columns={{ base: 2, sm: 2, md: 3, lg: 4, xl: 5 }}>
							{teams?.Teams?.length > 0 &&
								teams?.Teams?.map((team, index) => (
									<Picture key={team.id} name={team.name} role={team.role} team={team} />
								))}
						</SimpleGrid> */}
						<Box
							w="full"
							px={'16px'}
							py={'80px'}
							display="block"
							style={{
								backgroundColor: 'rgba(59, 107, 211, 0.1)'
							}}
						// my="100px"
						>
							<Box mx='auto' maxW="976px" align='center' >
								<div className='bottom-about'>
									<Text style={{ color: '#050038', fontSize: '36px', fontWeight: 'bold' }}>
										{/* We offer only the best, always */}
										We are guided by our corporate values and who we are
									</Text>
									<Text
										fontSize={{ base: '16px', md: '18px' }}
										lineHeight="38px"
										as="p"

									// mx="auto"
									// mt="20px"
									>
										{/* We are a social enterprise using our platform to positively influence the African
									continent with the aid of tech education, empowerment and opportunities. */}
										We are a team of vibrant thinkers, dedicated to the concepts of great value, excellence, and love. We always work to solve problems and deliver results on time.
										We are constantly reinventing ourselves to outdo our best inventions.
									</Text>
									<Link
										// mx="auto"
										fontWeight="bold"
										href="/partner-with-us"
										style={{
											width: 195,
											height: 56,
											backgroundColor: '#050038',
											borderRadius: 6,
											textAlign: 'center',
											color: '#fff',
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											margin: '0 auto',
											cursor: 'pointer'
										}}
									>
										Partner with us
									</Link>
								</div>
							</Box>
						</Box>
					</Box>
				</Box>
				<Footer removeMargin={true} />
			</div>
		);
	}
}

export default Aboutpage;
