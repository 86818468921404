import * as types from "../Action/actiontype";

const initialState = {
    resp: [],
    loading: true,
    error: ''
};

export const StateReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.STATE_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.STATE_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.STATE_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
export const CityReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.CITY_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.CITY_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.CITY_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
export const FileuploadReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.UPLOAD_FILE_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.UPLOAD_FILE_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}