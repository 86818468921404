import React, { useEffect } from 'react';
import Footer from '../../../Container/Footer';
import { Link } from 'react-router-dom';
import {Helmet} from 'react-helmet';

const Startups = () => {
	// useEffect(() => {
	// 	document.getElementById('navTop').style.background = '#10152c';
	// 	window.scrollTo(0, 0);
	// }, []);
	return (
		<>
			<Helmet>
					<title>  The Startup Engine | The Bulb Africa  </title>
					<meta name="title" content="The Startup Engine | The Bulb Africa " />
					<meta name="description" content="The Startup Engine at The Bulb™ finds, builds and nurture startups like Peexoo, Avale and Settl.me through incubation and acceleration. Find out how to be next! " />
			</Helmet>
			<div className="startup parallax mt-73 py-5">
				<div className="startup-inner py-md-5">
					<div className="container py-md-5 px-md-5 my-md-5 text-center text-white">
						<h1>Build what you imagine</h1>
						<p className="py-3">
							Helping African entrepreneurs grow tech ideas into business realities.
							<br />
							We find, nurture and grow startups through our incubation and accelerator programs.{' '}
						</p>
						<div className="startup-btn flex-wrap d-flex justify-content-center align-items-center">
							<Link to="/apply-incubator" style={{marginRight:"20px"}}>
								<button className="btn btn-cus-outline-warning px-5">Apply for incubation</button>
							</Link>
							<a href="https://startupacademy.thebulb.africa/">
								<button className="btn btn-cus-warning px-5 mr-sm-5">Apply for accelerator</button>
							</a>
						</div>
					</div>
				</div>
			</div>
			<Footer style={{ marginTop: '0px' }} />
		</>
	);
};
export default Startups;
