import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

import theBulbLogoDark from "../../Assets/img/the-bulb-logo-dark.png";
import Navbar from "../../Container/Navbar";
import Footer from "../../Container/Footer";
import rocket from "../../Assets/img/rocket.png";
import "./StartupEngine.css";
import apply from "../../Assets/img/apply-for-incubation.png";
import join from "../../Assets/img/join-our-accelerator.png";
import invest from "../../Assets/img/Invest-now.png";
import womanBoard from "../../Assets/img/woman-on-board.png";
import calculating from "../../Assets/img/calculating.png";
import MiniCards from "../../Components/MiniCards/MiniCards";
import TextCard from "../../Components/Card/TextCard";
import ImageCard from "../../Components/Card/ImageCard";
import Button from "../../Components/Button/Button";

const StartupEngine = () => {
  useEffect(() => {
    // document.getElementById("navTop").style.display = "none";
    // const items = document.getElementsByClassName("nav-link");
    // for (let i = 0; i < items.length; i++) {
    //   items[i].style.color = "#10152c";
    // }
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  });

  const companies = [
    {
      source: "lsetf",
    },
    {
      source: "act",
    },
    {
      source: "olaniwun-ajayi",
    },
    {
      source: "herconomy",
    },
    {
      source: "tech-jewel",
    },
    {
      source: "aws",
    },
    {
      source: "newchip",
    },
  ];

  const miniCardsData = [
    {
      image: apply,
      caption: "Apply for incubation",
      inAppLink: true,
      destination: "/incubator",
    },
    {
      image: join,
      caption: "Join our accelerator",
      inAppLink: false,
      destination: "https://accelerate.thebulb.africa/",
    },
    {
      image: invest,
      caption: "Invest now",
      inAppLink: false,
      destination: "https://illuminate.thebulb.africa/",
    },
  ];

  return (
    <>
      <Helmet>
        {/*TITLE TAGS & META TAGS*/}
        <title>
          Build The Next Big Thing with our Start Engine | The Bulb{" "}
        </title>

        <meta
          name="title"
          content="Build The Next Big Thing with our Start Engine | The Bulb "
        />
        <meta
          name="description"
          content="Are you a mission-driven African entrepreneur? Enrol on our incubator & accelerate program. Get funding for your startup. "
        />
        {/* Open Graph meta tags */}
        <meta
          property="og:title"
          content="Build The Next Big Thing with our Start Engine | The Bulb "
        />
        <meta
          property="og:url"
          content="https://thebulb.africa/startup-engine"
        />
        <meta
          property="og:description"
          content="Are you a mission-driven African entrepreneur? Enrol on our incubator & accelerate program. Get funding for your startup. "
        />
        <meta
          property="og:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta property="og:image:alt" content="The Bulb Africa- offical logo" />
        {/* twitter meta tags */}
        <meta
          name="twitter:title"
          content="Build The Next Big Thing with our Start Engine | The Bulb"
        />
        <meta
          name="twitter:description"
          content="Are you a mission-driven African entrepreneur? Enrol on our incubator & accelerate program. Get funding for your startup. "
        />
        <meta
          name="twitter:image"
          content="https://thebulb.africa/Images/homepage/logo.png"
        />
        <meta name="twitter:image:alt" content="The Bulb- offical logo" />
        <meta name="twitter:site" content="@TheBulbAfrica" />
        <meta name="twitter:card" content="summary" />
        {/* JSON LD Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org/",
              "@type": "Article",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "https://thebulb.africa/startup-engine"
              },
              "headline": "Build The Next Big Thing with our Start Engine | The Bulb",
              "description": "Are you a mission-driven African entrepreneur? Enrol on our incubator & accelerate program. Get funding for your startup.",
              "image": "https://thebulb.africa/Images/homepage/logo.png",
              "author": {
                "@type": "Organization",
                "name": "The Bulb Africa"
              },
              "publisher": {
                "@type": "Organization",
                "name": "",
                "logo": {
                  "@type": "ImageObject",
                  "url": ""
                }
              },
              "datePublished": "2020-01-20",
              "dateModified": "2022-09-21"
            }
          `}
        </script>
      </Helmet>

      <div className="fixed-nav">
        <Navbar
          // closeAll={setState}
          navImage={theBulbLogoDark}
          isLight={true}
        />
      </div>
      <div className="startup-engine">
        <div className="bg-rocket">
          <div className="engine-hero">
            <div className="engine-hero-text">
              <h1
                data-aos="fade-right"
                data-aos-duration="500"
                className="engine-heading"
              >
                We back impact-driven African tech entrepreneurs
              </h1>
              <p
                data-aos="fade-right"
                data-aos-duration="800"
                className="engine-para"
              >
                Our Startup Engine is geared towards incubating fascinating
                ideas, accelerating early-stage startups, and co-investing in
                startups that can drive growth and innovation in Africa.
              </p>
              <Button
                isAppLink={true}
                cta="Get Started"
                location="#"
                isLight="true"
              />
            </div>
            <img
              data-aos="fade-up-right"
              data-aos-duration="1000"
              className="rocket"
              src={rocket}
              alt=""
            />
          </div>

          <div className="second-hero">
            <h2 data-aos="zoom-out" data-aos-duration="500" className="sec-h2">
              More than 54% of African startups fail. Let’s help you get it
              right
            </h2>
            <p data-aos="zoom-out" data-aos-duration="700" className="sec-p">
              We employ the skills and experience of founders, investors, and
              entrepreneurs across several sectors of the tech industry to help
              founders build sustainable businesses.
            </p>
          </div>
        </div>

        <div className="imagine">
          <h3
            className="imagine-head"
            data-aos="zoom-in"
            data-aos-duration="500"
          >
            Build what you imagine
          </h3>
          <p
            className="imagine-para"
            data-aos="zoom-in"
            data-aos-duration="500"
          >
            Our incubator program finds, nurtures, and helps African founders
            grow their tech ideas into impactful business realities. We offer
            training and mentorship to founders who are at the beginning of
            their startup journey.
          </p>
          <Button
            isAppLink={true}
            cta="Apply for Incubation"
            location="/incubator"
          />
        </div>
      </div>
      <div className="engine-card-container">
        <div className="engine-card-content">
          <ImageCard
            source={womanBoard}
            className="engine-image-card"
            animationDuration="1000"
          />
          <TextCard
            className="engine-text-card"
            headingClassName="engine-text-card-heading"
            descriptionClassName="engine-text-card-description"
            isHyperLink={true}
            heading="Learn from the best and scale your startup"
            description="Our world-class facilitators have either founded, grown, or invested in some of Sub-Saharan Africa’s most successful startups. Accelerate by The Bulb Africa equips founders with knowledge and the resources to scale fast and succeed."
            cta="Join our accelerator"
            location="https://accelerate.thebulb.africa/"
            isLight={true}
          />
        </div>
        <div className="engine-card-content reverse">
          <TextCard
            className="engine-text-card"
            headingClassName="engine-text-card-heading"
            descriptionClassName="engine-text-card-description"
            isHyperLink={true}
            heading="We manage and co-invest in vetted early-stage startups"
            description="After receiving training and mentoring via our accelerator program, we co-invest in high-quality technology-focused startups. We support, fund, and provide investors with startups driven by revolutionary solutions that can produce high returns on investments."
            cta="Learn more"
            location="https://illuminate.thebulb.africa/"
            isLight={true}
          />
          <ImageCard
            source={calculating}
            className="engine-image-card"
            animationDuration="1000"
          />
        </div>
      </div>
      <div className="companies">
        <h2
          className="companies-heading"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          Companies that trust us
        </h2>
        <div className="companies-logo">
          {companies.map((image) => (
            <img
              data-aos="zoom-in"
              data-aos-duration="1000"
              src={require(`../../Assets/img/sponsor-logos/${image.source}.png`)}
              alt=""
            />
          ))}
        </div>
      </div>

      <div className="bottom">
        <h3 data-aos="fade-up" data-aos-duration="1000" className="bottom-h3">
          We incubate, accelerate, and provide funding access to Africa’s rising
          startups
        </h3>
        <p data-aos="fade-up" data-aos-duration="1000" className="bottom-p">
          Our goal is to help founders build, sustain and scale businesses that
          will provide impactful solutions and transform our continent.
        </p>
      </div>

      <MiniCards data={miniCardsData} />
      <Footer />
    </>
  );
};

export default StartupEngine;
