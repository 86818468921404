import hero1 from "../../../Assets/thebulb/hero1.jpg";
import hero3 from "../../../Assets/thebulb/hero3.jpg";
import hero2 from "../../../Assets/thebulb/hero2.jpg";

export const heroData = [
  {
    id: 1,
    header1: "We are a",
    header2: "Tech Talent Incubator",
    text: "We nurture, equip & build Africa's rising tech talents through immersive training programs designed to meet the innovations of the future.",
    button: "Learn More ",
    video: "FSTzqzwYfF0",
    link: "https://www.thebulb.africa/about-us",
  },
  {
    id: 2,
    header1: "",
    header2: "Building Sustainable Businesses Leveraging Technology",
    text: "We incubate, accelerate, and provide funding for mission-driven early-stage African tech startups.",
    button: "Build The Next Big Thing",
    img: hero1,
    link: "https://www.thebulb.africa/startup-engine",
  },
  {
    id: 3,
    header1: "",
    header2: "Bridging the Talent Gap in Africa",
    text: "We educate and connect tech talents from Africa to job opportunities in Africa and across the globe.",
    button: "Hire Our Talents",
    video: "LkSKJ3EXIus",
    link: "https://www.thebulb.africa/hire-talents",
  },
  {
    id: 4,
    header1: "",
    header2: "Building Scalable Products with Top Tech Talents",
    text: "We transform your fascinating ideas into leading-edge web and mobile applications. Design, build, and launch digital solutions.",
    button: "Let Build With You",
    img: hero2,
    link: "https://www.thebulb.africa/product-development",
  },
  {
    id: 5,
    header1: "",
    header2: "Empowering Women in Tech",
    text: "We run programs that inspire and boost female participation in tech.",
    button: "Learn More",
    video: "22jRoSCSFhM",
    link: "https://t.me/+mHzrmmoAx9RkY2Q0",
  },
  {
    id: 6,
    header1: "",
    header2: "Building the World's Largest Community for African Tech Talents",
    text: "We provide communities and co-working spaces for tech enthusiasts at all levels to inspire, build and collaborate.",
    button: "Come hangout with us",
    img: hero3,
    link: "https://www.thebulb.africa/our-communities",
  },
];
