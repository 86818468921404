
// Newsfeed action type start
export const NEWSFEED_EVENT_SUCCESS = "NEWSFEED_EVENT_SUCCESS" 
export const NEWSFEED_EVENT_COUNT_SUCCESS = "NEWSFEED_EVENT_COUNT_SUCCESS" 
export const NEWSFEED_EVENT_LOADING = "NEWSFEED_EVENT_LOADING"
export const NEWSFEED_EVENT_ERROR = "NEWSFEED_EVENT_ERROR"

export const NEWSFEED_EVENT_CALENDER_SUCCESS = "NEWSFEED_EVENT_CALENDER_SUCCESS"  
export const NEWSFEED_EVENT_CALENDER_LOADING = "NEWSFEED_EVENT_CALENDER_LOADING"
export const NEWSFEED_EVENT_CALENDER_ERROR = "NEWSFEED_EVENT_CALENDER_ERROR"

export const NEWSFEED_TAG_SUCCESS = "NEWSFEED_TAG_SUCCESS"
export const NEWSFEED_TAG_COUNT_SUCCESS = "NEWSFEED_TAG_COUNT_SUCCESS"
export const NEWSFEED_TAG_LOADING = "NEWSFEED_TAG_LOADING"
export const NEWSFEED_TAG_ERROR = "NEWSFEED_TAG_ERROR"

export const NEWSFEED_PRESS_SUCCESS = "NEWSFEED_PRESS_SUCCESS"
export const NEWSFEED_PRESS_COUNT_SUCCESS = "NEWSFEED_PRESS_COUNT_SUCCESS"
export const NEWSFEED_PRESS_LOADING = "NEWSFEED_PRESS_LOADING"
export const NEWSFEED_PRESS_ERROR = "NEWSFEED_PRESS_ERROR"

export const PRESS_USER_SUCCESS = "PRESS_USER_SUCCESS"
export const PRESS_USER_LOADING = "PRESS_USER_LOADING"
export const PRESS_USER_ERROR = "PRESS_USER_ERROR"

export const NEWSFEED_GALLERY_SUCCESS = "NEWSFEED_GALLERY_SUCCESS"
export const NEWSFEED_GALLERY_COUNT_SUCCESS = "NEWSFEED_GALLERY_COUNT_SUCCESS"
export const NEWSFEED_GALLERY_LOADING = "NEWSFEED_GALLERY_LOADING"
export const NEWSFEED_GALLERY_ERROR = "NEWSFEED_GALLERY_ERROR"
// Newsfeed action type end 

//common action type start
export const STATE_SUCCESS = "STATE_SUCCESS"
export const STATE_LOADING = "STATE_LOADING"
export const STATE_ERROR = "STATE_ERROR"

export const CITY_SUCCESS = "CITY_SUCCESS"
export const CITY_LOADING = "CITY_LOADING"
export const CITY_ERROR = "CITY_ERROR"

export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS"
export const UPLOAD_FILE_LOADING = "UPLOAD_FILE_LOADING"
export const UPLOAD_FILE_ERROR = "UPLOAD_FILE_ERROR"

//common action type start end



// submit idea action start
export const SUBMIT_IDEA_SUCCESS = "SUBMIT_IDEA_SUCCESS"
export const SUBMIT_IDEA_LOADING = "SUBMIT_IDEA_LOADING"
export const SUBMIT_IDEA_ERROR = "SUBMIT_IDEA_ERROR"
export const SUBMIT_IDEA_STATUS = "JOB_FORM_SUBMIT_STATUS"
//submit idea action end

// apply incubator idea action start
export const INCUBATOR_SUCCESS = "INCUBATOR_SUCCESS"
export const INCUBATOR_LOADING = "INCUBATOR_LOADING"
export const INCUBATOR_ERROR = "INCUBATOR_ERROR"
//apply incubator idea action end

// hire a devloper idea action start
export const HIRE_DEVELOPER_SUCCESS = "HIRE_DEVELOPER_SUCCESS"
export const HIRE_DEVELOPER_LOADING = "HIRE_DEVELOPER_LOADING"
export const HIRE_DEVELOPER_ERROR = "HIRE_DEVELOPER_ERROR"
//hire a devloper idea action end


//carrear action type start
export const JOB_FORM_SUBMIT_SUCCESS = "JOB_FORM_SUBMIT_SUCCESS"
export const JOB_FORM_SUBMIT_LOADING = "JOB_FORM_SUBMIT_LOADING"
export const JOB_FORM_SUBMIT_ERROR = "JOB_FORM_SUBMIT_ERROR"

export const CURRENT_JOB_VACANCY_SUCCESS = "CURRENT_JOB_VACANCY_SUCCESS"
export const CURRENT_JOB_VACANCY_COUNT_SUCCESS = "CURRENT_JOB_VACANCY_COUNT_SUCCESS"
export const CURRENT_JOB_VACANCY_LOADING = "CURRENT_JOB_VACANCY_LOADING"
export const CURRENT_JOB_VACANCY_ERROR = "CURRENT_JOB_VACANCY_ERROR"
//carrear action type start

// request a tour start
export const REQUEST_TOUR_SUCCESS = "REQUEST_TOUR_SUCCESS"
export const REQUEST_TOUR_LOADING = "REQUEST_TOUR_LOADING"
export const REQUEST_TOUR_ERROR = "REQUEST_TOUR_ERROR"
// request a tour start end

// partnershipwith us start
export const PARTNERSHIP_SUCCESS = "PARTNERSHIP_SUCCESS"
export const PARTNERSHIP_LOADING = "PARTNERSHIP_LOADING"
export const PARTNERSHIP_ERROR = "PARTNERSHIP_ERROR"
// partnershipwith us start end

// schedule a visit us start
export const SCHEDULE_VISIT_SUCCESS = "SCHEDULE_VISIT_SUCCESS"
export const SCHEDULE_VISIT_LOADING = "SCHEDULE_VISIT_LOADING"
export const SCHEDULE_VISIT_ERROR = "SCHEDULE_VISIT_ERROR"
// schedule a visit us start end

// work space visit us start
export const WORK_SPACE_SUCCESS = "WORK_SPACE_SUCCESS"
export const WORK_SPACE_LOADING = "WORK_SPACE_LOADING"
export const WORK_SPACE_ERROR = "WORK_SPACE_ERROR"
// work space visit us start end
