import React, { useState } from "react";

const Facilitators = (props) => {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow((show) => !show);
    props.toggleIsOpen(!show)
  }
  const { innerWidth } = window;

  return (
    <div className="facilitator" data-aos="fade-up" data-aos-duration="1000">
      <div id="jui" style={{
          borderRadius: (show && innerWidth) >= 768 && "12px 0 0 12px",
          padding: show && innerWidth && "68px 30px",
          borderBottom:
            show && innerWidth < 768 && "2px solid rgba(5, 0, 56, 0.05)",
          // backgroundColor: show ? "rgba(5, 0, 56, 0.1)" : 'rgba(5, 0, 56, 0.05)',
        }}
      >
        <img src={props.img} alt="Philip Facilitator" />
        <div className="social-icons">
          {props.instagram && (
            <a
              href={props.instagram}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white p-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="22"
                height="22"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 1024 1024"
              >
                <path
                  fill="rgba(164, 164, 164, 1)"
                  d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3S645.3 585.4 645.3 512S585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165c-3.1-64-17.7-120.8-64.5-167.6c-46.9-46.9-103.6-61.4-167.6-64.5c-55.2-3.1-109.9-2.6-165-2.6c-55.2 0-109.9-.5-165 2.6c-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6c46.9 46.9 103.6 61.4 167.6 64.5c55.2 3.1 109.9 2.6 165 2.6c55.2 0 109.9.5 165-2.6c64-3.1 120.8-17.7 167.6-64.5c46.9-46.9 61.4-103.6 64.5-167.6c3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9S717.1 398.5 717.1 512S625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9s47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z"
                />
              </svg>
            </a>
          )}
          {props.linkedIn && (
            <a
              href={props.linkedIn}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white p-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="22"
                height="22"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 1000 1000"
              >
                <path
                  fill="rgba(164, 164, 164, 1)"
                  d="M196.064.25C88.347.25.187 88.408.187 196.127v607.841c0 107.717 88.158 195.845 195.877 195.845h607.841c107.718 0 195.845-88.127 195.845-195.845V196.127C999.75 88.41 911.623.25 803.905.25H196.064zm49.266 164.948c51.648 0 83.461 33.906 84.443 78.475c0 43.585-32.797 78.444-85.442 78.444h-.969c-50.665 0-83.412-34.857-83.412-78.444c0-44.568 33.738-78.475 85.379-78.475zm445.08 208.31c99.329 0 173.79 64.922 173.79 204.436v260.449H713.247V595.406c0-61.06-21.847-102.718-76.476-102.718c-41.704 0-66.562 28.078-77.476 55.202c-3.987 9.704-4.967 23.257-4.967 36.832v253.671H403.375s1.981-411.613 0-454.233h150.984v64.324c20.06-30.95 55.942-74.977 136.051-74.977zm-521.556 10.685h150.953v454.202H168.854V384.193z"
                />
              </svg>
            </a>
          )}
          {props.twitter && (
            <a
              href={props.twitter}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white p-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                role="img"
                width="22"
                height="22"
                preserveAspectRatio="xMidYMid meet"
                viewBox="0 0 24 24"
              >
                <path
                  fill="rgba(164, 164, 164, 1)rgba(164, 164, 164, 1)"
                  d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003Z"
                />
              </svg>
            </a>
          )}
        </div>
        <h2>{props.name}</h2>
        <p>{props.title}</p>
        <div
          style={{
            display: show ? "none" : "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <hr style={{ width: "100%" }} />
          <button style={{ marginTop: "10px" }} onClick={toggleShow}>
            Learn more
          </button>
        </div>
      </div>

      <div className="aboutFaci" style={{ display: show ? "flex" : "none" }}>
        <div id="r-f">
          <p>{props.description1}</p>
          <p>{props.description2}</p>
          <p>{props.description3}</p>
          {props.description4 && <p>{props.description4}</p>}
          <button className="close-button" onClick={toggleShow}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default Facilitators;
