import React from 'react';

const ImageCard = (props) => (
	<div
		className={props.className}
	>
		<img
			src={props.source}
			data-aos='zoom-in'
			data-aos-duration={props.animationDuration}
			alt=""
		/>
	</div>
);

export default ImageCard;