import React from 'react';
import { Link } from 'react-router-dom';
import './MiniCards.css';

const MiniCards = (props) => (
	<div className={`mini-cards ${props.newClass}`}>
		{props.data.map( item =>
			<div
				className='mini-card'
				data-aos='fade-up'
				data-aos-duration='500'
			>
				{item.inAppLink ?
					<Link to={item.destination}>
						<img
							src={item.image}
							alt=""
							className={item.class}

						/>
						<h3 style={{padding: '20px'}}>
							{item.caption}
						</h3>
					</Link>
					:
					<a
						href={item.destination}
						target='_blank'
						rel='noreferrer noopenner'
					>
						<img
							src={item.image}
							alt=""
							className={item.class}
						/>
						<h3 style={{padding: '20px'}}>
							{item.caption}
						</h3>
					</a>
				}
			</div>
		)}
	</div>
);

export default MiniCards;