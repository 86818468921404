import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../Container/Footer';
import './index.css';
import { SVGHeader } from '../../Components/SVG/header';
import {Helmet} from 'react-helmet';

function PartnerWithUs() {
	useEffect(() => {
	// 	document.getElementById('navTop').style.display = 'flex';
	// 	document.getElementById('navTop').style.background = '#10152c';
	// 	const items = document.getElementsByClassName("nav-link");
    // for (let i = 0; i < items.length; i++) {
    //   items[i].style.color = "#fff";
    // }
		window.scrollTo(0, 0);
	}, []);
	const trainingArray = [
		{
			heading: 'Trained',
			text:
				'Our developers are IT experts. Hired for character and trained for skill. We’ll equip you with a developer trained in popular field such as iOS, Android, Javascript, React, Angular, Node, PHP, Python, Ruby and Java'
		},
		{
			heading: 'Tested',
			text:
				'Each of our developers is Bulb certified. World class standards in vocational aptitude keep our developers at the top of their game, while our in-house training keeps them ahead of the curve.'
		},
		{
			heading: 'Trusted',
			text:
				'We’re careful not only to treat your product with care but also your company culture. Through personality assessments, we assign you the best fitting developer that blends with your company culture and values.'
		}
	];

	return (
		<div style={{ position: 'relative' }} className="hire-developer">
			<Helmet>
					<title>  Hire Qualified Tech Talents | The Bulb Africa   </title>
					<meta name="title" content="Hire Qualified Tech Talents | The Bulb Africa  " />
					<meta name="description" content="We understand your needs! Hire well-trained and experienced tech professionals to solve any technological problem and deliver outstanding work on any IT project. " />
			</Helmet>
			<SVGHeader />
			<div className="container">
				<div className="top pt-5 pb-5">
					<div className="heading text-center mt-2">Hire the best in-demand developers</div>
					<div className="text  col-lg-11 text-center ml-auto mr-auto my-5">
						Hire well-trained and experienced web developers to identify, design, install and test your
						company software from the ground up. Each solution proffered to solve any technological problem
						your company might be facing is tailored specifically to fit and bridge the gap between your
						business and its customers.
					</div>
					{/* <Link to="/hire-developer/form"> */}
					<a href="https://forms.gle/sU9qZLKoPtBq25kF9" target='_blank' rel='noreferrer noopenner'>
						<div className="btn button  mb-5  p-2"> Hire our talents </div>
					</a>
					{/* </Link> */}
				</div>
			</div>
			<div className="container">
				<div className="train-section d-flex flex-column flex-lg-row justify-content-center mx-auto pt-0 pt-lg-5 mb-5">
					{trainingArray.map((item, index) => (
						<div className="training-card p-5 p-lg-4 mt-5 " key={index}>
							<div className="heading text-primary">{item.heading}</div>
							<div className="text mt-2 text-left">{item.text}</div>
						</div>
					))}
				</div>
			</div>

			<div className="hire-developer mt-5 mb-5">
				<div className="container ">
					<div className="img-section d-flex flex-column flex-lg-row mt-5 pt-5">
						<div className="col-lg-6 big-img mt-5 mt-lg-0">
							<img src={require('../../Assets/img/computer.png')} alt="" />
						</div>
						<ul className="ml-lg-5 col-lg-6">
							<div className="heading mt-2">How we work</div>
							<li className="d-flex mt-4">
								{/* <div className="star-img mr-3">
                  <img src={require("../../Assets/img/star.png")} alt="star" />
                </div> */}
								<div className="left-section mr-4">
									<div className="number text-center">01</div>
									<img src={require('../../Assets/img/line.png')} alt="line" className="ml-3" />
								</div>
								<div className="text-section">
									<div className="heading">Assessment</div>
									<div className="text">
										We assess the problem you’re trying to solve and who the product is to be built
										for.
									</div>
								</div>
							</li>
							<li className="d-flex mt-4 ">
								{/* <div className="star-img mr-3">
                  <img src={require("../../Assets/img/star.png")} alt="star" />
                </div> */}
								<div className="left-section mr-4">
									<div className="number text-center">02</div>
									<img src={require('../../Assets/img/line.png')} alt="line" className="ml-3" />
								</div>
								<div className="text-section">
									<div className="heading">Brainstorming</div>
									<div className="text">
										We come up with multiple solutions and pick what we believe works best to give
										each user a seamless and exciting experience.
									</div>
								</div>
							</li>
							<li className="d-flex mt-4">
								{/* <div className="star-img mr-3">
                  <img src={require("../../Assets/img/star.png")} alt="star" />
                </div> */}
								<div className="left-section mr-4">
									<div className="number text-center">03</div>
									{/* <img src={require("../../Assets/img/line.png")} alt=""/> */}
								</div>
								<div className="text-section">
									<div className="heading">Result</div>
									<div className="text">
										We collaborate to build an efficient product tailored to each user’s needs.
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div
				style={{
					margin: '195px 120px',
					backgroundColor: 'rgba(59, 107, 211, 0.1)',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					// padding: "81px 166px 68px",
					paddingTop: '81px',
					paddingBottom: '68px',
					margin: '7em auto'
				}}
				className="container "
			>
				<div style={{ textAlign: 'center', marginBottom: 70 }}>
					<p style={{ color: '#050038', fontSize: 32, fontWeight: '800' }}>Ready for that boost?</p>
					<p className=" col-lg-9 mx-auto text mt-3">
						Our goal is to positively influence the African continent with technology, with our commitment
						and strategy towards building amazing products, you’re assured of the best at all times.
					</p>
				</div>
				<a href="https://forms.gle/sU9qZLKoPtBq25kF9" target='_blank' rel='noreferrer noopenner'>
					<div className="btn button  mb-5  p-2"> Hire our talents </div>
				</a>
				{/* <Link to="/hire-developer/form">
					<div
						style={{
							fontWeight: "bold",
							width: 195,
							height: 56,
							backgroundColor: '#050038',
							borderRadius: 6,
							textAlign: 'center',
							color: '#fff',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
							justifySelf: 'center',
							cursor: 'pointer'
						}}
					>
						Hire our talents
					</div>
				</Link> */}
			</div>

			<Footer />
		</div>
	);
}

export default PartnerWithUs;
