import React, { Component } from 'react'
import Logo from '../../../Assets/img/logo-404.svg'
import emoji from '../../../Assets/img/sad-emoji.png'
import ArrowRight from '../../../Assets/img/chevron-arrow.svg'
import Footer from '../../../Container/Footer';

export default class NotFound extends Component {
    // componentDidMount() {
	// 		document.getElementById("navTop").style.background = "#10152c";
	// 		document.getElementById("navTop").style.display = "flex";
	// 		window.addEventListener('scroll', this.handleScroll, true);
	// 		const items = document.getElementsByClassName("nav-link");
	// 		for (let i = 0; i < items.length; i++) {
	// 			items[i].style.color = "#fff";
	// 		}
	// 		window.scrollTo(0, 0)
    //     // document.getElementById("navTop").style.display = "none";
    // }
    render() {
        return (
            <>
                <div className="notfound parallax">
                    <div className="notfound-inner min-vh-100 py-4 d-flex justify-content-center text-center align-items-center flex-column">
                        <img src={Logo} alt="Logo" className="img-fluid mb-4"/>
                        <img src={emoji} alt="sad emoji" className="img-fluid sademoji mb-3"/>
                        <h1 className="font-weight-bold large">Ooops, page not found</h1>
                        <p className="pb-3">We are very sorry for the inconvenience. It looks like you're trying to <br className="d-none d-md-block"/> access a page that has been deleted or never even existed.</p>
                        <a href="/" className="text-decoration-none"><button className="btn btn-warning text-white d-flex align-items-center px-5 py-2">Back home &nbsp; <img src={ArrowRight} alt="arrow-right" className="img-fluid" /></button></a>
                    </div>
                </div>
								<div style={{marginTop: '-100px'}}>
									<Footer />
								</div>
            </>
        )
    }
}
