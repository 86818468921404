import React from 'react';
import Button from '../Button/Button';

const TextCard = (props) => (
	<div
		className={props.className}
	>
		<h2
			className={props.headingClassName}
		>
			{props.heading}
		</h2>
		<p
			data-aos='fade-up'
			data-aos-duration='500'
			className={props.descriptionClassName}
		>
			{props.description}
		</p>
		{props.description2 && <p
			data-aos='fade-up'
			data-aos-duration='500'
			className={props.descriptionClassName}
		>
			{props.description2}
		</p>}
		<Button
			isHyperLink={props.isHyperLink}
			isAppLink={props.isAppLink}
			cta={props.cta}
			location={props.location}
			isLight={props.isLight}
		/>
	</div>
);

export default TextCard;