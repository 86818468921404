import React from "react";
import { Link } from "react-router-dom";
import "./Button.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Button = (props) => {
  return (
    <>
      {props.isAppLink && (
        <Link
          to={props.location}
          className={props.isLight ? "cta-light" : "cta"}
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          {props.cta}
        </Link>
      )}

      {props.isHyperLink && (
        <a
          href={props.location}
          target={props.self ? "_self" : "_blank"}
          rel="noreferrer noopenner"
          className={props.isLight ? "cta-light" : "cta"}
          data-aos="zoom-in"
          data-aos-duration="500"
        >
          {props.cta}
        </a>
      )}
    </>
  );
};

export default Button;
