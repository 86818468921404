import * as types from "../Action/actiontype";

const initialState = {
    resp: [],
    loading: true,
    error: '',
};

export const SubmitIdeaReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SUBMIT_IDEA_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.SUBMIT_IDEA_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.SUBMIT_IDEA_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}

export const IncubatorReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.INCUBATOR_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.INCUBATOR_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.INCUBATOR_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}

export const HireDeveloperReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.HIRE_DEVELOPER_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.HIRE_DEVELOPER_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.HIRE_DEVELOPER_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}

export const PartnershipReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.PARTNERSHIP_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.PARTNERSHIP_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.PARTNERSHIP_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}

export const RequestatourReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.REQUEST_TOUR_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.REQUEST_TOUR_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.REQUEST_TOUR_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}

export const ScheduleVisitReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SCHEDULE_VISIT_SUCCESS:
            return {
                ...state,
                loading: false,
                resp: payload
            };
        case types.SCHEDULE_VISIT_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            };
        case types.SCHEDULE_VISIT_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}