import React from 'react'
import { Link } from 'react-router-dom'

const Contactmap = ({ addressheading, houseno, address, country, operation, timing, day, button }) => {
    return (
        <div className="address">
            <h1>{addressheading}</h1>
            <div className="full-address d-flex flex-wrap mb-4">
                <div className="address-place mr-sm-4 mr-md-5">
                    <p>
                        <strong>{houseno}</strong> <br />
                        {address}<br />
                        {country}
                    </p>
                </div>
                <div className="address-time">
                    <p>
                        <strong>{operation}</strong><br />
                        {timing} <br />
                        {day}
                    </p>
                </div>
            </div>
            {button && button &&
                <div className="contact-buttons">
                    <Link to="/tour" className="mr-3"><button className="btn btn-outline-dark">Request a tour</button></Link>
                    {/* <a href="https://www.google.ca/maps/place/The+DMZ+at+Ryerson+University/@43.6565348,-79.3804611,3a,75y,276h,90t/data=!3m8!1e1!3m6!1s0BwZzvJ-AAIAAAQvPHoMEA!2e0!3e2!6s%2F%2Fgeo2.ggpht.com%2Fcbk%3Fpanoid%3D0BwZzvJ-AAIAAAQvPHoMEA%26output%3Dthumbnail%26cb_client%3Dsearch.TACTILE.gps%26thumb%3D2%26w%3D129%26h%3D106%26yaw%3D276.7702%26pitch%3D0!7i8000!8i4000!4m5!3m4!1s0x882b34cad13907bf:0x393038cf922e1378!8m2!3d43.656555!4d-79.380361!6m1!1e1" target="_blank">
                        <button className="btn btn-outline-dark">Take a 360 tour</button></a> */}
                    {/* <Link to="/contact" className="mr-3"><button className="btn btn-outline-dark">Take a 360 tour</button></Link> */}

                </div>
            }
        </div>
    )
}
export default Contactmap