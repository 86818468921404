import React from "react";
import rating4 from "../../../Assets/thebulb/rating4.png";
import rating5 from "../../../Assets/thebulb/rating5.png";
import { motion } from "framer-motion";

export const BulbReview = () => {
  return (
    <div className="lg:px-24 md:px-12 px-6 md:py-12 py-8 manrope bg-[#F7F8FB]">
      <div className="flex items-center gap-2 py-2 border-b-[1px] w-full border-b-[#BBBCC3] ">
        <span className="bg-[#FDBF17] w-2 h-2 rounded-full"></span>
        <p className="text-[#636262] text-[16px]">Testimonials</p>
      </div>
      <h1 className="text-[#13162D]  lg:text-6xl md:text-5xl text-3xl font-bold py-12 text-center">
        Word on the Street
      </h1>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-6  grid-cols-1 w-full">
        {BulbData.map((reviews) => (
          <BulbList
            key={reviews.id}
            review={reviews.review}
            reviewName={reviews.reviewName}
            rating={reviews.rating}
          />
        ))}
      </div>
      <div className="w-full text-center pt-6">
        <button className="bg-[#FDBF17] text-center  w-[173px] px-[40px] py-[14px] rounded-full text-[#13162D]">
          Learn More
        </button>
      </div>
    </div>
  );
};

export const BulbList = ({ review, reviewName, rating }) => {
  return (
    <motion.div
      whileHover={{
        scale: 0.9,
        transition: { duration: 0.5 },
      }}
      className="flex flex-col bg-[#fff] shadow-3xl gap-6 rounded-lg p-6"
    >
      <p className="text-[#4D4C4C] text-[14px] ">{review}</p>
      <img src={rating} alt="" width="106px" height="18px" />
      <h4 className="text-[#13162D] font-bold text-[16px]">{reviewName}</h4>
    </motion.div>
  );
};
const BulbData = [
  {
    id: "review1",
    review:
      "Serene tech hub with up-to-date facilities and innovation incubation programs. Workers are professional and sociable at the same time. The perfect hub for young, tech innovators. Equipped with maximum security to ensure safety of visitors..",
    reviewName: "Noble M.K",

    rating: rating5,
  },
  {
    id: "review2",
    review:
      "One of the few Tech hubs out there with intriguing interior design, elegant workspace, fascinating people with Amazing culture. The openness and readiness to accept criticism and feedback is a key component of the management's business operations. It's a place to be.",
    reviewName: "Oloruntobi Junaid",
    rating: rating4,
  },
  {
    id: "review3",
    review:
      "The Bulb Africa is a tech hub that promise to provide IT solutions.It was beautifully designed and set up to be a friendly environment for youth especially to work. It is a great place for IT solutions.",
    reviewName: "Olu Alabi Thomas",
    rating: rating5,
  },
  {
    id: "review4",
    review:
      "This place is next level when it comes to Office Ergonomics. Every aspect ranging from the furnishing to the Polite employees and their seamlessly easy stress free recruitment process is just superb and standard!I believe it will be everyone's dream to work at a place like this.",
    reviewName: "Kabiru Sambo",
    rating: rating5,
  },
  {
    id: "review5",
    review:
      "The Bulb have world class facilities. It's a good place to learn and grow in Tech.",
    reviewName: "Ifunanya William Onah",
    rating: rating5,
  },
  {
    id: "review6",
    review:
      "The Bulb is a nice looking building built and designed to be a serene world of it's own in the busy and noisy environment of Lagos. The decor and work area of the building is African and aesthetic.",
    reviewName: "Marlin Nicholas",
    rating: rating4,
  },
];

export default BulbData;
