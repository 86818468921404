import React, { useEffect } from 'react'
import Footer from '../../../Container/Footer'
import './terrms.style.css'
const Termsandcondition = () => {
	useEffect(() => {
		document.getElementById("navTop").style.background = "#10152c";
		document.getElementById("navTop").style.display = "flex";
		const items = document.getElementsByClassName("nav-link");
		for (let i = 0; i < items.length; i++) {
			items[i].style.color = "#fff";
		}
		window.scrollTo(0, 0)
    }, [])
    const A = '&'
    return (
        <>
            <div className=" terms-service" style={{paddingTop: "5rem"}}>
                <div className="headingTop py-5">
                    <div className="container">
                        <div className="heading-inner">

                            <h1>TERMS {A} CONDITIONS</h1>

                        </div>
                    </div>
                </div>

                <div className="terms-inner py-5 mb-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10">
                                <h3 className="section-title">Introduction</h3><br />
                                {/* <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea</p>

                                <p><strong>Lorem ipsum dolor sit amet, consetetur sadipscing elitr,</strong> sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores</p>

                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores</p>
                                <br/> */}

                                <h2><u>Application Process</u></h2>
                                <li> We accept technology-based startups, or startups with a significant technology
                                    component, and not limited to software startups only</li>
                                <li>We accept solo founders. Even though we advise that startups are more likely to
                                    succeed with a Co-founder.</li>
                                <li>The startup needs to be incorporated before applying</li>
                                <li>We consider every application equally. As such, you don’t need an ‘insider’ to
                                    apply.</li>
                                <li>We have no specific stage requirements, but most companies will have assembled
                                    a team and developed an initial product.</li><br />
                                <h4><u>Equity</u></h4>
                                <p>You must be willing to provide information on the following:</p>
                                <li>Incorporation or formation of any legal entity (like an LLC)</li>
                                <li>Kind of Entity formed and in what State or Country the Entity was formed</li>
                                <li>Breakdown of the equity ownership in percentages among the founders,
                                    employees and any other stakeholders</li>
                                <li>Investments your company has received – Name of Investor, Amount invested,
                                    valuation cap and the type of security sold.</li><br />

                                <h4><u>Privacy Policy</u></h4>
                                <li>We do not sign NDAs as no venture firm does at this stage. We are committed to
                                    respecting the confidentiality of the information you submit to us during the
                                    application process</li>
                                <li>We collect Personal Information when you submit an idea that alone or in
                                    combination with other information in our possession could be used to identify you</li><br />
                                <h4><u>Documentations</u></h4>
                                <p>You will be required to provide the following documents:</p>
                                <li>Pitch Deck and Full Business Plan</li>
                                <li>Certificate of Incorporation</li>
                                <li>Résumés and references of the team</li>
                                <li>Detailed product/service information</li><br />

                                <h4><u>Founders</u></h4>
                                <p>We will require the following:</p>
                                <li>Profile of all Founders in the Startup</li>
                                <li>Information on Founders that have left</li><br />

                                <h4><u>Legal</u></h4>
                                <p>You must be willing to provide information on the following:</p>
                                <li>Pending lawsuits</li>
                                <li>Authorization to use code written by a third-party that is not open source</li>
                                <li>Founders covered by non-competes of intellectual property agreements that
                                    overlap with your project.</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Termsandcondition
