import React from 'react';
import Back from '../../../Components/Back/Back';
import './index.css';

function Tour() {
	return (
		<div className="bg-door">
			<div className="overley d-flex align-items-center justify-content-center flex-column relative">
				<Back />

				<video height="100% " width="100%" controls>
					<source src={require('../../../Assets/img/tour-video.mp4')} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			</div>
		</div>
	);
}

export default Tour;
