import React, { useEffect, useState } from 'react'
import Footer from '../../Container/Footer'
import AlertDialog from '../../Components/Madal'
import Loader from '../../Components/Loader'
import { Requestatour } from '../../common/function'

let errormsg = "This field is required."

class Formcontact extends React.Component {
    constructor() {
        super();
        this.state = {
            fields: {
                title: '',
                fname: '',
                lname: '',
                phoneno: '',
                email: '',
                organization: '',
                groupsize: '',
                participantname: '',
                reasonTour: '',
                Additionalinfo: '',
            },
            errors: {},
            loader: false,
            status: null,
            setOpen: false,
            message: ""
        }
    }

    componentDidMount() {
        document.getElementById("navTop").style.background = "#10152c";
    }

    handleChange = (e) => {
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({ fields });
    }
    handleClose = () => {
        this.setState({
            setOpen: false
        })
    };
    clearState() {
        let fields = {
            title: '',
            fname: '',
            lname: '',
            phoneno: '',
            email: '',
            organization: '',
            groupsize: '',
            participantname: '',
            reasonTour: '',
            Additionalinfo: '',
        };
        fields['title'] = '';
        fields['fname'] = '';
        fields['lname'] = '';
        fields['phoneno'] = '';
        fields['email'] = '';
        fields['organization'] = '';
        fields['groupsize'] = '';
        fields['participantname'] = '';
        fields['reasonTour'] = '';
        fields['Additionalinfo'] = '';
        this.setState({ fields: fields, })
    }

    SubmitHandleForm = async (e) => {
        e.preventDefault();
        if (this.validateForm()) {
            this.setState({ loader: true });
            let data = {
                title: this.state.fields.title,
                first_name: this.state.fields.fname,
                last_name: this.state.fields.lname,
                mobile: this.state.fields.phoneno,
                email: this.state.fields.email,
                group_organization: this.state.fields.organization,
                group_size: this.state.fields.groupsize,
                participant_names: this.state.fields.participantname,
                reason: this.state.fields.reasonTour,
                info: this.state.fields.Additionalinfo
            }
            await Requestatour(data)
                .then(response => {
                    this.setState({
                        message: response.message,
                        loader: false,
                        setOpen: true,
                        status: response.status
                    })
                }).catch(err => {
                    this.setState({
                        loader: false,
                        message: err,
                        status: 0,
                        setOpen: true,
                    })
                })
            this.clearState()
        }
    }

    validateForm() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if (!fields['title']) {
            formIsValid = false;
            errors["title"] = errormsg
        }

        if (typeof fields['title'] !== "undefined") {
            if (!fields['title'].match(/^[a-zA-Z.]*$/)) {
                formIsValid = false;
                errors['title'] = errormsg
            }
        }

        if (!fields['fname']) {
            formIsValid = false;
            errors["fname"] = errormsg
        }

        if (typeof fields['fname'] !== "undefined") {
            if (!fields['fname'].match(/^[a-zA-Z]*$/)) {
                formIsValid = false;
                errors['fname'] = errormsg
            }
        }
        if (!fields['lname']) {
            formIsValid = false;
            errors["lname"] = "*Please Enter last Name"
        }
        if (typeof fields['lname'] !== 'undefined') {
            if (!fields['lname'].match(/^[a-zA-Z]*$/)) {
                formIsValid = false;
                errors['lname'] = errormsg
            }
        }
        if (!fields['email']) {
            formIsValid = false;
            errors["email"] = errormsg
        }

        if (typeof fields['email'] !== 'undefinedd') {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
            if (!pattern.test(fields['email'])) {
                formIsValid = false;
                errors["email"] = errormsg
            }
        }
        if (!fields['phoneno']) {
            formIsValid = false;
            errors["phoneno"] = errormsg
        }
        if (typeof fields['phoneno'] !== 'undefined') {
            if (!fields['phoneno'].match(/^[0-9+]/)) {
                formIsValid = false;
                errors['phoneno'] = errormsg
            }
        }
        if (!fields['organization']) {
            formIsValid = false;
            errors["organization"] = errormsg
        }

        if (typeof fields['organization'] !== 'undefined') {
            if (!fields['organization'].match(/^[a-zA-Z.-]*$/)) {
                formIsValid = false;
                errors['organization'] = errormsg
            }
        }
        if (!fields['groupsize']) {
            formIsValid = false;
            errors["groupsize"] = errormsg
        }
        if (typeof fields['groupsize'] !== 'undefined') {
            if (!fields['groupsize'].match(/^[a-zA-Z0-9-]*$/)) {
                formIsValid = false;
                errors['groupsize'] = errormsg
            }
        }
        if (!fields['participantname']) {
            formIsValid = false;
            errors["participantname"] = errormsg
        }
        if (!fields['reasonTour']) {
            formIsValid = false;
            errors["reasonTour"] = errormsg
        }
        if (!fields['Additionalinfo']) {
            formIsValid = false;
            errors["Additionalinfo"] = errormsg
        }
        this.setState({ errors: errors });
        return formIsValid;
    }
    render() {

        return (
            <>
                <div className="headingTop d-none d-md-block mt-73 pt-5 py-4">
                    <div className="container">
                        <div className="heading-inner">
                            <h1>Request a tour</h1>
                        </div>
                    </div>
                </div>
                <div className="reqest-tour mt-73 mt-md-3 py-3 py-sm-5 mb-5">
                    <div className="container">
                        <form onSubmit={this.SubmitHandleForm}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="request-heading">
                                        <h3>Individual Information</h3>
                                        <p className="d-none d-md-block">Please provide us with your contact information</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="title">Title {this.state.errors.title == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                <input type="text"
                                                    onChange={this.handleChange}
                                                    value={this.state.fields.title}
                                                    className="form-control"
                                                    name="title" />
                                                <div className="errorMsg">{this.state.errors.title}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="fname">First Name {this.state.errors.fname == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                <input type="text" onChange={this.handleChange} value={this.state.fields.fname} className="form-control" name="fname" />
                                                <div className="errorMsg">{this.state.errors.fname}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label for="lname">Last Name {this.state.errors.lname == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                <input type="text" onChange={this.handleChange} value={this.state.fields.lname} className="form-control" name="lname" />
                                                <div className="errorMsg">{this.state.errors.lname}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="phoneno">Phone Number {this.state.errors.phoneno == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                <input type="number" onChange={this.handleChange} value={this.state.fields.phoneno} className="form-control" name="phoneno" />
                                                <div className="errorMsg">{this.state.errors.phoneno}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="email">Email Address {this.state.errors.email == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                <input type="email" onChange={this.handleChange} value={this.state.fields.email} className="form-control" name="email" />
                                                <div className="errorMsg">{this.state.errors.email}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="request-heading">
                                        <h3>Group Information</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="organization">Organization {this.state.errors.organization == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                <input type="text" onChange={this.handleChange} value={this.state.fields.organization} className="form-control" name="organization" />
                                                <div className="errorMsg">{this.state.errors.organization}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="groupsize">Group Size {this.state.errors.groupsize == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                <input type="text" onChange={this.handleChange} value={this.state.fields.groupsize} className="form-control" name="groupsize" />
                                                <div className="errorMsg">{this.state.errors.groupsize}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="participantname">Participant Names {this.state.errors.participantname == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                <textarea className="form-control" onChange={this.handleChange} value={this.state.fields.participantname} rows="7" name="participantname"></textarea>
                                                <div className="errorMsg">{this.state.errors.participantname}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="reasonTour">Reason for the tour {this.state.errors.reasonTour == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                <textarea className="form-control" onChange={this.handleChange} value={this.state.fields.reasonTour} rows="7" name="reasonTour"></textarea>
                                                <div className="errorMsg">{this.state.errors.reasonTour}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label for="Additionalinfo">Additional Info {this.state.errors.Additionalinfo == errormsg ? <span className="errorMsg">*</span> : <span>*</span>}</label>
                                                <textarea className="form-control" onChange={this.handleChange} value={this.state.fields.Additionalinfo} rows="7" name="Additionalinfo"></textarea>
                                                <div className="errorMsg">{this.state.errors.Additionalinfo}</div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group text-center mt-4">
                                                <button type="submit" className="btn btn-warning">Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        {this.state.loader && this.state.loader &&
                            <Loader />
                        }
                    </div>
                </div>

                <AlertDialog
                    open={this.state.setOpen}
                    close={this.handleClose}
                    status={this.state.status}
                    message={this.state.message}
                />
                <Footer />
            </>
        )
    }
}


export default Formcontact