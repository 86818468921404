import React from "react";
import Cancelbtn from "../../Assets/thebulb/cancelbtn.svg"
import { Slide } from "react-awesome-reveal";

export const GetStarted = ({ hideDrops }) => {
  return (
    <div className="h-screeen fixed top-0 bottom-0 z-[999] md:py-12  lg:pt-[6rem] md:pt-[8rem] bg-[#e2e5f3] text-black  py-12 w-full pt-[6rem]">
      <Slide direction="down">
        <span className="lg:px-24 md:px-12 px-6  md:py-6 w-full flex justify-end">
          <img src={Cancelbtn} alt="" onClick={hideDrops} className="" />
        </span>
        <div className="   lg:h-screen h-fit lg:px-96 md:px-12  px-6 flex gap-4 items-center flex-col">
          <a
            href="https://bit.ly/fellowship-placement"
            className="bg-[#13162D] text-white  rounded-[16px] w-full  md:px-24 md:py-8 px-6 py-10"
          >
            <div className=" ">
              <h1 className="font-bold font-Gotic md:text-[40px] leading-[56px] text-center  ">
                I want to hire Talent 
              </h1>
              <p className="md:text-[20px]  text-[16px] text-center leading-[28px]">
                I'm ready to build an efficient team
              </p>
            </div>
          </a>
          <a
            href="/product-development"
            className="bg-[#FEDC80] rounded-[16px] w-full md:px-24 md:py-8 p-6"
          >
            <div className="">
              <h1 className="font-bold font-Gotic md:text-[40px] leading-[56px] text-center">
                I want to build a Product
              </h1>
              <p className="md:text-[20px]  text-[16px]  text-center leading-[28px]">
                I am ready to build the next big thing
              </p>
            </div>
          </a>
          <a
            href="/co-workspace"
            className="bg-[#F7F8FB]  rounded-[16px] w-full md:px-24 md:py-8 p-6"
          >
            <div className=" ">
              <h1 className="font-bold font-Gotic md:text-[40px] leading-[56px] text-center">
                I need an Office Space
              </h1>
              <p className="md:text-[20px] text-[16px] text-center leading-[28px]">
                I'm ready to improve my team's collaboration and creativity
              </p>
            </div>
          </a>
        </div>
      </Slide>
    </div>
  );
};
