import React from "react";

import { NewHome } from "./NewHome";
import { LogoSlide } from "./Logos/LogoSlide";
import { AboutBulb } from "./about/aboutBulb";
import { Talent } from "./Talent/Talent";
import { Numbers } from "./Numbers";
import { Footer } from "../../Components/HomeFooter/Footer";
import { BulbReview } from "./reviewpage/BulbReview";

import { BulbService } from "./BulbServices";
import { BulbSolution } from "./BulbSolution";
// import { Hero } from "./hero/Hero";
// import { Header } from "../../Components/homeHeader";
// import { Blog } from "./blogs/Blog";
// import { Slick } from "../../Components/Sticky";
// import { Marquee } from "../../components/marquee/Marquee";

export const Home = () => {
  return (
    <div className="">
      <div className="bg-white dark:bg-black w-full ">
        <NewHome />

        <LogoSlide />
        <AboutBulb />
        <Talent />
        <Numbers />
        <BulbReview />
        <BulbService />
        <BulbSolution />
        {/* <Blog /> */}
        <Footer />
      </div>
    </div>
  );
};
