import React from 'react';
import { LinkOverlay } from '@chakra-ui/react';
const ProfileOverlay = ({ linkedIn_url, type }) => {
	console.log(linkedIn_url);
	return (
		<LinkOverlay
			href={linkedIn_url}
			target='_blank'
			style={{
				width: type ? 246 : '100%',
				height: type ? 218 : '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.25)',
				position: 'absolute',
				display: 'flex',
				color: '#fff',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: "12px"
			}}
		>
			<p>Profile</p>
			<img alt="linkedin" style={{ cursor: 'pointer' }} src={require('../../Assets/img/linkedin.svg')} />
		</LinkOverlay>
	);
};

export default ProfileOverlay;
