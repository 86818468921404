import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';
import Header from '../Navbar';
import { aboutdetails } from '../../Const/data';
import { Helmet } from 'react-helmet';


const Dashboard = ({ headerv, clasname, clasnamebtn, btnclassadd, traningselect, callcalenderselect }) => {
	const [state, setstate] = useState(false);
	const [changetoggle, setChangetoggle] = useState(false);
	const [calenderchange, setCalenderchange] = useState(false);

	useEffect(() => {
		document.getElementById('navTop').style.background = 'transparent';
		document.getElementById('navTop').style.display = 'flex';
		const items = document.getElementsByClassName('nav-link');
		for (let i = 0; i < items.length; i++) {
			items[i].style.color = '#fff'
		}
	}, []);

	useEffect(() => {
		const script = document.createElement('script');
		script.src = './js/animate-bg.js';
		script.async = true;
		document.head.appendChild(script);
	});

	useEffect(() => {
		headerv && closeAll();
	}, [headerv]);

	const [setindidual, setSetindidual] = useState(false);
	const [corporate, setCorporate] = useState(false);
	const [ngo, setNgo] = useState(false);
	const [closemenubar, setClosemenubar] = useState(true);

	function addClass() {
		clasname(closemenubar);
		clasnamebtn(closemenubar);
		btnclassadd(closemenubar);
	}

	function closeAll() {
		setSetindidual(false);
		setCorporate(false);
		setNgo(false);
	}

	function inover() {
		setSetindidual(false);
		setCorporate(false);
		setNgo(false);
	}

	function onmouseiver() {
		setSetindidual(true);
		setCorporate(false);
		setNgo(false);
	}

	function corporatehover() {
		setCorporate(false);
		setSetindidual(false);
		setNgo(false);
		setCorporate(true);
	}

	function ngomousehover() {
		setNgo(true);
		setCorporate(false);
		setSetindidual(false);
	}

	let indi = '';
	let indiarrow = '';
	if (setindidual === true) {
		indi = ' individual-bg-slide';
		indiarrow = ' indiarrow';
	} else {
		indi = '';
		indiarrow = '';
	}

	let corporateclass = '';
	let coparrow = '';
	if (corporate === true) {
		corporateclass = ' corporation-bg-slide';
		coparrow = ' copratearrow';
	} else {
		corporateclass = '';
		coparrow = '';
	}

	let ngoclass = '';
	let ngoarrow = '';
	if (ngo === true) {
		ngoclass = ' ngo-bg-slide';
		ngoarrow = ' ngoratearrow';
	} else {
		ngoclass = '';
		ngoarrow = '';
	}
	return (
		<div
			style={{
				position: 'absolute',
				top: '0',
				left: '0',
				right: '0',
				zIndex: '-1'
			}}
		>
			<Helmet>
				<title>Africa's Leading Tech Talent Incubator | The Bulb</title>
				<meta
					name="title"
					content="Africa's Leading Tech Talent Incubator | The Bulb"
				/>
				<meta
					name="description"
					content="The Bulb™ is a platform for African tech talents leveraging technology. The Bulb connects innovation from Africa to global opportunities.  Partner with us!"
				/>
				{/* Open Graph meta tags */}

				<meta property="og:title" content="Africa’s Leading Tech Talent Incubator | The Bulb Africa" />
				<meta property="og:url" content="https://thebulb.africa" />
				<meta property="og:description" content="The Bulb™ is a platform for African tech talents leveraging technology. The Bulb connects innovation from Africa to global opportunities. Partner with us!" />
				<meta property="og:type" content="website" />
				<meta property="og:image" content="https://thebulb.africa/Images/homepage/logo.png" />
				<meta property="og:image:alt" content="The Bulb Africa- offical logo" />

				{/* twitter meta tags */}
				<meta name="twitter:title" content="Africa’s Leading Tech Talent Incubator | The Bulb Africa" />
				<meta name="twitter:description" content="The Bulb™ is a platform for African tech talents leveraging technology. The Bulb connects innovation from Africa to global opportunities. Partner with us" />
				<meta name="twitter:image" content="https://thebulb.africa/Images/homepage/logo.png" />
				<meta name="twitter:image:alt" content="The Bulb- offical logo" />
				<meta name="twitter:site" content="@TheBulbAfrica" />
				<meta name="twitter:card" content="summary" />

				<script type="application/ld+json">
					{
						`
							{
							"@context": "https://schema.org/",
							"@type": "WebSite",
							"name": "The Bulb Africa",
							"url": "https://thebulb.africa/",
							"potentialAction":
								{
									"@type": "SearchAction",
									"target": "https://thebulb.africa/about-us{search_term_string}",
									"query-input": "required name=search_term_string"
								}
							}
							
						`
					}
				</script>
			</Helmet>
			<div id="particles-js" className="uk-position-fixed"></div>
			<div className="slider position-relative" onClick={() => addClass(setClosemenubar(!closemenubar))}>
				<div className="slider_inner">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-md-7">
								<div className="slider-content">
									<h1 id="sliderref">
										We are a tech talent incubator{' '}
									</h1>
									<span className="w-1px d-inline-block">&nbsp;</span>
									<Typewriter
										options={{
											strings: [
												'We nurture, equip & build Africa’s rising tech talents through immersive training programs designed to meet the innovations of the future.',
												'We incubate, accelerate, and provide funding for mission-driven early-stage African tech startups.',
												'We connect tech talents from Africa to job opportunities in Africa and across the globe.',
												'We run programs that inspire and boost female participation in tech.',
												'We provide communities and co-working spaces for tech enthusiasts at all levels to inspire, build and collaborate.'
											],
											autoStart: true,
											loop: true,
											cursor: '',
											delay: 0,
											pauseFor: 7000,
											deleteSpeed: 0,
											wrapperClassName: 'typing'
										}}
									/>
								</div>
							</div>
							{/* The Bulb Backgroung Logo */}
							<div className="col-md-5">
								<img
									src={require('../../Assets/img/bulb.png')}
									alt="bulb"
									className="img-fluid"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="row m-0 slider-bottom">
					<div className={'slider-bottom-inner' + indiarrow} onMouseEnter={onmouseiver}>
						<img
							src={require('../../Assets/img/arrow.png')}
							alt="Down Arrow"
							className="img-fluid mx-auto"
							style={{ height: '4rem', width: '.8rem' }}
						/>
						<Link to="/institute"><h3>The Bulb Institute</h3></Link>
					</div>
					<div className={'slider-bottom-inner' + coparrow} onMouseEnter={corporatehover}>
						<img
							src={require('../../Assets/img/arrow.png')}
							alt="Down Arrow"
							className="img-fluid mx-auto"
							style={{ height: '4rem' , width: '.8rem'}}

						/>
						<Link to="/startup-engine"><h3>Startup Engine</h3></Link>
					</div>
					<div className={'slider-bottom-inner' + ngoarrow} onMouseEnter={ngomousehover}>
						<img
							src={require('../../Assets/img/arrow.png')}
							alt="Down Arrow"
							className="img-fluid mx-auto"
							style={{ height: '4rem', width: '.8rem' }}

						/>
						<Link to="/our-communities"><h3>Our Communities</h3></Link>
					</div>
				</div>

				<div className="row m-0 d-flex align-items-center position-absolute slider-hover-content">
					<div className={'slider-hover-inner indviduals-bg' + indi} onMouseLeave={inover}>
						<ul>
							<li>
								<Link to="/institute" target="_blank">Fellowship at The Bulb</Link>
								{/* <a href="https://www.fellowship.thebulb.africa" target="_blank">Fellowship at The Bulb</a> */}
							</li>
							<li>
								{' '}
								{/* <a href="https://nextgen.africa/" target="_blank">NextGen</a> */}
								<Link to="/institute" target="_blank">NextGen</Link>
								{' '}
							</li>
							{/* <li>
								<Link to="/community">Community</Link>
							</li> */}
							{/* <li><Link to="/training">Training</Link></li> */}
							{/* <li><Link to="/training" onClick={() => traningselect(false)}>Training</Link></li> */}
							<li>
								<Link to="/institute" target="_blank">Career Advance</Link>
							</li>


							<li>
								<Link to='/institute' target="_blank">Startup Academy</Link>
							</li>
							{/* <li>
								<Link to="/newsfeed" onClick={() => callcalenderselect(true)}>
									Events
								</Link>
							</li> */}
						</ul>
					</div>
					<div className={'slider-hover-inner corporation-bg' + corporateclass} onMouseLeave={inover}>
						<ul>
							<li>
								<Link to='/startup-engine' >The Incubator</Link>
							</li>
							{/* <li><Link to="/partner-with-us">Partner with us</Link></li> */}
							<li>
								<Link to='/startup-engine' >Accelerate by The Bulb</Link>
							</li>
							{/* <li><Link to="/training" onClick={() => traningselect(true)}>Training</Link></li> */}
							{/* <li> */}
							{/*<a href="http://desk.africa" target="_blank" rel="noopener noreferrer">
									Illuminate
								</a>*/}

							<li>
								<Link to='/startup-engine'>Illuminate</Link>
							</li>
							{/* </li> */}
							{/* <li><Link to="/newsfeed" onClick={() => callcalenderselect(true)}>Events</Link></li> */}
						</ul>
					</div>
					<div className={'slider-hover-inner ngo-bg' + ngoclass} onMouseLeave={inover}>
						<ul>
							{/* <li>
                <Link to="/partner-with-us">Corporate</Link>
              </li> */}
							<li>
								{/*<Link to="/partner-with-us">Partner with us</Link>*/}

								<Link to="/our-communities" target="_blank">The Desk</Link>

							</li>
							{/* <li><Link to="/">NGO</Link></li> */}
							{/* <li><Link to="/">Sponsorship request</Link></li> */}
							{/* <li><Link to="/training" onClick={() => traningselect(true)}>Training</Link></li> */}
							<li>
								{/*<Link to="/tour">Schedule a visit</Link>*/}
								<Link to='/our-communities'>Tech Jewel</Link>
							</li>

							<li>
								<Link to='/our-communities'>Leverage</Link>
							</li>

							<li>
								<Link to='/our-communities'>The Alumni</Link>
							</li>
							{/* <li><Link to="/newsfeed" onClick={() => callcalenderselect(true)}>Events</Link></li> */}
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};
const mapStateToProps = state => {
	return {
		navnarclassaddremove: state.classesadd
	};
};

const mapDispatchToProps = dispatch => {
	return {
		clasname: d => dispatch({ type: 'DIV_CLASS_ADD', payload: d }),
		clasnamebtn: d => dispatch({ type: 'BTN_EXPAND_ADD', payload: d }),
		btnclassadd: d => dispatch({ type: 'BTN_CLASS_ADD', payload: d })
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
