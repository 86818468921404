import React from "react";

import ServiceImg from "../../Assets/thebulb/serviceImage.png";

export const BulbService = () => {
  return (
    <main>
      <div className="lg:px-24 md:px-12 px-6 md:pt-12 pt-8 bg-[#fff]">
        <div className="flex w-full md:flex-row flex-col justify-between md:gap-6 items-end">
          <div className="flex flex-col  gap-6 md:w-1/2 w-full">
            <h1 className="lg:text-5xl md:text-4xl text-3xl font-bold lg:leading-[56px]  text-[#13162D] ">
              Lighting Up Africa The Bulb Way
            </h1>
            <p className="lg:text=[24px] md:text-[16px] text-sm lg:leading-[44px] md:leading-[25px] w-full text-[#4D4C4C]">
              The Bulb Africa cultivates a pipeline of African tech talent by
              equipping individuals and businesses with in-demand skills,
              fostering professional networks through its communities, and
              facilitating career opportunities through its recruitment engine.
            </p>
            <div className="flex flex-col gap-6 pb-12 ">
              {ServiceData.map((services) => (
                <ServiceList
                  key={services.id}
                  service={services.service}
                  serviceName={services.serviceName}
                />
              ))}
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-end">
            <img src={ServiceImg} alt="" />
          </div>
        </div>
      </div>

      <div className="service  text-[#fff] ">
        <div className="overlay lg:px-24 md:px-12 px-6 lg:py-24 md:py-12 py-8">
          <h1 className="lg:text-6xl md:text-5xl  text-[#E4E4E7] text-3xl pb-6  font-bold ">
            We have over 85% employability rate.
          </h1>
          <div className="flex md:flex-row flex-col w-full  items-end">
            <div className="md:w-[70%] w-full">
              <p className="serviceText lg:text-2xl md:text-[16PX] text-sm lg:leading-[44px] font-medium">
                Our graduates enjoy an 85% employment rate upon completion,
                thanks to our partnerships with leading local and international
                companies. We equip you with the in-demand skills employers
                seek, making you a highly sought-after talent in the global tech
                market.
              </p>
            </div>
            <div className="md:w-[30%] w-full flex md:justify-end justify-center md:py-0 py-6">
              <button className="bg-transparent text-center   w-[173px] px-[40px] py-[14px] border-[#FDBF17]  border rounded-full text-[#ffff]">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export const ServiceList = ({ service, serviceName }) => {
  return (
    <div className="flex flex-col bg-[#F7F8FB] shadow-sm gap-6 rounded-lg px-6 py-6">
      <h4 className="text-[#13162D] font-bold text-[16px] md:text-2xl font-Gotic">
        {serviceName}
      </h4>
      <p className="text-[#4D4C4C] md:text-[16px] text-[14px] font-medium  ">
        {service}
      </p>
    </div>
  );
};

const ServiceData = [
  {
    id: "1",
    service:
      "We nurture, equip & build Africa’s rising tech talents through immersive training programs designed to meet the innovations of the future.",
    serviceName: "Bridging the Talent Gap",
  },
  {
    id: "2",
    service:
      "Get access to well-trained and experienced software engineers, designers, and other tech experts to meet your business needs.",
    serviceName: "Hire the best in-demand tech talents.",
  },
  {
    id: "3",
    service:
      "Get efficient software built on cutting-edge technology by elite talents on the continent.",
    serviceName: "Building Sustainable Product",
  },
  {
    id: "4",
    service:
      "We provide your space to build, innovate and collaborate. Desk at The Bulb is a multi-location functional shared office for tech talents and startups to build the next big thing.",
    serviceName: "Virtual or Physical Work Space",
  },
];

export default ServiceData;
