import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../Container/Footer';
import Modal from '../../Components/Modal';
import { SVGHeader } from '../../Components/SVG/header';

import { Api } from '../../Const/Api';
import axios from 'axios';
import './index.css';

import { ReactSelectForm } from '../../Components/Select';

function PartnerWithUsForm({ history }) {
	const [selectedCountry, setSelectedCountry] = useState();
	const [developerData, setDeveloperData] = useState({
		linkedIn_url: '',
		business_name: '',
		company_url: '',
		twitter_url: '',
		city: '',
		email: '',
		first_name: '',
		last_name: ''
	});
	const [Loading, setLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [modelItems, setModelItems] = useState({
		text: '',
		btnText: '',
		imgUrl: require('../../Assets/img/fail.png'),
		handleModal: () => ({})
	});
	const [locations, setLocations] = useState({});

	useEffect(() => {
		document.getElementById('navTop').style.background = '#10152c';
		window.scrollTo(0, 0);
		axios.get(`${Api.paceBaseUrl}locations`).then(function (response) {
			if (response.status === 200) {
				setLoading(false);
				const result = response.data.data;
				const locations = result.reduce((c, v) => {
					c[v.country] = { states: v.states, countryCode: v.countryCode };
					return c;
				}, {});
				setLocations(locations);
			}
		});
	}, []);

	const handleModal = () => {
		setShowModal(false);
		history.push('/hire-developer');
	};
	const closeModal = () => {
		setShowModal(false);
	};

	//handle country change
	const handleCountryChange = event => {
		setSelectedCountry({ selectedCountry: event.target.value });
	};

	const handleInputChange = e => {
		setDeveloperData({
			...developerData,
			[e.target.name]: e.target.value
		});
	};

	const handleCityInputChange = value => {
		setDeveloperData({
			...developerData,
			city: value
		});
	};
	const handleCountryInputChange = value => {
		setSelectedCountry({ selectedCountry: value });
	};

	//handle country select option
	const countries = Object.keys(locations).map((country, index) => (
		<option key={index} value={country}>
			{country}
		</option>
	));

	// //handle state select option
	const states = selectedCountry
		? locations[selectedCountry.selectedCountry].states.map(stateObj => (
				<option key={stateObj.id} value={stateObj.state}>
					{stateObj.state}
				</option>
		  ))
		: '';

	const selectState = selectedCountry
		? locations[selectedCountry.selectedCountry].states.map(option => ({
				value: option.state,
				label: option.state
		  }))
		: [];

	const selectCountries = Object.keys(locations).map(country => ({
		value: country,
		label: country
	}));
	console.log(developerData);
	const handleSubmit = e => {
		setLoading(true);
		e.preventDefault();
		const payload = {
			...developerData,
			country: selectedCountry.selectedCountry,
			city: developerData.city
		};
		axios
			.post(`${Api.BaseUrl}hireDeveloper/save`, payload)
			.then(function (response) {
				console.log({ response });
				setLoading(false);

				if (response.status === 200) {
					setModelItems({
						text: 'Your request to hire developers has been successfully submitted, you will be contacted after a careful review of your application.',
						btnText: 'Home',
						imgUrl: '',
						handleModal
					});
				} else {
					setModelItems({
						text: 'Hey, something went wrong with your submission. Please return to the form page and make sure all fields are completed and resubmit',
						btnText: 'Close',
						imgUrl: require('../../Assets/img/fail.png'),
						handleModal: closeModal
					});
				}
				setShowModal(true);
				return;
			})
			.catch(error => {
				setLoading(false);

				setModelItems({
					text: 'Hey, something went wrong with your submission. Please return to the form page and make sure all fields are completed and resubmit',
					btnText: 'Close',
					imgUrl: require('../../Assets/img/fail.png'),
					handleModal: closeModal
				});
				setShowModal(true);
				return;
			});
	};

	return (
		<div style={{ position: 'relative', paddingTop: '3em' }} className="hire-developer-form ">
			<SVGHeader />
			{showModal && (
				<Modal
					text={modelItems.text}
					btnText={modelItems.btnText}
					imgUrl={modelItems.imgUrl}
					handleModal={modelItems.handleModal}
					history={history}
				/>
			)}
			<div className="container">
				<div className="img">
					<img
						src={require('../../Assets/img/close.svg')}
						alt="close"
						className=" d-block close"
						onClick={history.goBack}
					/>
				</div>
				<div className="heading  py-1">Hire the best in-demand developers</div>
				<form onSubmit={handleSubmit}>
					<div className="d-flex flex-column flex-lg-row">
						<div className="form-group mt-3 col-lg-6">
							<div className="required">*</div>
							<label htmlFor="first_name" className="pl-1 pr-2">
								First Name
							</label>
							<input
								type="text"
								value={developerData.first_name}
								className="form-control"
								name="first_name"
								id="first_name"
								onChange={handleInputChange}
								required
							/>
						</div>
						<div className="form-group mt-3 col-lg-6">
							<div className="required">*</div>
							<label htmlFor="last_name" className="pl-1 pr-2">
								Last Name
							</label>
							<input
								type="text"
								value={developerData.last_name}
								className="form-control"
								name="last_name"
								id="last_name"
								required
								onChange={handleInputChange}
							/>
						</div>
					</div>
					<div className="d-flex flex-column flex-lg-row">
						<div className="form-group mt-3 col-lg-6">
							<div className="required">*</div>
							<label htmlFor="email" className="pl-1 pr-2">
								Email address
							</label>
							<input
								type="email"
								value={developerData.email}
								className="form-control"
								name="email"
								id="email"
								required
								onChange={handleInputChange}
							/>
						</div>
						<div className="form-group mt-3 col-lg-6">
							<div className="required">*</div>
							<label htmlFor="phone_number" className="pl-1 pr-2">
								Phone number
							</label>
							<input
								type="tel"
								value={developerData.phone_number}
								pattern="^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$"
								maxLength={14}
								className="form-control"
								name="phone_number"
								id="phone_number"
								required
								onChange={handleInputChange}
							/>
						</div>
					</div>
					<div className="d-flex flex-column flex-lg-row">
						<div className="form-group mt-3 col-lg-6">
							<div className="required-non">*</div>
							<label htmlFor="linkedIn_url" className="pl-1 pr-2">
								Linkedin URL
							</label>
							<input
								value={developerData.linkedIn_url}
								type="url"
								className="form-control"
								name="linkedIn_url"
								id="linkedIn_url"
								onChange={handleInputChange}
							/>
						</div>
						<div className="form-group mt-3 col-lg-6">
							<div className="required-non">*</div>
							<label htmlFor="twitter_url" className="pl-1 pr-2">
								Twitter URL
							</label>
							<input
								value={developerData.twitter_url}
								type="url"
								className="form-control"
								name="twitter_url"
								id="twitter_url"
								onChange={handleInputChange}
							/>
						</div>
					</div>
					<div className="d-flex flex-column flex-lg-row">
						<div className="form-group mt-3 col-lg-6">
							<div className="required-non">*</div>
							<label htmlFor="company_url" className="pl-1 pr-2">
								Company URL
							</label>
							<input
								type="url"
								value={developerData.company_url}
								className="form-control"
								name="company_url"
								id="company_url"
								onChange={handleInputChange}
							/>
						</div>
						<div className="form-group mt-3 col-lg-6">
							<div className="required-non">*</div>
							<label htmlFor="business_name" className="pl-1 pr-2">
								Business name
							</label>
							<input
								type="text"
								value={developerData.business_name}
								className="form-control"
								name="business_name"
								id="business_name"
								onChange={handleInputChange}
							/>
						</div>
					</div>
					<div className="d-flex flex-column flex-lg-row">
						<div className="form-group mt-3 col-lg-6">
							<div className="required">*</div>
							<label style={{ zIndex: 20, position: 'relative' }} htmlFor="city" className="pl-1 pr-2">
								Country
							</label>

							<select
								name="country"
								className="form-control"
								id="country"
								required
								onChange={handleCountryChange}
							>
								<option>Select a Country</option>

								{countries}
							</select>
						</div>

						<div className="form-group mt-3 col-lg-6">
							<div className="required">*</div>
							<label style={{ zIndex: 20, position: 'relative' }} htmlFor="city" className="pl-1 pr-2">
								State
							</label>

							<select
								name="city"
								className="form-control"
								id="city"
								required
								onChange={handleInputChange}
							>
								<option>Select a State</option>
								{states}
							</select>
						</div>
					</div>
					<button className="btn button  mb-5  mt-5 p-2" disabled={Loading}>
						Submit{' '}
						{Loading && (
							<div className="spinner-grow sm bg-white ml-1 " role="status">
								<span className="sr-only">Loading...</span>
							</div>
						)}
					</button>
				</form>
			</div>
			<div className="d-lg-none">
				<Footer />
			</div>
		</div>
	);
}

export default PartnerWithUsForm;
