import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../Container/Footer';
import { Box } from '@chakra-ui/react';

import IndividualForm from './individualForm';
import GroupForm from './GroupForm';
import './index.css';
import { SVGHeader } from '../../Components/SVG/header';
import {Helmet} from 'react-helmet';

function Idea({ history }) {
	const [toggleBGcolor, setToggleBGcolor] = useState(false);
	useEffect(() => {
		document.getElementById('navTop').style.background = '#10152c';
		window.scrollTo(0, 0);
	}, []);

	const handleToggleColor = path => {
		if (path === 'yes') {
			setToggleBGcolor(true);
		}
		if (path === 'no') {
			// setToggleBGcolor(true);
			setToggleBGcolor(false);
		}
	};

	return (
		<>
			<Helmet>
					<title>  Schedule a Visit | The Bulb Africa   </title>
					<meta name="title" content=" Schedule a Visit | The Bulb Africa " />
					<meta name="description" content="Experience innovation in real time. Fill the form to get started.  " />
			</Helmet>
			<SVGHeader />
			<Box pos="relative" pt="30px" maxW="6xl" mx="auto" className="visit">
				<Box h="10px" mb="50px" px="10px" cursor="pointer" textAlign="right" ml="auto">
					<svg
						onClick={history.goBack}
						className=" close"
						width="20"
						height="20"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fill-rule="evenodd"
							clip-rule="evenodd"
							d="M8.42239 7.00537L13.7064 12.2919C14.0972 12.6829 14.0969 13.3165 13.7057 13.7072C13.3146 14.0979 12.6806 14.0976 12.2898 13.7066L7.00539 8.41965L1.70867 13.7062C1.31738 14.0968 0.683436 14.0963 0.292725 13.7052C-0.0979858 13.314 -0.0975145 12.6804 0.293778 12.2898L5.59009 7.00368L0.297052 1.70814C-0.0937731 1.31713 -0.0934864 0.683461 0.297692 0.292805C0.688871 -0.0978514 1.32281 -0.0975648 1.71363 0.293445L7.00709 5.5894L12.2889 0.317664C12.6802 -0.0728784 13.3142 -0.0724073 13.7049 0.318716C14.0956 0.70984 14.0951 1.3435 13.7038 1.73405L8.42239 7.00537Z"
							fill="#050038"
						/>
					</svg>
				</Box>
				<h1 className="heading text-center mt-5 mb-5">Experience innovation in real time</h1>

				<div className="container bg-img py-5">
					<div className="bg-white mx-lg-5">
						<div className="d-flex visit-heading bg-white">
							<div
								className={`individual btn col-6 text-center d-flex align-items-center justify-content-center ${
									!toggleBGcolor && 'bg-color '
								} `}
								onClick={() => handleToggleColor('no')}
							>
								Individual Information
							</div>
							<div
								className={`individual btn col-6 text-center d-flex align-items-center justify-content-center ${
									toggleBGcolor && 'bg-color '
								} `}
								onClick={() => handleToggleColor('yes')}
							>
								Group Information
							</div>
						</div>

						<div className="p px-5">
							(Please note that tour days are limited to Tuesdays and Thursdays only)
						</div>
						{!toggleBGcolor ? <IndividualForm /> : <GroupForm />}
					</div>
				</div>
			</Box>
			<Footer />
		</>
	);
}

export default Idea;
