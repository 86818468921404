import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = () => {
    return (
        <div className="cusloader"><CircularProgress />
            <p className="pt-2 mb-0">Please wait a momment...</p>
        </div>
    )
}

export default Loader