import React from "react";
import "./CourseCard.css";
import Button from "../../Button/Button";

const CourseCard = (props) => {
  return (
    <div className="course-card">
      <img
        data-aos="zoom-in"
        data-aos-duration="500"
        src={props.logo}
        className="course-logo"
      />
      <h3 data-aos="fade-right" data-aos-duration="700" className="course-name">
        {props.courseName}
      </h3>
      <p data-aos="fade-right" data-aos-duration="800" className="course-info">
        {props.courseInfo}
      </p>
      <p data-aos="fade-right" data-aos-duration="800" className="duration">
        Duration: {props.duration}-day course
      </p>
      <Button
        data-aos="flip-left"
        data-aos-duration="1000"  
        isHyperLink="true"
        location="https://forms.gle/Kt9xGiiW371ziz4k7"
        className="course-cta"
        cta="Build your startup"
      />
    </div>
  );
};

export default CourseCard;
